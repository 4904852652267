import TextareaAutosize from 'react-textarea-autosize';
export const TextArea=({autosize, value, rtl, defaultValue, maxLength,onClear,onInput,id,onChange,onKeyDown, error, error_class, label, required, language, readOnly, onBlur, wrapClass, labelfont})=>{
    
    return (
      <div
        className={`input form-group textarea-form-group input-field mb-0 ${
          language === "ar" ? "arabic_wrapper" : ""
        } ${value != "" ? "focused" : ""} ${readOnly ? "read-only-field" : ""} ${wrapClass?wrapClass:""} ${error?'field-error':''}`}
      >
        <div className="position-relative w-100">
          {autosize && (
            <TextareaAutosize
              id={id}
              className={`form-control ${
                error != null && error_class ? error_class : ""
              }`}
              value={value}
              defaultValue={defaultValue}
              onKeyDown={onKeyDown}
              onChange={onChange}
              maxLength={maxLength}
              onInput={onInput}
              rtl={rtl}
              readOnly={readOnly}
              onBlur={onBlur}
            />
          )}
          {!autosize && (
            <textarea
              id={id}
              className={`form-control ${
                error != null && error_class ? error_class : ""
              } `}
              value={value}
              defaultValue={defaultValue}
              onKeyDown={onKeyDown}
              onChange={onChange}
              maxLength={maxLength}
              onInput={onInput}
              rtl={rtl}
              onBlur={onBlur}
            >
              {value}
            </textarea>
          )}
          <label className={`control-label ${labelfont?labelfont:""}`} htmlFor={id}>
            {label}
            {required && (
              <img
                src={process.env.REACT_APP_URL + "images/star-field.svg"}
                className="required-field-icon"
                alt="required-field"
              ></img>
            )}
          </label>
          {onClear && (
            <span className="clear" onClick={onClear}>
              <svg viewBox="0 0 24 24">
                <path
                  className="line"
                  d="M 2,2 C 3.925,3.925 8.149999999999999,8.15 12,12 C 15.85,15.85 20.075,20.075 22,22"
                ></path>
                <path className="long" d="M9 15L20 4"></path>
                <path className="arrow" d="M13 11V7"></path>
                <path className="arrow" d="M17 11H13"></path>
              </svg>
            </span>
          )}
        </div>

        {!error && maxLength && wrapClass=="tester-text-area" &&
            <p className="caption">
                <span className="max-characters">
                    <>{value.length}/</>

                    {maxLength}
                </span>
            </p>
            }

            {error != null && (
                <span className="caption">
                    {error}
                </span>
            )}
      </div>
    );
}
