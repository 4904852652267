import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { LayoutResearcher } from '../../themes/userq/Layouts/layout_researcher';
import { useState, useEffect, useRef, useCallback } from "react";
import { Link } from 'react-router-dom';
import {
    getResearcherTestResultData,
    getTestTreeTestData,
    getTestTreeTestTaskData,
    getFirstClickData,
    getTreeChartData
} from "../../../services/researcher_test_results";
import toast, { LoaderIcon } from "react-hot-toast";
import Topbar from "./components/topbar";
import Sidebar from "./components/sidebar";
import { getCurrentPlansService } from "../../../services/plans";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import LinearAxisChart from "./components/linear_axis_chart";
import { calculateTime } from "../../../lib/helpers";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import SankeyChart from "./components/sankey_chart";
import { atom, useRecoilState, useRecoilValueLoadable_TRANSITION_SUPPORT_UNSTABLE } from 'recoil';
import { LoadingIcon } from "../../loader/loadingIconNew";
import { TestResultsHeader } from '../../themes/userq/TestResultsHeader/TestResultsHeader';
import { TestResultsSidebarNavigation } from './components/test_results_sidebar_navigation';
import DeleteAnswer from "./dialog/hide-participant";
import ReportAnswer from "./dialog/report-answer";
import exportAsImage from "../../../lib/exportAsImage";
import { downloadBlob } from "../../../lib/helpers";
import Checkbox from "../../layouts/elements/fields/checkbox";
import { TasksDropdown } from "../../themes/userq/TasksDropDown/TasksDropdown";
import { Text } from "../../themes/userq/Text/Text";
import { LinearAxisChartStatsBox } from "./components/linear_axis_chart_stats_box";
import { Button } from "../../themes/userq/Button/Button";
import { Icon } from "../../themes/userq/Icon/Icon";
import { TreeTasksOverviewStatsBox } from "./components/tree_tasks_overview_stats_box";
import { Helmet } from "react-helmet";
import { MultiSelecteDropdownList } from '../../themes/userq/MultiSelecteDropdownList/MultiSelecteDropdownList.js';
import { EmptySearchPlaceholder } from "../../themes/userq/EmptySearchPlaceholder/EmptySearchPlaceholder.js";
import TreeTestFollowupQuestionsResult from "./tree_test_followup-questions-results";

export default function TestResultTreeTest({isSharedLink}) {

    const firstClickScrollContainerRef = useRef(null);

    const pathScrollContainerRef = useRef(null);

    const [isPathScrolled, setIsPathScrolled] = useState(false);

    const [isFirstClickScrolled, setIsFirstClickScrolled] = useState(false);

    let { test_id } = useParams();

    const location = useLocation();

    const navigate = useNavigate();

    const exportRef = useRef();

    const [isLoading, setLoading] = useState(false);

    const [expandData, setExpandData] = useState(false);

    const [sectionLoading, setSectionLoading] = useState(false);

    const [resultcount, setResultCount] = useState(0);

    const testState = atom({
        key: 'testresult-' + test_id,
        default: ""
    });
    const planState = atom({
        key: 'testresultplan-' + test_id,
        default: ""
    });
    const tabsState = atom({
        key: 'testresulttabs-' + test_id,
        default: []
    });
    const currentTabState = atom({
        key: 'testresultcurrenttab-' + test_id,
        default: 1
    });
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);
    const [tabs, setTabs] = useState([]);
    const [currentTab, setCurrentTab] = useState(1);
    const [tasks, setTasks] = useState({});
    const [firstClick, setFirstClick] = useState({});
    const [treeChart, setTreeChart] = useState({});
    const [isTaskLoading, setTaskLoading] = useState(false);
    const [sortLoading, setSortLoading] = useState(false);
    const [isFirstClickLoading, setFirstClickLoading] = useState(false);
    const [isTreeChartLoading, setTreeChartLoading] = useState(false);
    const [openDeleteAnswerModal, setOpenDeleteAnswerModal] = useState({ active: false, answer_id: null });
    const [openReportAnswerModal, setOpenReportAnswerModal] = useState({ active: false, answer_id: null, type: null });
    const [treeState, setTreeState] = useState("horizontal");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState("tree-test-overview");
    const [show_support_panel, setShowSupportPanel] = useState(false);

    const projectState = atom({
        key: 'testresultproject-' + test_id,
        default: null
    });
    const [project, setProject] = useRecoilState(projectState);

    const handleFirstClickScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = firstClickScrollContainerRef.current.scrollTop > 0;

        // Update state based on the scroll position
        setIsFirstClickScrolled(scrolled);
    };
    const handlePathScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = pathScrollContainerRef.current.scrollTop > 0;

        // Update state based on the scroll position
        setIsPathScrolled(scrolled);
    };
    useEffect(() => {
        // Attach scroll event listener to the referenced element
        const firstClickSrollContainer = firstClickScrollContainerRef.current;

        if (firstClickSrollContainer) {
            firstClickSrollContainer.addEventListener('scroll', handleFirstClickScroll);

            // Remove the event listener when the component unmounts
            return () => {
                firstClickSrollContainer.removeEventListener('scroll', handleFirstClickScroll);
            };
        }
    }, []);
    useEffect(() => {
        // Attach scroll event listener to the referenced element
        const pathSrollContainer = pathScrollContainerRef.current;

        if (pathSrollContainer) {
            pathSrollContainer.addEventListener('scroll', handlePathScroll);

            // Remove the event listener when the component unmounts
            return () => {
                pathSrollContainer.removeEventListener('scroll', handlePathScroll);
            };
        }
    }, [tasks]);
    useEffect(() => {



        if (tabs.length > 0) {

            var task = tabs[currentTab - 1];

            if (tasks[task.id].initial_loaded == false) {
                getTaskAttempts(task.id, tasks[task.id].page, tasks[task.id].order, tasks[task.id].direction);
            }


            if (tasks[task.id].firstclick_loaded == false) {
                getFirstClick(task.id);
            }
        }
    }, [tabs])

    const downloadSVG = useCallback(() => {
        const svg = exportRef.current.getElementsByTagName('div')[0].innerHTML;
        const blob = new Blob([svg], { type: "image/svg+xml" });

        downloadBlob(blob, `treechart.svg`);
    }, []);

    const deleteAnswerModal = (id, hiddenResult) => {
        setOpenDeleteAnswerModal({ active: true, test_result_id: id, hidden_result: hiddenResult });
    }

    const closeDeleteAnswerModal = (data) => {

        // if successfully delete then delete object of array from answerArray
        if (data) {

            var localTasks = { ...tasks };

            localTasks[tabs[currentTab - 1].id]["tests"] = [];

            localTasks[tabs[currentTab - 1].id]["initial_loaded"] = false;

            setTasks(localTasks);


            getTaskAttempts(tabs[currentTab - 1].id, 1, tasks[tabs[currentTab - 1].id].order, tasks[tabs[currentTab - 1].id].direction == "asc" ? "desc" : "asc","");


            getFirstClick(tabs[currentTab - 1].id);
        }
        setOpenDeleteAnswerModal({ active: false, test_result_id: null, hidden_result: false })

    }

    const reportAnswerModal = (id) => {
        setOpenReportAnswerModal({ active: true, answer_id: id, type: 'methodology' });
    }
    const closeReportAnswerModal = (data) => {
        setOpenReportAnswerModal({ active: false, answer_id: null });
    }
    const getTestTreeTestDataApi = () => {

        //if(!tabs){
        let token = ReactSession.get("token");

        setSectionLoading(true);

        setCurrentTab(1);

        let guest_result_token = '';

        if(location.pathname.substr(1, 1) === 'v'){

            // create guest_result_token
            if (!ReactSession.get("guest_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("guest_result_token", randomToken);
            }

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = '';

        if(location.pathname.substr(1, 1) === 'a'){

            // create guest_result_token
            if (!ReactSession.get("admin_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("admin_result_token", randomToken);
            }

            admin_result_token = ReactSession.get("admin_result_token");
        }

        getTestTreeTestData({ test_id: test_id, guest_result_token: guest_result_token, admin_result_token: admin_result_token }, token).then((response) => {
            setSectionLoading(false);

            if (response.success) {

                var tasks = response.data.api_tasks;

                var viewTasks = {};

                for (var i = 0; i < tasks.length; i++) {
                    tasks[i].type = "task";

                    viewTasks[tasks[i].id] = {
                        "view": "path",
                        "page": 1,
                        "has_records": true,
                        "initial_loaded": false,
                        "firstclick_loaded": false,
                        "treechart_loaded": false,
                        "order": "result_id",
                        "direction": "asc",
                        "firstclickorder": "label",
                        "firstclickdirection": "asc",
                        "outcome_filter":[],
                        "tests": [],
                        'results_data':tasks[i].results_data,
                        'is_question_exits':tasks[i].count_question
                    };
                }


                console.log(viewTasks)

                setTasks(viewTasks);

                setTabs(tasks);

                setResultCount(response.data.resultcount);


                //tasks.unshift({"title":"Tree testing overview","type":"overview","success_count":response.data.api_overview.success_count, "direct_count":response.data.api_overview.direct_count, "total_count":response.data.api_overview.total_count});

                //console.log(tasks)


            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: response.success ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
        /*} else {
            
            var viewTasks = {};

            for(var i = 1; i<tabs.length; i++){
                
                
                viewTasks[tabs[i].id] = {
                    "view":"path",
                    "page":1,
                    "has_records":true,
                    "initial_loaded":false,
                    "order":"created_at",
                    "direction":"desc",
                    "tests":[]
                };
            }
            setTasks(viewTasks);
            
        }*/
    };
    const getTaskAttempts = (task_id, page, order, direction, outcome_filter) => {
        let token = ReactSession.get("token");

        setTaskLoading(true);

        let guest_result_token = '';

        if(location.pathname.substr(1, 1) === 'v'){

            // create guest_result_token
            if (!ReactSession.get("guest_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("guest_result_token", randomToken);
            }

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = '';

        if(location.pathname.substr(1, 1) === 'a'){

            // create guest_result_token
            if (!ReactSession.get("admin_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("admin_result_token", randomToken);
            }

            admin_result_token = ReactSession.get("admin_result_token");
        }

        getTestTreeTestTaskData({ page: page, outcome_filter:outcome_filter, test_id: test_id, task_id: task_id, order: order, direction: direction, guest_result_token: guest_result_token, admin_result_token:admin_result_token }, token).then((response) => {
            setTaskLoading(false);

            setSortLoading(false);

            if (response.success) {

                var localTasks = { ...tasks };

                localTasks[task_id]["has_records"] = response.has_records;

                if (response.data.length > 0) {
                    localTasks[task_id]["page"] = page + 1;

                } else {
                    localTasks[task_id]["has_records"] = false;
                }
                localTasks[task_id]["initial_loaded"] = true;

                if (response.data.length < 10) {
                    localTasks[task_id]["has_records"] = false;
                }

                localTasks[task_id]["has_records"] = response.has_records;

                if (page == 1) {
                    localTasks[task_id]["tests"] = response.data;
                } else {
                    localTasks[task_id]["tests"] = localTasks[task_id]["tests"].concat(response.data);
                }

                localTasks[task_id]["outcome_filter"] = outcome_filter;

                localTasks[task_id]["order"] = order;

                localTasks[task_id]["direction"] = direction;
                setTasks(localTasks);

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: response.success ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }
    const getFirstClick = (task_id) => {
        let token = ReactSession.get("token");

        setFirstClickLoading(true);

        let guest_result_token = '';

        if(location.pathname.substr(1, 1) === 'v'){

            // create guest_result_token
            if (!ReactSession.get("guest_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("guest_result_token", randomToken);
            }

            guest_result_token = ReactSession.get("guest_result_token");
        }

        let admin_result_token = '';

        if(location.pathname.substr(1, 1) === 'a'){

            // create guest_result_token
            if (!ReactSession.get("admin_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("admin_result_token", randomToken);
            }

            guest_result_token = ReactSession.get("admin_result_token");
        }

        getFirstClickData({ test_id: test_id, task_id: task_id, guest_result_token:guest_result_token, admin_result_token:admin_result_token }, token).then((response) => {
            setFirstClickLoading(false);

            if (response.success) {

                var localTasks = { ...tasks };


                localTasks[task_id]["firstclick_loaded"] = true;

                setTasks(localTasks);

                var firstClickLocal = Object.assign({}, firstClick);

                firstClickLocal[task_id] = response.data.first_click;


                setFirstClick(firstClickLocal);


            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: response.success ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }
    const getTreeChart = (task_id) => {
        let token = ReactSession.get("token");

        setTreeChartLoading(true);

        let guest_result_token = '';

        if(location.pathname.substr(1, 1) === 'v'){

            // create guest_result_token
            if (!ReactSession.get("guest_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("guest_result_token", randomToken);
            }

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = '';

        if(location.pathname.substr(1, 1) === 'a'){

            // create guest_result_token
            if (!ReactSession.get("admin_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("admin_result_token", randomToken);
            }

            admin_result_token = ReactSession.get("admin_result_token");
        }

        getTreeChartData({ test_id: test_id, task_id: task_id, guest_result_token: guest_result_token, admin_result_token:admin_result_token }, token).then((response) => {
            setTreeChartLoading(false);

            if (response.success) {

                /*var localTasks = {...tasks};

                localTasks[task_id]["treechart_loaded"] = true;
                
                setTasks(localTasks);

                var  localTabs = Object.assign([],tabs);

                for(var i=0;i<localTabs.length;i++){
                    if(localTabs[i].id==task_id){
                        var tab = {...localTabs[i]};

                        tab['api_tree'] = response.data.tree_chart.api_tree;

                        tab['total_navigation'] = response.data.tree_chart.total_navigation;

                        tab['depth'] = response.data.tree_chart.depth;

                        tab['leaf_nodes'] = response.data.tree_chart.leaf_nodes;

                        localTabs[i] = tab;
                    }
                }
                setTabs(localTabs);
                */

                var tab = {};

                tab['api_tree'] = response.data.tree_chart.api_tree;

                tab['total_navigation'] = response.data.tree_chart.total_navigation;

                tab['depth'] = response.data.tree_chart.depth;

                tab['leaf_nodes'] = response.data.tree_chart.leaf_nodes;

                tab['answer_path'] = response.data.tree_chart.answer_path;

                var treeChartLocal = Object.assign({}, treeChart);

                treeChartLocal[task_id] = tab;

                console.log(treeChartLocal)

                setTreeChart(treeChartLocal);

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: response.success ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }

    const getTestafterEndTest = () => {

        let token = ReactSession.get("token");

        let guest_result_token = '';

		if(location.pathname.substr(1, 1) === 'v'){

			// create guest_result_token
			if (!ReactSession.get("guest_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("guest_result_token", randomToken);
			}

			guest_result_token = ReactSession.get("guest_result_token");
		}
		let admin_result_token = '';

		if(location.pathname.substr(1, 1) === 'a'){

			// create guest_result_token
			if (!ReactSession.get("admin_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("admin_result_token", randomToken);
			}

			admin_result_token = ReactSession.get("admin_result_token");
		}

        getResearcherTestResultData({ test_id: test_id,guest_result_token:guest_result_token, admin_result_token:admin_result_token }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        })

    }

    const getTest = () => {

        if (!test) {
            let token = ReactSession.get("token");

            setLoading(true);

            let guest_result_token = '';

            if(location.pathname.substr(1, 1) === 'v'){

                // create guest_result_token
                if (!ReactSession.get("guest_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("guest_result_token", randomToken);
                }

                guest_result_token = ReactSession.get("guest_result_token");
            }
            let admin_result_token = '';

            if(location.pathname.substr(1, 1) === 'a'){

                // create guest_result_token
                if (!ReactSession.get("admin_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("admin_result_token", randomToken);
                }

                admin_result_token = ReactSession.get("admin_result_token");
            }

            getResearcherTestResultData({ test_id: test_id, guest_result_token: guest_result_token, admin_result_token:admin_result_token }, token).then(
                (response) => {
                    setLoading(false);

                    if (response.success) {

                        if(response.workspace_project_team_member){
                            if(response.workspace_project_team_member.status!='active'){
                                navigate("/wsp/"+response.workspace_project_team_member.workspace_id+"/p/"+response.workspace_project_team_member.workspace_project_id+"/project-no-access")
                            }
                        }
                        if(response.test && response.test.workspace_project){
                            setProject(response.test.workspace_project);
                        }
                        // check test is available for sharing
                        if(location.pathname.substr(1, 1) === 'v' && response.is_share_result){
                            navigate(`/v/results/${test_id}/results-not-available`);
                        }

                        // check if guest user not validate there password
                        if(location.pathname.substr(1, 1) === 'v' && response.test.password && !response.test_password_verify){

                            navigate(`/v/results/${test_id}/password`);
                        }

                        setTest(response.test);

                        getTestTreeTestDataApi();
                    } else {
                        if(response.message == "Project not found.") {
							navigate("/404");
						} else if(response.message == "Test Record Not found") {
							navigate("/404");
						} else {
                            toast(
                                <div className="toastinner">
                                    {response.message}
                                    <a
                                        onClick={() => {
                                            toast.dismiss();
                                        }}
                                    >
                                        &times;
                                    </a>
                                </div>,
                                {
                                    className: response.success ? "successtoast" : "errortoast",
                                    position: "bottom-center",
                                    duration: 2000,
                                }
                            );
                        }
                    }
                }
            );
        } else {
            getTestTreeTestDataApi();
        }
    };

    const getPlan = () => {

        if (!planType) {
            let token = ReactSession.get("token");

            setLoading(true);

            getCurrentPlansService(token).then((response) => {
                setLoading(false);

                if (response.success) {
                    setPlanType(response.plan.type);

                    getTest();
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: response.success ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        } else {
            getTest();
        }
    };

    useEffect(() => {
        //document.title = process.env.REACT_APP_NAME + " - Analytics | Tree Testing";
        //getPlan();

        getTest();

    }, []);

    const renderOverview = () => {

        var task = tabs[currentTab - 1];

        var success_perc = task.total_count > 0 ? ((task.success_count / task.total_count) * 100).toFixed(0) : 0;

        var direct_perc = task.total_count > 0 ? ((task.direct_count / task.total_count) * 100).toFixed(0) : 0;

        return (
            <div className="tree-testing-top-graph gradient-bg">
                <div className="tree-test-top-graphwrap">
                    <div className="tree-tst-grph-data">
                        <h4>Task success %</h4>
                        <p>Out of all the tasks completed,  <b>{success_perc}% of the testers ended up at the correct answer</b></p>
                        <div className="tree-task-graph-hold">
                            <LinearAxisChart height="100" id="overview-success-chart" percentage={success_perc} />
                        </div>
                    </div>
                    <div className="tree-tst-grph-data">
                        <h4>Task success directness %</h4>
                        <p>Out of all the tasks completed,  <b>{direct_perc}% of the testers ended up at the correct answer directly, without backtracking. </b></p>
                        <div className="tree-task-graph-hold">
                            <LinearAxisChart height="100" id="overview-success-chart" percentage={direct_perc} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const renderPath = (test, correct_answer_id, testg) => {
        //console.log(correct_answer_id);
        if (!test.path) {
            return <></>;
        }
        return test.path.map(function (pitem, index) {

            var ele = this.testg.home_name;

            if (pitem.node) {
                ele = pitem.node.title;



                if (correct_answer_id == pitem.node.id) {
                    ele = <b>{pitem.node.title}</b>;
                }
            }
            var dir = <></>;
            if (index != 0) {
                if (pitem.direction == "forward") {
                    dir = <>&gt;</>;
                } else {
                    dir = <>&lt;</>;
                }
            }
            return <span key={"path-" + index}> {dir} {ele}</span>;
        }, { testg: testg });
    }
    /*const jumptoSection = (area) => {
        setActiveMenu(area);
        document.getElementById(area).scrollIntoView({
            behavior: "smooth",
            block: "center",
        });

    }*/
    function Position(obj) {
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
        }
    }
    const jumptoSection = (area) => {
        setActiveMenu(area);

        var fixedElementHeight = document.querySelector('.researcher-header').offsetHeight + document.querySelector('.test-results-header-researcher').offsetHeight + document.querySelector('.tree-navigator-active-link').offsetHeight;



        var offsetTop = Position(document.getElementById(area));
        window.scrollTo({ top: offsetTop - fixedElementHeight + 0, behavior: "smooth" });

    }

    const  renderFollowup = ()=>{

        var task = tabs[currentTab - 1];
        return (
            <>
                {(tasks[task.id]["is_question_exits"] > 0) &&
                <div className='prototype-followup-section'>
                    <Text cssClasses={'pt-5'} type={"h4"}>Follow up question</Text>
                    {tasks[task.id] &&
                    <>
                        <TreeTestFollowupQuestionsResult
                            resultData={tasks[task.id]["results_data"]}
                            resultCount={tasks[task.id]["results_data"].length}
                        />
                    </>
                    }
                </div>
                }
            </>
        );
    }
    const renderTask = () => {

        var task = tabs[currentTab - 1];



        var success_perc = task.total_count > 0 ? ((task.success_count / task.total_count) * 100).toFixed(0) : 0;

        var direct_perc = task.total_count > 0 ? ((task.direct_count / task.total_count) * 100).toFixed(0) : 0;

        var total_tasks = task.indirect_failure_count + task.direct_failure_count + task.indirect_success_count + task.skipped_count;

        var indirect_failure_perc = total_tasks > 0 ? ((task.indirect_failure_count / total_tasks) * 100).toFixed(0) : 0;

        var direct_failure_perc = total_tasks > 0 ? ((task.direct_failure_count / total_tasks) * 100).toFixed(0) : 0;

        var skipped_perc = total_tasks > 0 ? ((task.skipped_count / total_tasks) * 100).toFixed(0) : 0;

        var indirect_success_perc = total_tasks > 0 ? ((task.indirect_success_count / total_tasks) * 100).toFixed(0) : 0;

        var direct_success_perc = total_tasks > 0 ? ((task.direct_success_count / total_tasks) * 100).toFixed(0) : 0;

        /*
        var task = tabs[currentTab-1];
        if(tasks[task.id].initial_loaded==false){
            getTaskAttempts(task.id, tasks[task.id].page);
        }*/

        return (
            <>
                <div className="d-flex justify-content-between align-items-center tree-testing-correct-ans-wrap mb-32">
                    <div className={`curt-ans-left-side  `} id="tree-test-overview">

                        <>
                            <span className="correctpathlabel">
                                <img src={process.env.REACT_APP_URL + "images/green-check.svg"} />
                                Correct path<strong>:</strong>

                            </span>

                            {task.correct_answer && task.correct_answer.map(function (item, index) {
                                if (index == task.correct_answer.length - 1) {
                                    return <span className={`${test.language == 'ar' ? 'arabic-font' : ''}`} key={"nodeans" + index}> <b> {item}</b></span>
                                } else {
                                    return <span className={`${test.language == 'ar' ? 'arabic-font' : ''}`} key={"nodeans" + index} style={{ fontWeight: '700' }} > {item} &gt; </span>
                                }
                            })}
                        </>
                    </div>
                    {/*<div className="curt-ans-right-side">
                        <span>Average time <strong>-</strong></span><b>{calculateTime(task.average_time_spent)}</b>
                        </div>*/}
                </div>
                <div className="tree-testing-top-graph gradient-bg">
                    <Text type={"h4"} fontWeight={"medium-font"}>Test overview</Text>
                    <div className="tasksuccess-outerbox row">

                        <div className="col-md-6">
                            <LinearAxisChartStatsBox title={"Task success %"} size={"medium"} percentage={success_perc} />
                        </div>
                        <div className="col-md-6">
                            <LinearAxisChartStatsBox title={"Task success directness  %"} size={"medium"} percentage={direct_perc} />
                        </div>

                    </div>
                    <div className="tree-test-top-graphwrap pt-32 tree-overview-results-box">
                        {/*<div className="tree-tst-grph-data">
                            <h4>Task success %</h4>
                            <div className="tree-task-graph-hold">
                                <LinearAxisChart expandData={expandData} height="100" id="overview-success-chart" percentage={success_perc} />
                            </div>
                        </div>
                        <div className="tree-tst-grph-data">
                            <h4>Task success directness %</h4>
                            <div className="tree-task-graph-hold">
                                <LinearAxisChart expandData={expandData} height="100" id="overview-success-chart" percentage={direct_perc} />
                            </div>
                        </div>*/}

                        <TreeTasksOverviewStatsBox tasks={[{
                            direct_failure: direct_failure_perc,
                            direct_failure_count: task.direct_failure_count,
                            indirect_failure: indirect_failure_perc,
                            indirect_failure_count: task.indirect_failure_count,
                            task_skipped: skipped_perc,
                            task_skipped_count: task.skipped_count,
                            direct_success: direct_success_perc,
                            direct_success_count: task.direct_success_count,
                            indirect_success: indirect_success_perc,
                            indirect_success_count: task.indirect_success_count,

                        }]} title={"Task completion"} hide={true} />
                        {/*}
                        <div className="tree-tst-grph-data">
                            <h4>Task completion</h4>
                            <div className="completion-task-infor-wrap">
                                <div className="comp-infow-repeat">
                                    <span className="info-bg-1"></span>
                                    Indirect failure
                                </div>
                                <div className="comp-infow-repeat">
                                    <span className="info-bg-2"></span>
                                    Direct failure
                                </div>
                                <div className="comp-infow-repeat">
                                    <span className="info-bg-3"></span>
                                    Task skipped
                                </div>
                                <div className="comp-infow-repeat">
                                    <span className="info-bg-4"></span>
                                    Indirect success
                                </div>
                                <div className="comp-infow-repeat">
                                    <span className="info-bg-5"></span>
                                    Direct success
                                </div>
                            </div>
                            {(task.indirect_failure_count + task.direct_failure_count + task.skipped_count  + task.direct_success_count) >0 &&
                            <div className="completion-selected-task-count">
                                <div className="comp-selected-task-count-repeat" style={{"width": indirect_failure_perc+"%" }}>
                                    <span className="info-bg-1"></span>
                                    {task.indirect_failure_count >0 &&
                                    <p>{task.indirect_failure_count}</p>
                                    }
                                </div>
                                <div className="comp-selected-task-count-repeat" style={{"width": direct_failure_perc+"%" }}>
                                    <span className="info-bg-2"></span>
                                    {task.direct_failure_count >0 &&
                                    <p>{task.direct_failure_count}</p>
                                    }
                                </div>
                                <div className="comp-selected-task-count-repeat" style={{"width": skipped_perc +"%"}}>
                                    <span className="info-bg-3"></span>
                                    {task.skipped_count >0 &&
                                    <p>{task.skipped_count}</p>
                                    }
                                </div>
                                <div className="comp-selected-task-count-repeat" style={{"width": indirect_success_perc+ "%"}}>
                                    <span className="info-bg-4"></span>
                                    {task.indirect_success_count >0 &&
                                    <p>{task.indirect_success_count}</p>
                                    }
                                </div>
                                <div className="comp-selected-task-count-repeat" style={{"width": direct_success_perc+"%"}}>
                                    <span className="info-bg-5"></span>
                                    {task.direct_success_count >0 &&
                                    <p>{task.direct_success_count}</p>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                        */}
                    </div>
                </div>
                <div className="bd-que-table-wrapper pt-32 tree-testing-tbldata-wrap" id="tree-test-path">
                    <div className="free-question-table-header">
                        <div className="free-question-table-header-left">
                            <Text type={"h4"} fontWeight={"medium-font"}>Path</Text>
                        </div>
                        <div className="free-question-table-header-right tree-path-outcome-filyer">
                            <MultiSelecteDropdownList
                                type={"text"}
                                id={"outcome"}
                                name="Outcome filter"
                                label="Outcome filter"
                                value={tasks[task.id].outcome_filter}
                                options={[{"label":"Direct success","value":"Direct success"},{"label":"Indirect success","value":"Indirect success"},{"label":"Direct failure","value":"Direct failure"},{"label":"Indirect failure","value":"Indirect failure"},{"label":"Task skipped","value":"Task skipped"}]}
                                onChange={(items) => {

                                    let selected = [];

                                    items.forEach(function (item) {
                                        selected.push(item);
                                    })

                                    var localTasks = { ...tasks };

                                    localTasks[task.id]["initial_loaded"] = false;

                                    setTasks(localTasks);

                                    setSortLoading(true);

                                    getTaskAttempts(task.id, 1, tasks[task.id].order, tasks[task.id].direction, selected);


                                }}
                            />
                            <Button
                                tag={"anchor"}
                                target={"_blank"} href={isSharedLink=="viewer" ? "/v/results/" + test_id + "/tree-view/" + task.id : (isSharedLink=="admin" ? "/a/results/" + test_id + "/tree-view/" + task.id : "/r/results/" + test_id + "/tree-view/" + task.id) }
                                type={"secondary"}
                                label={"Open tree view"}
                                iconLeft={
                                    <Icon  value={"maximize"} size={"small"} />
                                }
                                size={"medium"}
                            />
                        </div>
                    </div>
                    {tasks[task.id].view == "path" &&
                    <>
                        <div className="bd-que-ans-table-data-inner bd-que-buy-user-table tree-path-view-table" id="field1">
                            <div className="table-responsive sort-loading-table" >
                                {/*sortLoading &&
                                        <div className="page-loader accountloading-box result-sections-loader table-loader ">
                                            <LoadingIcon />
                                        </div>
                            */}
                                <div ref={pathScrollContainerRef} className={`max-height-table  ${isPathScrolled ? 'scrolled' : ''}  ${tasks[task.id].tests && tasks[task.id].tests.length>8 ? "responsive-table-scroll":""}`}>
                                    <table className="table ">
                                        <thead>
                                        <tr>
                                            <th className="bd-thcol-1">
                                                <div className={`justify-content-between`}>
                                                    Participants
                                                </div>
                                            </th>
                                            <th className="bd-thcol-3">

                                                <div className={`${tasks[task.id].order == "outcome" ? tasks[task.id].direction : ''} col-sort justify-content-between`} onClick={() => {

                                                    var localTasks = { ...tasks };

                                                    //localTasks[task.id]["tests"] = [];

                                                    localTasks[task.id]["initial_loaded"] = false;

                                                    setTasks(localTasks);

                                                    setSortLoading(true);

                                                    getTaskAttempts(task.id, 1, "outcome", tasks[task.id].direction == "asc" ? "desc" : "asc",tasks[task.id].outcome_filter);
                                                }}>
                                                    Outcome
                                                    {tasks[task.id].order == "outcome" && tasks[task.id].direction == "asc" &&
                                                    <span>

                                                                    <img src={process.env.REACT_APP_URL + "img/sortup.svg"} />
                                                                </span>
                                                    }
                                                    {tasks[task.id].order == "outcome" && tasks[task.id].direction == "desc" &&
                                                    <span>

                                                                    <img src={process.env.REACT_APP_URL + "img/sortdown.svg"} />
                                                                </span>
                                                    }
                                                    {tasks[task.id].order != "outcome" &&
                                                    <span>

                                                                    <img src={process.env.REACT_APP_URL + "img/sort-icon.svg"} />
                                                                </span>
                                                    }
                                                </div>
                                            </th>
                                            <th className="bd-thcol-2">Answer</th>

                                            <th className="bd-thcol-4">
                                                <div className={`${tasks[task.id].order == "time_spent" ? tasks[task.id].direction : ''} col-sort justify-content-between`} onClick={() => {

                                                    var localTasks = { ...tasks };

                                                    //localTasks[task.id]["tests"] = [];

                                                    localTasks[task.id]["initial_loaded"] = false;

                                                    setTasks(localTasks);

                                                    setSortLoading(true);

                                                    getTaskAttempts(task.id, 1, "time_spent", tasks[task.id].direction == "asc" ? "desc" : "asc",tasks[task.id].outcome_filter);
                                                }}>
                                                    Time spent
                                                    {tasks[task.id].order == "time_spent" && tasks[task.id].direction == "asc" &&
                                                    <span>

                                                                    <img src={process.env.REACT_APP_URL + "img/sortup.svg"} />
                                                                </span>
                                                    }
                                                    {tasks[task.id].order == "time_spent" && tasks[task.id].direction == "desc" &&
                                                    <span>

                                                                    <img src={process.env.REACT_APP_URL + "img/sortdown.svg"} />
                                                                </span>
                                                    }
                                                    {tasks[task.id].order != "time_spent" &&
                                                    <span>

                                                                    <img src={process.env.REACT_APP_URL + "img/sort-icon.svg"} />
                                                                </span>
                                                    }
                                                </div>
                                            </th>
                                            <th className="bd-thcol-5">
                                                <div className={`${tasks[task.id].order == "created_at" ? tasks[task.id].direction : ''} col-sort justify-content-between`} onClick={() => {


                                                    var localTasks = { ...tasks };

                                                    //localTasks[task.id]["tests"] = [];

                                                    //localTasks[task.id]["initial_loaded"] = false;

                                                    setSortLoading(true);
                                                    setTasks(localTasks);
                                                    getTaskAttempts(task.id, 1, "created_at", tasks[task.id].direction == "asc" ? "desc" : "asc",tasks[task.id].outcome_filter);
                                                }}>
                                                    Date
                                                    {tasks[task.id].order == "created_at" && tasks[task.id].direction == "asc" &&
                                                    <span>

                                                                    <img src={process.env.REACT_APP_URL + "img/sortup.svg"} />
                                                                </span>
                                                    }
                                                    {tasks[task.id].order == "created_at" && tasks[task.id].direction == "desc" &&
                                                    <span>

                                                                    <img src={process.env.REACT_APP_URL + "img/sortdown.svg"} />
                                                                </span>
                                                    }
                                                    {tasks[task.id].order != "created_at" &&
                                                    <span>

                                                                    <img src={process.env.REACT_APP_URL + "img/sort-icon.svg"} />
                                                                </span>
                                                    }
                                                </div>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tasks[task.id].tests.map(function (item, index) {

                                            return (
                                                <tr key={"test" + item.id} className={` ${(item.result && item.result.is_reported == 1) ? 'disabled-row-1' : ''}`}>
                                                    <td className="bd-q-pat-col">
                                                                    <span>
                                                                        {item.result &&
                                                                        (item.result.user_type === 'Unknown')?item.result.user_type+" ":item.result.user_type
                                                                        }
                                                                        {item.result && item.result.result_id ?
                                                                            item.result.result_id
                                                                            :
                                                                            index + 1
                                                                        }
                                                                    </span>
                                                    </td>
                                                    <td className="bd-outcome-col">
                                                        <div className="test_outcome_td">
                                                            {(item.is_skipped == 1 ? <>
                                                                    <span className={"skipped_dot"} style={{ background: "rgb(204, 204, 204)" }}></span>
                                                                    Task skipped
                                                                </> :
                                                                <>
                                                                    <span className={(item.is_direct == 1 ? "direct" : "indirect") + "_" + (item.status == "Success" ? "success" : "failure") + "_dot"}></span>
                                                                    {item.is_direct == 1 ? "Direct" : "Indirect"}
                                                                    {" "}
                                                                    {item.status == "Success" ? "success" : "failure"}
                                                                </>)}
                                                        </div>
                                                    </td>
                                                    <td className="bd-q-ans-col">
                                                        <div className={`${test.language == 'ar' ? 'arabic-font' : ''} bd-qu-td-ans tree-test-ans-col`}>

                                                            {item.is_skipped == 1 ? '--':renderPath(item, task.correct_answer_id, test)}

                                                        </div>
                                                    </td>

                                                    <td className="bd-time-col">{calculateTime(item.time_spent)}</td>
                                                    <td className="bd-q-date-col">
                                                        <div className="bd-col-action-wrap d-flex">
                                                            <span>{moment(item.created_at).format("DD/MM/YYYY")}</span>
                                                            <span
                                                                className="pro-menu-icon small-dropdown mid-menu-wrap dropdown-withclose-btn">

                                                                        {/*
                                                           <Dropdown>
                                                                <Dropdown.Toggle
                                                                    id={"dropdownMenuButton" + item.id}
                                                                    className="dropdown-toggle"
                                                                >
                                                                    <img
                                                                        src={process.env.REACT_APP_URL + "images/menu-dotts.svg"}
                                                                        alt="img"
                                                                    />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <div className="dropdown-close">
                                                                        <Dropdown.Toggle>
                                                                            <img
                                                                                src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                                                alt="img"
                                                                            />
                                                                        </Dropdown.Toggle>
                                                                    </div>
                                                                    <div className="drop-down-link-hold">
                                                                        <Dropdown.Item onClick={() => deleteAnswerModal(item.result.id,(item.result && item.result.is_hidden)?true:false)}>
                                                                        {(item.result && item.result.is_hidden)?'Show participant':'Hide participant'}
                                                                            <span>
                                                                                <img
                                                                                    src={
                                                                                        process.env.REACT_APP_URL +
                                                                                        "images/eye.svg"
                                                                                    }
                                                                                    alt="img"
                                                                                />
                                                                            </span>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => reportAnswerModal(item.result.id)}>
                                                                            Report participant
                                                                            <span>
                                                                                <img
                                                                                    src={
                                                                                        process.env.REACT_APP_URL + "images/warning.svg"
                                                                                    }
                                                                                    alt="img"
                                                                                />
                                                                            </span>
                                                                        </Dropdown.Item>
                                                                    </div>

                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                                                */}
                                                                    </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        {tasks[task.id].tests.length == 0 && !isTaskLoading &&
                                        <tr>
                                            <td colSpan={10} className="border-0">
                                                <div className="text-center">
                                                    <EmptySearchPlaceholder type={"filter"} clearSearch={()=>{
                                                        setSortLoading(true);
                                                        getTaskAttempts(task.id, 1, tasks[task.id].order, tasks[task.id].direction,[]);
                                                    }} cssClass="by-user-placeholder" />
                                                </div>
                                            </td>
                                        </tr>
                                        }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {tasks[task.id].initial_loaded && !isTaskLoading && tasks[task.id].has_records &&
                            <div className="loadmorebtn-box">
                                <Button
                                    type={"primary"}
                                    microLoading={false}
                                    label={"Load more"}
                                    size={"medium"}
                                    onClick={() => { getTaskAttempts(task.id, tasks[task.id].page, tasks[task.id].order, tasks[task.id].direction,tasks[task.id].outcome_filter); }}
                                />
                            </div>
                            }
                            {isTaskLoading && !sortLoading && tasks[task.id].page > 1 &&
                            <div className="loadmorebtn-box">
                                <Button
                                    type={"primary"}
                                    microLoading={true}
                                    label={"Load more"}
                                    size={"medium"}
                                    onClick={() => { getTaskAttempts(task.id, tasks[task.id].page, tasks[task.id].order, tasks[task.id].direction,tasks[task.id].outcome_filter); }}
                                />
                            </div>
                            }
                        </div>

                    </>
                    }
                    {tasks[task.id].view == "tree" &&
                    <div className="bd-que-ans-table-data-inner bd-que-buy-user-table" id="field2">
                        <div className="treew-view-graph-wrap w-100" >
                            {task.id in treeChart && treeChart[task.id].api_tree.length > 0 &&
                            <>
                                <div className="bd-que-ans-table-data-inner bd-que-buy-user-table  tree-chart-full-top-row" id="field2">
                                    <div className="bd-question-top-slide d-flex justify-content-between">
                                        <div className="bd-que-slide-left b-tree-left">
                                            <div className="d-flex">
                                                <Checkbox className={"plan-change-switch"} onChange={(event) => { setTreeState(treeState == 'horizontal' ? "radial" : "horizontal") }} value={"radial"} checked={treeState == "radial" ? true : false} label="Radial" bigLabel={false} beforeLabel={"Horizontal"} />
                                            </div>

                                        </div>
                                        <div className="bd-que-slide-right tree-options-right">

                                                    <span className="small-dropdown">
                                                        <Dropdown>
                                                            <Dropdown.Toggle id={"dropdownMenuButton"} className="dropdown-toggle">
                                                                Download
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#" onClick={() => exportAsImage(exportRef.current, "treechart")}>
                                                                    Download  PNG
                                                                    <span><img src={process.env.REACT_APP_URL + "images/download.svg"} /></span>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#" onClick={() => downloadSVG(exportRef.current)}>
                                                                    Download  SVG
                                                                    <span><img src={process.env.REACT_APP_URL + "images/download.svg"} /></span>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </span>

                                        </div>
                                    </div>
                                </div>
                                <div className="treew-view-graph-wrap" ref={exportRef}>

                                    <SankeyChart expandData={expandData} radial={treeState == "radial" ? true : false} height={treeState == "radial" ? treeChart[task.id].depth * 220 : treeChart[task.id].leaf_nodes * 50 + 100} depth={treeChart[task.id].depth} answer_path={treeChart[task.id].answer_path} total_navigation={treeChart[task.id].total_navigation} rawdata={{ "id": treeChart[task.id].api_tree[0]["id"], "depth": treeChart[task.id].api_tree[0]["depth"], "value": treeChart[task.id].api_tree[0]["value"], "participants": treeChart[task.id].api_tree[0]["participants"], "correct_answer_count": treeChart[task.id].api_tree[0]["correct_answer_count"], "incorrect_answer_count": treeChart[task.id].api_tree[0]["incorrect_answer_count"], "skipped_count": treeChart[task.id].api_tree[0]["skipped_count"], "nominated_answer_count": treeChart[task.id].api_tree[0]["nominated_answer_count"], "back_count": treeChart[task.id].api_tree[0]["back_count"], title: treeChart[task.id].api_tree[0]["title"], children: treeChart[task.id].api_tree[0]['children'] }} owidth={100} />
                                </div>
                            </>
                            }
                            {!(task.id in treeChart) && isTreeChartLoading &&
                            <div className="page-loader accountloading-box result-sections-loader">
                                <LoadingIcon />
                            </div>
                            }
                        </div>
                    </div>
                    }
                    <div className="first-click-table-wrap pt-32" id="tree-test-first-click">
                        <div className="free-question-table-header">
                            <div className="free-question-table-header-left">
                                <Text type={"h4"} fontWeight={"medium-font"}>First click</Text>
                            </div>
                        </div>
                        <div ref={firstClickScrollContainerRef} className={`max-height-table  ${isFirstClickScrolled ? 'scrolled' : ''}  ${task.id in firstClick && firstClick[task.id].length>8 ? "responsive-table-scroll":""}`}>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Label</th>
                                    <th className="text-right" >
                                        <div className={`${tasks[task.id].firstclickorder == "visitedfirst" ? tasks[task.id].firstclickdirection : ''} col-sort justify-content-between`}
                                             onClick={() => {
                                                 var data = Object.assign([], firstClick[task.id]);


                                                 var direction = "asc";;

                                                 if (tasks[task.id].firstclickorder != "visitedfirst") {
                                                     data.sort((a, b) => a.visited_first - b.visited_first);
                                                 } else {
                                                     if (tasks[task.id].firstclickdirection == "desc") {
                                                         direction = "asc";
                                                         data.sort((a, b) => a.visited_first - b.visited_first);
                                                     } else {
                                                         direction = "desc";
                                                         data.sort((a, b) => b.visited_first - a.visited_first);
                                                     }
                                                 }

                                                 var first_click = Object.assign({}, firstClick);

                                                 first_click[task.id] = data;

                                                 setFirstClick(first_click);


                                                 var ltasks = Object.assign({}, tasks);

                                                 ltasks[task.id]["firstclickdirection"] = direction;

                                                 ltasks[task.id]["firstclickorder"] = "visitedfirst";

                                                 setTasks(ltasks);


                                             }}
                                        >
                                            Visited first
                                            {tasks[task.id].firstclickorder == "visitedfirst" && tasks[task.id].firstclickdirection == "asc" &&
                                            <span>

                                                        <img src={process.env.REACT_APP_URL + "img/sortup.svg"} />
                                                    </span>
                                            }
                                            {tasks[task.id].firstclickorder == "visitedfirst" && tasks[task.id].firstclickdirection == "desc" &&
                                            <span>

                                                        <img src={process.env.REACT_APP_URL + "img/sortdown.svg"} />
                                                    </span>
                                            }
                                            {tasks[task.id].firstclickorder != "visitedfirst" &&
                                            <span>

                                                        <img src={process.env.REACT_APP_URL + "img/sort-icon.svg"} />
                                                    </span>
                                            }
                                        </div>
                                    </th>
                                    <th className="text-right">

                                        <div className={`${tasks[task.id].firstclickorder == "visitedduring" ? tasks[task.id].firstclickdirection : ''} col-sort justify-content-between`}
                                             onClick={() => {

                                                 //alert(1)
                                                 var data = Object.assign([], firstClick[task.id]);


                                                 var direction = "asc";;

                                                 if (tasks[task.id].firstclickorder != "visitedduring") {
                                                     data.sort((a, b) => a.visited_first - b.visited_first);
                                                 } else {
                                                     if (tasks[task.id].firstclickdirection == "desc") {
                                                         direction = "asc";
                                                         data.sort((a, b) => a.visited_first - b.visited_first);
                                                     } else {
                                                         direction = "desc";
                                                         data.sort((a, b) => b.visited_first - a.visited_first);
                                                     }
                                                 }

                                                 var first_click = Object.assign({}, firstClick);

                                                 first_click[task.id] = data;

                                                 setFirstClick(first_click);


                                                 var ltasks = Object.assign({}, tasks);

                                                 ltasks[task.id]["firstclickdirection"] = direction;

                                                 ltasks[task.id]["firstclickorder"] = "visitedduring";

                                                 setTasks(ltasks);
                                             }}
                                        >
                                            Visited during
                                            {tasks[task.id].firstclickorder == "visitedduring" && tasks[task.id].firstclickdirection == "asc" &&
                                            <span>

                                                        <img src={process.env.REACT_APP_URL + "img/sortup.svg"} />
                                                    </span>
                                            }
                                            {tasks[task.id].firstclickorder == "visitedduring" && tasks[task.id].firstclickdirection == "desc" &&
                                            <span>

                                                        <img src={process.env.REACT_APP_URL + "img/sortdown.svg"} />
                                                    </span>
                                            }
                                            {tasks[task.id].firstclickorder != "visitedduring" &&
                                            <span>

                                                        <img src={process.env.REACT_APP_URL + "img/sort-icon.svg"} />
                                                    </span>
                                            }
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {(task.id in firstClick) &&
                                <>
                                    {!isFirstClickLoading && firstClick[task.id].map(function (item) {
                                        return (
                                            <tr key={"firstclick" + item.title}>
                                                <td>
                                                    <div className={`first-click-td ${test.language == 'ar' ? 'arabic-font' : ''}`}>{item.title}</div>
                                                </td>
                                                <td className="text-right">

                                                    {item.visited_first} %

                                                </td>
                                                <td className="text-right">{item.visited_during} %</td>
                                            </tr>
                                        );
                                    })}

                                    {firstClick[task.id].length == 0 && !isFirstClickLoading &&
                                    <tr>
                                        <td className="no-data-available" colSpan={10}>No data available</td>
                                    </tr>
                                    }
                                </>
                                }
                                {isFirstClickLoading &&
                                <tr>
                                    <td colSpan={10}>
                                        <div className="page-loader accountloading-box result-sections-loader">
                                            <LoadingIcon />
                                        </div>
                                    </td>
                                </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


            </>
        );
    }
    const renderFirstClick = (task_id) => {
        if (!(task_id in firstClick)) {
            getFirstClick(task_id);
        }
    }
    const renderTreeChart = (task_id) => {
        if (!(task_id in treeChart)) {
            getTreeChart(task_id);
        }
    }
    const callbackTopbar = (data) => {
        getTest();
        getTestafterEndTest();
    }
    return (
        <LayoutResearcher
            isSharedLink={isSharedLink}
            fixed_header_target={true}
            isLoading={false}
            wrapClass={"researcher-test-view-overflow create-test-data-wrapper"}
            resultHeaderClass={"result-header"}
            skipCheck={false}
            extendedFooter={false}
            activeMenu={""}
            openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >
            <Helmet>
                <title>Analyse tree testing  | UserQ</title>
            </Helmet>
            {isLoading &&
            <div className="page-loader accountloading-box result-sections-loader">
                <LoadingIcon />
            </div>
            }
            {!isLoading && test && (
                <div className="three-column-layout two-column-layout">
                    <TestResultsHeader project={project} isSharedLink={isSharedLink} test={test} endedOn={test.status === 'completed' ? moment.utc(test.ended_on).format('DD-MM-Y') : ''} callbackTopbar={callbackTopbar} />


                    <div className="three-column-layout-body">
                        <div className="three-column-layout-left">
                            <TestResultsSidebarNavigation
                                isSharedLink={isSharedLink}
                                methodology={(test.methodology === 'Five Seconds Test') ? "5 second test" : test.methodology}
                                selected_index={4}
                                test_id={(location.pathname.substr(1, 1) === 'v')?test_id:test.id}
                                screening={test && test.screening_questions  && test.screening_questions.length>0 ? "Yes" : "No"}

                            />
                        </div>
                        <div className="two-column-right-side">


                            <>
                                {sectionLoading &&
                                <div className="page-loader accountloading-box result-sections-loader">
                                    <LoadingIcon />
                                </div>
                                }
                                {!sectionLoading && resultcount === 0 &&
                                <div className={`no-participants-data no-questions-data ${dropdownOpen ? "black-overlay" : ""}`}>
                                    <div className="no-page-data-wrap" style={{ marginTop: "80px" }}>
                                        <div className="no-data-icon">
                                            <Icon colorClass={"gray-700-svg"} value={"attention"} size={"large"} />
                                        </div>

                                        <Text type={"subtitle-1"} fontWeight="medium-font">Details are not available at the moment as the test didn’t receive any response yet. Please come back later.</Text>
                                    </div>
                                </div>
                                }
                                {!sectionLoading && resultcount > 0 && tabs &&
                                <>
                                    <div className="bd-question-view-wrap tree-test-result">
                                        <div className="question-result-header question-result-data">
                                            <div className={`question-dropdown-container`}>

                                                <TasksDropdown rtl={test.language == "ar" ? true : false} options={tabs.map((item) => {
                                                    return { "title": item.title, subtitle: item.test_result_task_count };
                                                })} changeActiveOptionProp={(currentTab) => {

                                                    setCurrentTab(currentTab);

                                                    if (currentTab != 1) {
                                                        var task = tabs[currentTab - 1];
                                                        if (tasks[task.id].initial_loaded == false) {
                                                            getTaskAttempts(task.id, tasks[task.id].page, tasks[task.id].order, tasks[task.id].direction);
                                                        }
                                                        if (tasks[task.id].firstclick_loaded == false) {
                                                            getFirstClick(task.id);
                                                        }
                                                    }
                                                    jumptoSection("tree-test-overview");
                                                }
                                                } dropdownOpen={(state) => setDropdownOpen(state)} active={currentTab}/>
                                            </div>

                                            <div className="accountnaviagtion-outer result-navigation-outer tree-navigator-active-link">
                                                    <span className={`${activeMenu == "tree-test-overview" ? "active" : ""}`} onClick={() => { jumptoSection("tree-test-overview") }}>
                                                        <a data-href={`#tree-test-overview`}>Task overview</a>
                                                    </span>
                                                <span className={`${activeMenu == "tree-test-path" ? "active" : ""}`} onClick={() => { jumptoSection("tree-test-path") }} >
                                                        <a data-href={`#tree-test-path`}>Path</a>
                                                    </span>
                                                <span className={`${activeMenu == "tree-test-first-click" ? "active" : ""}`} onClick={() => { jumptoSection("tree-test-first-click") }}>
                                                        <a data-href={`#tree-test-first-click`}>First click</a>
                                                    </span>
                                            </div>

                                        </div>

                                        <div className={`question-result-data ${dropdownOpen ? "black-overlay" : ""}`}>

                                            <div className="test-result-inner-data" >
                                                {tabs.length > 0 && tabs[currentTab - 1].type == "overview" &&
                                                <>{renderOverview()}</>
                                                }
                                                {tabs.length > 0 && tasks[tabs[currentTab - 1].id] && tabs[currentTab - 1].type == "task" &&
                                                <>{renderTask()}</>
                                                }

                                            </div>
                                        </div>
                                    </div>


                                    <div className="bd-question-view-wrap tree-test-follow-up-questions">

                                        <div className={`question-result-data ${dropdownOpen ? "black-overlay" : ""}`}>

                                            <div className="test-result-inner-data" >

                                                {tabs.length > 0 && tasks[tabs[currentTab - 1].id] && tabs[currentTab - 1].type == "task" &&
                                                <>{renderFollowup()}</>
                                                }
                                                <div className="bd-question-bottom-pagination-arrow">
                                                    <div className="bd-que-slide-arrow tablebottompagination">
                                                        <Button
                                                            type={"secondary"}
                                                            microLoading={false}
                                                            label={"Previous task"}
                                                            size={"medium"}
                                                            onClick={(e) => {
                                                                console.log(tasks)
                                                                e.preventDefault(); if (currentTab > 1) {
                                                                    setCurrentTab(currentTab - 1);

                                                                }
                                                                if (currentTab != 1) {
                                                                    var task = tabs[currentTab - 1 - 1];
                                                                    if (tasks[task.id].initial_loaded == false) {
                                                                        getTaskAttempts(task.id, tasks[task.id].page, tasks[task.id].order, tasks[task.id].direction);
                                                                    }
                                                                    if (tasks[task.id].firstclick_loaded == false) {
                                                                        getFirstClick(task.id);
                                                                    }
                                                                }
                                                                jumptoSection("tree-test-overview");
                                                            }}
                                                            iconLeft={
                                                                <Icon value={"back arrow"} size={"medium"} />
                                                            }
                                                            state={`${currentTab === 1 ? 'disabled' : ''}`}
                                                        />
                                                        <Button
                                                            type={"secondary"}
                                                            label={"Next task"}
                                                            size={"medium"}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (currentTab < tabs.length) { setCurrentTab(currentTab + 1); }

                                                                if (currentTab + 1 > 0) {
                                                                    var task = tabs[currentTab + 1 - 1];
                                                                    if (tasks[task.id].initial_loaded == false) {
                                                                        getTaskAttempts(task.id, tasks[task.id].page, tasks[task.id].order, tasks[task.id].direction);
                                                                    }
                                                                    if (tasks[task.id].firstclick_loaded == false) {
                                                                        getFirstClick(task.id);
                                                                    }

                                                                }
                                                                jumptoSection("tree-test-overview");
                                                            }}
                                                            iconRight={
                                                                <Icon value={"forward-arrow"} size={"medium"} />
                                                            }
                                                            state={`${currentTab === tabs.length ? 'disabled' : ''}`}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }
                            </>
                        </div>
                    </div>
                    <DeleteAnswer
                        test_id={test_id}
                        openModal={openDeleteAnswerModal}
                        closeModal={closeDeleteAnswerModal} />
                    <ReportAnswer
                        test_id={test_id}
                        openModal={openReportAnswerModal}
                        closeModal={closeReportAnswerModal} />
                </div>
            )}
            {!isSharedLink &&
            <a onClick={(e)=>{
                e.preventDefault();

                setShowSupportPanel(true);

            }}  href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
                Support
            </a>
            }
        </LayoutResearcher>
    );
}
