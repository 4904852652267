import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Box } from "../Box/Box";
import { Text } from "../Text/Text";
import React, { memo, useState } from "react";
import "./information_tiles_box.css";
import { Icon } from "../Icon/Icon";
export const InformationTilesBox = ({ tiles }) => {
    return (
        <div className="did-you-know-slider-hold did-you-know-slider didyou-know-slider-component sign-up-cards">
            <Box size={"signup-card"}>
                <OwlCarousel
                    className={`owl-theme`}
                    loop={false}
                    dots={true}
                    margin={0}
                    nav={tiles.length > 0}
                    mouseDrag={true}
                    touchDrag={true}
                    responsiveclassName={true}
                    autoplay={true}
                    autoplayTimeout={1000 * 60 * 7}
                    responsive={{
                        0: { items: 1 },
                        600: { items: 1 },
                        1000: { items: 1 },
                        1600: { items: 1 },
                    }}
                    // onChanged={(object) => updateCarouselPosition(object)}
                >
                    {tiles.map((item, index) => {
                        return <InformationTile item={item} index={index} />;
                    })}
                </OwlCarousel>
            </Box>
        </div>
    );
};
export const InformationTile = ({ item, index }) => {
    return (
        <div key={"slide" + index} className={`did-you-know-slide`}>
            <div className="mb-20">
                <Icon
                    size={"large"}
                    colorClass={item.colorClass}
                    value={item.Icon}
                />
            </div>

            <Text
                type={"body-text-2"}
                cssClasses={"color-black mb-12"}
                fontWeight={"semibold-font"}
            >
                {item.title}
            </Text>
            <Text type={"body-text-3"} fontWeight="medium-font">
                {item.subtitle}
            </Text>
        </div>
    );
};
