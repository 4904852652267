import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { LayoutResearcher } from '../../themes/userq/Layouts/layout_researcher';
import { useState, useEffect, useRef } from "react";
import {
    getResearcherTestResultData,
    getTestPreferenceTestData,
    getLoadMoreSurveyQuestionData,
    getPreferenceTestQuestionResultData
} from "../../../services/researcher_test_results";
import toast from "react-hot-toast";
import Topbar from "./components/topbar";
import Sidebar from "./components/sidebar";
import { getCurrentPlansService } from "../../../services/plans";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import LinearAxisChart from "./components/linear_axis_chart";
import { calculateTime, renderTestTitle, showToast } from "../../../lib/helpers";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import SankeyChart from "./components/sankey_chart";
import { atom, useRecoilState, useRecoilValueLoadable_TRANSITION_SUPPORT_UNSTABLE } from 'recoil';
import TaskAttempts from "./components/preference_test/task_attempts";
import { LoadingIcon } from "../../loader/loadingIcon";
import { TestResultsHeader } from "../../themes/userq/TestResultsHeader/TestResultsHeader";
import { TestResultsSidebarNavigation } from "./components/test_results_sidebar_navigation";
import { Text } from "../../themes/userq/Text/Text";
import { Icon } from "../../themes/userq/Icon/Icon";
import DesignTest from "./dialog/design_test";
import { QuestionsDropdown } from '../../themes/userq/QuestionsDropdown/QuestionsDropdown';
import { LikertScaleAggregateData } from '../../themes/userq/LikertScaleAggregateData/LikertScaleAggregateData';
import { LikertScaleChart } from '../../themes/userq/LikertScaleChart/LikertScaleChart';
import { LikertScaleByUser } from '../../themes/userq/LikertScaleByUser/LikertScaleByUser';
import { Button } from '../../themes/userq/Button/Button';
import { Search } from '../../themes/userq/Search/Search';

import { ByUserTable } from '../../themes/userq/ByUserTable/ByUserTable';
import { PieStatsBoxSingleChoice } from './components/pie_stats_box_singlechoice';
import { HorizontalBarChartStatsBoxMultipleChoice } from './components/horizontal_bar_chart_stats_box_multiplechoice';
import { HorizontalBarChartStatsBoxRankingScale } from './components/horizontal_bar_chart_stats_box_rankingscale';
import { AggregatedDataTable } from '../../themes/userq/AggregatedDataTable/AggregatedDataTable';
import HideParticipant from './dialog/hide-participant';
import ReportAnswer from "./dialog/report-answer";
import { Helmet } from "react-helmet";
import { RatingScaleChart } from '../../themes/userq/RatingScale/RatingScaleChart';
import { RatingMultiSelect } from "../../themes/userq/MultiSelecteDropdownList/RatingMultiSelect";
import { Preview } from "../../themes/userq/Preview/preview";

export function compare(a, b) {

    const value1 = a[1]["averageranking"];
    const value2 = b[1]["averageranking"];

    let comparison = 0;

    if (value1 > value2) {
        comparison = -1;
    } else if (value1 < value2) {
        comparison = 1;
    }
    return comparison;
}

export default function TestResultPreferenceTest({isSharedLink}) {
    let { test_id } = useParams();

    const location = useLocation();

    const scrollContainerRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [pref, setPref] = useState(null);

    const [activeIndex, setActiveIndex] = useState(0);

    const [fullView, setFullView] = useState(false);

    const [section, setSection] = useState("Aggregated");

    const [order, setOrder] = useState("Preference");

    const [pref_direction, setPrefDirection] = useState("desc");

    const [votes_direction, setVotesDirection] = useState("asc");

    const [time_direction, setTimeDirection] = useState("asc");

    const [designs, setDesigns] = useState([]);

    const [sectionLoading, setSectionLoading] = useState(false);


    const [openDesignModal, setOpenDesignModal] = useState({ active: false, img: null });

    const testState = atom({
        key: 'testresult-' + test_id,
        default: ""
    });
    const planState = atom({
        key: 'testresultplan-' + test_id,
        default: ""
    });
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);
    const [task, setTask] = useState({});

    const [questionArray, setQuestionArray] = useState([]);
    const [resultCount, setResultCount] = useState(0);
    const [taskDetails, setTaskDetails] = useState(null);

    const [isActive, setActive] = useState(0);

    const [dropdownQuestions, setDropdownQuestions] = useState(false);

    const [metaData, setMetaData] = useState({ questionType: "", anyOptionHaveImage:false, totalResultCount: 0, userType: "", aggregate_total_voters: 0, sort_time: "", sort_date: "", sort_vote: "", load_more: "", test_question_id: "", page: 1 });
    const [search, setSearch] = useState("");
    const [searchLoading, setSearchLoading] = useState("");
    const [chartData, setChartData] = useState({});
    const [rankingchartData, setRankingChartData] = useState({});
    const [matrixArray, setMatrixArray] = useState([]);
    const [matrixheight, setMatrixHeight] = useState(600);
    const [sortedchartData, setSortedChartData] = useState([]);
    const [highestRanking, setHighestRanking] = useState(null);
    const [rankingTableData, setRankingTableData] = useState([]);
    const [searchByUser, setSearchByUser] = useState("");
    const [rating, setRating] = useState([]);
    const [searchAggregateData, setSearchAggregateData] = useState("");
    const [likertQuestion, setLikertQuestion] = useState({});
    
    const [predictions, setPredictions] = useState({"average":null, csat:null, nps:null});

    const [sortLoading, setSortLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState("chart");
    const [answerArray, setAnswerArray] = useState([]);
    const [aggregrateArray, setAggregrateArray] = useState([]);

    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [openDeleteAnswerModal, setOpenDeleteAnswerModal] = useState({ active: false, test_result_id: null, hidden_result: false });
    const [openReportAnswerModal, setOpenReportAnswerModal] = useState({ active: false, answer_id: null });
    const [show_support_panel, setShowSupportPanel] = useState(false);

    const projectState = atom({
        key: 'testresultproject-' + test_id,
        default: null
    });
    const [project, setProject] = useRecoilState(projectState);

    let searchController = useRef();

    const sortDesigns = (order, dir, designs = []) => {
        var api_designs = [];

        if (designs.length > 0) {
            api_designs = Object.assign([], designs);
        } else {
            api_designs = Object.assign([], task.designs);
        }

        if (order == "preference") {
            order = "votes";
        }
        if (order == "time") {
            order = "time_spent";
        }

        var max = api_designs[0];

        for (var i = 0; i < api_designs.length - 1; i++) {

            for (var j = i + 1; j < api_designs.length; j++) {


                if (dir == "desc") {

                    if (parseInt(api_designs[i][order]) < parseInt(api_designs[j][order])) {
                        var temp = api_designs[i];

                        api_designs[i] = api_designs[j];

                        api_designs[j] = temp;

                    }
                } else {
                    if (parseInt(api_designs[i][order]) > parseInt(api_designs[j][order])) {
                        var temp = api_designs[i];

                        api_designs[i] = api_designs[j];

                        api_designs[j] = temp;

                    }
                }

            }

        }
        setDesigns(api_designs);
        return api_designs;
    }
    const getTestPreferenceTestDataApi = () => {


        let token = ReactSession.get("token");

        setSectionLoading(true);

        let guest_result_token = '';

		if(location.pathname.substr(1, 1) === 'v'){

			// create guest_result_token
			if (!ReactSession.get("guest_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("guest_result_token", randomToken);
			}

			guest_result_token = ReactSession.get("guest_result_token");
		}
        let admin_result_token = '';

		if(location.pathname.substr(1, 1) === 'a'){

			// create guest_result_token
			if (!ReactSession.get("admin_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("admin_result_token", randomToken);
			}

			admin_result_token = ReactSession.get("admin_result_token");
		}

        getTestPreferenceTestData({ test_id: test_id, guest_result_token:guest_result_token,admin_result_token:admin_result_token }, token).then((response) => {
            //setSectionLoading(false);

            if (response.success) {

                setTask(response.data);

                var pref = [];

                var votes = null;





                setDesigns(response.data.designs);

                setOrder("preference");

                setPrefDirection("desc");

                var designsLoc = sortDesigns("preference", "desc", response.data.designs);

                if (designsLoc.length > 0) {

                    for (var key in designsLoc) {


                        if (designsLoc[key].votes != votes && parseInt(key) != 0 || designsLoc[key].votes == 0) {
                            break;
                        }
                        votes = designsLoc[key].votes;

                        pref.push(designsLoc[key].title ? designsLoc[key].title : "design " + (parseInt(key) + 1));


                    }
                    if (pref.length > 0) {
                        if (pref.length > 1) {
                            let last = pref.pop();
                            let result = pref.join(', ') + ' and ' + last;
                            setPref(result);
                        } else {

                            setPref(pref.join(", "));
                        }
                    }
                }
                getQuestions();

            } else {
                if(response.message == "Project not found.") {
                    navigate("/404");
                } else {
                    showToast(response.message, "error");
                }
            }
        });

    };

    const getTestafterEndTest = () => {

        let token = ReactSession.get("token");

        let guest_result_token = '';

		if(location.pathname.substr(1, 1) === 'v'){

			// create guest_result_token
			if (!ReactSession.get("guest_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("guest_result_token", randomToken);
			}

			guest_result_token = ReactSession.get("guest_result_token");
		}
		let admin_result_token = '';

		if(location.pathname.substr(1, 1) === 'a'){

			// create guest_result_token
			if (!ReactSession.get("admin_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("admin_result_token", randomToken);
			}

			admin_result_token = ReactSession.get("admin_result_token");
		}

        getResearcherTestResultData({ test_id: test_id,guest_result_token:guest_result_token, admin_result_token:admin_result_token }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
            } else {
                showToast(response.message, "error");
            }
        })

    }

    const getTest = () => {

        if (!test) {
            let token = ReactSession.get("token");

            setLoading(true);

            let guest_result_token = '';

            if(location.pathname.substr(1, 1) === 'v'){

                // create guest_result_token
                if (!ReactSession.get("guest_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("guest_result_token", randomToken);
                }

                guest_result_token = ReactSession.get("guest_result_token");
            }
            let admin_result_token = '';

            if(location.pathname.substr(1, 1) === 'a'){

                // create guest_result_token
                if (!ReactSession.get("admin_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("admin_result_token", randomToken);
                }

                admin_result_token = ReactSession.get("admin_result_token");
            }

            getResearcherTestResultData({ test_id: test_id, guest_result_token:guest_result_token, admin_result_token:admin_result_token }, token).then(
                (response) => {
                    setLoading(false);

                    if (response.success) {

                        if(response.workspace_project_team_member){
                            if(response.workspace_project_team_member.status!='active'){
                                navigate("/wsp/"+response.workspace_project_team_member.workspace_id+"/p/"+response.workspace_project_team_member.workspace_project_id+"/project-no-access")
                            }
                        }
                        if(response.test && response.test.workspace_project){
                            setProject(response.test.workspace_project);
                        }
                        // check test is available for sharing
                        if(location.pathname.substr(1, 1) === 'v' && response.is_share_result){
                            navigate(`/v/results/${test_id}/results-not-available`);
                        }

                        // check if guest user not validate there password
						if(location.pathname.substr(1, 1) === 'v' && response.test.password && !response.test_password_verify){

							navigate(`/v/results/${test_id}/password`);
						}
                        setTest(response.test);

                        getTestPreferenceTestDataApi();
                    } else {
                        if(response.message == "Project not found.") {
							navigate("/404");
						} else if(response.message == "Test Record Not found") {
							navigate("/404");
						} else {
                            showToast(response.message, "error");
                        }
                    }
                }
            );
        } else {
            getTestPreferenceTestDataApi();
        }
    };
    const handleScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = scrollContainerRef.current.scrollTop > 0;
    
        // Update state based on the scroll position
        setIsScrolled(scrolled);
    };
    useEffect(() => {
        // Attach scroll event listener to the referenced element
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);

            // Remove the event listener when the component unmounts
            return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [rankingTableData]);
    useEffect(() => {
        //document.title = process.env.REACT_APP_NAME + " - Analytics | Preference Test";
        getTest();
    }, []);
    const renderFullView = () => {
        var designs_render = [];

        var designs_loop = [];

        if (designs.length > 0) {
            designs_loop.push(designs[activeIndex]);
        }
        designs_loop.forEach(function (item, i) {
            designs_render.push(
                <div className="preference-test-design-wrap preference-taking-task-wrap"
                    style={{ backgroundColor: item.color ? item.color : "" }}
                >


                    <div className="prefer-design-image-view-hold">
                        <div className="prefer-test-selected-design-view">


                            <>
                                {item.type == "image" &&
                                    <img src={process.env.REACT_APP_IMG_URL + item.design_file} />
                                }
                                {item.type == "video" &&
                                    <video width="320" height="240" controls>
                                        <source src={process.env.REACT_APP_IMG_URL + item.design_file} type="video/mp4" />
                                    </video>
                                }
                            </>


                            <div className="design-max-screen-icon" onClick={() => { setActiveIndex(i); setFullView(true) }}>
                            <Icon colorClass="gray-900-svg" size={"small"} value={'maximize'} hover="true" />
                            </div>
                        </div>
                        <div className="test-design-name-and-option-wrap">
                            <h3 className={`h3 prefer-design-name ${(test.language == "ar") ? "arabic-font" : "arabic_wrapper"}`}>
                                <span>
                                    {activeIndex.toString().length == 1 ? "0" + (activeIndex + 1) : (activeIndex + 1)}
                                </span>
                                {item.title}
                            </h3>
                        </div>
                    </div>
                </div>

            );
        });

        return (
            <div className="design-fixed-full-view">
                <div className="prefer-design-slider-hold">
                    <div onClick={() => { setFullView(false) }} className="close-design-modal"><img src={process.env.REACT_APP_URL + "images/close-menu.svg"} alt="icon" /></div>
                    <div className="pref-test-btns-wrap">

                        <div className={`btn prevPage ${activeIndex == 0 ? 'disabled-nav' : ''}`} onClick={() => { if (activeIndex != 0) { setActiveIndex(activeIndex - 1); } }}>
                        <Icon colorClass="gray-50-svg" value="back-arrow" />
                        </div>
                        <div className={`btn nextPage ${activeIndex == designs.length - 1 ? 'disabled-nav' : ''}`} onClick={() => { if (activeIndex < designs.length - 1) { setActiveIndex(activeIndex + 1); } }}>
                            <Icon colorClass="gray-50-svg" value="forward-arrow" />
                        </div>
                    </div>
                    {designs_render}
                </div>
            </div>
        );

    };

    const callbackTopbar = (data) => {
        getTest();
        getTestafterEndTest();
    }
    const getQuestions = () => {
        let token = ReactSession.get("token");

        setSectionLoading(true);

        let guest_result_token = '';

		if(location.pathname.substr(1, 1) === 'v'){

			// create guest_result_token
			if (!ReactSession.get("guest_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("guest_result_token", randomToken);
			}

			guest_result_token = ReactSession.get("guest_result_token");
		}
        let admin_result_token = '';

		if(location.pathname.substr(1, 1) === 'a'){

			// create guest_result_token
			if (!ReactSession.get("admin_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("admin_result_token", randomToken);
			}

			admin_result_token = ReactSession.get("admin_result_token");
		}

        getPreferenceTestQuestionResultData({ test_id: test_id, guest_result_token:guest_result_token,admin_result_token:admin_result_token }, token).then((response) => {
            setSectionLoading(false);

            if (response.success) {

                setQuestionArray(response.data);
                changeActiveQuestion(1, response.data);
                setResultCount(response.resultcount);
                /*SetResultCount(response.resultcount);
                setTaskDetails(response.taskdetails)*/

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    };
    const changeActiveQuestion = (index, data) => {
        setActive(index)
        setDropdownQuestions(false); // hide dropdown value
        setSearch(""); // hide dropdown value
        setSearchByUser("");
        setRating([]);
        // filter data from array
        const dataAnswer = data.filter((item, indexItem) => indexItem + 1 === index)[0];

        if (dataAnswer) {

            setAnswerArray(dataAnswer.result);  // set all test result data list
            setAggregrateArray(dataAnswer.aggregate);
            if (dataAnswer.question_type == "multiplechoice") {
                setChartData(dataAnswer.chartDataMultipleChoice);
            }
            else {
                setChartData(dataAnswer.chartData);
            }
            if (dataAnswer.question_type == "rankingscale") {
                setRankingChartData(dataAnswer.rankingchartData);
                setRankingTableData([dataAnswer.rankingtableData]);
            }
            if (dataAnswer.question_type == "likertscale") {
                let matrixData = [];

                dataAnswer.matrix.forEach((items) => {

                    var totalheat = 0;
                    dataAnswer.matrix.forEach((entry) => {
                        if (items.y == entry.y) {
                            totalheat = totalheat + entry.heat
                        }
                    })
                    var fill = '';
                    if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 80) {
                        fill = '#5C208D';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 60 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 80) {
                        fill = '#793EAB';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 40 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 60) {
                        fill = '#9759CB';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 20 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 40) {
                        fill = '#BF8EE8';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) > 0 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 20) {
                        fill = '#EFDCFF';
                    }
                    else {
                        fill = '#E9E9E9';
                    }


                    matrixData.push({
                        x: items.x,
                        // x: items.x.length > 15 ? (test.language == "ar" ? "..." + items.x.substring(0, 14) : items.x.substring(0, 14) + "..") : items.x,
                        y: items.y,
                        // y: items.y.length > 20 ? (test.language == "ar" ? "..." + items.y.substring(0, 19) : items.y.substring(0, 19) + "...") : items.y,
                        // heat: (Math.round((items.heat * 100 / totalheat) * 100) / 100) + "%",
                        heat: items.heat,
                        fill: fill,
                        // participants:items.heat>1?items.heat+" Participants":items.heat+" Participant",
                        participants: (Math.round((items.heat * 100 / totalheat) * 100) / 100) + "% Participants",
                        percent_participants: (Math.round((items.heat * 100 / totalheat) * 100) / 100),
                    })
                })


                setMatrixArray(matrixData);
                setMatrixHeight(dataAnswer.subquestionscount * 30 + 50);
                setLikertQuestion(dataAnswer.questions);
            }
            if (dataAnswer.question_type == "ratingscale") {
                
                setMatrixArray(dataAnswer.matrix);
                setLikertQuestion(dataAnswer.questions);
                setPredictions({"average":dataAnswer.average, "nps":dataAnswer.nps, "csat":dataAnswer.csat});
            }

            let sortable = [];
            for (var option in dataAnswer.chartData) {
                sortable.push([option, dataAnswer.chartData[option]]);
            }

            sortable.sort(function (a, b) {
                return b[1] - a[1];
            });

            setSortedChartData(sortable);

            setMetaData({
                'anyOptionHaveImage': dataAnswer.any_option_have_image ? dataAnswer.any_option_have_image : false,
                'questionType': dataAnswer.question_type,
                'totalResultCount': dataAnswer.result_count,
                'userType': (dataAnswer.question_type === 'free') ? "by-user" : "chart",
                'aggregate_total_voters': dataAnswer.aggregate_total_voters,
                sort_result_id: "asc",
                sort_time: "",
                sort_date: "",
                sort_vote: "",
                load_more: (dataAnswer.result_count > 10) ? true : false,
                test_question_id: dataAnswer.id,
                page: 1
            });
        }

    }
    function Position(obj){
        var currenttop = 0;
        if (obj.offsetParent){
         do{
          currenttop += obj.offsetTop;
         }while ((obj = obj.offsetParent));
         return [currenttop];
         }
       }
    const jumptoSection = (area, questiontype) => {
        var fixedElementHeight =  document.querySelector('.researcher-header').offsetHeight +  document.querySelector('.test-results-header-researcher').offsetHeight +  document.querySelector('.follow-up-question-header').offsetHeight;
       
        //setActiveMenu(area);
        var offsetTop = Position(document.getElementById(area + "-" + questiontype));
        window.scrollTo({top:offsetTop-fixedElementHeight+0, behavior:"smooth"});

    }
    const jumpToElem = (elem, fixedElementHeight) => {
        var offsetTop = Position(document.getElementById(elem));
        window.scrollTo({top:offsetTop-fixedElementHeight, behavior:"smooth"});

    }
    const searchData = (search) => {
        setSearch(search);


        // if search record from aggregated data
        if (metaData.userType === 'aggregated') {
            const dataAnswer = questionArray.filter((item, indexItem) => indexItem + 1 === isActive)[0];
            if (dataAnswer) {
                const newAggregrateArray = dataAnswer.aggregate.filter(items => items.option.toLowerCase().indexOf(search.toLowerCase()) > -1);
                setAggregrateArray(newAggregrateArray)
            }
        } else {
            setSearchLoading(true);
            let token = ReactSession.get("token");
            getLoadMoreSurveyQuestionData({
                test_id: test_id,
                test_question_id: metaData.test_question_id,
                page: 1,
                type: metaData.questionType,
                search: search,
                questiontype: "Preference Test"
            }, token).then((response) => {
                setSearchLoading(false);

                if (response.success) {
                    setAnswerArray(response.data);
                    setMetaData({ ...metaData, page: 1, load_more: response.more_records });

                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        }

    }
    const sortData = (type) => {
        if (type === 'time') {
            if (metaData.sort_time === 'asc') {
                // setAnswerArray([]);
                sortDataApi('time', 'desc');
            } else {
                // setAnswerArray([]);
                sortDataApi('time', 'asc');
            }
        } else if (type === 'date') {
            if (metaData.sort_date === 'asc') {
                // setAnswerArray([]);
                sortDataApi('date', 'desc');
            } else {
                // setAnswerArray([]);
                sortDataApi('date', 'asc');
            }
        } else if (type === 'vote') {
            if (metaData.sort_vote === 'asc') {
                setAggregrateArray(aggregrateArray.sort((a, b) => (a.vote < b.vote) ? 1 : -1));
                setMetaData({ ...metaData, sort_vote: "desc" });
            } else {
                setAggregrateArray(aggregrateArray.sort((a, b) => (a.vote > b.vote) ? 1 : -1));
                setMetaData({ ...metaData, sort_vote: "asc" });
            }
        }  else if (type === 'result_id') {
            if (metaData.sort_result_id === 'asc') {
                // setAnswerArray([]);
                sortDataApi('result_id', 'desc');
            } else {
                // setAnswerArray([]);
                sortDataApi('result_id', 'asc');
            }
        }


    }

    const sortDataApi = (sortColumn, orderBy) => {

        // setMetaData({ ...metaData, load_more: true });
        // setLoadMoreLoading(true);
        setSortLoading(true);
        let token = ReactSession.get("token");
        let guest_result_token = '';

		if(location.pathname.substr(1, 1) === 'v'){

			// create guest_result_token
			if (!ReactSession.get("guest_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("guest_result_token", randomToken);
			}

			guest_result_token = ReactSession.get("guest_result_token");
		}
        let admin_result_token = '';

		if(location.pathname.substr(1, 1) === 'a'){

			// create guest_result_token
			if (!ReactSession.get("admin_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("admin_result_token", randomToken);
			}

			admin_result_token = ReactSession.get("admin_result_token");
		}
        getLoadMoreSurveyQuestionData({
            test_id: test_id,
            test_question_id: metaData.test_question_id,
            page: 1,
            type: metaData.questionType,
            search: metaData.questionType == 'free'?search:searchByUser,
            sort_column: sortColumn,
            order_by: orderBy,
            questiontype: "Preference Test",
            guest_result_token:guest_result_token,
            admin_result_token: admin_result_token
        }, token).then((response) => {
            // setLoadMoreLoading(false);
            setSortLoading(false);

            if (response.success) {
                setAnswerArray(response.data);

                if (sortColumn === 'time') {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_result_id:"", sort_time: "desc", sort_date: "", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_result_id:"",sort_time: "asc", sort_date: "", page: 1, load_more: response.more_records });
                    }
                } else if (sortColumn === 'result_id') {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_result_id:"desc", sort_time: "", sort_date: "", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_result_id:"asc", sort_time: "", sort_date: "", page: 1, load_more: response.more_records });
                    }
                } else {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_result_id:"", sort_time: "", sort_date: "desc", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_result_id:"", sort_time: "", sort_date: "asc", page: 1, load_more: response.more_records });
                    }
                }

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }

    const loadMore = () => {

        setLoadMoreLoading(true);
        let token = ReactSession.get("token");

        let guest_result_token = '';

		if(location.pathname.substr(1, 1) === 'v'){

			// create guest_result_token
			if (!ReactSession.get("guest_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("guest_result_token", randomToken);
			}

			guest_result_token = ReactSession.get("guest_result_token");
		}
        let admin_result_token = '';

		if(location.pathname.substr(1, 1) === 'a'){

			// create guest_result_token
			if (!ReactSession.get("admin_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("admin_result_token", randomToken);
			}

			admin_result_token = ReactSession.get("admin_result_token");
		}
        
        getLoadMoreSurveyQuestionData({
            test_id: test_id,
            test_question_id: metaData.test_question_id,
            page: metaData.page + 1,
            type: metaData.questionType,
            search: metaData.questionType == 'free'?search:searchByUser,
            questiontype: "Preference Test",
            guest_result_token:guest_result_token,
            admin_result_token: admin_result_token
        }, token).then((response) => {
            setLoadMoreLoading(false);

            if (response.success) {
                setAnswerArray(answerArray.concat(response.data));
                setMetaData({ ...metaData, page: metaData.page + 1, load_more: response.more_records });

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }
    const hideParticipantModal = (id, hiddenResult) => {
        setOpenDeleteAnswerModal({ active: true, test_result_id: id, hidden_result: hiddenResult });
    }
    const reportAnswerModal = (id) => {
        setOpenReportAnswerModal({ active: true, answer_id: id });
    }
    const closeReportAnswerModal = (data) => {
        setOpenReportAnswerModal({ active: false, answer_id: null });
    }
    const searchByAggregateData = (searchAggregateData) => {
        setSearchAggregateData(searchAggregateData);
        const dataAnswer = questionArray.filter((item, indexItem) => indexItem + 1 === isActive)[0];
        if (dataAnswer) {
            const newAggregrateArray = dataAnswer.aggregate.filter(items => items.option.toLowerCase().indexOf(searchAggregateData.toLowerCase()) > -1);
            setAggregrateArray(newAggregrateArray)
        }
    }
    const searchByUserData = (searchByUser) => {
        // setMetaData({...metaData,"search":search})
        setSearchByUser(searchByUser);

        setSearchLoading(true);
        //setTimeout(function () {
        if (searchController.current) {
            searchController.current.abort();

        }
        const controller = new AbortController();

        searchController.current = controller;


        let token = ReactSession.get("token");
        getLoadMoreSurveyQuestionData({
            test_id: test_id,
            test_question_id: metaData.test_question_id,
            page: 1,
            type: metaData.questionType,
            search: searchByUser,
            questiontype: "Preference Test"
        }, token, searchController.current?.signal).then((response) => {
            if (response.success) {
                setSearchLoading(false);
                setAnswerArray(response.data);
                setMetaData({ ...metaData, page: 1, load_more: response.more_records });

            } else {
                setSearchLoading(false);
                showToast(response.message, "error");
            }
        });
    }
    const searchByRating = (rating) => {
        // setMetaData({...metaData,"search":search})
        //setSearchByUser(searchByUser);

        setRating(rating);
        setSearchLoading(true);
        //setTimeout(function () {
        if (searchController.current) {
            searchController.current.abort();

        }
        const controller = new AbortController();

        searchController.current = controller;


        let token = ReactSession.get("token");
        getLoadMoreSurveyQuestionData({
            test_id: test_id,
            test_question_id: metaData.test_question_id,
            page: 1,
            type: metaData.questionType,
            rating: rating,
            questiontype: "Preference Test"
        }, token, searchController.current?.signal).then((response) => {
            if (response.success) {
                setSearchLoading(false);
                setAnswerArray(response.data);
                setMetaData({ ...metaData, page: 1, load_more: response.more_records });

            } else {
                setSearchLoading(false);
                showToast(response.message, "error");
            }
        });
    }
    const renderRankingQuestionTable = () => {

        var colors = [
            { color: "rgba(47, 46, 85)", text: "white-text" },
            { color: "rgba(89, 89, 114)", text: "white-text" },
            { color: "rgb(150, 255, 252)", text: "" },
            { color: "rgb(255, 102, 173)", text: "white-text" },
            { color: "rgb(119, 225, 254)", text: "" },
            { color: "rgba(47, 46, 85)", text: "white-text" },
            { color: "rgba(89, 89, 114)", text: "white-text" },
            { color: "rgb(150, 255, 252)", text: "" },
            { color: "rgb(255, 102, 173)", text: "white-text" },
            { color: "rgb(119, 225, 254)", text: "" },
            { color: "rgba(47, 46, 85)", text: "white-text" },
            { color: "rgba(89, 89, 114)", text: "white-text" },
            { color: "rgb(150, 255, 252)", text: "" },
            { color: "rgb(255, 102, 173)", text: "white-text" },
            { color: "rgb(119, 225, 254)", text: "" },
        ];

        var tableData = [];
        for (var i in rankingTableData[0])
            tableData.push([i, rankingTableData[0][i]]);
        tableData.sort(compare);

        return (
            <div ref={scrollContainerRef} className={`ranking-table-holder max-height-table  ${isScrolled ? 'scrolled' : ''} ${tableData && tableData.length>8 ? "responsive-table-scroll":""}`}>
                <table className="table prefer-aggregatd-data-table ranking-table">
                    <thead>
                        <tr>
                            <th>Answers</th>
                            {tableData.map(function (item, index) {
                                return (<th>{index + 1 == 1 ? "1st" : index + 1 == 2 ? "2nd" : index + 1 == 3 ? "3rd" : index + 1 + "th"}</th>)
                            })}
                            <th className="score-heading-table"><b>Score</b></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map(function (item, index) {
                            return (
                                <tr className={`${index == 0 ? "ranking-table-first-row" : ""}`}>
                                    <td>
                                        <div className='d-flex table-answer-column-outer'>
                                            <div style={{ backgroundColor: colors[index].color }} className='table-color-box'></div>
                                            <span className={`table-answer-column ${test.language == "ar" ? "arabic-font" : ""}`}>{item[0]}</span>
                                        </div>
                                    </td>
                                    {tableData.map(function (itemm, i) {
                                        return (
                                            <td>
                                                <div className='d-flex table-answer-column-votes'>
                                                    <span className='table-votes'>{item[1]["votes"][i + 1]}</span>
                                                    <span className='table-percentage'> ({Math.round((item[1]["votes"][i + 1] * 100 / metaData.totalResultCount) * 100) / 100}%)</span>
                                                </div>

                                            </td>
                                        )
                                    })}
                                    <td>
                                        {Math.round(item[1]["averageranking"] * 100) / 100}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {/* <div className='ranking-table-footer'>
                    Total: <span>{metaData.totalResultCount == 1 ? metaData.totalResultCount + ' response' : metaData.totalResultCount + ' responses'}</span>
                </div> */}
            </div>
        )
    }
    const closeDeleteAnswerModal = (data) => {

        // if successfully delete then delete object of array from answerArray
        if (data) {

            const getAnswerArray = answerArray;
            for (var i = 0; i < getAnswerArray.length; i++) {
                if (openDeleteAnswerModal.test_result_id === getAnswerArray[i].test_result_id) {

                    if (getAnswerArray[i].result.is_hidden === 1) {
                        getAnswerArray[i].result.is_hidden = 0;
                    } else {
                        getAnswerArray[i].result.is_hidden = 1;
                    }
                }
            }
        }
        setOpenDeleteAnswerModal({ active: false, test_result_id: null, hidden_result: false })

    }

    return (
        <LayoutResearcher
            isSharedLink={isSharedLink}
            fixed_header_target={true}
            isLoading={false}
            wrapClass={"researcher-test-view-overflow create-test-data-wrapper"}
            resultHeaderClass={"result-header"}
            skipCheck={false}
            extendedFooter={false}
            activeMenu={""}
            openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
        >
            <Helmet>
                <title>Analyse preference test  | UserQ</title>
            </Helmet>
            {test && (
                <div className="three-column-layout two-column-layout researcher-resultwrap preference-test-result-wrap analyze-results-page-wrapper">


                    <TestResultsHeader project={project} isSharedLink={isSharedLink} test={test} endedOn={test.status === 'completed' ? moment.utc(test.ended_on).format('DD-MM-Y') : ''} callbackTopbar={callbackTopbar} />

                    <div className="three-column-layout-body">
                        <div className="three-column-layout-left">

                            <TestResultsSidebarNavigation
                                isSharedLink={isSharedLink}
                                methodology={(test.methodology === 'Five Seconds Test') ? "5 second test" : test.methodology}
                                selected_index={4}
                                test_id={(location.pathname.substr(1, 1) === 'v')?test_id:test.id}
                                screening={test && test.screening_questions  && test.screening_questions.length>0 ? "Yes" : "No"}
								
                            />
                        </div>

                        <div className="two-column-right-side preference-test-analytics-data">
                            {sectionLoading &&
                                <div className={'page-loader accountloading-box result-sections-loader'}>
                                    <LoadingIcon />
                                </div>
                            }
                            {!sectionLoading &&

                                <>
                                    {task && task.total_answers == 0 &&
                                        <div className={`no-participants-data no-questions-data no-questions-added`}>
                                            <div className="no-page-data-wrap" style={{ marginTop: "80px" }}>
                                                <div className="no-data-icon">
                                                    <Icon colorClass={"gray-700-svg"} value={"attention"} size={"large"} />
                                                </div>

                                                <Text type={"subtitle-1"} fontWeight="medium-font">
                                                    Details are not available at the moment as the test didn’t receive any response yet. Please come back later.

                                                </Text>
                                            </div>
                                        </div>
                                    }
                                    {task && task.total_answers > 0 &&
                                        <div className="bd-question-view-wrap preference-test-result">

                                            <div className='question-result-header question-result-data'>
                                                <div className='question-dropdown-container'>
                                                    <div className='dropdown-header'>
                                                        <div>
                                                            <div>
                                                                <span className="questions-dropdown-result-count">
                                                                    {task.total_answers} answer{task.total_answers == 1 ? '' : 's'}
                                                                </span>
                                                            </div>
                                                            <Text type={"subtitle-2"} fontWeight="medium-font" cssClasses={`${test.language == 'ar' ? 'arabic-font' : ''}`}>
                                                                {task.title}
                                                            </Text>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="accountnaviagtion-outer result-navigation-outer preference-question-navigator-active-link">
                                                        <span className={`${(section === "Aggregated") ? 'active' : ''}`} onClick={() => {
                                                            //setSection("Aggregated")
                                                            /*document.getElementById("Aggregated").scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "center",
                                                            });*/
                                                            var fixedElementHeight =  document.querySelector('.researcher-header').offsetHeight +  document.querySelector('.test-results-header-researcher').offsetHeight + document.querySelector('.preference-question-navigator-active-link').offsetHeight;
       
                                                            jumpToElem("Aggregated",fixedElementHeight);
                                                        }}>
                                                            <a data-href={`#Aggregated`}>Aggregated data</a>
                                                        </span>
                                                        <span className={`${(section === "User") ? 'active' : ''}`} onClick={() => {
                                                           // setSection("User")
                                                            /*document.getElementById("User").scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "center",
                                                            });*/
                                                            //window.scrollTo({top:1032})
                                                            var fixedElementHeight =  document.querySelector('.researcher-header').offsetHeight +  document.querySelector('.test-results-header-researcher').offsetHeight +  document.querySelector('.preference-question-navigator-active-link').offsetHeight;
       
                                                            jumpToElem("byuserid",fixedElementHeight);
                                                        }}>
                                                            <a data-href={`#byuserid`}>By user</a>
                                                        </span>
                                                    </div>

                                            </div>
                                            <div className="bd-que-table-wrapper">

                                                <div className="question-result-data ">

                                                   

                                                    <div className="bd-que-ans-table-data-inner bd-que-aggregated-table prefer-anayl-data-table">
                                                        <div className="test-result-inner-data preference-test-results-container">
                                                            <div className=" table-responsive pt-40" id={"Aggregated"}>
                                                                {/*{fullView &&*/}
                                                                {/*    <>{renderFullView()}</>*/}
                                                                {/*}*/}
                                                                <div className='preference-test-question-table-header' >
                                                                    <div className={"preference-test-question-table-top"}>
                                                                        <Text type="h4">
                                                                            Aggregated data
                                                                        </Text>
                                                                    </div>
                                                                    <div className={"preference-test-question-table-bottom d-flex mt-32 purpletext"}>
                                                                        {pref &&
                                                                            <>
                                                                                <Text type="subtitle-1" fontWeight={"medium-font"}>
                                                                                    It looks like your participants prefer <span className={` ${test.language == 'ar' ? 'arabic-font' : ''}`}>{pref}</span>!
                                                                                </Text>


                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div  className={`max-height-table `}>
                                                                    <table className="prefrence-aggreatedtable prefer-aggregatd-data-table table mt-32">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Design preview</th>
                                                                                <th>Design name</th>
                                                                                <th
                                                                                    onClick={() => {
                                                                                        setOrder("preference");

                                                                                        setPrefDirection(pref_direction == "asc" ? "desc" : "asc");

                                                                                        sortDesigns("preference", pref_direction == "asc" ? "desc" : "asc");
                                                                                    }}
                                                                                >
                                                                                    <div className={`${order == "preference" ? pref_direction : ''} col-sort justify-content-between`}>
                                                                                        Preference %
                                                                                        {order == "preference" && pref_direction == "asc" &&
                                                                                            <Icon value={'sortup'} />
                                                                                        }
                                                                                        {order == "preference" && pref_direction == "desc" &&
                                                                                            <Icon value={'sortdown'} />
                                                                                        }
                                                                                        {order != "preference" &&
                                                                                            <Icon value={'sort-icon'} />
                                                                                        }
                                                                                    </div>
                                                                                </th>
                                                                                <th
                                                                                    onClick={() => {
                                                                                        setOrder("votes");

                                                                                        setVotesDirection(votes_direction == "asc" ? "desc" : "asc");

                                                                                        sortDesigns("votes", votes_direction == "asc" ? "desc" : "asc");
                                                                                    }}
                                                                                >
                                                                                    <div className={`${order == "votes" ? votes_direction : ''} col-sort justify-content-between`}>
                                                                                        Votes
                                                                                        {order == "votes" && votes_direction == "asc" &&
                                                                                            <Icon value={'sortup'} />
                                                                                        }
                                                                                        {order == "votes" && votes_direction == "desc" &&
                                                                                            <Icon value={'sortdown'} />
                                                                                        }
                                                                                        {order != "votes" &&
                                                                                            <Icon value={'sort-icon'} />
                                                                                        }
                                                                                    </div>
                                                                                </th>
                                                                                <th
                                                                                    onClick={() => {
                                                                                        setOrder("time_spent");

                                                                                        setTimeDirection(time_direction == "asc" ? "desc" : "asc");

                                                                                        sortDesigns("time_spent", time_direction == "asc" ? "desc" : "asc");
                                                                                    }}
                                                                                >
                                                                                    <div className={`${order == "time_spent" ? time_direction : ''} col-sort justify-content-between`}>
                                                                                        Time spent
                                                                                        {order == "time_spent" && time_direction == "asc" &&
                                                                                            <Icon value={'sortup'} />
                                                                                        }
                                                                                        {order == "time_spent" && time_direction == "desc" &&
                                                                                            <Icon value={'sortdown'} />
                                                                                        }
                                                                                        {order != "time_spent" &&
                                                                                            <Icon value={'sort-icon'} />
                                                                                        }
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {designs.map(function (item, index) {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="preference-test-thum-preview">


                                                                                                <div className="prefer-design-image-view-hold">
                                                                                                    <div className="prefer-test-selected-design-view">
                                                                                                        {item.type == "video" &&
                                                                                                            <div className="video-design-icon">
                                                                                                                <img src={process.env.REACT_APP_URL + "images/video-play-btn.svg"} alt="icon" />
                                                                                                            </div>
                                                                                                        }
                                                                                                        {/* <img src={process.env.REACT_APP_IMG_URL + item.thumb} /> */}
                                                                                                        <img src={process.env.REACT_APP_IMG_URL + item.thumb} style={{ width: '300px', height: '140px' }} />
                                                                                                        <div className="design-max-screen-icon" onClick={() => { setOpenDesignModal({ active: true, color: item.color, img: process.env.REACT_APP_IMG_URL + item.design_file, type: item.type, title: item.title }); }}>
                                                                                                            <Icon colorClass="gray-900-svg" size={"small"} value={'maximize'} hover="true" />
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className={` ${test.language == 'ar' ? 'arabic-font' : ''}`}>
                                                                                            {item.title}
                                                                                        </td>
                                                                                        <td>
                                                                                            {task.total_answers > 0 ? Math.round(((item.votes / task.total_answers) * 100) * 100) / 100 : "0"}%
                                                                                        </td>
                                                                                        <td>
                                                                                            {item.votes}
                                                                                        </td>
                                                                                        <td>
                                                                                            {calculateTime(item.time_spent)}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>


                                                            {test &&
                                                                <>
                                                                    <div id={"byuserid"} >
                                                                        <div className='preference-test-question-table-header preference-test-question-table-bottom-header  pt-40'>
                                                                            <div  className={"preference-test-question-table-top "} >
                                                                                <Text type="h4">
                                                                                    By user
                                                                                </Text>
                                                                            </div>
                                                                            <div className={"preference-test-question-table-bottom d-flex purpletext mt-32"}>
                                                                                {pref &&
                                                                                    <>
                                                                                        <Text type="subtitle-1" fontWeight={"medium-font"}>
                                                                                            It looks like your participants prefer <span className={` ${test.language == 'ar' ? 'arabic-font' : ''}`}>{pref}</span>!
                                                                                        </Text>


                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='table-responsive preference-test-table-bottom'>
                                                                            <TaskAttempts isSharedLink={isSharedLink} test_id={test_id} test={test} />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                            {!sectionLoading && questionArray && questionArray.length > 0 && resultCount > 0 &&
                                <div className='five-second-test-result-wrap'>
                                    <div className="result-follow-up-heading">
                                        <Text type={'h4'}>Follow up questions</Text>
                                    </div>
                                    <div className="bd-question-view-wrap">

                                        <div className="accountnaviagtion-outer result-navigation-outer d-block  follow-up-question-header">
                                            <div className="question-result-header" >

                                                <QuestionsDropdown questionArray={questionArray} changeActiveQuestionProp={(index) => {
                                                    changeActiveQuestion(index, questionArray);
                                                    /*document.getElementById("followup-results").scrollIntoView({
                                                        behavior: "auto",
                                                        block: "start",
                                                    });*/
                                                    setActiveMenu("chart");

                                                    var fixedElementHeight = document.querySelector('.researcher-header').offsetHeight + document.querySelector('.test-results-header-researcher').offsetHeight;

                                                    var offsetTop = Position(document.getElementById("followup-results"));
                                                    window.scrollTo({ top: offsetTop - fixedElementHeight, behavior: "smooth" });

                                                }} dropdownOpen={(state) => setDropdownOpen(state)} resultCount={metaData.totalResultCount} test={test} active={isActive} />

                                                {metaData.totalResultCount >= 1 &&
                                                    <>
                                                        {(metaData.questionType == 'singlechoice' || metaData.questionType == 'multiplechoice' || metaData.questionType == 'likertscale' || metaData.questionType == 'ratingscale') ?
                                                            <div className="follow-up-data-nav question-navigator-active-link">
                                                                <span className={`${activeMenu == 'chart' ? "active" : ''}`} onClick={() => { jumptoSection("chart", metaData.questionType) }}>
                                                                    <a data-href={`#chart-${metaData.questionType}`}>Chart</a>
                                                                </span>
                                                                {metaData.questionType != "ratingscale" &&
                                                                    <span className={`${activeMenu == 'aggregate-data' ? "active" : ''}`} onClick={() => { jumptoSection("aggregate-data", metaData.questionType) }}>
                                                                        <a data-href={`#aggregate-data-${metaData.questionType}`}>Aggregated data</a>
                                                                    </span>
                                                                }
                                                                <span className={`${activeMenu == 'byuser' ? "active" : ''}`} onClick={() => { jumptoSection("byuser", metaData.questionType) }}>
                                                                    <a data-href={`#byuser-${metaData.questionType}`}>By user</a>
                                                                </span>
                                                            </div>
                                                            : <></>}
                                                    </>}
                                            </div>
                                        </div>
                                        

                                        {metaData.totalResultCount < 1  ?

                                            <div className={`no-participants-data no-questions-data ${dropdownOpen ? "black-overlay" : ""}`}>
                                                <div className="no-page-data-wrap" style={{ marginTop: "80px" }}>
                                                    <div className="no-data-icon">
                                                        <Icon colorClass={"gray-700-svg"} value={"attention"} size={"large"} />
                                                    </div>

                                                    <Text type={"subtitle-1"} fontWeight="medium-font">{resultCount > 0 ? <>Details are not available at the moment as this question didn’t receive any response yet. Users might have skipped this question.</>: <>Details are not available at the moment as the test didn’t receive any response yet. Please come back later.</>}</Text>
                                                </div>
                                            </div>
                                            :
                                            <>
                                            {/*<div className="result-follow-up-heading">
                                                <Text type={'h4'}>Follow up questions</Text>
                                            </div>*/}
                                            
                                            <div  id="followup-results" className={`question-result-data  ${dropdownOpen ? "black-overlay" : ""}`}>
                                                
                                               

                                                {/*<div className='five-second-result-design'>
                                                        <Text type={"body-text-1"} fontWeight="medium-font">You can find the design that has been shown to participants for 5 seconds here</Text>
                                                        <Button
                                                            size={'medium'}
                                                            type={'primary'}
                                                            iconLeft={<Icon value={'eye-white'} />}
                                                            label={'View design'}
                                                            onClick={(e) => {
                                                                setOpenDesignModal(true);
                                                            }}
                                                        />
                                                        </div>*/}

                                            <div></div>

                                                {metaData.questionType === 'free' &&
                                                    <div className='free-question-result-data test-result-inner-data'>
                                                        <div className='free-question-table-header'>
                                                            <div className='free-question-table-header-left'>
                                                                <Text type="subtitle-1" fontWeight="medium-font">Free text</Text>
                                                            </div>
                                                            <div className='free-question-table-header-right'>
                                                                <Search
                                                                    size={"small"}
                                                                    placeholder="Search in answers"
                                                                    onChange={(e) => searchData(e.target.value)}
                                                                    onClear={() => {
                                                                        setTimeout(() => {
                                                                            setSearch("");
                                                                            searchData("")
                                                                        }, 1000)
                                                                    }}
                                                                    loading={searchLoading}
                                                                    value={search}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="table-responsive">
                                                        <ByUserTable methodology={"preference-test"} tableClass={"preference-test-user-table"} isSharedLink={isSharedLink} metaData={metaData} answerArray={answerArray} sortData={sortData} hideParticipantModal={hideParticipantModal} reportAnswerModal={reportAnswerModal} search={search} test={test} loadMore={loadMore} loadMoreLoading={loadMoreLoading} clearSearch={()=>{setSearch("");
                                                                        searchData("")}}/>
                                                       </div>
                                                    </div>
                                                }
                                                {metaData.questionType === 'singlechoice' &&
                                                    <div  id="chart-singlechoice">
                                                    <div className='free-question-result-data test-result-inner-data test-class'>
                                                        <div className='free-question-table-header' >
                                                            <div className='free-question-table-header-left'>
                                                                <Text type="h4" fontWeight="bold-font">Chart</Text>
                                                            </div>
                                                        </div>
                                                        <div className='chart-data' >
                                                            <PieStatsBoxSingleChoice data={chartData} sortedchartData={sortedchartData} title={<span>{metaData.totalResultCount}
                                                                {metaData.totalResultCount == 1 ? ' Answer' : ' Answers'}
                                                            </span>} test={test} totalAnswers={metaData.totalResultCount} />
                                                        </div>
                                                        <div className='aggregated-data-table' id="aggregate-data-singlechoice">
                                                            <div className='free-question-table-header'>
                                                                <div className='free-question-table-header-left'  >
                                                                    <Text type="h4" fontWeight="bold-font">Aggregated data</Text>
                                                                </div>
                                                                <div className='free-question-table-header-right'>
                                                                    <Search
                                                                        size={"small"}
                                                                        placeholder="Search in answers"
                                                                        onChange={(e) => searchByAggregateData(e.target.value)}
                                                                        onClear={() => {
                                                                            setTimeout(() => {
                                                                                setSearchAggregateData("");
                                                                                searchByAggregateData("")
                                                                            }, 0)
                                                                        }}
                                                                        loading={false}
                                                                        value={searchAggregateData}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="table-responsive">
                                                            <AggregatedDataTable metaData={metaData} aggregrateArray={aggregrateArray} sortData={sortData} search={searchAggregateData} test={test} clearSearch={()=>{setSearchAggregateData("");
                                                                        searchByAggregateData("")}}/>
                                                            </div>
                                                        </div>
                                                        <div className='by-user-table'  id="byuser-singlechoice">
                                                            <div className='free-question-table-header'>
                                                                <div className='free-question-table-header-left'>
                                                                    <Text type="h4" fontWeight="bold-font">By user</Text>
                                                                </div>
                                                                <div className='free-question-table-header-right'>
                                                                    <Search
                                                                        size={"small"}
                                                                        placeholder="Search in answers"
                                                                        onChange={(e) => searchByUserData(e.target.value)}
                                                                        onClear={() => {
                                                                            setTimeout(() => {
                                                                                setSearchByUser("");
                                                                                searchByUserData("")
                                                                            }, 0)
                                                                        }}
                                                                        loading={searchLoading}
                                                                        value={searchByUser}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div></div>
                                                            <div className="table-responsive">
                                                            <ByUserTable methodology={"preference-test"} tableClass={"preference-test-user-table"} metaData={metaData} answerArray={answerArray} sortData={sortData} hideParticipantModal={hideParticipantModal} reportAnswerModal={reportAnswerModal} search={searchByUser} test={test} loadMore={loadMore} loadMoreLoading={loadMoreLoading} clearSearch={()=>{setSearchByUser("");
                                                                        searchByUserData("")}}/>
                                                           </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                }
                                                {metaData.questionType === 'multiplechoice' &&
                                                    <div  id="chart-multiplechoice">
                                                    <div className='free-question-result-data test-result-inner-data' >
                                                        <div className='free-question-table-header'>
                                                            <div className='free-question-table-header-left'>
                                                                <Text type="h4" fontWeight="bold-font">Chart</Text>
                                                            </div>
                                                        </div>
                                                        <div className='chart-data' >
                                                            <div className="parti-ga-grapg-area">
                                                                <HorizontalBarChartStatsBoxMultipleChoice resultCount={metaData.totalResultCount} test={test} title={<span>{metaData.totalResultCount}{metaData.totalResultCount == 1 ? ' Answer' : ' Answers'}</span>} data={chartData} />
                                                            </div>
                                                        </div>
                                                        <div className='aggregated-data-table' id="aggregate-data-multiplechoice">
                                                            <div className='free-question-table-header'>
                                                                <div className='free-question-table-header-left' >
                                                                    <Text type="h4" fontWeight="bold-font">Aggregated data</Text>
                                                                </div>
                                                                <div className='free-question-table-header-right'>
                                                                    <Search
                                                                        size={"small"}
                                                                        placeholder="Search in answers"
                                                                        onChange={(e) => searchByAggregateData(e.target.value)}
                                                                        onClear={() => {
                                                                            setTimeout(() => {
                                                                                setSearchAggregateData("");
                                                                                searchByAggregateData("")
                                                                            }, 0)
                                                                        }}
                                                                        loading={false}
                                                                        value={searchAggregateData}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="table-responsive">
                                                            <AggregatedDataTable metaData={metaData} aggregrateArray={aggregrateArray} sortData={sortData} search={searchAggregateData} test={test} clearSearch={()=>{setSearchAggregateData("");
                                                                        searchByAggregateData("")}}/>
                                                           </div>
                                                        </div>
                                                        <div  id="byuser-multiplechoice" style={{display:"flex", width:"100%"}}>
                                                            <div className='by-user-table' style={{width:"100%"}} >
                                                                <div className='free-question-table-header'>
                                                                    <div className='free-question-table-header-left'>
                                                                        <Text type="h4" fontWeight="bold-font">By user</Text>
                                                                    </div>
                                                                    <div className='free-question-table-header-right'>
                                                                        <Search
                                                                            size={"small"}
                                                                            placeholder="Search in answers"
                                                                            onChange={(e) => searchByUserData(e.target.value)}
                                                                            onClear={() => {
                                                                                setTimeout(() => {
                                                                                    setSearchByUser("");
                                                                                    searchByUserData("")
                                                                                }, 0)
                                                                            }}
                                                                            loading={searchLoading}
                                                                            value={searchByUser}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div ></div>
                                                                <div className="table-responsive">
                                                                <ByUserTable methodology={"preference-test"} tableClass={"preference-test-user-table"} metaData={metaData} answerArray={answerArray} sortData={sortData} hideParticipantModal={hideParticipantModal} reportAnswerModal={reportAnswerModal} search={searchByUser} test={test} loadMore={loadMore} loadMoreLoading={loadMoreLoading} clearSearch={()=>{setSearchByUser("");
                                                                        searchByUserData("")}}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                }
                                                {metaData.questionType === 'likertscale' &&
                                                    <div  id="chart-likertscale">
                                                    <div className='free-question-result-data test-result-inner-data'>
                                                        <div className='free-question-table-header'>
                                                            <div className='free-question-table-header-left'>
                                                                <Text type="h4" fontWeight="bold-font">Chart</Text>
                                                            </div>
                                                        </div>
                                                        <div className='chart-data' >
                                                            <LikertScaleChart data={matrixArray} likertQuestion={likertQuestion} test={test} />
                                                        </div>
                                                        <div className='aggregated-data-table' id="aggregate-data-likertscale">
                                                            <div className='free-question-table-header'>
                                                                <div className='free-question-table-header-left'>
                                                                    <Text type="h4" fontWeight="bold-font">Aggregated data</Text>
                                                                </div>
                                                            </div>
                                                            <div className={`likert-area-chart ${test.language == "ar" ? "arabic-font" : ""}`}>
                                                                <LikertScaleAggregateData data={matrixArray} likertQuestion={likertQuestion} />
                                                            </div>
                                                        </div>
                                                        <div  id="byuser-likertscale" style={{display:"flex", width:"100%"}}>
                                                            <div className='by-user-table' style={{width:"100%"}}>
                                                                <div className='free-question-table-header'>
                                                                    <div className='free-question-table-header-left'>
                                                                        <Text type="h4" fontWeight="bold-font">By user</Text>
                                                                    </div>
                                                                </div>
                                                                <div></div>
                                                                <LikertScaleByUser methodology={"preference-test"} tableClass={"preference-test-user-table"} likertQuestion={likertQuestion} metaData={metaData} test_id={test_id} test={test} answerArray={answerArray} hideParticipantModal={hideParticipantModal} reportAnswerModal={reportAnswerModal} loadMore={loadMore} loadMoreLoading={loadMoreLoading} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                }
                                                {metaData.questionType == 'rankingscale' &&
                                                    <div id="chart-rankingscale">
                                                        <div className="test-result-inner-data parti-ga-grapg-area">
                                                            <div className="barchart-ranking-scale">
                                                                <HorizontalBarChartStatsBoxRankingScale test={test} rankingchartData={rankingchartData} />
                                                            </div>
                                                            <div className="ranking-scale-table mt-40">
                                                                {renderRankingQuestionTable()}
                                                            </div>
                                                            <div className="ranking-page-infodata-wrap">
                                                                <h3>How to read this bar chart?</h3>
                                                                <p>The bar chart is based on the score each ranked option has received. The scores represent the average ranking.</p>
                                                                <h3>How the scores are calculated?</h3>
                                                                <p>Think of the score as a weighted average. Suppose you have 5 options for testers to rank. The testers’ most preferred option (ranked as #1 most of the times) is given the largest weight (in this case 5). Their least preferred option has a weight of 1. The weights are then multiplied by the numbers of testers selected a particular option.
                                                                    Their sum is divided by the total number of testers.</p>
                                                                <div className="response-text-wrap">
                                                                    <p>X<sub>1</sub> W<sub>1</sub> + X<sub>2</sub> W<sub>2</sub>
                                                                        + X<sub>3</sub> W<sub>3</sub> + ... + X<sub>n</sub> W<sub>n</sub></p>
                                                                    <span>Total responses</span>
                                                                </div>
                                                                <div className="ranking-wrap-info-bottom">
                                                                    Where:
                                                                    <p>X - number of testers</p>
                                                                    <p>W - weight of the ranked option</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                }
                                                {metaData.questionType === 'ratingscale' &&
                                                <div id="chart-ratingscale">
                                                    <div className='free-question-result-data test-result-inner-data'>
                                                        <div className='free-question-table-header' id="chart-ratingscale">
                                                            <div className='free-question-table-header-left'>
                                                                <Text type="h4" fontWeight="bold-font">Chart</Text>
                                                            </div>
                                                        </div>
                                                        <div className='chart-data'>
                                                            {matrixArray && Object.keys(likertQuestion).length !== 0 &&
                                                            <>
                                                            
                                                            <RatingScaleChart 
                                                                test={test} 
                                                                data={matrixArray} 
                                                                question={{"type":likertQuestion.scale_type=="Emotions"?"emoticons":likertQuestion.scale_type,"scale":likertQuestion.scale_length.match(/\d+/)[0], labels: likertQuestion.labels}} 
                                                                predictions={predictions}
                                                            />
                                                            </>
                                                            }
                                                            
                                                        </div>
                                                        
                                                        <div className='by-user-table'>
                                                            <div className='free-question-table-header'>
                                                                <div className='free-question-table-header-left'>
                                                                    <Text type="h4" fontWeight="bold-font">By user</Text>
                                                                </div>
                                                            </div>
                                                            <div className='free-question-table-header'>
                                                                <div className='free-question-table-header-left'>
                                                                    <Text cssClasses={"gray-color"} type="body-text-3" fontWeight="medium-font">{metaData.totalResultCount} answer{metaData.totalResultCount>1?'s':''}</Text>
                                                                </div>

                                                                <div className='free-question-table-header-right'>
                                                                    {Object.keys(likertQuestion).length !== 0 &&
                                                                    <RatingMultiSelect onChange={(rating)=>{

                                                                            searchByRating(rating);
                                                                        }} 
                                                                        value={rating}
                                                                        type={
                                                                            function(){
                                                                                var scale = {"Stars":"stars","Emotions":"emoticons","Numeric":"numeric"};
                                                                                
                                                                                return scale[likertQuestion.scale_type];
                                                                                
                                                                            }()} 
                                                                        scale={likertQuestion.scale_length.match(/\d+/)[0]} />
                                                                      }
                                                                    {/*<Search
                                                                        label="Search"
                                                                        onChange={(e) => searchByUserData(e.target.value)}
                                                                        onClear={() => {
                                                                            setTimeout(() => {
                                                                                setSearchByUser("");
                                                                                searchByUserData("")
                                                                            }, 0)
                                                                        }}
                                                                        loading={searchLoading}
                                                                        value={searchByUser}
                                                                    />*/}
                                                                </div>
                                                            </div>
                                                            <div id="byuser-ratingscale"></div>
                                                            {Object.keys(likertQuestion).length !== 0 &&
                                                                <ByUserTable 
                                                                    methodology={"preference-test"}
                                                                    tableClass={"preference-test-user-table"} 
                                                                    metaData={metaData} 
                                                                    answerArray={answerArray} 
                                                                    sortData={sortData} 
                                                                    hideParticipantModal={hideParticipantModal} 
                                                                    reportAnswerModal={reportAnswerModal} 
                                                                    search={search} 
                                                                    test={test} 
                                                                    loadMore={loadMore} 
                                                                    loadMoreLoading={loadMoreLoading} 
                                                                    clearSearch={()=>{
                                                                        setRating([]);
                                                                        searchByRating([]);
                                                                    }}
                                                                    question={{"type":likertQuestion.scale_type=="Emotions"?"emoticons":likertQuestion.scale_type,"scale":likertQuestion.scale_length.match(/\d+/)[0]}} 
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                }

                                                <div className="bd-question-bottom-pagination-arrow prev-next-button-bottom">
                                                    <div className="bd-que-slide-arrow tablebottompagination">
                                                        <Button
                                                            type={"secondary"}
                                                            microLoading={false}
                                                            label={"Previous question"}
                                                            size={"medium"}
                                                            onClick={(e) => {
                                                                e.preventDefault(); if (isActive > 1) {
                                                                    changeActiveQuestion(isActive - 1, questionArray)
                                                                }
                                                                var fixedElementHeight =  document.querySelector('.researcher-header').offsetHeight +  document.querySelector('.test-results-header-researcher').offsetHeight ;
                
                                                                            var offsetTop = Position(document.getElementById("followup-results"));
                                                                            window.scrollTo({top:offsetTop-fixedElementHeight, behavior:"smooth"});
                                                            }}
                                                            iconLeft={
                                                                <Icon value={"back arrow"} size={"medium"} />
                                                            }
                                                            state={`${isActive === 1 ? 'disabled' : ''}`}
                                                        />
                                                        <Button
                                                            type={"secondary"}
                                                            label={"Next question"}
                                                            size={"medium"}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (isActive < questionArray.length) { changeActiveQuestion(isActive + 1, questionArray) }
                                                                var fixedElementHeight =  document.querySelector('.researcher-header').offsetHeight +  document.querySelector('.test-results-header-researcher').offsetHeight ;
                
                                                                            var offsetTop = Position(document.getElementById("followup-results"));
                                                                            window.scrollTo({top:offsetTop-fixedElementHeight, behavior:"smooth"});
                                                            }}
                                                            iconRight={
                                                                <Icon value={"forward-arrow"} size={"medium"} />
                                                            }
                                                            state={`${isActive === questionArray.length ? 'disabled' : ''}`}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                        }

                                    </div>
                                </div>
                            }

                            {!sectionLoading && questionArray && questionArray.length === 0 && resultCount > 0 &&
                                <div className={`no-participants-data no-questions-data ${dropdownOpen ? "black-overlay" : ""}`}>
                                    <div className="no-page-data-wrap" style={{ marginTop: "80px" }}>
                                        <div className="no-data-icon">
                                            <Icon colorClass={"gray-700-svg"} value={"attention"} size={"large"} />
                                        </div>

                                        <Text type={"subtitle-1"} fontWeight="medium-font">You have not added any questions to your test.</Text>
                                    </div>
                                </div>
                            }
                            {!sectionLoading && resultCount === 0 && false &&
                                <div className={`no-participants-data no-questions-data ${dropdownOpen ? "black-overlay" : ""}`}>
                                    <div className="no-page-data-wrap" style={{ marginTop: "80px" }}>
                                        <div className="no-data-icon">
                                            <Icon colorClass={"gray-700-svg"} value={"attention"} size={"large"} />
                                        </div>

                                        <Text type={"subtitle-1"} fontWeight="medium-font">Details are not available at the moment as the test didn’t receive any response yet. Please come back later.</Text>
                                    </div>
                                </div>
                            }
                        </div>


                    </div>
                    <HideParticipant
                        test_id={test_id}
                        openModal={openDeleteAnswerModal}
                        closeModal={closeDeleteAnswerModal} />
                    <ReportAnswer
                        test_id={test_id}
                        openModal={openReportAnswerModal}
                        closeModal={closeReportAnswerModal} />
                        {/*active: true, color: item.color, img: process.env.REACT_APP_IMG_URL + item.design_file, type: item.type, title: item.title*/}
                    <Preview 
                        open={openDesignModal.active} 
                        img={openDesignModal.img}
                        file_type={openDesignModal.type}
                        background={openDesignModal.color}
                        close={()=>{
                            setOpenDesignModal({...openDesignModal, active: false});
                        }}
                    />
                    {/*<DesignTest
                        openModal={openDesignModal}
                    closeModal={() => { setOpenDesignModal({ active: false, img: null }) }} />*/}
                </div>
            )}
            {!isSharedLink &&
            <a onClick={(e)=>{
                e.preventDefault();

                setShowSupportPanel(true);

            }}  href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'>
                <Icon
                    value={"support"}
                    colorClass={"gray-50-svg"}
                    size={"medium"}
                />
                Support
            </a>
            }
        </LayoutResearcher>
    );
}
