import { useEffect, useRef, useState } from "react";
import "./SelectCreateDropdownList.css";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";
import Skeleton from "react-loading-skeleton";

export const SelectCreateDropdownList = ({
    label,
    error,
    options,
    readOnly,
    onChange,
    onCreateOption,
    loading,
    value,
    className,
    rtl
}) => {
    const [selected, setSelected] = useState(null);

    const [showDropdown, setShowDropDown] = useState(false);

    const [searchEnabled, setSearchEnabled] = useState(false);

    const [search, setSearch] = useState("");

    const searchRef = useRef(null);

    const ref = useRef(null);

    useEffect(() => {
        if (value) {
            setSelected(value);
        }
    }, [value, options]);

    useEffect(() => {
        if (!loading) {
            options.forEach((item) => {
                if (item.value === selected) {
                    setSearch("");
                    setSearchEnabled(false);
                    setShowDropDown(false);
                }
            });
        }

        //
    }, [loading]);

    useEffect(() => {
        if (searchEnabled && searchRef && searchRef.current) {
            searchRef.current.focus();
        }
    }, [searchEnabled]);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (
                ref.current &&
                !ref.current.contains(event.target) &&
                searchRef.current &&
                !searchRef.current.contains(event.target)
            ) {
                setShowDropDown(false);
                setSearchEnabled(false);
                setSearch("");
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    const renderSelectBox = () => {
        return (
            <div
                className={`members-multiselect-dropdown-inner  ${
                    rtl == true ? "arabic_wrapper" : ""
                } `}>
                <div
                    className={`multiselectlist-dropdown-box-container ${
                        selected ? "project-selected" : ""
                    } ${searchEnabled ? "input-active" : ""}`}
                    onClick={(e) => {
                        e.preventDefault();
                        setShowDropDown(!showDropdown);

                        setSearchEnabled(true);
                    }}
                >
                    <div className="d-flex align-items-center select-member-dropdown">
                        {loading ? (
                            <>
                                <Skeleton
                                    width={24}
                                    height={24}
                                    style={{
                                        borderRadius: "110px",
                                        marginTop: "9px",
                                    }}
                                />
                            </>
                        ) : (
                            <Icon
                                value={"folder"}
                                size={"medium"}
                                hover={"true"}
                                colorClass={
                                    showDropdown || value
                                        ? "gray-900-svg"
                                        : "gray-600-svg"
                                }
                            />
                        )}

                        {searchEnabled ? (
                            <input
                                ref={searchRef}
                                type={"text"}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                                onClick={(e) => {
                                    setShowDropDown(true);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                      
                                        let filteredOptions = search
                                            ? options.filter(function (option) {
                                                var rgxp = new RegExp(search.trim().toLowerCase(), "g");
                                                var searchTitle = option.label.trim().toLowerCase();
                                                if (
                                                    searchTitle.match(rgxp) &&
                                                    searchTitle.match(rgxp).length > 0
                                                ) {
                                                    return option;
                                                }
                                            })
                                            : options;

                                        if(filteredOptions.length ==0){
                                            event.preventDefault();
                                            
                                            onCreateOption(search);
                                        }
                                    }
                                }}
                            />
                        ) : selected && selected != null ? (
                            <Text
                                type={"body-text-2"}
                                fontWeight={"normal-font"}
                            >
                                {options.map((item) => {
                                    if (item.value == selected) {
                                        return (
                                            <div key={"selected-option"+item.value} className="selected-project-item">
                                                <span className="selected-project-name">
                                                    {item.label}{" "}
                                                </span>
                                                <span className="selected-project-member">
                                                    / {item.sublabel}
                                                </span>
                                            </div>
                                        );
                                    }
                                })}
                            </Text>
                        ) : (
                            <Text
                                type={"body-text-2"}
                                fontWeight={"normal-font"}
                            >
                                {label}
                            </Text>
                        )}
                        <Text type={"caption"} cssClasses={"project-label"}>
                            Project folder name
                        </Text>
                    </div>
                    <div
                        className={`multiselectlist-dropdown-dropdown-icon-container`}
                    >
                        <Icon
                            colorClass={"gray-900-svg"}
                            value={showDropdown ? "Chevron up" : "Chevron"}
                            hover={true}
                        />
                    </div>
                </div>
            </div>
        );
    };
    const renderDropDown = () => {
        if (showDropdown) {
            let filteredOptions = search
                ? options.filter(function (option) {
                      var rgxp = new RegExp(search.trim().toLowerCase(), "g");
                      var searchTitle = option.label.trim().toLowerCase();
                      if (
                          searchTitle.match(rgxp) &&
                          searchTitle.match(rgxp).length > 0
                      ) {
                          return option;
                      }
                  })
                : options;

            return (
                <div
                    className={`multiselectlist-dropdown-options ${
                        rtl == true ? "arabic_wrapper" : ""
                    }`}
                    style={{
                        position: "absolute",
                        background: "#fff",
                        width: "200px",
                    }}
                >
                    <>
                        {filteredOptions.length == 0 && (
                            <div
                                key={"create-select-option"}
                                className={`multiselectlist-dropdown-option cursor-pointer active-option} `}
                                onClick={(e) => {

                                    
                                    e.preventDefault();
                                    onCreateOption(search);
                                }}
                            >
                                Create {search.length >0 &&
                                    <>"<span>{search}</span>"</>
                                } 
                            </div>
                        )}
                    </>
                    {filteredOptions.map((option, index) => {
                        return (
                            <div
                                key={"select-option" + index}
                                className={`multiselectlist-dropdown-option ${
                                    option.value == selected
                                        ? "disabled-option"
                                        : "active-option"
                                } `}
                                onClick={(e) => {
                                    if (selected !== option.value) {
                                        setSelected(option.value);

                                        setSearchEnabled(false);

                                        setSearch("");

                                        setShowDropDown(false);

                                        if (onChange) {
                                            onChange(option.value);
                                        }
                                    }
                                }}
                            >
                                {option.label}{" "}
                                <span className="selected-project-member">
                                    / {option.sublabel}
                                </span>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return <></>;
        }
    };
    return (
        <div
            ref={ref}
            className={`multiselectlist-dropdown-box select-create-dropdown position-relative ${
                error ? "field-error" : ""
            } ${className ? className : ""} ${
                readOnly ? "multiselectlist-dropdown-readonly" : ""
            }`}
        >
            {renderSelectBox()}
            {renderDropDown()}
            {error != null && <span className="caption">{error}</span>}
        </div>
    );
};
