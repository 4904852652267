import React, { useCallback } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { LayoutResearcher } from "../../themes/userq/Layouts/layout_researcher";
import { useState, useEffect, useRef } from "react";
import {
    getResearcherTestResultData,
    getTestCardSortingRCategoryesultData,
    getTestCardSortingCardsResultData,
    getTestCardSortingMatrixResultData,
    getTestCardSortingSimilarityMatrixResultData,
    getTestCardSortingByUserResultData,
    getTestCardSortingByUserLoadMoreResultData,
    getLoadMoreQuestionData,
} from "../../../services/researcher_test_results";
import toast from "react-hot-toast";
import Topbar from "./components/topbar";
import Sidebar from "./components/sidebar";
import { getCurrentPlansService } from "../../../services/plans";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import { atom, useRecoilState } from "recoil";
import moment from "moment";
import AnyChart from "anychart-react";
import { LoadingIcon } from "../../loader/loadingIcon";
import { Dropdown } from "react-bootstrap";
import exportAsImage from "../../../lib/exportAsImage";
import { downloadBlob, showToast } from "../../../lib/helpers";
import { TestResultsHeader } from "../../themes/userq/TestResultsHeader/TestResultsHeader";
import { TestResultsSidebarNavigation } from "./components/test_results_sidebar_navigation";
import { Text } from "../../themes/userq/Text/Text";
import { Icon } from "../../themes/userq/Icon/Icon";
import { Search } from "../../themes/userq/Search/Search";
import { ByUserTable } from "../../themes/userq/ByUserTable/ByUserTable";
import { Button } from "../../themes/userq/Button/Button";
import { Helmet } from "react-helmet";
import ReportTesterModal from "../../themes/userq/Modal/report_tester";
import Skeleton from "react-loading-skeleton";

export default function TestResultCardSorting({ isSharedLink }) {
    const scrollContainerRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const exportSimilarityRef = useRef();
    const exportResultGridRef = useRef();

    const dataCardFetchedRef = useRef(false);
    const dataCategoryFetchedRef = useRef(false);
    const dataUserFetchedRef = useRef(false);

    const [resultCount, SetResultCount] = useState(0);

    const testState = atom({
        key: "testresult-" + test_id,
        default: "",
    });
    const planState = atom({
        key: "testresultplan-" + test_id,
        default: "",
    });

    const [isLoading, setLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);

    const [byUserMetaData, setByUserMetaData] = useState({
        totalResultCount: 0,
        sort_time: "",
        sort_date: "",
        sort_category: "",
        load_more: "",
        page: 1,
    });
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [sortLoading, setSortLoading] = useState(false);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [reportTesterModal, setReportTesterModal] = useState({
        open: false,
        test_result_id: "",
    });

    const [categoryArray, setCategoryArray] = useState([]);
    const [byUserArray, setByUserArray] = useState([]);
    const [cardArray, setCardArray] = useState([]);
    const [matrixArray, setMatrixArray] = useState([]);
    const [similarityMatrixArray, setSimilarityMatrixArray] = useState([]);
    const [metaData, setMetaData] = useState({
        active: "category",
        sorting_type: "open",
        cardSort: "",
        categorySort: "",
        categoryLoadMore: false,
        cardLoadMore: false,
        categoryPage: 1,
        cardPage: 1,
    });
    const [byCategoryMetaData, setByCategoryMetaData] = useState({
        loadMore: false,
        page: 1,
    });
    const [byCardMetaData, setByCardMetaData] = useState({
        loadMore: false,
        page: 1,
    });
    const [byUserDataLoading, setByUserDataLoading] = useState(false);

    const [expandData, setExpandData] = useState(false);
    const [expandSimilarityData, setExpandSimilarityData] = useState(false);
    const [show_support_panel, setShowSupportPanel] = useState(false);

    const projectState = atom({
        key: "testresultproject-" + test_id,
        default: null,
    });
    const [project, setProject] = useRecoilState(projectState);

    const handleScroll = () => {
        // Check the scroll position or any other logic you need
        const scrolled = scrollContainerRef.current.scrollTop > 0;

        // Update state based on the scroll position
        setIsScrolled(scrolled);
    };
    useEffect(() => {
        setIsScrolled(false);
        // Attach scroll event listener to the referenced element
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);

            const scrolled = scrollContainerRef.current.scrollTop > 0;

            setIsScrolled(scrolled);

            // Remove the event listener when the component unmounts
            return () => {
                scrollContainer.removeEventListener("scroll", handleScroll);
            };
        }
    }, [metaData, cardArray, byUserArray, categoryArray]);

    const getTestafterEndTest = () => {
        let token = ReactSession.get("token");

        let guest_result_token = '';

		if(location.pathname.substr(1, 1) === 'v'){

			// create guest_result_token
			if (!ReactSession.get("guest_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("guest_result_token", randomToken);
			}

			guest_result_token = ReactSession.get("guest_result_token");
		}
		let admin_result_token = '';

		if(location.pathname.substr(1, 1) === 'a'){

			// create guest_result_token
			if (!ReactSession.get("admin_result_token")) {
				const randomToken = Math.random().toString(36).substr(2, 15);
				ReactSession.set("admin_result_token", randomToken);
			}

			admin_result_token = ReactSession.get("admin_result_token");
		}

        getResearcherTestResultData(
            { test_id: test_id, guest_result_token: guest_result_token, admin_result_token: admin_result_token},
            token
        ).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
            } else {
                showToast(response.message, "error");
            }
        });
    };

    const getTest = () => {
        let token = ReactSession.get("token");

        setSectionLoading(true);

        let guest_result_token = "";
        if (location.pathname.substr(1, 1) === "v") {
            // create guest_result_token
            if (!ReactSession.get("guest_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("guest_result_token", randomToken);
            }

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = "";
        if (location.pathname.substr(1, 1) === "a") {
            // create guest_result_token
            if (!ReactSession.get("admin_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("admin_result_token", randomToken);
            }

            admin_result_token = ReactSession.get("admin_result_token");
        }

        getResearcherTestResultData(
            {
                test_id: test_id,
                guest_result_token: guest_result_token,
                admin_result_token: admin_result_token,
            },
            token
        ).then((response) => {
            setSectionLoading(false);

            if (response.success) {
                if (response.workspace_project_team_member) {
                    if (
                        response.workspace_project_team_member.status !=
                        "active"
                    ) {
                        navigate(
                            "/wsp/" +
                                response.workspace_project_team_member
                                    .workspace_id +
                                "/p/" +
                                response.workspace_project_team_member
                                    .workspace_project_id +
                                "/project-no-access"
                        );
                    }
                }
                if (response.test && response.test.workspace_project) {
                    setProject(response.test.workspace_project);
                }
                // check test is available for sharing
                if (
                    location.pathname.substr(1, 1) === "v" &&
                    response.is_share_result
                ) {
                    navigate(`/v/results/${test_id}/results-not-available`);
                }

                // check if guest user not validate there password
                if (
                    location.pathname.substr(1, 1) === "v" &&
                    response.test.password &&
                    !response.test_password_verify
                ) {
                    navigate(`/v/results/${test_id}/password`);
                }

                setTest(response.test);
                getCardSorting();
            } else {
                if (response.message == "Project not found.") {
                    navigate("/404");
                } else if (response.message == "Test Record Not found") {
                    navigate("/404");
                } else {
                    navigate("/dashboard");
                    showToast(response.message, "error");
                }
            }
        });
    };

    const getCardSorting = () => {
        let token = ReactSession.get("token");

        setSectionLoading(true);

        let guest_result_token = "";
        if (location.pathname.substr(1, 1) === "v") {
            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = "";
        if (location.pathname.substr(1, 1) === "a") {
            admin_result_token = ReactSession.get("admin_result_token");
        }

        const controller = new AbortController();

        dataCategoryFetchedRef.current = controller;

        getTestCardSortingRCategoryesultData(
            {
                test_id: test_id,
                guest_result_token: guest_result_token,
                admin_result_token: admin_result_token,
                page: metaData.categoryPage,
            },
            token,
            dataCategoryFetchedRef.current?.signal
        ).then((response) => {
            setSectionLoading(false);

            if (response.success) {
                SetResultCount(response.resultcount);

                if (response.resultcount > 0) {
                    setByCategoryMetaData({
                        loadMore: response.more_records === true ? true : false,
                        page: response.more_records === true ? 2 : 1,
                    });

                    if (response.more_records === true) {
                        loadMoreCategoryData(
                            byCategoryMetaData.page + 1,
                            response.category
                        );
                    }
                }

                setMetaData({
                    ...metaData,
                    sorting_type:
                        response.test.card_sorting.card_sorting || "open",
                });

                setCategoryArray(response.category);
            } else {
                showToast(response.message, "error");
            }
        });
    };

    const loadMoreCategoryData = (currentPage, categoryData) => {
        let token = ReactSession.get("token");

        let guest_result_token = "";
        if (location.pathname.substr(1, 1) === "v") {
            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = "";
        if (location.pathname.substr(1, 1) === "a") {
            admin_result_token = ReactSession.get("admin_result_token");
        }

        const controller = new AbortController();

        dataCategoryFetchedRef.current = controller;

        getTestCardSortingRCategoryesultData(
            {
                test_id: test_id,
                guest_result_token: guest_result_token,
                admin_result_token: admin_result_token,
                page: currentPage,
            },
            token,
            dataCategoryFetchedRef.current?.signal
        ).then((response) => {
            if (response.success) {
                setByCategoryMetaData({
                    loadMore: response.more_records === true ? true : false,
                    page:
                        response.more_records === true
                            ? byCategoryMetaData.page + 1
                            : byCategoryMetaData.page,
                });

                if (response.more_records === true) {
                    var newCategoryData = categoryData.concat(
                        response.category
                    );

                    loadMoreCategoryData(currentPage + 1, newCategoryData);
                }

                // while loading if user click sort then show skeleton
                if (
                    ReactSession.get("categorysortEnable") &&
                    response.more_records === true
                ) {
                    setCategoryArray([]);
                } else {
                    // data check availble for sorting
                    if (ReactSession.get("categorysortEnable")) {
                        var sortedDataCategory = categoryData.concat(
                            response.category
                        );

                        // asc sorting
                        if (ReactSession.get("categorysortEnable") === "asc") {
                            setCategoryArray(
                                sortedDataCategory.sort((a, b) =>
                                    a.total_cards > b.total_cards ? 1 : -1
                                )
                            );
                        } else {
                            setCategoryArray(
                                sortedDataCategory.sort((a, b) =>
                                    a.total_cards < b.total_cards ? 1 : -1
                                )
                            );
                        }
                    } else {
                        setCategoryArray(
                            categoryData.concat(response.category)
                        );
                    }
                }
            } else {
                showToast(response.message, "error");
            }
        });
    };

    // get by user data
    const getByUserData = () => {
        let token = ReactSession.get("token");

        setByUserDataLoading(true);

        let guest_result_token = "";
        if (location.pathname.substr(1, 1) === "v") {
            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = "";
        if (location.pathname.substr(1, 1) === "a") {
            admin_result_token = ReactSession.get("admin_result_token");
        }

        const controller = new AbortController();

        dataUserFetchedRef.current = controller;

        getTestCardSortingByUserResultData(
            {
                test_id: test_id,
                guest_result_token: guest_result_token,
                admin_result_token: admin_result_token,
            },
            token,
            dataUserFetchedRef.current?.signal
        ).then((response) => {
            setByUserDataLoading(false);

            if (response.success) {
                //console.log(response.by_user);

                var arrayData = [];
                var iterator = 0;
                response.by_user.forEach((items, index) => {
                    var userArray = [];

                    items.relation_data.forEach(
                        (relationData, indexRelation) => {
                            arrayData[iterator] = { ...items };
                            arrayData[iterator]["relation"] = Object.assign(
                                {},
                                items.relation_data[indexRelation]
                            );
                            arrayData[iterator]["new"] = indexRelation
                                ? false
                                : true;
                            arrayData[iterator]["is_more_category"] =
                                items.relation_category_data_count > 3 &&
                                indexRelation + 1 === 3
                                    ? true
                                    : false;

                            arrayData[iterator]["is_less_category"] =
                                items.relation_category_data_count ===
                                    indexRelation + 1 && indexRelation + 1 > 3
                                    ? true
                                    : false;
                            arrayData[iterator]["hide_row"] =
                                indexRelation + 1 > 3 ? true : false;

                            iterator++;
                        }
                    );
                    //relation_data
                });

                //  console.log(arrayData)

                //setByUserArray(response.by_user);
                setByUserArray(arrayData);

                setByUserMetaData({
                    ...byUserMetaData,
                    totalResultCount: response.resultcount,
                    sort_time: "",
                    sort_date: "",
                    sort_category: "",
                    load_more: response.resultcount > 10 ? true : false,
                    page: 1,
                });
            } else {
                showToast(response.message, "error");
            }
        });
    };

    const ght = (data, item) => {
        return (data.abbx = item);
    };

    const getCardSortingCards = () => {
        let token = ReactSession.get("token");

        //setDataLoading(true);
        setByCardMetaData({
            ...byCardMetaData,
            loadMore: true,
        });

        let guest_result_token = "";
        if (location.pathname.substr(1, 1) === "v") {
            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = "";
        if (location.pathname.substr(1, 1) === "a") {
            admin_result_token = ReactSession.get("admin_result_token");
        }

        const controller = new AbortController();

        dataCardFetchedRef.current = controller;

        getTestCardSortingCardsResultData(
            {
                test_id: test_id,
                guest_result_token: guest_result_token,
                admin_result_token: admin_result_token,
                page: metaData.cardPage,
            },
            token,
            dataCardFetchedRef.current?.signal
        ).then((response) => {
            //setDataLoading(false);

            if (response.success) {
                setCardArray(response.cards);

                setByCardMetaData({
                    loadMore: response.more_records === true ? true : false,
                    page: response.more_records === true ? 2 : 1,
                });

                if (response.more_records === true) {
                    loadMoreCardData(byCardMetaData.page + 1, response.cards);
                }
            } else {
                showToast(response.message, "error");
            }
        });
    };

    const loadMoreCardData = (currentPage, cardData) => {
        let token = ReactSession.get("token");

        let guest_result_token = "";
        if (location.pathname.substr(1, 1) === "v") {
            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = "";
        if (location.pathname.substr(1, 1) === "a") {
            admin_result_token = ReactSession.get("admin_result_token");
        }

        const controller = new AbortController();

        dataCardFetchedRef.current = controller;

        getTestCardSortingCardsResultData(
            {
                test_id: test_id,
                guest_result_token: guest_result_token,
                admin_result_token: admin_result_token,
                page: currentPage,
            },
            token,
            dataCardFetchedRef.current?.signal
        ).then((response) => {
            if (response.success) {
                setByCardMetaData({
                    loadMore: response.more_records === true ? true : false,
                    page:
                        response.more_records === true
                            ? byCardMetaData.page + 1
                            : byCardMetaData.page,
                });

                if (response.more_records === true) {
                    var newCardData = cardData.concat(response.cards);

                    loadMoreCardData(currentPage + 1, newCardData);
                }

                // while loading if user click sort then show skeleton
                if (
                    ReactSession.get("cardsortEnable") &&
                    response.more_records === true
                ) {
                    setCardArray([]);
                } else {
                    // data check availble for sorting
                    if (ReactSession.get("cardsortEnable")) {
                        var sortedDataCard = cardData.concat(response.cards);

                        // asc sorting
                        if (ReactSession.get("cardsortEnable") === "asc") {
                            setCardArray(
                                sortedDataCard.sort((a, b) =>
                                    a.total_category > b.total_category ? 1 : -1
                                )
                            );
                        } else {
                            setCardArray(
                                sortedDataCard.sort((a, b) =>
                                    a.total_category < b.total_category ? 1 : -1
                                )
                            );
                        }
                    } else {
                        setCardArray(cardData.concat(response.cards));
                    }
                }
            } else {
                showToast(response.message, "error");
            }
        });
    };

    useEffect(() => {
        getTest();

        // set sortng false
        ReactSession.set("cardsortEnable", false);
        ReactSession.set("categorysortEnable", false);
    }, []);

    useEffect(() => {
        return () => clearApiRequest();
    }, [location.pathname]);

    const clearApiRequest = () => {
        // cancel api call when page changed
        if (dataCardFetchedRef.current) {
            dataCardFetchedRef.current.abort();
        }

        if (dataCategoryFetchedRef.current) {
            dataCategoryFetchedRef.current.abort();
        }

        if (dataUserFetchedRef.current) {
            dataUserFetchedRef.current.abort();
        }
    };

    const callbackTopbar = (data) => {
        getTest();
        getTestafterEndTest();
    };

    const changeType = (type) => {
        setMetaData({ ...metaData, active: type });
        //setMetaData({ ...metaData, 'active': type, cardSort: "", categorySort: "" })

        //console.log(type)
        if (type === "card") {
            if (cardArray.length === 0) {
                getCardSortingCards();
            }
        } else if (type === "user") {
            if (byUserArray.length === 0) {
                getByUserData();
            }
        }
    };

    const sortData = (type) => {
        if (type === "card") {
            if (metaData.cardSort === "asc") {
                setCardArray(
                    cardArray.sort((a, b) =>
                        a.total_category < b.total_category ? 1 : -1
                    )
                );
                setMetaData({ ...metaData, cardSort: "desc" });
                ReactSession.set("cardsortEnable", "desc");
            } else {
                setCardArray(
                    cardArray.sort((a, b) =>
                        a.total_category > b.total_category ? 1 : -1
                    )
                );
                setMetaData({ ...metaData, cardSort: "asc" });
                ReactSession.set("cardsortEnable", "asc");
            }
        } else if (type === "category") {
            if (metaData.categorySort === "asc") {
                setCategoryArray(
                    categoryArray.sort((a, b) =>
                        a.total_cards < b.total_cards ? 1 : -1
                    )
                );
                setMetaData({ ...metaData, categorySort: "desc" });
                ReactSession.set("categorysortEnable", "desc");
            } else {
                setCategoryArray(
                    categoryArray.sort((a, b) =>
                        a.total_cards > b.total_cards ? 1 : -1
                    )
                );
                setMetaData({ ...metaData, categorySort: "asc" });
                ReactSession.set("categorysortEnable", "asc");
            }
        }
    };

    const showMoreOption = (index, totalCount) => {
        var oldCategoryArray = categoryArray;
        oldCategoryArray[index].results_count = totalCount;

        var catData = [];
        oldCategoryArray.forEach((items) => {
            catData.push(items);
        });

        setCategoryArray(catData);
    };

    const showMoreOptionByUser = (index, totalCount) => {
        var oldArray = byUserArray;
        oldArray[index].relation.total_card_count_extra = totalCount;

        var catData = [];
        oldArray.forEach((items) => {
            catData.push(items);
        });

        setByUserArray(catData);
    };

    const showMoreOptionByUserCategory = (index, id, type) => {
        var oldArray = byUserArray;

        if (type === "more") {
            var allDataArray = [];
            oldArray.forEach((item) => {
                if (item.id === id) {
                    var checkHideRow = item.hide_row;
                    if (checkHideRow) {
                        item.hide_row = false;
                        item.hide_row_extra = true;
                    }

                    var checkMoreCategory = item.is_more_category;
                    if (checkMoreCategory) {
                        item.is_more_category = false;
                        item.is_more_categoryextra = true;
                    }

                    allDataArray.push(item);
                } else {
                    allDataArray.push(item);
                }
            });
        } else {
            var allDataArray = [];
            oldArray.forEach((item) => {
                if (item.id === id) {
                    item.hide_row = false;

                    var checkHideRow = item.hide_row_extra;
                    if (checkHideRow) {
                        item.hide_row = true;
                        item.hide_row_extra = false;
                    }

                    var checkMoreCategory = item.is_more_categoryextra;
                    if (checkMoreCategory) {
                        item.is_more_category = true;
                        item.is_more_categoryextra = false;
                    }

                    allDataArray.push(item);
                } else {
                    allDataArray.push(item);
                }
            });
        }

        // console.log(allDataArray)

        setByUserArray(allDataArray);
    };

    const calculateTime = (time) => {
        var d = Math.floor(time / (3600 * 24));
        var h = Math.floor((time % (3600 * 24)) / 3600);
        var m = Math.floor((time % 3600) / 60);
        var s = Math.floor(time % 60);

        if (m > 0) {
            return `${m}min ${s}sec`;
        } else if (s > 0) {
            return ` ${s}sec`;
        } else {
            return "-";
        }
    };

    const loadMore = () => {
        setLoadMoreLoading(true);

        let guest_result_token = "";
        if (location.pathname.substr(1, 1) === "v") {
            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = "";
        if (location.pathname.substr(1, 1) === "a") {
            admin_result_token = ReactSession.get("admin_result_token");
        }

        const controller = new AbortController();

        dataUserFetchedRef.current = controller;

        let token = ReactSession.get("token");
        getTestCardSortingByUserLoadMoreResultData(
            {
                test_id: test_id,
                page: byUserMetaData.page + 1,
                sort_column: sortBy,
                order_by: sortOrder,
                guest_result_token: guest_result_token,
                admin_result_token: admin_result_token,
            },
            token,
            dataUserFetchedRef.current?.signal
        ).then((response) => {
            setLoadMoreLoading(false);

            if (response.success) {
                var arrayData = [];
                var iterator = 0;
                response.by_user.forEach((items, index) => {
                    var userArray = [];

                    items.relation_data.forEach(
                        (relationData, indexRelation) => {
                            arrayData[iterator] = { ...items };
                            arrayData[iterator]["relation"] = Object.assign(
                                {},
                                items.relation_data[indexRelation]
                            );
                            arrayData[iterator]["new"] = indexRelation
                                ? false
                                : true;
                            arrayData[iterator]["is_more_category"] =
                                items.relation_category_data_count > 3 &&
                                indexRelation + 1 === 3
                                    ? true
                                    : false;

                            arrayData[iterator]["is_less_category"] =
                                items.relation_category_data_count ===
                                    indexRelation + 1 && indexRelation + 1 > 3
                                    ? true
                                    : false;
                            arrayData[iterator]["hide_row"] =
                                indexRelation + 1 > 3 ? true : false;

                            iterator++;
                        }
                    );
                    //relation_data
                });

                console.log(arrayData);

                //setByUserArray(response.by_user);
                // setByUserArray(arrayData);

                setByUserArray(byUserArray.concat(arrayData));

                setByUserMetaData({
                    ...byUserMetaData,
                    load_more: response.more_records,
                    page: byUserMetaData.page + 1,
                });
            } else {
                showToast(response.message, "error");
            }
        });
    };

    const sortUserData = (type) => {
        if (type === "time") {
            if (byUserMetaData.sort_time === "asc") {
                sortDataApi("time", "desc");
            } else {
                sortDataApi("time", "asc");
            }
        } else if (type === "date") {
            if (byUserMetaData.sort_date === "asc") {
                sortDataApi("date", "desc");
            } else {
                sortDataApi("date", "asc");
            }
        } else if (type === "category") {
            if (byUserMetaData.sort_category === "asc") {
                sortDataApi("category", "desc");
            } else {
                sortDataApi("category", "asc");
            }
        }
    };

    const sortDataApi = (sortColumn, orderBy) => {
        setSortLoading(true);
        setSortBy(sortColumn);
        setSortOrder(orderBy);

        let guest_result_token = "";
        if (location.pathname.substr(1, 1) === "v") {
            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = "";
        if (location.pathname.substr(1, 1) === "v") {
            admin_result_token = ReactSession.get("admin_result_token");
        }

        const controller = new AbortController();

        dataUserFetchedRef.current = controller;

        let token = ReactSession.get("token");
        getTestCardSortingByUserLoadMoreResultData(
            {
                test_id: test_id,
                page: 1,
                sort_column: sortColumn,
                order_by: orderBy,
                guest_result_token: guest_result_token,
                admin_result_token: admin_result_token,
            },
            token,
            dataUserFetchedRef.current?.signal
        ).then((response) => {
            setSortLoading(false);

            if (response.success) {
                var arrayData = [];
                var iterator = 0;
                response.by_user.forEach((items, index) => {
                    var userArray = [];

                    items.relation_data.forEach(
                        (relationData, indexRelation) => {
                            arrayData[iterator] = { ...items };
                            arrayData[iterator]["relation"] = Object.assign(
                                {},
                                items.relation_data[indexRelation]
                            );
                            arrayData[iterator]["new"] = indexRelation
                                ? false
                                : true;
                            arrayData[iterator]["is_more_category"] =
                                items.relation_category_data_count > 3 &&
                                indexRelation + 1 === 3
                                    ? true
                                    : false;

                            arrayData[iterator]["is_less_category"] =
                                items.relation_category_data_count ===
                                    indexRelation + 1 && indexRelation + 1 > 3
                                    ? true
                                    : false;
                            arrayData[iterator]["hide_row"] =
                                indexRelation + 1 > 3 ? true : false;
                            iterator++;
                        }
                    );
                    //relation_data
                });

                console.log(arrayData);

                setByUserArray(arrayData);

                if (sortColumn === "time") {
                    if (orderBy === "desc") {
                        setByUserMetaData({
                            ...byUserMetaData,
                            sort_category: "",
                            sort_time: "desc",
                            sort_date: "",
                            page: 1,
                            load_more: response.more_records,
                        });
                    } else {
                        setByUserMetaData({
                            ...byUserMetaData,
                            sort_category: "",
                            sort_time: "asc",
                            sort_date: "",
                            page: 1,
                            load_more: response.more_records,
                        });
                    }
                } else if (sortColumn === "category") {
                    if (orderBy === "desc") {
                        setByUserMetaData({
                            ...byUserMetaData,
                            sort_category: "desc",
                            sort_time: "",
                            sort_date: "",
                            page: 1,
                            load_more: response.more_records,
                        });
                    } else {
                        setByUserMetaData({
                            ...byUserMetaData,
                            sort_category: "asc",
                            sort_time: "",
                            sort_date: "",
                            page: 1,
                            load_more: response.more_records,
                        });
                    }
                } else {
                    if (orderBy === "desc") {
                        setByUserMetaData({
                            ...byUserMetaData,
                            sort_result_id: "",
                            sort_time: "",
                            sort_date: "desc",
                            page: 1,
                            load_more: response.more_records,
                        });
                    } else {
                        setByUserMetaData({
                            ...byUserMetaData,
                            sort_result_id: "",
                            sort_time: "",
                            sort_date: "asc",
                            page: 1,
                            load_more: response.more_records,
                        });
                    }
                }
                // setMetaData({...metaData,page:metaData.page+1,load_more:response.more_records});
            } else {
                showToast(response.message, "error");
            }
        });
    };
    const checkIfScrollToAdd = () => {
        if (metaData.active === "category" && categoryArray.length > 8) {
            return true;
        } else if (metaData.active === "card" && cardArray.length > 8) {
            return true;
        } else if (metaData.active === "user" && byUserArray.length > 8) {
            return true;
        }
        return false;
    };

    return (
        <LayoutResearcher
            isSharedLink={isSharedLink}
            fixed_header_target={true}
            isLoading={false}
            wrapClass={"researcher-test-view-overflow create-test-data-wrapper"}
            resultHeaderClass={"result-header"}
            skipCheck={false}
            extendedFooter={false}
            activeMenu={""}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
        >
            <Helmet>
                <title>Analyse card sorting | UserQ</title>
            </Helmet>
            {test && (
                <div className="three-column-layout two-column-layout researcher-resultwrap cardsorting-test-result-wrap">
                    {/*<Topbar test={test} callbackTopbar={callbackTopbar} />*/}
                    <TestResultsHeader
                        project={project}
                        isSharedLink={isSharedLink}
                        test={test}
                        endedOn={
                            test.status === "completed"
                                ? moment.utc(test.ended_on).format("DD-MM-Y")
                                : ""
                        }
                        callbackTopbar={callbackTopbar}
                    />

                    <div className="three-column-layout-body">
                        <div className="three-column-layout-left">
                            {/*<Sidebar test={test} planType={planType} hideSidebar={expandData || expandSimilarityData} activeMenu={location.pathname.split("/").pop()} />*/}
                            <TestResultsSidebarNavigation
                                isSharedLink={isSharedLink}
                                methodology={
                                    test.methodology === "Five Seconds Test"
                                        ? "5 second test"
                                        : test.methodology
                                }
                                selected_index={4}
                                test_id={
                                    location.pathname.substr(1, 1) === "v"
                                        ? test_id
                                        : test.id
                                }
                                screening={
                                    test &&
                                    test.screening_questions &&
                                    test.screening_questions.length > 0
                                        ? "Yes"
                                        : "No"
                                }
                            />
                        </div>

                        <div className={`two-column-right-side`}>
                            {sectionLoading && (
                                <div
                                    className={
                                        "page-loader accountloading-box result-sections-loader"
                                    }
                                >
                                    <LoadingIcon />
                                </div>
                            )}
                            {!sectionLoading && resultCount !== 0 && (
                                <div className="bd-question-view-wrap">
                                    <div className="question-result-header question-result-data ">
                                        <div className="question-dropdown-container">
                                            <div className="dropdown-header">
                                                <div
                                                    className={
                                                        "card-sorting-test-results-header"
                                                    }
                                                >
                                                    <p className="questions-dropdown-result-head">
                                                        <span
                                                            className={
                                                                "questions-dropdown-result-card-type overline gray-color"
                                                            }
                                                        >
                                                            {" "}
                                                            {test.card_sorting
                                                                .card_sorting ===
                                                            "close"
                                                                ? " Closed card sorting"
                                                                : " Open card sorting"}
                                                        </span>{" "}
                                                        <span className="questions-dropdown-result-count">
                                                            ({resultCount}{" "}
                                                            {resultCount > 1
                                                                ? " ANSWERS"
                                                                : " ANSWER"}
                                                            )
                                                        </span>
                                                    </p>

                                                    <Text
                                                        type={"body-text-1"}
                                                        cssClasses={
                                                            "black-color"
                                                        }
                                                        fontWeight="medium-font"
                                                    >
                                                        {
                                                            test.card_sorting
                                                                .title
                                                        }
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>

                                        {!expandData && !expandSimilarityData && (
                                            <>
                                                <div className="accountnaviagtion-outer result-navigation-outer">
                                                    <span
                                                        className={`${
                                                            metaData.active ===
                                                            "category"
                                                                ? "active"
                                                                : ""
                                                        }`}
                                                        onClick={() => {
                                                            changeType(
                                                                "category"
                                                            );
                                                        }}
                                                    >
                                                        <a>By category</a>
                                                    </span>
                                                    <span
                                                        className={`${
                                                            metaData.active ===
                                                            "card"
                                                                ? "active"
                                                                : ""
                                                        }`}
                                                        onClick={() => {
                                                            changeType("card");
                                                        }}
                                                    >
                                                        <a>By card</a>
                                                    </span>
                                                    <span
                                                        className={`${
                                                            metaData.active ===
                                                            "user"
                                                                ? "active"
                                                                : ""
                                                        }`}
                                                        onClick={() => {
                                                            changeType("user");
                                                        }}
                                                    >
                                                        <a>By user</a>
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className="question-result-data ">
                                        {dataLoading && (
                                            <div
                                                className={
                                                    "page-loader accountloading-box result-sections-loader"
                                                }
                                            >
                                                <LoadingIcon />
                                            </div>
                                        )}
                                        {!dataLoading && (
                                            <div className="test-result-inner-data">
                                                <div
                                                    className={`card-sort-result-table-data`}
                                                >
                                                    <div className="card-sorting-result-matrix_buttons d-flex justify-content-end align-items center mb-20">
                                                        {/*{(test.publish_date > '13-10') && */}
                                                        <Link
                                                            to={`${
                                                                location.pathname.substr(
                                                                    1,
                                                                    1
                                                                ) === "v"
                                                                    ? `/v/results/${test_id}/similarity-matrix`
                                                                    : location.pathname.substr(
                                                                          1,
                                                                          1
                                                                      ) === "a"
                                                                    ? `/a/results/${test_id}/similarity-matrix`
                                                                    : `/r/results/${test_id}/similarity-matrix`
                                                            }`}
                                                            target={"_blank"}
                                                        >
                                                            <Button
                                                                size={"medium"}
                                                                type={
                                                                    "secondary"
                                                                }
                                                                iconLeft={
                                                                    <Icon
                                                                        size={
                                                                            "small"
                                                                        }
                                                                        value={
                                                                            "maximize"
                                                                        }
                                                                    />
                                                                }
                                                                label={
                                                                    "Open similarity matrix "
                                                                }
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    // setConfirmModal({
                                                                    //     open: true
                                                                    // });
                                                                }}
                                                            />
                                                        </Link>
                                                        {/*}*/}

                                                        {test.card_sorting
                                                            .card_sorting ===
                                                            "close" && (
                                                            <Link
                                                                to={`${
                                                                    location.pathname.substr(
                                                                        1,
                                                                        1
                                                                    ) === "v"
                                                                        ? `/v/results/${test_id}/results-grid`
                                                                        : location.pathname.substr(
                                                                              1,
                                                                              1
                                                                          ) ===
                                                                          "a"
                                                                        ? `/a/results/${test_id}/results-grid`
                                                                        : `/r/results/${test_id}/results-grid`
                                                                }`}
                                                                target={
                                                                    "_blank"
                                                                }
                                                            >
                                                                <Button
                                                                    size={
                                                                        "medium"
                                                                    }
                                                                    type={
                                                                        "secondary"
                                                                    }
                                                                    iconLeft={
                                                                        <Icon
                                                                            size={
                                                                                "small"
                                                                            }
                                                                            value={
                                                                                "maximize"
                                                                            }
                                                                        />
                                                                    }
                                                                    label={
                                                                        "Open result grid"
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        // setConfirmModal({
                                                                        //     open: true
                                                                        // });
                                                                    }}
                                                                />
                                                            </Link>
                                                        )}
                                                    </div>
                                                    {metaData.active !==
                                                        "matrix" &&
                                                        metaData.active !==
                                                            "similarity" && (
                                                            <>
                                                                <div
                                                                    ref={
                                                                        scrollContainerRef
                                                                    }
                                                                    className={`table-responsive ${
                                                                        metaData.active ===
                                                                        "user"
                                                                            ? "by-user-table-card"
                                                                            : ""
                                                                    }  max-height-table  ${
                                                                        isScrolled
                                                                            ? "scrolled"
                                                                            : ""
                                                                    } ${
                                                                        checkIfScrollToAdd()
                                                                            ? "responsive-table-scroll"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    <table className="table">
                                                                        <thead>
                                                                            {metaData.active ===
                                                                                "category" && (
                                                                                <tr className="card-sorting-category-view-tr">
                                                                                    <th className="bd-thcol-1 pl-0">
                                                                                        Category
                                                                                    </th>
                                                                                    <th className="bd-thcol-2">
                                                                                        <div
                                                                                            onClick={() =>
                                                                                                sortData(
                                                                                                    "category"
                                                                                                )
                                                                                            }
                                                                                            className={`${
                                                                                                metaData.categorySort
                                                                                                    ? metaData.categorySort
                                                                                                    : ""
                                                                                            } col-sort justify-content-between`}
                                                                                        >
                                                                                            No.
                                                                                            of
                                                                                            cards
                                                                                            <span>
                                                                                                {metaData.categorySort ? (
                                                                                                    <>
                                                                                                        {metaData.categorySort ===
                                                                                                        "asc" ? (
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "sortup"
                                                                                                                }
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "sortdown"
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <Icon
                                                                                                        value={
                                                                                                            "sort-icon"
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                            </span>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th className="bd-thcol-3">
                                                                                        Cards
                                                                                    </th>
                                                                                    <th className="bd-thcol-4">
                                                                                        Frequency
                                                                                    </th>
                                                                                </tr>
                                                                            )}
                                                                            {metaData.active ===
                                                                                "card" && (
                                                                                <tr className="card-buy-category-view-tr">
                                                                                    <th className="pl-0">
                                                                                        Cards
                                                                                    </th>
                                                                                    <th>
                                                                                        <div
                                                                                            onClick={() =>
                                                                                                sortData(
                                                                                                    "card"
                                                                                                )
                                                                                            }
                                                                                            className={`${
                                                                                                metaData.cardSort
                                                                                                    ? metaData.cardSort
                                                                                                    : ""
                                                                                            } col-sort justify-content-between pr-3`}
                                                                                        >
                                                                                            Sorted
                                                                                            into
                                                                                            <span>
                                                                                                {metaData.cardSort ? (
                                                                                                    <>
                                                                                                        {metaData.cardSort ===
                                                                                                        "asc" ? (
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "sortup"
                                                                                                                }
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "sortdown"
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <Icon
                                                                                                        value={
                                                                                                            "sort-icon"
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                            </span>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th>
                                                                                        Category
                                                                                    </th>
                                                                                </tr>
                                                                            )}

                                                                            {metaData.active ===
                                                                                "user" && (
                                                                                <tr className="card-sorting-user-view-tr">
                                                                                    <th className="bd-thcol-1 pl-0">
                                                                                        Participant
                                                                                    </th>
                                                                                    <th className="bd-thcol-2">
                                                                                        <div
                                                                                            onClick={() => {
                                                                                                sortUserData(
                                                                                                    "time"
                                                                                                );
                                                                                            }}
                                                                                            className={`${
                                                                                                byUserMetaData.sort_time
                                                                                                    ? byUserMetaData.sort_time
                                                                                                    : ""
                                                                                            } col-sort justify-content-between`}
                                                                                        >
                                                                                            Time
                                                                                            Spent
                                                                                            <span>
                                                                                                {byUserMetaData.sort_time ? (
                                                                                                    <>
                                                                                                        {byUserMetaData.sort_time ===
                                                                                                        "asc" ? (
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "sortup"
                                                                                                                }
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "sortdown"
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <Icon
                                                                                                        value={
                                                                                                            "sort-icon"
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                            </span>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th className="bd-thcol-3">
                                                                                        <div
                                                                                            onClick={() => {
                                                                                                sortUserData(
                                                                                                    "date"
                                                                                                );
                                                                                            }}
                                                                                            className={`${
                                                                                                byUserMetaData.sort_date
                                                                                                    ? byUserMetaData.sort_date
                                                                                                    : ""
                                                                                            } col-sort justify-content-between`}
                                                                                        >
                                                                                            Date
                                                                                            <span>
                                                                                                {byUserMetaData.sort_date ? (
                                                                                                    <>
                                                                                                        {byUserMetaData.sort_date ===
                                                                                                        "asc" ? (
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "sortup"
                                                                                                                }
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "sortdown"
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <Icon
                                                                                                        value={
                                                                                                            "sort-icon"
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                            </span>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th className="bd-thcol-4">
                                                                                        <div
                                                                                            onClick={() => {
                                                                                                sortUserData(
                                                                                                    "category"
                                                                                                );
                                                                                            }}
                                                                                            className={`${
                                                                                                byUserMetaData.sort_category
                                                                                                    ? byUserMetaData.sort_category
                                                                                                    : ""
                                                                                            } col-sort justify-content-between`}
                                                                                        >
                                                                                            No
                                                                                            of
                                                                                            categories
                                                                                            <span>
                                                                                                {byUserMetaData.sort_category ? (
                                                                                                    <>
                                                                                                        {byUserMetaData.sort_category ===
                                                                                                        "asc" ? (
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "sortup"
                                                                                                                }
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "sortdown"
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <Icon
                                                                                                        value={
                                                                                                            "sort-icon"
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                            </span>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th className="bd-thcol-5">
                                                                                        Category
                                                                                    </th>
                                                                                    <th className="bd-thcol-6">
                                                                                        No
                                                                                        of
                                                                                        cards
                                                                                    </th>
                                                                                    <th className="bd-thcol-7">
                                                                                        Cards
                                                                                    </th>
                                                                                    <th className="bd-thcol-8"></th>
                                                                                </tr>
                                                                            )}
                                                                        </thead>
                                                                        <tbody>
                                                                            {metaData.active ===
                                                                                "category" && (
                                                                                <>
                                                                                    {categoryArray.map(
                                                                                        (
                                                                                            items,
                                                                                            index
                                                                                        ) => {
                                                                                            return (
                                                                                                <tr
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                >
                                                                                                    <td className="bd-q-pat-col">
                                                                                                        <div className="card-catname-view-td">
                                                                                                            {items.is_custom ===
                                                                                                                0 && (
                                                                                                                <Icon
                                                                                                                    value={
                                                                                                                        "Folder"
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                            {items.is_custom ===
                                                                                                                1 && (
                                                                                                                <Icon
                                                                                                                    value={
                                                                                                                        "Added folder"
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                            <span
                                                                                                                className={
                                                                                                                    test.language !==
                                                                                                                    "en"
                                                                                                                        ? "cairo-font"
                                                                                                                        : ""
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    items.name
                                                                                                                }
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="bd-q-ans-col">
                                                                                                        {
                                                                                                            items.total_cards
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td className="bd-time-col">
                                                                                                        <div className="card-sorting-selected-cards-td-view table-cards-repeat">
                                                                                                            {items.results &&
                                                                                                                items.results
                                                                                                                    .slice(
                                                                                                                        0,
                                                                                                                        items.results_count
                                                                                                                    )
                                                                                                                    .map(
                                                                                                                        (
                                                                                                                            data,
                                                                                                                            index
                                                                                                                        ) => {
                                                                                                                            return (
                                                                                                                                <p
                                                                                                                                    key={
                                                                                                                                        index
                                                                                                                                    }
                                                                                                                                    className={` ${
                                                                                                                                        data.result &&
                                                                                                                                        data
                                                                                                                                            .result
                                                                                                                                            .is_reported
                                                                                                                                            ? "disabled-row-1"
                                                                                                                                            : ""
                                                                                                                                    } ${
                                                                                                                                        test.language !==
                                                                                                                                        "en"
                                                                                                                                            ? "cairo-font"
                                                                                                                                            : ""
                                                                                                                                    }`}
                                                                                                                                >
                                                                                                                                    {
                                                                                                                                        data.name
                                                                                                                                    }
                                                                                                                                </p>
                                                                                                                            );
                                                                                                                        }
                                                                                                                    )}

                                                                                                            {items
                                                                                                                .results
                                                                                                                .length >
                                                                                                                3 &&
                                                                                                            items.results_count !==
                                                                                                                items
                                                                                                                    .results
                                                                                                                    .length ? (
                                                                                                                <p
                                                                                                                    className={
                                                                                                                        "show-options more"
                                                                                                                    }
                                                                                                                    onClick={() => {
                                                                                                                        showMoreOption(
                                                                                                                            index,
                                                                                                                            items
                                                                                                                                .results
                                                                                                                                .length
                                                                                                                        );
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Icon
                                                                                                                        size={
                                                                                                                            "medium"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            "Chevron"
                                                                                                                        }
                                                                                                                        colorClass={
                                                                                                                            "secondry-purple-200-svg"
                                                                                                                        }
                                                                                                                    />
                                                                                                                    Show{" "}
                                                                                                                    {items
                                                                                                                        .results
                                                                                                                        .length -
                                                                                                                        3}{" "}
                                                                                                                    more{" "}
                                                                                                                    {items
                                                                                                                        .results
                                                                                                                        .length -
                                                                                                                        3 ===
                                                                                                                    1
                                                                                                                        ? "option"
                                                                                                                        : "options"}
                                                                                                                </p>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {items
                                                                                                                        .results
                                                                                                                        .length !==
                                                                                                                        0 &&
                                                                                                                        items
                                                                                                                            .results
                                                                                                                            .length >
                                                                                                                            3 && (
                                                                                                                            <p
                                                                                                                                className={
                                                                                                                                    "show-options less"
                                                                                                                                }
                                                                                                                                onClick={() => {
                                                                                                                                    showMoreOption(
                                                                                                                                        index,
                                                                                                                                        3
                                                                                                                                    );
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <Icon
                                                                                                                                    size={
                                                                                                                                        "medium"
                                                                                                                                    }
                                                                                                                                    value={
                                                                                                                                        "Chevron-up"
                                                                                                                                    }
                                                                                                                                    colorClass={
                                                                                                                                        "secondry-purple-200-svg"
                                                                                                                                    }
                                                                                                                                />
                                                                                                                                Show
                                                                                                                                less
                                                                                                                                options
                                                                                                                            </p>
                                                                                                                        )}
                                                                                                                </>
                                                                                                            )}

                                                                                                            <b>
                                                                                                                {items
                                                                                                                    .results
                                                                                                                    .length ===
                                                                                                                0
                                                                                                                    ? "-"
                                                                                                                    : ""}
                                                                                                            </b>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="bd-q-date-col">
                                                                                                        <div className="table-cards-repeat">
                                                                                                            {items.results &&
                                                                                                                items.results
                                                                                                                    .slice(
                                                                                                                        0,
                                                                                                                        items.results_count
                                                                                                                    )
                                                                                                                    .map(
                                                                                                                        (
                                                                                                                            data,
                                                                                                                            index
                                                                                                                        ) => {
                                                                                                                            return (
                                                                                                                                <p
                                                                                                                                    key={
                                                                                                                                        index
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    {
                                                                                                                                        data.frequency
                                                                                                                                    }
                                                                                                                                </p>
                                                                                                                            );
                                                                                                                        }
                                                                                                                    )}

                                                                                                            {items
                                                                                                                .results
                                                                                                                .length ===
                                                                                                            0
                                                                                                                ? 0
                                                                                                                : ""}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            );
                                                                                        }
                                                                                    )}

                                                                                    {byCategoryMetaData.loadMore &&
                                                                                        [
                                                                                            1,
                                                                                            2,
                                                                                            3,
                                                                                            4,
                                                                                            5,
                                                                                        ].map(
                                                                                            (
                                                                                                items
                                                                                            ) => {
                                                                                                return (
                                                                                                    <tr
                                                                                                        key={
                                                                                                            items
                                                                                                        }
                                                                                                    >
                                                                                                        <td className="bd-q-pat-col">
                                                                                                            <div className="card-catname-view-td">
                                                                                                                <Skeleton
                                                                                                                    width={
                                                                                                                        150
                                                                                                                    }
                                                                                                                    height={
                                                                                                                        30
                                                                                                                    }
                                                                                                                    style={{
                                                                                                                        borderRadius:
                                                                                                                            "5px",
                                                                                                                        marginBottom:
                                                                                                                            "0px",
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td className="bd-q-ans-col">
                                                                                                            <Skeleton
                                                                                                                width={
                                                                                                                    150
                                                                                                                }
                                                                                                                height={
                                                                                                                    30
                                                                                                                }
                                                                                                                style={{
                                                                                                                    borderRadius:
                                                                                                                        "5px",
                                                                                                                    marginBottom:
                                                                                                                        "0px",
                                                                                                                }}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td className="bd-time-col">
                                                                                                            <div className="card-sorting-selected-cards-td-view table-cards-repeat">
                                                                                                                <Skeleton
                                                                                                                    width={
                                                                                                                        150
                                                                                                                    }
                                                                                                                    height={
                                                                                                                        30
                                                                                                                    }
                                                                                                                    style={{
                                                                                                                        borderRadius:
                                                                                                                            "5px",
                                                                                                                        marginBottom:
                                                                                                                            "0px",
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td className="bd-q-date-col">
                                                                                                            <div className="table-cards-repeat">
                                                                                                                <Skeleton
                                                                                                                    width={
                                                                                                                        150
                                                                                                                    }
                                                                                                                    height={
                                                                                                                        30
                                                                                                                    }
                                                                                                                    style={{
                                                                                                                        borderRadius:
                                                                                                                            "5px",
                                                                                                                        marginBottom:
                                                                                                                            "0px",
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                </>
                                                                            )}

                                                                            {metaData.active ===
                                                                                "card" && (
                                                                                <>
                                                                                    {cardArray.map(
                                                                                        (
                                                                                            items,
                                                                                            index
                                                                                        ) => {
                                                                                            return (
                                                                                                <tr
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                >
                                                                                                    <td>
                                                                                                        <b
                                                                                                            className={
                                                                                                                test.language !==
                                                                                                                "en"
                                                                                                                    ? "cairo-font"
                                                                                                                    : ""
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                items.name
                                                                                                            }
                                                                                                        </b>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div className="card-catname-td">
                                                                                                            {
                                                                                                                items.total_category
                                                                                                            }
                                                                                                            {items.total_category >
                                                                                                            1
                                                                                                                ? " categories"
                                                                                                                : " category"}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {items.results.map(
                                                                                                            (
                                                                                                                data,
                                                                                                                index
                                                                                                            ) => {
                                                                                                                return (
                                                                                                                    <div
                                                                                                                        className={`card-catname-view-td ${
                                                                                                                            data.result &&
                                                                                                                            data
                                                                                                                                .result
                                                                                                                                .is_reported
                                                                                                                                ? "disabled-row-1"
                                                                                                                                : ""
                                                                                                                        }`}
                                                                                                                    >
                                                                                                                        {data
                                                                                                                            .category
                                                                                                                            .is_custom ===
                                                                                                                            0 && (
                                                                                                                            <Icon
                                                                                                                                value={
                                                                                                                                    "folder"
                                                                                                                                }
                                                                                                                            />
                                                                                                                        )}
                                                                                                                        {data
                                                                                                                            .category
                                                                                                                            .is_custom ===
                                                                                                                            1 && (
                                                                                                                            <Icon
                                                                                                                                value={
                                                                                                                                    "Added folder"
                                                                                                                                }
                                                                                                                            />
                                                                                                                        )}
                                                                                                                        <p
                                                                                                                            key={
                                                                                                                                index
                                                                                                                            }
                                                                                                                            className={
                                                                                                                                test.language !==
                                                                                                                                "en"
                                                                                                                                    ? "cairo-font"
                                                                                                                                    : ""
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {data
                                                                                                                                .category
                                                                                                                                .name ||
                                                                                                                                ""}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                );
                                                                                                            }
                                                                                                        )}

                                                                                                        {items
                                                                                                            .results
                                                                                                            .length ===
                                                                                                        0
                                                                                                            ? "-"
                                                                                                            : ""}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            );
                                                                                        }
                                                                                    )}

                                                                                    {byCardMetaData.loadMore &&
                                                                                        [
                                                                                            1,
                                                                                            2,
                                                                                            3,
                                                                                            4,
                                                                                            5,
                                                                                        ].map(
                                                                                            (
                                                                                                items
                                                                                            ) => {
                                                                                                return (
                                                                                                    <tr
                                                                                                        key={
                                                                                                            items
                                                                                                        }
                                                                                                        className={
                                                                                                            "loading-cards-items"
                                                                                                        }
                                                                                                    >
                                                                                                        <td>
                                                                                                            <Skeleton
                                                                                                                width={
                                                                                                                    150
                                                                                                                }
                                                                                                                height={
                                                                                                                    30
                                                                                                                }
                                                                                                                style={{
                                                                                                                    borderRadius:
                                                                                                                        "5px",
                                                                                                                    marginBottom:
                                                                                                                        "0px",
                                                                                                                }}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className="card-catname-td">
                                                                                                                <Skeleton
                                                                                                                    width={
                                                                                                                        150
                                                                                                                    }
                                                                                                                    height={
                                                                                                                        30
                                                                                                                    }
                                                                                                                    style={{
                                                                                                                        borderRadius:
                                                                                                                            "5px",
                                                                                                                        marginBottom:
                                                                                                                            "0px",
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Skeleton
                                                                                                                width={
                                                                                                                    150
                                                                                                                }
                                                                                                                height={
                                                                                                                    30
                                                                                                                }
                                                                                                                style={{
                                                                                                                    borderRadius:
                                                                                                                        "5px",
                                                                                                                    marginBottom:
                                                                                                                        "0px",
                                                                                                                }}
                                                                                                            />
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                </>
                                                                            )}

                                                                            {metaData.active ===
                                                                                "user" && (
                                                                                <>
                                                                                    {byUserArray.map(
                                                                                        (
                                                                                            items,
                                                                                            index
                                                                                        ) => {
                                                                                            return (
                                                                                                <tr
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                    className={`${
                                                                                                        items.new
                                                                                                            ? "new-added-by-user-row"
                                                                                                            : "old-added-by-user-row"
                                                                                                    } ${
                                                                                                        items &&
                                                                                                        items.is_reported
                                                                                                            ? "disabled-row-1"
                                                                                                            : ""
                                                                                                    } ${
                                                                                                        items.hide_row
                                                                                                            ? "d-none"
                                                                                                            : ""
                                                                                                    }`}
                                                                                                >
                                                                                                    <td>
                                                                                                        {items.new && (
                                                                                                            <>
                                                                                                                {items &&
                                                                                                                items.user_type ===
                                                                                                                    "Unknown"
                                                                                                                    ? items.user_type +
                                                                                                                      " "
                                                                                                                    : items.user_type}

                                                                                                                {items &&
                                                                                                                items.result_id
                                                                                                                    ? items.result_id
                                                                                                                    : index +
                                                                                                                      1}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {items.new && (
                                                                                                            <>
                                                                                                                {calculateTime(
                                                                                                                    items.time_spent
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {items.new && (
                                                                                                            <>
                                                                                                                {moment(
                                                                                                                    items.created_at
                                                                                                                ).format(
                                                                                                                    "MM/DD/YYYY"
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {items.new && (
                                                                                                            <>
                                                                                                                {items
                                                                                                                    .relation
                                                                                                                    .total_category_count &&
                                                                                                                    items
                                                                                                                        .relation
                                                                                                                        .total_category_count
                                                                                                                        .length}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        className={
                                                                                                            "by-user-category-td"
                                                                                                        }
                                                                                                    >
                                                                                                        <div className="table-cards-repeat">
                                                                                                            <p
                                                                                                                key={
                                                                                                                    index
                                                                                                                }
                                                                                                                className={
                                                                                                                    "cat-data by-user-data-cat"
                                                                                                                }
                                                                                                            >
                                                                                                                {items
                                                                                                                    .relation
                                                                                                                    .total_category
                                                                                                                    .is_custom ===
                                                                                                                    0 && (
                                                                                                                    <Icon
                                                                                                                        value={
                                                                                                                            "folder"
                                                                                                                        }
                                                                                                                    />
                                                                                                                )}
                                                                                                                {items
                                                                                                                    .relation
                                                                                                                    .total_category
                                                                                                                    .is_custom ===
                                                                                                                    1 && (
                                                                                                                    <Icon
                                                                                                                        value={
                                                                                                                            "Added folder"
                                                                                                                        }
                                                                                                                    />
                                                                                                                )}
                                                                                                                <span>
                                                                                                                    {items
                                                                                                                        .relation
                                                                                                                        .total_category &&
                                                                                                                        items
                                                                                                                            .relation
                                                                                                                            .total_category
                                                                                                                            .name}
                                                                                                                </span>
                                                                                                            </p>

                                                                                                            {items.is_more_category && (
                                                                                                                <p
                                                                                                                    className={
                                                                                                                        "show-options more by-user-category-td-more"
                                                                                                                    }
                                                                                                                    onClick={() => {
                                                                                                                        showMoreOptionByUserCategory(
                                                                                                                            index,
                                                                                                                            items.id,
                                                                                                                            "more"
                                                                                                                        );
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Icon
                                                                                                                        size={
                                                                                                                            "medium"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            "Chevron"
                                                                                                                        }
                                                                                                                        colorClass={
                                                                                                                            "secondry-purple-200-svg"
                                                                                                                        }
                                                                                                                    />
                                                                                                                    Show{" "}
                                                                                                                    {items.relation_category_data_count -
                                                                                                                        3}{" "}
                                                                                                                    more{" "}
                                                                                                                    {items.relation_category_data_count -
                                                                                                                        3 ===
                                                                                                                    1
                                                                                                                        ? "category"
                                                                                                                        : "categories"}
                                                                                                                </p>
                                                                                                            )}

                                                                                                            {items.is_less_category && (
                                                                                                                <p
                                                                                                                    className={
                                                                                                                        "show-options more by-user-category-td-less"
                                                                                                                    }
                                                                                                                    onClick={() => {
                                                                                                                        showMoreOptionByUserCategory(
                                                                                                                            index,
                                                                                                                            items.id,
                                                                                                                            "less"
                                                                                                                        );
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Icon
                                                                                                                        size={
                                                                                                                            "medium"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            "Chevron-up"
                                                                                                                        }
                                                                                                                        colorClass={
                                                                                                                            "secondry-purple-200-svg"
                                                                                                                        }
                                                                                                                    />
                                                                                                                    Show
                                                                                                                    less
                                                                                                                    categories
                                                                                                                </p>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {
                                                                                                            items
                                                                                                                .relation
                                                                                                                .total_card_count
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div className="table-cards-repeat">
                                                                                                            {items
                                                                                                                .relation
                                                                                                                .total_card &&
                                                                                                                items.relation.total_card
                                                                                                                    .slice(
                                                                                                                        0,
                                                                                                                        items
                                                                                                                            .relation
                                                                                                                            .total_card_count_extra
                                                                                                                    )
                                                                                                                    .map(
                                                                                                                        (
                                                                                                                            data,
                                                                                                                            index
                                                                                                                        ) => {
                                                                                                                            return (
                                                                                                                                <p
                                                                                                                                    key={
                                                                                                                                        index
                                                                                                                                    }
                                                                                                                                    className={` ${
                                                                                                                                        test.language !==
                                                                                                                                        "en"
                                                                                                                                            ? "cairo-font"
                                                                                                                                            : ""
                                                                                                                                    }`}
                                                                                                                                >
                                                                                                                                    {
                                                                                                                                        data.name
                                                                                                                                    }
                                                                                                                                </p>
                                                                                                                            );
                                                                                                                        }
                                                                                                                    )}

                                                                                                            {items
                                                                                                                .relation
                                                                                                                .total_card
                                                                                                                .length >
                                                                                                                3 &&
                                                                                                            items
                                                                                                                .relation
                                                                                                                .total_card_count_extra !==
                                                                                                                items
                                                                                                                    .relation
                                                                                                                    .total_card
                                                                                                                    .length ? (
                                                                                                                <p
                                                                                                                    className={
                                                                                                                        "show-options more"
                                                                                                                    }
                                                                                                                    onClick={() => {
                                                                                                                        showMoreOptionByUser(
                                                                                                                            index,
                                                                                                                            items
                                                                                                                                .relation
                                                                                                                                .total_card
                                                                                                                                .length
                                                                                                                        );
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Icon
                                                                                                                        size={
                                                                                                                            "medium"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            "Chevron"
                                                                                                                        }
                                                                                                                        colorClass={
                                                                                                                            "secondry-purple-200-svg"
                                                                                                                        }
                                                                                                                    />
                                                                                                                    Show{" "}
                                                                                                                    {items
                                                                                                                        .relation
                                                                                                                        .total_card
                                                                                                                        .length -
                                                                                                                        3}{" "}
                                                                                                                    more{" "}
                                                                                                                    {items
                                                                                                                        .relation
                                                                                                                        .total_card
                                                                                                                        .length -
                                                                                                                        3 ===
                                                                                                                    1
                                                                                                                        ? "card"
                                                                                                                        : "cards"}
                                                                                                                </p>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {items
                                                                                                                        .relation
                                                                                                                        .total_card
                                                                                                                        .length !==
                                                                                                                        0 &&
                                                                                                                        items
                                                                                                                            .relation
                                                                                                                            .total_card
                                                                                                                            .length >
                                                                                                                            3 && (
                                                                                                                            <p
                                                                                                                                className={
                                                                                                                                    "show-options less"
                                                                                                                                }
                                                                                                                                onClick={() => {
                                                                                                                                    showMoreOptionByUser(
                                                                                                                                        index,
                                                                                                                                        3
                                                                                                                                    );
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <Icon
                                                                                                                                    size={
                                                                                                                                        "medium"
                                                                                                                                    }
                                                                                                                                    value={
                                                                                                                                        "Chevron-up"
                                                                                                                                    }
                                                                                                                                    colorClass={
                                                                                                                                        "secondry-purple-200-svg"
                                                                                                                                    }
                                                                                                                                />
                                                                                                                                Show
                                                                                                                                less
                                                                                                                                cards
                                                                                                                            </p>
                                                                                                                        )}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="tableDropdown">
                                                                                                        {items.new && (
                                                                                                            <>
                                                                                                                {items &&
                                                                                                                    items.user_type !==
                                                                                                                        "Unknown" &&
                                                                                                                    location.pathname.substr(
                                                                                                                        1,
                                                                                                                        1
                                                                                                                    ) !==
                                                                                                                        "v" && (
                                                                                                                        <span className="hamburger-menu ">
                                                                                                                            <Dropdown className="dropdown">
                                                                                                                                <Dropdown.Toggle
                                                                                                                                    id={
                                                                                                                                        "dropdownMenuButton" +
                                                                                                                                        items.id
                                                                                                                                    }
                                                                                                                                    className="dropdown-toggle"
                                                                                                                                >
                                                                                                                                    <span className="menu-item">
                                                                                                                                        <Icon
                                                                                                                                            value={
                                                                                                                                                "Quick menu"
                                                                                                                                            }
                                                                                                                                            colorClass={
                                                                                                                                                "gray-900-svg hamburger-menu-img"
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </span>
                                                                                                                                </Dropdown.Toggle>
                                                                                                                                <Dropdown.Menu
                                                                                                                                    className={
                                                                                                                                        "hamburger-items"
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <div className="drop-down-link-hold byuser-table-link-hold">
                                                                                                                                        <Dropdown.Item
                                                                                                                                            onClick={() => {
                                                                                                                                                // open report tester modal
                                                                                                                                                setReportTesterModal(
                                                                                                                                                    {
                                                                                                                                                        open: true,
                                                                                                                                                        test_result_id:
                                                                                                                                                            items.id,
                                                                                                                                                    }
                                                                                                                                                );
                                                                                                                                                //reportAnswerModal(result.result.id)
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span>
                                                                                                                                                Report
                                                                                                                                                participant
                                                                                                                                            </span>
                                                                                                                                            <Icon
                                                                                                                                                value="warning"
                                                                                                                                                size="medium"
                                                                                                                                            />
                                                                                                                                        </Dropdown.Item>
                                                                                                                                    </div>
                                                                                                                                </Dropdown.Menu>
                                                                                                                            </Dropdown>
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            );
                                                                                        }
                                                                                    )}

                                                                                    {byUserDataLoading &&
                                                                                        [
                                                                                            1,
                                                                                            2,
                                                                                            3,
                                                                                            4,
                                                                                            5,
                                                                                        ].map(
                                                                                            (
                                                                                                items
                                                                                            ) => {
                                                                                                return (
                                                                                                    <tr
                                                                                                        key={
                                                                                                            items
                                                                                                        }
                                                                                                        className={`old-added-by-user-row`}
                                                                                                    >
                                                                                                        <td>
                                                                                                            <Skeleton
                                                                                                                width={
                                                                                                                    100
                                                                                                                }
                                                                                                                height={
                                                                                                                    30
                                                                                                                }
                                                                                                                style={{
                                                                                                                    borderRadius:
                                                                                                                        "5px",
                                                                                                                    marginBottom:
                                                                                                                        "0px",
                                                                                                                }}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Skeleton
                                                                                                                width={
                                                                                                                    100
                                                                                                                }
                                                                                                                height={
                                                                                                                    30
                                                                                                                }
                                                                                                                style={{
                                                                                                                    borderRadius:
                                                                                                                        "5px",
                                                                                                                    marginBottom:
                                                                                                                        "0px",
                                                                                                                }}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Skeleton
                                                                                                                width={
                                                                                                                    100
                                                                                                                }
                                                                                                                height={
                                                                                                                    30
                                                                                                                }
                                                                                                                style={{
                                                                                                                    borderRadius:
                                                                                                                        "5px",
                                                                                                                    marginBottom:
                                                                                                                        "0px",
                                                                                                                }}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Skeleton
                                                                                                                width={
                                                                                                                    50
                                                                                                                }
                                                                                                                height={
                                                                                                                    30
                                                                                                                }
                                                                                                                style={{
                                                                                                                    borderRadius:
                                                                                                                        "5px",
                                                                                                                    marginBottom:
                                                                                                                        "0px",
                                                                                                                }}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td
                                                                                                            className={
                                                                                                                "by-user-category-td"
                                                                                                            }
                                                                                                        >
                                                                                                            <Skeleton
                                                                                                                width={
                                                                                                                    100
                                                                                                                }
                                                                                                                height={
                                                                                                                    30
                                                                                                                }
                                                                                                                style={{
                                                                                                                    borderRadius:
                                                                                                                        "5px",
                                                                                                                    marginBottom:
                                                                                                                        "0px",
                                                                                                                }}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Skeleton
                                                                                                                width={
                                                                                                                    50
                                                                                                                }
                                                                                                                height={
                                                                                                                    30
                                                                                                                }
                                                                                                                style={{
                                                                                                                    borderRadius:
                                                                                                                        "5px",
                                                                                                                    marginBottom:
                                                                                                                        "0px",
                                                                                                                }}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Skeleton
                                                                                                                width={
                                                                                                                    100
                                                                                                                }
                                                                                                                height={
                                                                                                                    30
                                                                                                                }
                                                                                                                style={{
                                                                                                                    borderRadius:
                                                                                                                        "5px",
                                                                                                                    marginBottom:
                                                                                                                        "0px",
                                                                                                                }}
                                                                                                            />
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                </>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                {metaData.active ===
                                                                    "user" &&
                                                                    byUserMetaData.load_more &&
                                                                    !loadMoreLoading &&
                                                                    !byUserDataLoading && (
                                                                        <div
                                                                            className={
                                                                                "load-more-data"
                                                                            }
                                                                        >
                                                                            <Button
                                                                                type="primary"
                                                                                size={`large`}
                                                                                label="Load more"
                                                                                onClick={() =>
                                                                                    loadMore()
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}

                                                                {metaData.active ===
                                                                    "user" &&
                                                                    byUserMetaData.load_more &&
                                                                    loadMoreLoading && (
                                                                        <div
                                                                            className={
                                                                                "load-more-data"
                                                                            }
                                                                        >
                                                                            <Button
                                                                                type="primary"
                                                                                size={`large`}
                                                                                onClick={() => {}}
                                                                                microLoading={
                                                                                    true
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                            </>
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {!sectionLoading && resultCount === 0 && (
                                <div
                                    className={`no-participants-data no-questions-data`}
                                >
                                    <div
                                        className="no-page-data-wrap"
                                        style={{ marginTop: "80px" }}
                                    >
                                        <div className="no-data-icon">
                                            <Icon
                                                value={"Attention"}
                                                colorClass={"gray-700-svg"}
                                                size={"large"}
                                            />
                                        </div>

                                        <Text
                                            type={"subtitle-1"}
                                            fontWeight="medium-font"
                                        >
                                            Details are not available at the
                                            moment as the test didn’t receive
                                            any response yet. Please come back
                                            later.
                                        </Text>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {!isSharedLink && (
                <a
                    onClick={(e) => {
                        e.preventDefault();

                        setShowSupportPanel(true);
                    }}
                    href={process.env.REACT_APP_URL + "r/support"}
                    target="_blank"
                    className="support-button"
                >
                    <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z"
                            stroke="#9759CB"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    Support
                </a>
            )}

            <ReportTesterModal
                test={test}
                openModal={reportTesterModal.open}
                selectedTestResultId={reportTesterModal.test_result_id}
                close={() => {
                    setReportTesterModal({ ...reportTesterModal, open: false });
                }}
                confirm={(type, totalParticipantSelected) => {
                    setReportTesterModal({ ...reportTesterModal, open: false });
                    sessionStorage.setItem("is_reported_tester", "true");
                }}
            />
        </LayoutResearcher>
    );
}
