import { useNavigate, useSearchParams, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession.js";
import React, { useEffect, useState, useRef } from "react";
//import LayoutResearcher from "../../layouts/layout_researcher.js";
import { LayoutResearcher } from "../../themes/userq/Layouts/layout_researcher";
import { getPackagesService } from "../../../services/packages";
import {
    getBillingInfoService,
    updateBillingInfoService,
} from "../../../services/user";
import {
    addNewPaymentMethodService,
    getPaymentMethodsService,
    deletePaymentMethodService,
    setDefaultCard,
} from "../../../services/payment_methods";
import { getProjects } from "../../../services/project.js";
import toast from "react-hot-toast";
import { countries } from "../../../data/stripe_countries.js";
import Cleave from "cleave.js/react";
import Select from "react-select";
import validator from "validator";
import ConfirmModal from "../../dialog/confirm_modal";
import {
    buyCreditsService,
    applyCouponService,
    makePaymentService,
} from "../../../services/credits";
import InputField from "../../layouts/elements/fields/input";
import { FormattedMessage } from "react-intl";
import { atom, useResetRecoilState } from "recoil";
import { LoadingIcon } from "../../loader/loadingIcon";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import {
    amountFormat,
    encryptClient,
    numberWithCommas,
    roundNumber,
    showToast,
} from "../../../lib/helpers.js";
import { DropdownIndicator } from "../../../lib/helpers";
import NewTestModal from "../tests/new_test_modal.js";
import NewProjectModal from "../projects/new_project_modal.js";
import CreditsConfirmationModal from "./credits_confirmation_modal";
import { Text } from "../../themes/userq/Text/Text.js";
import { Accordian } from "../../themes/userq/Accordion/Accordion.js";
import { Input } from "../../themes/userq/Input/Input.js";
import { Button } from "../../themes/userq/Button/Button.js";
import { Tag } from "../../themes/userq/Tag/Tag.js";
import CheckoutModal from "./checkout_modal.js";
import { RadioButton } from "../../themes/userq/RadioButton/RadioButton.js";
import ConfirmationModal from "../../themes/userq/Modal/ConfirmationModal.js";
import LoadingModal from "../../themes/userq/Modal/LoadingModal.js";
import creditCardType from "credit-card-type";
import { Icon } from "../../themes/userq/Icon/Icon.js";
import { RatingMultiSelect } from "../../themes/userq/MultiSelecteDropdownList/RatingMultiSelect.js";
import { getWorkspaceService } from "../../../services/workspaces.js";
import { Helmet } from "react-helmet";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function BuyCredits(props) {
    return (
        <Elements stripe={stripePromise}>
            <PaymentComponent {...props} />
        </Elements>
    );
}
const PaymentComponent = (props) => {
    ReactSession.setStoreType("localStorage");

    const stripe = useStripe();

    const errorRef = useRef(null);

    const [firstErrorRef, setFirstErrorRef] = useState(null);

    let user = ReactSession.get("user");

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [countryLabelClass, setCountryLabelClass] =
        useState("inactive_label");

    const [isLoading, setLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [creditPrice, setCreditPrice] = useState(1);

    const [discountTag, setDiscountTag] = useState(null);

    const [couponLoading, setCouponLoading] = useState(false);

    const [cardFormLoading, setCardFormLoading] = useState(false);

    const [billingLoading, setBillingLoading] = useState(false);

    const [accordians, setAccordians] = useState({
        first: false,
        second: false,
        third: false,
        fourth: false,
    });

    const [projects, setProjects] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [modalMethodology, setModalMethodology] = useState("");
    const [openNewTestModal, setOpenNewTestModal] = useState(false);
    const [openCreditConfirmationModal, setOpenCreditConfirmationModal] =
        useState(false);
    const [userSettings, setUserSettings] = useState({
        publishing_credits: {
            card_sorting: null,
            preference_test: null,
            tree_test: null,
            survey: null,
        },
    });
    const abortController = new AbortController();

    const [confirmModal, setConfirmModal] = useState({
        open: false,
        target_id: null,
    });

    const [isBillingEditable, setBillingEditable] = useState(false);

    const [packages, setPackages] = useState([]);

    const [paymentMethods, setPaymentMethods] = useState([]);

    const [selected_package, setSelectPackage] = useState(null);

    const [checkoutModal, setCheckoutModal] = useState({ open: false });

    const [addNewCard, setAddNewCard] = useState(false);

    const [credits, setCredits] = useState(50);

    const [credits_error, setCreditsError] = useState(null);

    const [couponError, setCouponError] = useState("");

    const creditsState = atom({
        key: "credits",
        default: 0,
    });
    const reset = useResetRecoilState(creditsState);

    const [formValues, setFormValues] = useState({
        //name: "",
        //country: null,
        //city: "",
        //address: "",
        card_no: "",
        //card_name: "",
        exp_date: "",
        cvv: "",
        payment_method_id: "",
        //card_type: "",
        credits: "50",
        promo_code: "",
        description: "",
        discount: 0,
        applied: false,
        future_use: true,
    });

    const [billingInfo, setBillingInfo] = useState({
        name: "",
        country: null,
        city: "",
        address: "",
    });

    const [formErrors, setFormErrors] = useState({
        email: null,
        name: null,
        country: null,
        city: null,
        address: null,
        card_no: null,
        card_name: null,
        expiration_date: null,
        cvv: null,
        credits: null,
        promo_code: null,
        error_class: null,
    });

    const [show_support_panel, setShowSupportPanel] = useState(false);

    const { workspaceid } = useParams();

    const [teamMember, setTeamMember] = useState(null);

    const [workspace, setWorkspace] = useState(null);

    const [openFrozenModal, setOpenFrozenModal] = useState(0);

    const [sameCardError, setSameCardError] = useState(false);

    const closeNewTestModal = (reload) => {
        setOpenNewTestModal(false);
        navigate("/r/credits");

        if (reload) {
            getProjectsApi();
        }
    };

    const closeModal = (reload) => {
        setOpenModal(false);
        navigate("/r/credits");

        if (reload) {
            getProjectsApi();
        }
    };

    const closeCreditConfirmationModal = (reload) => {
        setOpenCreditConfirmationModal(false);

        // if (reload) {
        //     getProjectsApi();
        // }
    };

    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        };
    };
    const clearFormValue = (name) => {
        return ({ target: { value } }) => {
            setTimeout(function () {
                setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
            }, 1000);
        };
    };
    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const validatePaymentForm = () => {
        var error = false;

        if (formValues.card_no == "") {
            error = true;
        } else if (!validator.isCreditCard(formValues.card_no)) {
            error = true;
        }

        if (formValues.exp_date == "") {
            error = true;
        }
        var new_exp_date = formValues.exp_date.replaceAll(" ", "");

        var new_exp_date_arr = new_exp_date.split("/");

        if (new_exp_date.length == 5) {
            if (
                new Date().getTime() >
                    new Date(
                        "20" +
                            new_exp_date_arr[1] +
                            "-" +
                            new_exp_date_arr[0] +
                            "-01"
                    ).getTime() ||
                isNaN(
                    new Date(
                        "20" +
                            new_exp_date_arr[1] +
                            "-" +
                            new_exp_date_arr[0] +
                            "-01"
                    ).getTime()
                )
            ) {
                error = true;
            }
        } else if (new_exp_date.length > 0) {
            error = true;
        }
        if (formValues.cvv == "") {
            error = true;
        }
        return error;
    };
    const validatePaymentFormError = () => {
        var error = false;

        if (!validator.isCreditCard(formValues.card_no)) {
            error = true;
        }

        var new_exp_date = formValues.exp_date.replaceAll(" ", "");

        if (new_exp_date.length == 5) {
            if (
                new Date().getTime() > new Date("01/" + new_exp_date).getTime()
            ) {
                error = true;
            }
        } else if (new_exp_date.length > 0) {
            error = true;
        }
        if (formValues.cvv == "") {
            error = true;
        }
        return error;
    };
    const validateCreditCard = (value) => {
        if (!validator.isCreditCard(value)) {
            setFormErrors((oldValues) => ({
                ...oldValues,
                card_no: "Enter valid Credit Card Number!",
                error_class: "card-error",
            }));
            // showFormError("card_no", "Enter valid Credit Card Number!");
        } else {
            setFormErrors((oldValues) => ({ ...oldValues, card_no: null }));
            // showFormError("card_no", null);
        }
        var card_type = creditCardType(value);

        if (card_type.length > 0 && card_type[0].length) {
            if (
                card_type[0].type != "visa" &&
                card_type[0].type != "mastercard"
            ) {
                setFormErrors((oldValues) => ({
                    ...oldValues,
                    card_no: "Only visa and mastercard are allowed.",
                    error_class: "card-error",
                }));
            } else {
                setFormErrors((oldValues) => ({ ...oldValues, card_no: null }));
                // showFormError("card_no", null);
            }
        } else {
            setFormErrors((oldValues) => ({ ...oldValues, card_no: null }));
            // showFormError("card_no", null);
        }
        /*if (formValues.card_type != "visa" && formValues.card_type != "mastercard") {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": "Only visa and mastercard are allowed.", "error_class": "card-error" }));
            // showFormError("card_no", "Only visa and mastercard are allowed.");
        } else {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": null }));
            // showFormError("card_no", null);
        }*/
    };

    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    };

    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";

        setFormErrors(formErrorsLocal);
    };
    const setDefaultCardValue = (value) => {
        let token = ReactSession.get("token");
        var data = new FormData();
        data.append("stripe_id", value);
        setDefaultCard(data, token).then((response) => {
            if (response.success) {
                getPaymentMethods();
            }
        });
    };
    const addCustomCredits = (plan_id, plan_price) => {
        if (parseInt(credits) < 50) {
            var id = toast(
                <div className="toastinner">
                    <>
                        <FormattedMessage id="Please enter a valid amount greater than or equal to 50" />
                    </>
                    <a
                        onClick={() => {
                            toast.dismiss(id);
                        }}
                    >
                        &times;
                    </a>
                </div>,
                {
                    className: "errortoast",
                    position: "bottom-center",
                }
            );
        } else {
            setFormValues({ ...formValues, credits: credits });

            removePromoCode();

            setSelectPackage(plan_id);
        }
    };
    const renderPaymentMethods = () => {
        var render = [];

        paymentMethods.forEach(function (method, index) {
            render.push(
                <div
                    key={"methods" + method.id}
                    className="checkboxes radio_btn_class gender_btn d-flex card-radio-wrap"
                >
                    <div className="d-flex addedpayment-cards-wrap">
                        <div className="card-left-side">
                            <label
                                htmlFor={method.stripe_payment_method_id}
                                className="mt-0"
                            >
                                {method.card_expire_status !== 2 && 
                                <RadioButton
                                    id={method.stripe_payment_method_id}
                                    type="radio"
                                    isChecked={
                                        formValues.payment_method_id ==
                                        method.id
                                            ? true
                                            : false
                                    }
                                    onChange={() => {
                                        setFormValues({
                                            ...formValues,
                                            payment_method_id: method.id,
                                            card_no: "",
                                            cvv: "",
                                            exp_date: "",
                                        });
                                        setSameCardError(false);
                                    }}
                                    name="payment_method"
                                    value={method.stripe_payment_method_id}
                                    className="hidden radio_btn_input"
                                    required="required"
                                />
                                }
                                <p>
                                    <img
                                        src={
                                            process.env.REACT_APP_URL +
                                            "images/" +
                                            method.brand +
                                            "-icon.svg"
                                        }
                                        alt="brand-icon"
                                    />{" "}
                                    {/*{method.brand} ending in *{method.last_4}{" "}
                                    <b>{method.card_name}</b>*/}
                                </p>
                            </label>
                            {/*{method.default == 1 && (
                                <span className="primary-card">Primary</span>
                            )}*/}
                        </div>

                        {user.permission != "Editor" && (
                            <div className="card-right-side">
                                {method.card_expire_status == 2 
                                ?
                                    <Tag title="Expired" type="danger-tag" />
                                :
                                <>
                                </>
                                }
                                {/*method.default == 0 && (
                                    <span
                                        className="make-primary-card"
                                        onClick={() => {
                                            setDefaultCardValue(method.stripe_payment_method_id);
                                        }}
                                    >
                                        Make primary
                                    </span>
                                    )*/}
                                <b className="card-last-number">
                                    **** {method.last_4}{" "}
                                </b>
                                {/*<span
                                    className="delete-account-btn"
                                    onClick={() => {
                                        setConfirmModal({
                                            open: true,
                                            target_id: method.id,
                                            confirm_title: "Delete Card",
                                            confirm_btn_title: "Delete",
                                            confirm_message:
                                                "Are you sure you want to delete this card?",
                                        });
                                    }}
                                >
                                    {" "}
                                    <Icon value={"delete"} size={"medium"} hover={true} />
                                </span>*/}
                            </div>
                        )}
                    </div>
                </div>
            );
        });

        if (render.length > 0) {
            return render;
        } else {
            return <></>;
            return (
                <div className="no_payment_add">No payment methods added.</div>
            );
        }
    };
    const renderPackagesHtmlWithLoader = (packageData) => {
        var render = [];
        packageData.forEach(function (packageItem) {
            if (!selected_package) {
                if (packageItem.is_default == 1) {
                    removePromoCode();
                    setSelectPackage(packageItem);
                }
            }
            if (packageItem.is_custom != 1) {
                render.push(
                    isLoading ? (
                        <tr>
                            <td>
                                <Skeleton />
                            </td>
                            <td>
                                <Skeleton />
                            </td>
                            <td>
                                <Skeleton />
                            </td>
                        </tr>
                    ) : (
                        <tr
                            className={`${
                                selected_package &&
                                selected_package.id == packageItem.id
                                    ? "selected-package"
                                    : ""
                            }`}
                            onClick={() => {
                                setCreditsError("");
                                setFormValues({
                                    ...formValues,
                                    discount: 0,
                                    promo_code: "",
                                    credits: packageItem.credits,
                                });
                                setCreditPrice(packageItem.discounted_price);
                                setDiscountTag(packageItem.subtitle);
                                setSelectPackage(packageItem);
                                console.log({
                                    ...formValues,
                                    discount: 0,
                                    promo_code: "",
                                    credits: packageItem.credits,
                                })
                            }}
                        >
                            <td>
                                <div className="package-title-wrap">
                                    <Text
                                        type={"body-text-2"}
                                        fontWeight={"semi-bold-font"}
                                    >
                                        {numberWithCommas(packageItem.credits)}
                                    </Text>
                                    <Tag
                                        type={"pink-filled-tag"}
                                        title={packageItem.subtitle}
                                    />
                                </div>
                            </td>
                            <td>
                                {packageItem.discounted_price && (
                                    <Text
                                        type={"body-text-2"}
                                        fontWeight={"semi-bold-font"}
                                    >
                                        $
                                        {numberWithCommas(
                                            roundNumber(
                                                packageItem.discounted_price,
                                                2
                                            )
                                        )}{" "}
                                        /credit
                                    </Text>
                                )}
                            </td>
                            <td>
                                <Text
                                    type={"body-text-2"}
                                    fontWeight={"semi-bold-font"}
                                >
                                    {"$" +
                                        numberWithCommas(
                                            roundNumber(packageItem.price, 2)
                                        )}
                                </Text>
                            </td>
                        </tr>
                    )
                );
                /* 
                render.push(
                    <div
                        key={"package" + packageItem.id}
                        className={`buy-credit-repeat-col ${packageItem.is_custom == 1 ? "custom-credit-hold" : ""
                            }  ${isLoading ? 'buy-credits-skeleton' : ''}`}
    
                        id={`${packageItem.is_custom == 1 ? "r_credits_custom" : "r_credits_"+packageItem.credits}`}
    
                        onClick={() => {
                            setSelectPackage(packageItem);
                            document.getElementById("billing-info-section").scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }}
                    >
                        <div
                            className={`buy-credit-repeat ${selected_package && selected_package.id == packageItem.id
                                ? "plan-selected"
                                : ""
                                }  `}
                        >
                            <div className="buy-credit-top">
    
                                {isLoading ?
                                    <>
                                        <h3><Skeleton /></h3>
                                    </>
                                    :
                                    <>
                                        {packageItem.is_custom == 1 && (
                                            <>
                                                <h3>{packageItem.subtitle}</h3>
                                                <p>{packageItem.description}</p>
                                            </>
    
                                        )}
    
    
    
                                        {packageItem.is_custom != 1 && (
                                            <>
                                                <span className="dis-offier-sec pink-color">
                                                    {packageItem.subtitle}
                                                </span>
                                                <h3>
                                                    {packageItem.credits} <sub>Credits</sub>
                                                </h3>
    
                                            </>
                                        )}
                                    </>
    
                                }
    
                            </div>
    
                            {isLoading ?
                                <>
                                    <div className="buy-credit-mid-sec">
                                        <p>
                                            <Skeleton height={35} />
                                        </p>
                                        <div className="buy-cr-price-wrap">
                                            <h3><Skeleton height={20} /></h3>
                                        </div>
    
                                        <Skeleton height={20} />
                                    </div>
                                </>
                                :
                                <>
                                    {packageItem.is_custom == 1 && (
                                        <div className="buy-credit-mid-sec">
                                            <div className="buy-custom-credit-form">
                                                <div className="input form-group input-field">
                                                    <InputField
                                                        type={"text"}
                                                        min="50"
                                                        inline_label={"credits"}
                                                        label={"Total credits"}
                                                        id="credit"
                                                        value={formValues.credits}
                                                        onChange={(event) => {
    
                                                            removePromoCode();
    
                                                            setFormValues({ ...formValues, credits: event.target.value })
                                                            //setCredits(event.target.value);
    
                                                            setFormValues({ ...formValues, credits: event.target.value });
    
                                                            var credits = parseInt(event.target.value);
    
                                                            if (credits < 500) {
                                                                setCreditPrice(1);
                                                            } else if (credits >= 500 && credits < 1000) {
                                                                setCreditPrice(0.95);
                                                            } else if (credits >= 1000 && credits < 1500) {
                                                                setCreditPrice(0.9);
                                                            } else if (credits >= 1500 && credits < 2000) {
                                                                setCreditPrice(0.85);
                                                            } else if (credits >= 2000) {
                                                                setCreditPrice(0.8);
                                                            }
                                                            setCreditsError(null);
    
                                                            let regExp = /[\u0600-\u06FF]/g;
    
                                                            let found = regExp.test(event.target.value);
    
                                                            if(found){
                                                                setCreditsError("[0-9] allowed.");
                                                            }
                                                            regExp = /^[0-9]*$/;
    
                                                            found = regExp.test(event.target.value);
    
                                                            if(!found){
                                                                setCreditsError("[0-9] allowed.");
                                                            }
    
    
                                                        }}
    
                                                        error_class={"input_error"}
                                                        error={credits_error}
                                                        onBlur={(event) => {
    
    
                                                            if (parseInt(event.target.value) < 50 || event.target.value == "") {
                                                                setFormValues({ ...formValues, credits: "50" });
    
                                                            }
                                                            var credits = parseInt(event.target.value);
    
                                                            if (credits < 500) {
                                                                setCreditPrice(1);
                                                            } else if (credits >= 500 && credits < 1000) {
                                                                setCreditPrice(0.95);
                                                            } else if (credits >= 1000 && credits < 1500) {
                                                                setCreditPrice(0.9);
                                                            } else if (credits >= 1500 && credits < 2000) {
                                                                setCreditPrice(0.85);
                                                            } else if (credits >= 2000) {
                                                                setCreditPrice(0.8);
                                                            }
                                                        }}
                                                        name="credits"
                                                    />
    
                                                </div>
                                            </div>
                                            <button
                                                className="btn secondary-btn"
                                                onClick={() => {
                                                    addCustomCredits(packageItem, packageItem.discounted_price);
                                                }}
                                            >
                                                <span className="without-hover-btn">
                                                     {"Buy for $" +
                                                        (formValues.credits
                                                            ? roundNumber(packageItem.discounted_price * parseInt(formValues.credits) * creditPrice, 2)
                                                            : 0)}
                                                </span>
                                                <span className="hover-sate-btn">{"Buy for $" +
                                                    (formValues.credits
                                                        ? roundNumber(packageItem.discounted_price * parseInt(formValues.credits) * creditPrice, 2)
                                                        : 0)}</span>
                                            </button>
                                        </div>
                                    )}
                                    {packageItem.is_custom != 1 && (
                                        <div className="buy-credit-mid-sec">
                                            <p>
                                                <b>{packageItem.description} </b>
                                            </p>
                                            <div className="buy-cr-price-wrap">
    
    
                                                {!packageItem.discounted_price && (
                                                    <h3>${roundNumber(packageItem.discounted_price, 2)} /credit</h3>
                                                )}
                                                {packageItem.discounted_price && (
                                                    <h3 className="dis-price-wrap pink-color">
                                                        ${roundNumber(packageItem.discounted_price, 2)} /credit
                                                        
                                                    </h3>
                                                )}
                                            </div>
                                            <button
                                                className="btn secondary-btn"
                                                onClick={() => {
                                                    setSelectPackage(packageItem);
                                                }}
                                            >
                                                <span className="without-hover-btn">
                                                    {"Buy for $" + roundNumber(packageItem.price, 2)}
                                                </span>
                                                <span className="hover-sate-btn">{"Buy for $" + roundNumber(packageItem.price, 2)}</span>
                                            </button>
                                        </div>
                                    )}
                                </>
    
                            }
    
                        </div>
                    </div>
                );  
                */
            }
        });

        return render;
    };
    const renderPackages = () => {
        const packageData = isLoading
            ? [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]
            : packages;
        return renderPackagesHtmlWithLoader(packageData);
    };
    const renderCustomPackage = () => {
        if (isLoading) {
            return (
                <div className="buy-credit-mid-sec">
                    <div className="package-top-section">
                        <Text type={"h2"}>
                            <Skeleton width={"90%"} />
                        </Text>
                        <Text type={"body-text-3"} fontWeight="medium-font">
                            <Skeleton width={"90%"} />
                        </Text>
                        <Skeleton width={"100%"} height={50} />
                        <Text type={"body-text-3"} fontWeight="semi-bold-font">
                            <Skeleton width={"80px"} />
                        </Text>
                    </div>
                    <div className="package-buld-amount-discount">
                        <Text type={"body-text-3"} fontWeight="semi-bold-font">
                            <Skeleton width={"270px"} />
                        </Text>
                    </div>
                    <div className="package-button-wrapper">
                        <Text type={"body-text-2"} fontWeight="semi-bold-font">
                            <Skeleton width={"150px"} />
                        </Text>
                        <Text type={"h2"}>
                            <Skeleton width={"50px"} />
                        </Text>
                        <Skeleton
                            width={"100%"}
                            height={"60px"}
                            borderRadius={50}
                            style={{ marginTop: "50px" }}
                        />
                    </div>
                </div>
            );
        }
        return packages.map(function (packageItem) {
            if (packageItem.is_custom == 1) {
                return (
                    <div className="buy-credit-mid-sec">
                        <div className="package-top-section">
                            <Text type={"h2"}>
                                How many credits do you need?
                            </Text>
                            <Text type={"body-text-3"} fontWeight="medium-font">
                                {packageItem.description}
                            </Text>
                            <Input
                                clearField={false}
                                required={true}
                                type={"text"}
                                maxLength={5}
                                hideMaxChars={true}
                                min="50"
                                inline_label={"credits"}
                                label={"Credits"}
                                id="credit"
                                value={formValues.credits}
                                onChange={(event) => {
                                    const re = /^[0-9\b]+$/;

                                    // if value is not blank, then test the regex

                                    if (
                                        event.target.value === "" ||
                                        re.test(event.target.value)
                                    ) {
                                        removePromoCode();

                                        setFormValues({
                                            ...formValues,
                                            discount: 0,
                                            promo_code: "",
                                            credits: event.target.value,
                                        });

                                        var credits = parseInt(
                                            event.target.value
                                        );

                                        var packageMatch = null;

                                        var creditPrice = null;

                                        var discountTag = null;

                                        packages.forEach(function (
                                            packageItem
                                        ) {
                                            if (
                                                packageItem.credits == credits
                                            ) {
                                                packageMatch = packageItem;

                                                creditPrice =
                                                    packageItem.discounted_price;

                                                discountTag =
                                                    packageItem.subtitle;
                                            }
                                        });

                                        if (!packageMatch) {
                                            if (credits < 500) {
                                                packages.forEach(function (
                                                    packageItem
                                                ) {
                                                    if (
                                                        packageItem.is_custom ==
                                                        1
                                                    ) {
                                                        packageMatch =
                                                            packageItem;

                                                        creditPrice =
                                                            packageItem.discounted_price;

                                                        discountTag =
                                                            packageItem.subtitle;
                                                    }
                                                });
                                            } else {
                                                packages.forEach(function (
                                                    packageItem
                                                ) {
                                                    if (
                                                        packageItem.is_custom ==
                                                        1
                                                    ) {
                                                        packageMatch =
                                                            packageItem;
                                                    }
                                                    if (
                                                        credits >=
                                                        packageItem.credits
                                                    ) {
                                                        creditPrice =
                                                            packageItem.discounted_price;

                                                        discountTag =
                                                            packageItem.subtitle;
                                                    }
                                                });
                                            }
                                        }

                                        if (packageMatch != null) {
                                            setCreditPrice(creditPrice);

                                            setSelectPackage(packageMatch);

                                            setDiscountTag(discountTag);
                                        } else {
                                            setSelectPackage(null);

                                            setDiscountTag(null);

                                            setCreditPrice(1);
                                        }

                                        /*if (credits < 500) {
                                            setCreditPrice(1);
                                        } else if (credits >= 500 && credits < 1000) {
                                            setCreditPrice(0.95);
                                        } else if (credits >= 1000 && credits < 1500) {
                                            setCreditPrice(0.9);
                                        } else if (credits >= 1500 && credits < 2000) {
                                            setCreditPrice(0.85);
                                        } else if (credits >= 2000) {
                                            setCreditPrice(0.8);
                                        }*/
                                        setCreditsError(null);

                                        let regExp = /[\u0600-\u06FF]/g;

                                        let found = regExp.test(
                                            event.target.value
                                        );

                                        if (found) {
                                            setCreditsError("[0-9] allowed.");
                                        }
                                        regExp = /^[0-9]*$/;

                                        found = regExp.test(event.target.value);

                                        if (!found) {
                                            setCreditsError("[0-9] allowed.");
                                        }

                                        if (credits < 50) {
                                            setCreditsError(
                                                "A minimum of 50 credits need to be purchased per transaction."
                                            );
                                        }
                                    }
                                }}
                                error_class={"input_error"}
                                error={credits_error}
                                name="credits"
                            />
                            <Text
                                type={"body-text-3"}
                                fontWeight="semi-bold-font"
                            >
                                1 credit=$
                                {creditPrice != 1
                                    ? amountFormat(creditPrice)
                                    : creditPrice}
                            </Text>
                        </div>
                        <div className="package-buld-amount-discount">
                            <Text
                                type={"body-text-3"}
                                fontWeight="semi-bold-font"
                            >
                                Get upto <span>20%</span> discount on bulk
                                amount
                            </Text>
                        </div>
                        <div className="package-button-wrapper">
                            <div className="d-flex justify-content-between credit-price-hold">
                                <div className="buy-credits-price-wrap-class">
                                    <Text
                                        type={"body-text-2"}
                                        fontWeight="semi-bold-font"
                                    >
                                        Price for {formValues.credits} credits
                                    </Text>
                                    <div className="d-flex  align-items-center">
                                        <Text type={"h2"}>
                                            $
                                            {formValues.credits
                                                ? amountFormat(
                                                      packageItem.discounted_price *
                                                          parseInt(
                                                              formValues.credits
                                                          ) *
                                                          creditPrice,
                                                      2
                                                  )
                                                : 0}
                                        </Text>
                                        {discountTag &&
                                            discountTag != "How many?" && (
                                                <Tag
                                                    type={"pink-filled-tag"}
                                                    title={discountTag}
                                                />
                                            )}
                                    </div>
                                </div>

                                <div className="credits-deposited text-right">
                                    <Text
                                        type={"caption"}
                                        fontWeight="medium-font"
                                        cssClasses={"d-block mb-12"}
                                    >
                                        Credits will be deposited into
                                    </Text>

                                    <div className="buy-credits-price-wrap-class">
                                        <Text
                                            type={"body-text-2"}
                                            fontWeight="semi-bold-font"
                                        >
                                            {(function () {
                                                let workspace =
                                                    ReactSession.get(
                                                        "workspace"
                                                    );
                                                return workspace
                                                    ? workspace.title
                                                    : "";
                                            })()}
                                        </Text>
                                    </div>
                                </div>
                            </div>
                            <Button
                                type={"primary"}
                                size={"large"}
                                label={"Check out"}
                                onClick={() => {
                                    if (
                                        workspace &&
                                        workspace.status === "frozen" &&
                                        teamMember &&
                                        teamMember.role === "member"
                                    ) {
                                        setOpenFrozenModal(openFrozenModal + 1);

                                        return;
                                    }
                                    if (!formValues.credits) {
                                        setCreditsError("Required");
                                    }
                                    setFormValues({
                                        ...formValues,
                                        discount: 0,
                                        promo_code: "",
                                    });

                                    ///setCreditsError("");

                                    setCouponError("");

                                    resetFormErrors();

                                    if (formValues.credits >= 50) {
                                        setCheckoutModal({
                                            ...checkoutModal,
                                            open: true,
                                        });
                                    }

                                    var data_layer_ecommerce = {};

                                    data_layer_ecommerce["currency"] = "USD";

                                    data_layer_ecommerce["value"] =
                                        formValues.credits
                                            ? amountFormat(
                                                  packageItem.discounted_price *
                                                      parseInt(
                                                          formValues.credits
                                                      ) *
                                                      creditPrice,
                                                  2
                                              )
                                            : 0;

                                    data_layer_ecommerce["items"] = [];

                                    data_layer_ecommerce["items"].push({
                                        item_id: "1",
                                        item_name: "Credits",
                                    });
                                    if (
                                        data_layer_ecommerce["items"].length > 0
                                    ) {
                                        data_layer_ecommerce["items"][0][
                                            "price"
                                        ] = creditPrice;
                                        data_layer_ecommerce["items"][0][
                                            "quantity"
                                        ] = formValues.credits;
                                        data_layer_ecommerce["items"][0][
                                            "index"
                                        ] = 0;

                                        if (creditPrice < 1) {
                                            data_layer_ecommerce["items"][0][
                                                "discount"
                                            ] = amountFormat(
                                                1 - creditPrice,
                                                2
                                            );
                                        }
                                    }
                                    window.dataLayer = window.dataLayer || [];
                                    window.dataLayer.push({ ecommerce: null });
                                    window.dataLayer.push({
                                        event: "begin_checkout",
                                        ecommerce: data_layer_ecommerce,
                                    });
                                    console.log({
                                        event: "begin_checkout",
                                        ecommerce: data_layer_ecommerce,
                                    });
                                }}
                                state={
                                    formValues.credits >= 50
                                        ? "active"
                                        : "disabled"
                                }
                            />
                        </div>
                    </div>
                );
            } else {
                return <></>;
            }
        });
    };
    useEffect(
        function () {
            scroll();
        },
        [firstErrorRef]
    );

    const scroll = () => {
        if (errorRef && errorRef.current) {
            const y =
                errorRef.current.getBoundingClientRect().top +
                window.pageYOffset -
                100;

            window.scrollTo({ top: y, behavior: "smooth" });
        }
    };

    const getProjectsApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getProjects(token, abortController.signal)
            .then((response) => {
                if (response.success) {
                    setProjects(response.projects);
                    setUserSettings(response.settings);
                }
                setLoading(false);
            })
            .catch(function (err) {
                console.error(` Err: ${err}`);
            });
    };

    const getPaymentMethods = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getPaymentMethodsService(token).then((response) => {
            setLoading(false);

            if (response.success) {
                /*if (response.payment_methods.length > 0) {
                    setFormValue("payment_method_id", response.payment_methods[0].id);
                }*/
                var exist = false;
                response.payment_methods.forEach(function (method) {
                    if (method.default == 1) {

                        if(method.card_expire_status!=2){
                            exist = true;
                            setFormValue("payment_method_id", method.id);
                        }
                    }
                });
                if (!exist) {
                    if (response.payment_methods.length > 0) {

                        if(response.payment_methods[0].card_expire_status!=2) {
                            setFormValue(
                                "payment_method_id",
                                response.payment_methods[0].id
                            );
                        }
                    }
                }
                setPaymentMethods(response.payment_methods);
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const deletePaymentMethod = (id) => {
        let token = ReactSession.get("token");

        setLoading(true);

        deletePaymentMethodService({ id: id }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                getPaymentMethods();
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const fetchPackages = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getPackagesService(token).then((response) => {
            setLoading(false);

            if (response.success) {
                setPackages(response.packages);

                response.packages.forEach(function (packageItem) {
                    if (packageItem.is_custom == 1) {
                        setSelectPackage(packageItem);
                    }
                });
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const getBillingInfo = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getBillingInfoService(token).then((response) => {
            setLoading(false);

            if (response.success) {
                //setFormValues()

                for (var key in response.billing_address) {
                    if (response.billing_address[key] == null) {
                        response.billing_address[key] = "";
                    }
                }
                var country = null;

                countries.forEach(function (item) {
                    if (item.value == response.billing_address.country) {
                        country = item;
                    }
                });
                if (response.billing_address.country == "") {
                    setCountryLabelClass("inactive_label");
                } else {
                    setCountryLabelClass("active_label");
                }
                setBillingInfo((oldValues) => ({
                    ...oldValues,
                    ["address"]: response.billing_address.address,
                    ["city"]: response.billing_address.city,
                    ["country"]: country,
                    ["name"]: response.billing_address.name,
                }));

                setFormValues((oldValues) => ({
                    ...oldValues,
                    ["address"]: response.billing_address.address,
                    ["city"]: response.billing_address.city,
                    ["country"]: country,
                    ["name"]: response.billing_address.name,
                }));
                if (!response.billing_address.name) {
                    setBillingEditable(true);
                }
                window.setLabels();
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    useEffect(function () {
        // document.title =  "Buy credits | "+process.env.REACT_APP_NAME;
        getWorkspaceApi();
        fetchPackages();
        //getBillingInfo();
        //getProjectsApi();

        // rediret to dashboard if role is collaborator editor
        if (user.permission === "Editor") {
            navigate("/dashboard");
        }

        getPaymentMethods();
    }, []);
    useEffect(
        function () {
            setTimeout(function () {
                window.setLabels();
            }, 100);
        },
        [formValues, isLoading]
    );
    const removePromoCode = () => {
        setFormValues({ ...formValues, discount: 0, promo_code: "" });
    };
    const applyCouponApi = () => {
        if (!couponLoading) {
            resetFormErrors();

            var error = false;

            if (!formValues.promo_code) {
                error = true;
                setFormErrors({
                    ...formErrors,
                    promo_code: "Required field",
                    error_class: "input_error",
                });
            } else if (creditPrice < 1) {
                error = true;

                setFormValues({
                    ...formValues,
                    promo_code: "",
                    description: "",
                });

                showToast(
                    "Promo Codes cannot be applied to bulk buy discounts.",
                    "error"
                );
            }
            if (!error) {
                setCouponLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData.promo_code = formValues.promo_code;

                if (selected_package && selected_package.is_custom != 1) {
                    formData.credits = selected_package.credits;

                    formData.amount = selected_package.price;
                } else {
                    formData.credits = formValues.credits;

                    formData.amount = formValues.credits;
                }

                applyCouponService(formData, token).then((response) => {
                    setCouponLoading(false);

                    if (response.success) {
                        setFormValues({
                            ...formValues,
                            discount: response.discount,
                            description: response.description,
                        });

                        showToast(response.message, "success");

                        /*var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "successtoast",
                                position: "bottom-center",
                            }
                        );*/
                    } else {
                        setCouponError(response.message);

                        /*var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );*/
                    }
                });
            }
        }
    };
    const buyCreditsApi = () => {
        resetFormErrors();

        if (!formLoading) {
            var error = false;

            var card_error = false;

            if (formValues.payment_method_id == "") {
                card_error = validatePaymentMethodForm() || sameCardError;

                error = card_error;
            }
            /*setFirstErrorRef(null);

            if (!selected_package) {

                showToast("Please select package","error");
                var id = toast(
                    <div className="toastinner">
                        Please select package
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
                error = true;
            } else if (formValues.payment_method_id == "") {

                showToast("Please select payment_method","error");

                var id = toast(
                    <div className="toastinner">
                        Please select payment_method
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
                error = true;
            }*/
            /*if (!error) {
                var address_error = false;

                var first_error_ref = null;
                var form_errors = formErrors;

                if (formValues.name == "") {
                    // showFormError("name", "Required field");
                    form_errors = {
                        ...form_errors,
                        name: "Required field",
                        error_class: "input_error",
                    };
                    address_error = true;
                    error = true;
                    if (!first_error_ref) {

                        first_error_ref = "name";
                    }
                }
                if (formValues.city == "") {
                    // showFormError("city", "Required field");
                    form_errors = {
                        ...form_errors,
                        city: "Required field",
                        error_class: "input_error",
                    };
                    address_error = true;
                    error = true;
                    if (!first_error_ref) {

                        first_error_ref = "city";
                    }
                }
                if (!formValues.country) {
                    // showFormError("country", "Required field");
                    form_errors = {
                        ...form_errors,
                        country: "Required field",
                        error_class: "input_error",
                    };
                    address_error = true;
                    error = true;
                    if (!first_error_ref) {

                        first_error_ref = "country";
                    }
                }
                if (formValues.address == "") {
                    // showFormError("address", "Required field");
                    form_errors = {
                        ...form_errors,
                        address: "Required field",
                        error_class: "input_error",
                    };
                    address_error = true;
                    error = true;
                    if (!first_error_ref) {

                        first_error_ref = "address";
                    }
                }
                setFormErrors(form_errors);
            }

            if (address_error) {

                if (first_error_ref) {
                    if (errorRef && errorRef.current) {

                        scroll();
                    }
                    setFirstErrorRef(first_error_ref);
                }
                var id = toast(
                    <div className="toastinner">
                        Please reverify billing information. Some of the required fields are missing.
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }*/

            if (!error) {
                // setCheckoutModal({...checkoutModal, open:false});

                setFormLoading(true);

                const token = ReactSession.get("token");

                var formData = Object.assign({}, formValues);

                formData["package_id"] = selected_package.id;

                formData["card_no"] = encryptClient(formData.card_no);

                formData["cvv"] = encryptClient(formData.cvv);

                formData["workspace_id"] = workspaceid;

                /*if (formValues.country) {
                    formData["country"] = formValues.country.value;
                }*/
                buyCreditsService(formData, token).then((response) => {
                    if (response.success) {
                        if (response.client_secret) {
                            setFormValues({
                                ...formValues,
                                order_id: response.order_id,
                                invoice_id: response.invoice_id,
                            });

                            stripe
                                .confirmCardPayment(response.client_secret)
                                .then(handleStripeJsResult);
                        } else {
                            setFormLoading(false);
                            setCheckoutModal({ ...checkoutModal, open: false });
                            reset();

                            showToast(
                                "Credits purchased successfully",
                                "success"
                            );

                            var data_layer_ecommerce = {};

                            data_layer_ecommerce["currency"] = "USD";

                            if (response.purchase_details.order.promo_code) {
                                data_layer_ecommerce["coupon"] =
                                    response.purchase_details.order.promo_code;
                            }
                            data_layer_ecommerce["value"] =
                                response.purchase_details.order.amount;

                            data_layer_ecommerce["transaction_id"] =
                                response.purchase_details.transaction_id;

                            data_layer_ecommerce["items"] = [];

                            data_layer_ecommerce["items"].push({
                                item_id: "1",
                                item_name: "Credits",
                            });
                            if (data_layer_ecommerce["items"].length > 0) {
                                data_layer_ecommerce["items"][0]["price"] =
                                    creditPrice;
                                data_layer_ecommerce["items"][0]["quantity"] =
                                    response.purchase_details.order.credits;
                                data_layer_ecommerce["items"][0]["index"] = 0;

                                if (creditPrice < 1) {
                                    data_layer_ecommerce["items"][0][
                                        "discount"
                                    ] = roundNumber(1 - creditPrice, 2);
                                }
                            }

                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({ ecommerce: null });
                            window.dataLayer.push({
                                event: "purchase",
                                ecommerce: data_layer_ecommerce,
                            });
                            console.log({
                                event: "purchase",
                                ecommerce: data_layer_ecommerce,
                            });

                            if (searchParams.get("return")) {
                                navigate(-1);
                            } else {
                                navigate("/wsp/" + workspaceid + "/credits");
                                //setCheckoutModal({...checkoutModal,open:false});
                                //setOpenCreditConfirmationModal(true);
                            }
                        }
                    } else {
                        setFormLoading(false);

                        if (
                            response.error_code &&
                            response.error_code === "same_card_twice"
                        ) {
                            setSameCardError(true);
                        }

                        showToast(response.message, "error");
                        /*if(true){
                            navigate(
                                "/wsp/" + workspaceid + "/noaccess",
                            );
                        }*/

                        /*var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );*/
                    }
                });
            }
        }
    };
    function handleStripeJsResult(result) {
        if (result.error) {
            setFormLoading(false);
            // Show error in payment form
        } else {
            setFormLoading(true);

            var formData = formValues;

            formData["package_id"] = selected_package.id;

            const token = ReactSession.get("token");

            formData["payment_intent_id"] = result.paymentIntent.id;

            formData["workspace_id"] = workspaceid;

            makePaymentService(formData, token).then((response) => {
                setFormLoading(false);

                if (response.success) {
                    setCheckoutModal({ ...checkoutModal, open: false });

                    showToast("Credits purchased successfully", "success");

                    var data_layer_ecommerce = {};

                    data_layer_ecommerce["currency"] = "USD";

                    if (response.purchase_details.order.promo_code) {
                        data_layer_ecommerce["coupon"] =
                            response.purchase_details.order.promo_code;
                    }
                    data_layer_ecommerce["value"] =
                        response.purchase_details.order.amount;

                    data_layer_ecommerce["transaction_id"] =
                        response.purchase_details.transaction_id;

                    data_layer_ecommerce["items"] = [];

                    data_layer_ecommerce["items"].push({
                        item_id: "1",
                        item_name: "Credits",
                    });
                    if (data_layer_ecommerce["items"].length > 0) {
                        data_layer_ecommerce["items"][0]["price"] = creditPrice;
                        data_layer_ecommerce["items"][0]["quantity"] =
                            response.purchase_details.order.credits;
                        data_layer_ecommerce["items"][0]["index"] = 0;

                        if (creditPrice < 1) {
                            data_layer_ecommerce["items"][0]["discount"] =
                                roundNumber(1 - creditPrice, 2);
                        }
                    }

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({ ecommerce: null });
                    window.dataLayer.push({
                        event: "purchase",
                        ecommerce: data_layer_ecommerce,
                    });
                    console.log({
                        event: "purchase",
                        ecommerce: data_layer_ecommerce,
                    });
                    navigate("/wsp/" + workspaceid + "/credits");
                } else {
                    var id = toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss(id);
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                        }
                    );

                    /*if(true){
                        navigate(
                            "/wsp/" + workspaceid + "/noaccess",
                        );
                    }*/
                }
            });
        }
    }

    const validatePaymentMethodForm = () => {
        var error = false;

        var form_errors = formErrors;

        var firsterrorid = null;

        if (formValues.card_no == "") {
            form_errors = {
                ...form_errors,
                card_no: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        } else if (!validator.isCreditCard(formValues.card_no)) {
            showFormError("card_no", "Enter valid Credit Card Number!");
            form_errors = {
                ...form_errors,
                card_no: "Enter valid Credit Card Number!",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        }
        /*if (formValues.card_name == "") {
            form_errors = {
                ...form_errors,
                card_name: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "cardholder-name" : firsterrorid;
        }*/
        var new_exp_date = formValues.exp_date.replaceAll(" ", "");

        var new_exp_date_arr = new_exp_date.split("/");

        if (new_exp_date.length == 5) {
            if (
                new Date().getTime() >
                    new Date(
                        "20" +
                            new_exp_date_arr[1] +
                            "-" +
                            new_exp_date_arr[0] +
                            "-01"
                    ).getTime() ||
                isNaN(
                    new Date(
                        "20" +
                            new_exp_date_arr[1] +
                            "-" +
                            new_exp_date_arr[0] +
                            "-01"
                    ).getTime()
                )
            ) {
                form_errors = {
                    ...form_errors,
                    exp_date: "Expiry cannot be past date",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid =
                    firsterrorid == null ? "expiration-date" : firsterrorid;
            }
        } else if (new_exp_date.length > 0) {
            form_errors = {
                ...form_errors,
                exp_date: "Invalid CVV/CVC",
                error_class: "input_error",
            };
            error = true;
            firsterrorid =
                firsterrorid == null ? "expiration-date" : firsterrorid;
        }
        if (formValues.cvv == "") {
            form_errors = {
                ...form_errors,
                cvv: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "cvv" : firsterrorid;
        }

        setFormErrors(form_errors);

        console.log(firsterrorid);
        if (firsterrorid != null) {
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        return error;
    };

    const addNewPaymentMethod = () => {
        resetFormErrors();

        if (!cardFormLoading) {
            var error = false;

            // resetFormErrors();

            error = validatePaymentMethodForm();

            if (!error) {
                setCardFormLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData["card_no"] = encryptClient(formValues.card_no);

                formData["exp_date"] = formValues.exp_date;

                formData["cvv"] = encryptClient(formValues.cvv);

                formData["card_name"] = formValues.card_name;

                addNewPaymentMethodService(formData, token).then((response) => {
                    setCardFormLoading(false);

                    if (response.success) {
                        setFormValue("card_name", "");
                        setFormValue("card_no", "");
                        setFormValue("cvv", "");
                        setFormValue("exp_date", "");

                        getPaymentMethods();

                        setAddNewCard(false);

                        window.setLabels();
                    } else {
                        var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }
                });
            }
        }
    };
    const updateBillingInfo = () => {
        resetFormErrors();

        if (!billingLoading) {
            var error = false;

            resetFormErrors();

            if (formValues.name == "") {
                showFormError("name", "Required field");
                error = true;
            } else if (formValues.address == "") {
                showFormError("address", "Required field");
                error = true;
            } else if (formValues.city == "") {
                showFormError("city", "Required field");
                error = true;
            } else if (!formValues.country) {
                showFormError("country", "Required field");
                error = true;
            }

            if (!error) {
                setBillingLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData["name"] = formValues.name;

                formData["address"] = formValues.address;

                formData["country"] = formValues.country.value;

                formData["city"] = formValues.city;

                updateBillingInfoService(formData, token).then((response) => {
                    setBillingLoading(false);

                    if (response.success) {
                        for (var key in response.billing_address) {
                            if (response.billing_address[key] == null) {
                                response.billing_address[key] = "";
                            }
                        }
                        var country = null;

                        countries.forEach(function (item) {
                            if (
                                item.value == response.billing_address.country
                            ) {
                                country = item;
                            }
                        });

                        setBillingInfo((oldValues) => ({
                            ...oldValues,
                            ["address"]: response.billing_address.address,
                            ["city"]: response.billing_address.city,
                            ["country"]: country,
                            ["name"]: response.billing_address.name,
                        }));

                        setFormValues((oldValues) => ({
                            ...oldValues,
                            ["address"]: response.billing_address.address,
                            ["city"]: response.billing_address.city,
                            ["country"]: country,
                            ["name"]: response.billing_address.name,
                        }));

                        setBillingEditable(false);

                        window.setLabels();
                    } else {
                        var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }
                });
            }
        }
    };

    const getWorkspaceApi = () => {
        let token = ReactSession.get("token");
        setLoading(true);
        getWorkspaceService(token, workspaceid)
            .then((response) => {
                if (response.success) {
                    setLoading(false);

                    let user = ReactSession.get("user");

                    if (response.workspace && response.workspace_team_member) {
                        setWorkspace(response.workspace);
                        setTeamMember(response.workspace_team_member);
                        if (
                            response.workspace_team_member.status !==
                                "invitation_accepted" &&
                            response.workspace_team_member.status !== "active"
                        ) {
                            navigate(
                                "/wsp/" + response.workspace.id + "/noaccess"
                            );
                        }
                    }
                } else {
                    if (response.message === "Workspace not found.") {
                        navigate("/404/");
                    }
                    setLoading(false);
                    showToast(response.message, "error");
                }
            })
            .catch((error) => {
                console.error("Error fetching workspace:", error);

                setLoading(false);
            });
    };

    const resetPaymentMethods = () => {
        setSameCardError(false);
        var exist = false;
        paymentMethods.forEach(function (method) {
            if (method.default == 1) {

                if(method.card_expire_status!==2){
                    exist = true;
                    // setFormValue("payment_method_id", method.id);
                    setFormValues({
                        ...formValues,
                        card_no: "",
                        exp_date: "",
                        cvv: "",
                        //credits: 50,
                        promo_code: "",
                        description: "",
                        discount: 0,
                        applied: false,
                        future_use: true,
                        payment_method_id: method.id,
                    });
                }
            }
        });
        if (!exist) {
            if (paymentMethods.length > 0) {
                
                if(paymentMethods[0].card_expire_status !==2){
                    setFormValues({
                        ...formValues,
                        card_no: "",
                        exp_date: "",
                        cvv: "",
                       // credits: formValues.credits ? formValues.credits:"50" ,
                        promo_code: "",
                        description: "",
                        discount: 0,
                        applied: false,
                        future_use: true,
                        payment_method_id: paymentMethods[0].id,
                    });
                }
            }
        }

        // check if user have not added any card then adding new card will be enable automatically
        if (paymentMethods.length === 0) {
            setAddNewCard(true);
            setFormValues({
                ...formValues,
                card_no: "",
                exp_date: "",
                cvv: "",
                //credits: "50",
                promo_code: "",
                description: "",
                discount: 0,
                applied: false,
                future_use: true,
                payment_method_id: "",
            });
        } else {
            setAddNewCard(false);
        }
    };

    return (
        <LayoutResearcher
            isLoading={false}
            wrapClass={`buy-credits-wrapper  ${
                workspace &&
                workspace.status == "frozen" &&
                teamMember &&
                teamMember.role == "member"
                    ? "frozen-account"
                    : ""
            }`}
            isWorkspaceFrozen={
                workspace &&
                workspace.status == "frozen" &&
                teamMember &&
                teamMember.role == "member"
                    ? true
                    : false
            }
            skipCheck={true}
            extendedFooter={false}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
            openFrozenModal={openFrozenModal}
        >
            <Helmet>
                <title>{"Buy credits"} | UserQ</title>
            </Helmet>

            <div className="page-heading-wrap page-heading-wrap d-flex  align-items-center justify-content-between">
                <Text type="h1">Buy Credits</Text>
            </div>
            <div className="buy-credits-page-wrapper">
                <div className="buy-credits-packages-wrapper">
                    <div className="row">
                        <div className="col-md-6 custom-package-col-wrap">
                            <div className="buy-credit-option-wrap">
                                {renderCustomPackage()}
                            </div>
                        </div>
                        <div className="col-md-6 buy-credits-col-wrap">
                            <div className="buy-credit-option-wrap">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Credits</th>
                                            <th>Price Per Credit</th>
                                            <th>Buy it for</th>
                                        </tr>
                                    </thead>
                                    <tbody>{renderPackages()}</tbody>
                                </table>
                                <Text
                                    type={"body-text-2"}
                                    fontWeight={"medium-font"}
                                >
                                    You can use credits to recruit participants
                                    from the UserQ panel for your tests at any
                                    time. Plus, they don't expire – so take
                                    advantage of bulk-buying discounts now.
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
                {!isLoading && false && (
                    <>
                        <div className="profile-form-repeat">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-info-text">
                                        <h3
                                            className="d-flex align-items-center bill-h-dflex"
                                            id="billing-info-section"
                                        >
                                            Billing information
                                            {user.permission != "Editor" && (
                                                <>
                                                    {!isBillingEditable && (
                                                        <span
                                                            className="edit-icon"
                                                            onClick={() => {
                                                                setBillingEditable(
                                                                    true
                                                                );
                                                                setTimeout(
                                                                    function () {
                                                                        window.setLabels();
                                                                    },
                                                                    100
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_URL +
                                                                    "images/edit-icon.svg"
                                                                }
                                                            />
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    {isBillingEditable && (
                                        <div className="row">
                                            <div
                                                className="col-md-6"
                                                ref={
                                                    firstErrorRef == "name"
                                                        ? errorRef
                                                        : null
                                                }
                                            >
                                                <div className="profile-form-group">
                                                    <div className="input form-group input-field">
                                                        <input
                                                            type="text"
                                                            readOnly={
                                                                !isBillingEditable
                                                            }
                                                            className={`form-control ${
                                                                formErrors.name !=
                                                                null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                            } `}
                                                            value={
                                                                formValues.name
                                                            }
                                                            onChange={set(
                                                                "name"
                                                            )}
                                                            id="name"
                                                        />
                                                        <label
                                                            htmlFor="name"
                                                            className="control-label"
                                                        >
                                                            Name/Company name
                                                        </label>
                                                        <span
                                                            className="clear"
                                                            onClick={clearFormValue(
                                                                "name"
                                                            )}
                                                        >
                                                            <svg viewBox="0 0 24 24">
                                                                <path
                                                                    className="line"
                                                                    d="M2 2L22 22"
                                                                />
                                                                <path
                                                                    className="long"
                                                                    d="M9 15L20 4"
                                                                />
                                                                <path
                                                                    className="arrow"
                                                                    d="M13 11V7"
                                                                />
                                                                <path
                                                                    className="arrow"
                                                                    d="M17 11H13"
                                                                />
                                                            </svg>
                                                        </span>
                                                        {formErrors.name !=
                                                            null && (
                                                            <span
                                                                className={
                                                                    formErrors.error_class
                                                                }
                                                            >
                                                                {
                                                                    formErrors.name
                                                                }
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="profile-form-group ">
                                                    <div className="input input-field form-group read-only-field">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={user.email}
                                                            readOnly
                                                            id="email"
                                                        />
                                                        <label htmlFor="email">
                                                            Email
                                                        </label>
                                                        <div className="data-field-icon">
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_URL +
                                                                    "images/fill-check.svg"
                                                                }
                                                                alt="icon"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-md-6"
                                                ref={
                                                    firstErrorRef == "country"
                                                        ? errorRef
                                                        : null
                                                }
                                            >
                                                <div className="profile-form-group">
                                                    <div className="input-field mb-0">
                                                        <div
                                                            className={`${
                                                                formErrors.country !=
                                                                null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                            } `}
                                                        >
                                                            <label
                                                                className={
                                                                    countryLabelClass
                                                                }
                                                                htmlFor="country"
                                                            >
                                                                Country
                                                            </label>
                                                            <Select
                                                                isClearable
                                                                id="country"
                                                                isDisabled={
                                                                    !isBillingEditable
                                                                }
                                                                value={
                                                                    formValues.country
                                                                        ? formValues.country
                                                                        : null
                                                                }
                                                                placeholder="Country"
                                                                options={
                                                                    countries
                                                                }
                                                                onChange={(
                                                                    country
                                                                ) => {
                                                                    if (
                                                                        country ==
                                                                        null
                                                                    ) {
                                                                        setCountryLabelClass(
                                                                            "inactive_label"
                                                                        );
                                                                    } else {
                                                                        setCountryLabelClass(
                                                                            "active_label"
                                                                        );
                                                                        setFormErrors(
                                                                            {
                                                                                ...formErrors,
                                                                                country:
                                                                                    null,
                                                                            }
                                                                        );
                                                                    }
                                                                    setFormValue(
                                                                        "country",
                                                                        country
                                                                    );
                                                                }}
                                                                components={{
                                                                    DropdownIndicator,
                                                                }}
                                                            />
                                                        </div>
                                                        {formErrors.country !=
                                                            null && (
                                                            <span
                                                                className={
                                                                    formErrors.error_class
                                                                }
                                                            >
                                                                {
                                                                    formErrors.country
                                                                }
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-md-6"
                                                ref={
                                                    firstErrorRef == "city"
                                                        ? errorRef
                                                        : null
                                                }
                                            >
                                                <div className="profile-form-group">
                                                    <div className="input form-group input-field">
                                                        <input
                                                            type="text"
                                                            readOnly={
                                                                !isBillingEditable
                                                            }
                                                            className={`form-control ${
                                                                formErrors.city !=
                                                                null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                            } `}
                                                            value={
                                                                formValues.city
                                                            }
                                                            onChange={set(
                                                                "city"
                                                            )}
                                                            id="city"
                                                        />
                                                        <label
                                                            htmlFor="city"
                                                            className="control-label"
                                                        >
                                                            City
                                                        </label>
                                                        <span
                                                            className="clear"
                                                            onClick={clearFormValue(
                                                                "city"
                                                            )}
                                                        >
                                                            <svg viewBox="0 0 24 24">
                                                                <path
                                                                    className="line"
                                                                    d="M2 2L22 22"
                                                                />
                                                                <path
                                                                    className="long"
                                                                    d="M9 15L20 4"
                                                                />
                                                                <path
                                                                    className="arrow"
                                                                    d="M13 11V7"
                                                                />
                                                                <path
                                                                    className="arrow"
                                                                    d="M17 11H13"
                                                                />
                                                            </svg>
                                                        </span>
                                                        {formErrors.city !=
                                                            null && (
                                                            <span
                                                                className={
                                                                    formErrors.error_class
                                                                }
                                                            >
                                                                {
                                                                    formErrors.city
                                                                }
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-md-6"
                                                ref={
                                                    firstErrorRef == "address"
                                                        ? errorRef
                                                        : null
                                                }
                                            >
                                                <div className="profile-form-group">
                                                    <div className="input form-group input-field ">
                                                        <input
                                                            type="text"
                                                            readOnly={
                                                                !isBillingEditable
                                                            }
                                                            id="address "
                                                            className={`form-control ${
                                                                formErrors.address !=
                                                                null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                            } `}
                                                            value={
                                                                formValues.address
                                                            }
                                                            onChange={set(
                                                                "address"
                                                            )}
                                                        />
                                                        <label
                                                            htmlFor="address "
                                                            className="control-label"
                                                        >
                                                            Address{" "}
                                                        </label>
                                                        <span className="clear">
                                                            <svg
                                                                viewBox="0 0 24 24"
                                                                onClick={clearFormValue(
                                                                    "address"
                                                                )}
                                                            >
                                                                <path
                                                                    className="line"
                                                                    d="M2 2L22 22"
                                                                />
                                                                <path
                                                                    className="long"
                                                                    d="M9 15L20 4"
                                                                />
                                                                <path
                                                                    className="arrow"
                                                                    d="M13 11V7"
                                                                />
                                                                <path
                                                                    className="arrow"
                                                                    d="M17 11H13"
                                                                />
                                                            </svg>
                                                        </span>
                                                        {formErrors.address !=
                                                            null && (
                                                            <span
                                                                className={
                                                                    formErrors.error_class
                                                                }
                                                            >
                                                                {
                                                                    formErrors.address
                                                                }
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {billingInfo.address && (
                                                <div className="col-md-12">
                                                    <div className="flex-buttons-wrap justify-content-end">
                                                        <button
                                                            type="button"
                                                            className="btn form-btn secondary-btn"
                                                            onClick={() => {
                                                                setFormValue(
                                                                    "name",
                                                                    billingInfo.name
                                                                );
                                                                setFormValue(
                                                                    "country",
                                                                    billingInfo.country
                                                                );
                                                                setFormValue(
                                                                    "address",
                                                                    billingInfo.address
                                                                );
                                                                setFormValue(
                                                                    "city",
                                                                    billingInfo.city
                                                                );
                                                                resetFormErrors();
                                                                setBillingEditable(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn form-btn"
                                                            onClick={
                                                                updateBillingInfo
                                                            }
                                                        >
                                                            Save
                                                            {billingLoading && (
                                                                <i
                                                                    className="fa fa-spinner fa-spin"
                                                                    aria-hidden="true"
                                                                    style={{
                                                                        marginLeft:
                                                                            "5px",
                                                                    }}
                                                                ></i>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {!isBillingEditable && (
                                        <div className="bill-inactive-wrap">
                                            <div className="row">
                                                <div
                                                    className="col-md-6"
                                                    ref={
                                                        firstErrorRef == "name"
                                                            ? errorRef
                                                            : null
                                                    }
                                                >
                                                    <div className="profile-form-group">
                                                        <div className="input-field">
                                                            <label>
                                                                Name/Company
                                                                name
                                                            </label>
                                                            <p>
                                                                {billingInfo.name
                                                                    ? billingInfo.name
                                                                    : "-"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <div className="input-field inactive-text-wrp">
                                                            <label>
                                                                Your email
                                                            </label>
                                                            <p>{user.email}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-md-6"
                                                    ref={
                                                        firstErrorRef ==
                                                        "country"
                                                            ? errorRef
                                                            : null
                                                    }
                                                >
                                                    <div className="profile-form-group">
                                                        <div className="input-field">
                                                            <label>
                                                                Country
                                                            </label>
                                                            <p>
                                                                {billingInfo.country
                                                                    ? billingInfo
                                                                          .country
                                                                          .label
                                                                    : "-"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-md-6"
                                                    ref={
                                                        firstErrorRef == "city"
                                                            ? errorRef
                                                            : null
                                                    }
                                                >
                                                    <div className="profile-form-group">
                                                        <div className="input-field">
                                                            <label>City</label>
                                                            <p>
                                                                {billingInfo.city
                                                                    ? billingInfo.city
                                                                    : "-"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-md-6"
                                                    ref={
                                                        firstErrorRef ==
                                                        "address"
                                                            ? errorRef
                                                            : null
                                                    }
                                                >
                                                    <div className="profile-form-group">
                                                        <div className="input-field mb-0">
                                                            <label>
                                                                Address{" "}
                                                            </label>
                                                            <p>
                                                                {billingInfo.address
                                                                    ? billingInfo.address
                                                                    : "-"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="profile-form-repeat">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-info-text">
                                        <h3>Your payment methods</h3>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {renderPaymentMethods()}
                                            {user.permission != "Editor" && (
                                                <>
                                                    {!addNewCard && (
                                                        <div
                                                            className="add-new-card-option mb-0"
                                                            onClick={() => {
                                                                setAddNewCard(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <div className="create-btn add-new-card-btn">
                                                                Add new card{" "}
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            process
                                                                                .env
                                                                                .REACT_APP_URL +
                                                                            "images/plus-white.svg"
                                                                        }
                                                                    />
                                                                </span>{" "}
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            {addNewCard == true && (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="add-card-field-hold">
                                                                <div className="profile-form-group">
                                                                    <div className="input form-group input-field">
                                                                        <div className="position-relative w-100">
                                                                            <Cleave
                                                                                id="card-number"
                                                                                options={{
                                                                                    creditCard: true,
                                                                                    onCreditCardTypeChanged:
                                                                                        (
                                                                                            type
                                                                                        ) => {
                                                                                            setFormValues(
                                                                                                {
                                                                                                    ...formValues,
                                                                                                    card_type:
                                                                                                        type,
                                                                                                }
                                                                                            );
                                                                                        },
                                                                                }}
                                                                                onChange={set(
                                                                                    "card_no"
                                                                                )}
                                                                                className={`form-control ${
                                                                                    formErrors.card_no !=
                                                                                    null
                                                                                        ? formErrors.error_class
                                                                                        : ""
                                                                                } `}
                                                                                value={
                                                                                    formValues.card_no
                                                                                }
                                                                                onKeyUp={(
                                                                                    e
                                                                                ) => {
                                                                                    validateCreditCard(
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    );
                                                                                }}
                                                                            />

                                                                            <label htmlFor="card-number">
                                                                                Card
                                                                                number
                                                                            </label>
                                                                            <span
                                                                                className="clear"
                                                                                onClick={clearFormValue(
                                                                                    "card_no"
                                                                                )}
                                                                            >
                                                                                <svg viewBox="0 0 24 24">
                                                                                    <path
                                                                                        className="line"
                                                                                        d="M2 2L22 22"
                                                                                    />
                                                                                    <path
                                                                                        className="long"
                                                                                        d="M9 15L20 4"
                                                                                    />
                                                                                    <path
                                                                                        className="arrow"
                                                                                        d="M13 11V7"
                                                                                    />
                                                                                    <path
                                                                                        className="arrow"
                                                                                        d="M17 11H13"
                                                                                    />
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                        {formValues.card_type &&
                                                                            formValues.card_type !=
                                                                                "unknown" && (
                                                                                <span className="selected_card_type">
                                                                                    <img
                                                                                        src={
                                                                                            process
                                                                                                .env
                                                                                                .REACT_APP_URL +
                                                                                            "images/" +
                                                                                            formValues.card_type +
                                                                                            "-icon.svg"
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            )}
                                                                        {formErrors.card_no !=
                                                                            null && (
                                                                            <span
                                                                                className={
                                                                                    formErrors.error_class
                                                                                }
                                                                            >
                                                                                {
                                                                                    formErrors.card_no
                                                                                }
                                                                            </span>
                                                                        )}
                                                                        <div className="card-secure-text">
                                                                            <img
                                                                                src={
                                                                                    process
                                                                                        .env
                                                                                        .REACT_APP_URL +
                                                                                    "images/" +
                                                                                    "lock-icon.svg"
                                                                                }
                                                                            />
                                                                            <span>
                                                                                This
                                                                                is
                                                                                a
                                                                                secure
                                                                                128-bit
                                                                                SSL
                                                                                encrypted
                                                                                payment
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="profile-form-group">
                                                                <div className="input form-group input-field">
                                                                    <input
                                                                        type="text"
                                                                        id="cardholder-name"
                                                                        className={`form-control ${
                                                                            formErrors.card_name !=
                                                                            null
                                                                                ? formErrors.error_class
                                                                                : ""
                                                                        } `}
                                                                        value={
                                                                            formValues.card_name
                                                                        }
                                                                        onChange={set(
                                                                            "card_name"
                                                                        )}
                                                                    />
                                                                    <label htmlFor="cardholder-name">
                                                                        Cardholder’s
                                                                        name
                                                                    </label>
                                                                    <span
                                                                        className="clear"
                                                                        onClick={clearFormValue(
                                                                            "card_name"
                                                                        )}
                                                                    >
                                                                        <svg viewBox="0 0 24 24">
                                                                            <path
                                                                                className="line"
                                                                                d="M2 2L22 22"
                                                                            />
                                                                            <path
                                                                                className="long"
                                                                                d="M9 15L20 4"
                                                                            />
                                                                            <path
                                                                                className="arrow"
                                                                                d="M13 11V7"
                                                                            />
                                                                            <path
                                                                                className="arrow"
                                                                                d="M17 11H13"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    {formErrors.card_name !=
                                                                        null && (
                                                                        <span
                                                                            className={
                                                                                formErrors.error_class
                                                                            }
                                                                        >
                                                                            {
                                                                                formErrors.card_name
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="profile-form-group">
                                                                <div className="input form-group input-field">
                                                                    <Cleave
                                                                        id="expiration-date"
                                                                        options={{
                                                                            date: true,
                                                                            datePattern:
                                                                                [
                                                                                    "m",
                                                                                    "y",
                                                                                ],
                                                                        }}
                                                                        onChange={set(
                                                                            "exp_date"
                                                                        )}
                                                                        className={`form-control ${
                                                                            formErrors.exp_date !=
                                                                            null
                                                                                ? formErrors.error_class
                                                                                : ""
                                                                        } `}
                                                                        value={
                                                                            formValues.exp_date
                                                                        }
                                                                    />
                                                                    <label htmlFor="expiration-date">
                                                                        Expiration
                                                                        date
                                                                        mm/yy
                                                                    </label>
                                                                    <span
                                                                        className="clear"
                                                                        onClick={clearFormValue(
                                                                            "exp_date"
                                                                        )}
                                                                    >
                                                                        <svg viewBox="0 0 24 24">
                                                                            <path
                                                                                className="line"
                                                                                d="M2 2L22 22"
                                                                            />
                                                                            <path
                                                                                className="long"
                                                                                d="M9 15L20 4"
                                                                            />
                                                                            <path
                                                                                className="arrow"
                                                                                d="M13 11V7"
                                                                            />
                                                                            <path
                                                                                className="arrow"
                                                                                d="M17 11H13"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    {formErrors.exp_date !=
                                                                        null && (
                                                                        <span
                                                                            className={
                                                                                formErrors.error_class
                                                                            }
                                                                        >
                                                                            {
                                                                                formErrors.exp_date
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="profile-form-group">
                                                                <div className="input form-group input-field">
                                                                    <div className="position-relative w-100">
                                                                        {formValues.card_type ==
                                                                            "amex" && (
                                                                            <Cleave
                                                                                id="cvv"
                                                                                options={{
                                                                                    blocks: [
                                                                                        4,
                                                                                    ],
                                                                                    numericOnly: true,
                                                                                }}
                                                                                onChange={set(
                                                                                    "cvv"
                                                                                )}
                                                                                className={`form-control ${
                                                                                    formErrors.cvv !=
                                                                                    null
                                                                                        ? formErrors.error_class
                                                                                        : ""
                                                                                } `}
                                                                                value={
                                                                                    formValues.cvv
                                                                                }
                                                                            />
                                                                        )}
                                                                        {formValues.card_type !=
                                                                            "amex" && (
                                                                            <Cleave
                                                                                id="cvv"
                                                                                options={{
                                                                                    blocks: [
                                                                                        3,
                                                                                    ],
                                                                                    numericOnly: true,
                                                                                }}
                                                                                onChange={set(
                                                                                    "cvv"
                                                                                )}
                                                                                className={`form-control ${
                                                                                    formErrors.cvv !=
                                                                                    null
                                                                                        ? formErrors.error_class
                                                                                        : ""
                                                                                } `}
                                                                                value={
                                                                                    formValues.cvv
                                                                                }
                                                                            />
                                                                        )}
                                                                        <label
                                                                            className="control-label"
                                                                            htmlFor="cvv"
                                                                        >
                                                                            CVV/CVC
                                                                        </label>
                                                                        <span
                                                                            className="clear"
                                                                            onClick={clearFormValue(
                                                                                "cvv"
                                                                            )}
                                                                        >
                                                                            <svg viewBox="0 0 24 24">
                                                                                <path
                                                                                    className="line"
                                                                                    d="M2 2L22 22"
                                                                                />
                                                                                <path
                                                                                    className="long"
                                                                                    d="M9 15L20 4"
                                                                                />
                                                                                <path
                                                                                    className="arrow"
                                                                                    d="M13 11V7"
                                                                                />
                                                                                <path
                                                                                    className="arrow"
                                                                                    d="M17 11H13"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        {formErrors.cvv !=
                                                                            null && (
                                                                            <span
                                                                                className={
                                                                                    formErrors.error_class
                                                                                }
                                                                            >
                                                                                {
                                                                                    formErrors.cvv
                                                                                }
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div className="d-flex justify-content-end cvv-holder">
                                                                        <span tooltip="CVV is the last three digits on the back of your credit card.">
                                                                            What
                                                                            is
                                                                            cvv/cvc?{" "}
                                                                            <img
                                                                                src={
                                                                                    process
                                                                                        .env
                                                                                        .REACT_APP_URL +
                                                                                    "images/info-icon.svg"
                                                                                }
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"row"}>
                                                        <div className="col-md-12">
                                                            <div className="flex-buttons-wrap justify-content-end">
                                                                <button
                                                                    type="button"
                                                                    className="btn form-btn secondary-btn"
                                                                    onClick={() => {
                                                                        setFormValue(
                                                                            "card_name",
                                                                            ""
                                                                        );
                                                                        setFormValue(
                                                                            "card_no",
                                                                            ""
                                                                        );
                                                                        setFormValue(
                                                                            "cvv",
                                                                            ""
                                                                        );
                                                                        setFormValue(
                                                                            "exp_date",
                                                                            ""
                                                                        );
                                                                        resetFormErrors();
                                                                        setAddNewCard(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn form-btn"
                                                                    onClick={
                                                                        addNewPaymentMethod
                                                                    }
                                                                >
                                                                    Save
                                                                    {cardFormLoading && (
                                                                        <i
                                                                            className="fa fa-spinner fa-spin"
                                                                            aria-hidden="true"
                                                                            style={{
                                                                                marginLeft:
                                                                                    "5px",
                                                                            }}
                                                                        ></i>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile-form-repeat mb-0">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-info-text">
                                        <h3>Promo Code</h3>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="promocode-field-wrap">
                                        {formValues.discount == 0 && (
                                            <>
                                                <InputField
                                                    label="Promo Code"
                                                    onChange={set("promo_code")}
                                                    onClear={() => {
                                                        setTimeout(() => {
                                                            setFormValues({
                                                                ...formValues,
                                                                promo_code: "",
                                                            });
                                                        }, 1000);
                                                    }}
                                                    value={
                                                        formValues.promo_code
                                                    }
                                                    // maxLength="50"
                                                    id="promo_code"
                                                    error={
                                                        formErrors.promo_code
                                                    }
                                                    error_class={
                                                        formErrors.error_class
                                                    }
                                                />

                                                <div className="apply-promo-code-btn d-flex justify-content-end">
                                                    <button
                                                        type="button"
                                                        className="button primary-btn m-0"
                                                        onClick={() => {
                                                            applyCouponApi();
                                                        }}
                                                        id="r_promo_code"
                                                    >
                                                        Apply
                                                        {couponLoading && (
                                                            <i
                                                                className="fa fa-spinner fa-spin"
                                                                aria-hidden="true"
                                                                style={{
                                                                    marginLeft:
                                                                        "5px",
                                                                }}
                                                            ></i>
                                                        )}
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                        {formValues.discount > 0 && (
                                            <div
                                                id="r_promo_code_applied"
                                                className="applied-promo-code-wrap"
                                            >
                                                <>
                                                    <label>Promo Code</label>
                                                    <p
                                                        className="promocode-text"
                                                        id="r_promo_code_applied"
                                                    >
                                                        <i
                                                            class="fa fa-check-circle"
                                                            aria-hidden="true"
                                                        ></i>
                                                        Promo Code applied :{" "}
                                                        <span>
                                                            {
                                                                formValues.promo_code
                                                            }
                                                        </span>
                                                    </p>
                                                    <span className="remove-promocode-icon">
                                                        <img
                                                            src={
                                                                process.env
                                                                    .REACT_APP_URL +
                                                                "images/trash.svg"
                                                            }
                                                            alt="trash-icon"
                                                            onClick={() => {
                                                                removePromoCode();
                                                            }}
                                                        />
                                                    </span>
                                                    <br />
                                                    Chec
                                                    <div
                                                        className={
                                                            "promocode_description"
                                                        }
                                                    >
                                                        <p>
                                                            {
                                                                formValues.description
                                                            }
                                                        </p>
                                                    </div>
                                                </>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selected_package &&
                            ((selected_package.is_custom == 1 &&
                                parseInt(formValues.credits) > 0) ||
                                selected_package.is_custom != 1) && (
                                <>
                                    <div className="profile-form-repeat border-0 mb-0">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-info-text">
                                                    <h3>Summary</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                {selected_package && (
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="plan-summery-test">
                                                                {selected_package.is_custom ==
                                                                    0 && (
                                                                    <div className="sumery-text-repeat mt-0">
                                                                        <span>
                                                                            {
                                                                                selected_package.credits
                                                                            }{" "}
                                                                            Credits
                                                                        </span>
                                                                        <div className="total-credit-prce-wrap">
                                                                            <h4>
                                                                                $
                                                                                {
                                                                                    selected_package.price
                                                                                }
                                                                                <span className="crdis-price-text">
                                                                                    $
                                                                                    {
                                                                                        selected_package.credits
                                                                                    }
                                                                                </span>
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {(formValues.discount >
                                                                    0 ||
                                                                    formValues.credits >=
                                                                        500) &&
                                                                    selected_package.is_custom ==
                                                                        1 && (
                                                                        <div className="sumery-text-repeat mt-0">
                                                                            <span>
                                                                                {
                                                                                    formValues.credits
                                                                                }{" "}
                                                                                Credits
                                                                            </span>

                                                                            <div className="total-credit-prce-wrap">
                                                                                <h4>
                                                                                    $
                                                                                    {formValues.credits *
                                                                                        creditPrice}
                                                                                    {formValues.credits >=
                                                                                        500 && (
                                                                                        <span className="crdis-price-text">
                                                                                            $
                                                                                            {
                                                                                                formValues.credits
                                                                                            }
                                                                                        </span>
                                                                                    )}
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                {formValues.discount >
                                                                    0 && (
                                                                    <div className="sumery-text-repeat applied-promo-discount">
                                                                        <span>
                                                                            Discount
                                                                        </span>

                                                                        <div className="total-credit-prce-wrap">
                                                                            <h4>
                                                                                -
                                                                                $
                                                                                {roundNumber(
                                                                                    formValues.discount,
                                                                                    2
                                                                                )}
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div
                                                                    className={`sumery-text-repeat ${
                                                                        selected_package.is_custom ==
                                                                        1
                                                                            ? "mt-0"
                                                                            : ""
                                                                    } `}
                                                                >
                                                                    <div className="total-biling-head">
                                                                        Total
                                                                        (USD)
                                                                    </div>
                                                                    <div className="billing-total-ammount">
                                                                        <h3>
                                                                            {selected_package.is_custom ==
                                                                                1 && (
                                                                                <>
                                                                                    $
                                                                                    {roundNumber(
                                                                                        selected_package.discounted_price *
                                                                                            parseInt(
                                                                                                formValues.credits
                                                                                            ) *
                                                                                            creditPrice -
                                                                                            formValues.discount,
                                                                                        2
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                            {selected_package.is_custom ==
                                                                                0 && (
                                                                                <>
                                                                                    $
                                                                                    {roundNumber(
                                                                                        selected_package.price -
                                                                                            formValues.discount,
                                                                                        2
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {!selected_package && (
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="plan-summery-test">
                                                                Please select
                                                                package
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inner-form-btn res-profle-btn-right justify-content-end plan-subscribe-btn">
                                        <button
                                            type="button"
                                            className="btn form-btn"
                                            onClick={() => {
                                                buyCreditsApi();
                                            }}
                                            id="r_buy_credits_pay_now"
                                        >
                                            Pay Now
                                            {formLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{
                                                        marginLeft: "5px",
                                                    }}
                                                ></i>
                                            )}
                                        </button>
                                    </div>
                                </>
                            )}
                    </>
                )}

                <div className="buy-credits-faq-wrapper">
                    <Text type="h1">FAQs</Text>
                    <Accordian
                        title={"How much does it cost to use UserQ?"}
                        open={accordians["first"] ? true : false}
                        onChange={() => {
                            let new_accordians = Object.assign({}, accordians);

                            for (var key in accordians) {
                                if (key == "first") {
                                    if (accordians[key]) {
                                        new_accordians[key] = false;
                                    } else {
                                        new_accordians[key] = true;
                                    }
                                }
                            }
                            //ReactSession.set("accordians",new_accordians);
                            setAccordians(new_accordians);
                        }}
                    >
                        <Text type={"body-text-2"} fontWeight={"medium-font"}>
                            We offer you complete flexibility with our
                            pay-as-you-go pricing, so you will only pay for what
                            you use, without the need to commit to a
                            subscription or contract. This means you can quickly
                            scale your testing requirements up or down depending
                            on the needs of your business.
                        </Text>

                        <Text type={"body-text-2"} fontWeight={"medium-font"}>
                            Each credit costs $1. When your account has enough
                            available credits, you will have access to an
                            unlimited number of users, tests and respondents.
                            When you publish your tests, you’ll need to use your
                            credits to be able to recruit testers from the UserQ
                            panel. If you want to use your own group of testers,
                            you can get a free link to share with them to your
                            test – no credits needed.
                        </Text>
                    </Accordian>
                    <Accordian
                        title={"What are credits?"}
                        open={accordians["second"] ? true : false}
                        onChange={() => {
                            let new_accordians = Object.assign({}, accordians);

                            for (var key in accordians) {
                                if (key == "second") {
                                    if (accordians[key]) {
                                        new_accordians[key] = false;
                                    } else {
                                        new_accordians[key] = true;
                                    }
                                }
                            }
                            //ReactSession.set("accordians",new_accordians);
                            setAccordians(new_accordians);
                        }}
                    >
                        <Text type={"body-text-2"} fontWeight={"medium-font"}>
                            Credits are used to recruit testers for your
                            projects and pay them for their time and insights.
                            You’ll need credits to publish any tests using
                            UserQ’s panel.
                        </Text>

                        <Text type={"body-text-2"} fontWeight={"medium-font"}>
                            Credits start at $1 each, with the minimum number of
                            credits you can buy at any one time is 50. If you’re
                            buying 50 credits, you would pay $50 at $1 each. But
                            when you choose to buy your credits in bulk (500 or
                            more), you’ll qualify for a discount. Here’s how it
                            would work:
                        </Text>
                        <ul>
                            <li>
                                <Text
                                    type={"body-text-2"}
                                    fontWeight={"medium-font"}
                                >
                                    500 credits = 5% discount, so you pay $475
                                </Text>
                            </li>
                            <li>
                                <Text
                                    type={"body-text-2"}
                                    fontWeight={"medium-font"}
                                >
                                    1000 credits =10% discount, so you pay $900
                                </Text>
                            </li>
                            <li>
                                <Text
                                    type={"body-text-2"}
                                    fontWeight={"medium-font"}
                                >
                                    1500 credits = 15% discount, so you pay
                                    $1275
                                </Text>
                            </li>
                            <li>
                                <Text
                                    type={"body-text-2"}
                                    fontWeight={"medium-font"}
                                >
                                    2000 credits = 20% discount, so you pay
                                    $1600
                                </Text>
                            </li>
                        </ul>
                        <Text type={"body-text-2"} fontWeight={"medium-font"}>
                            The discounts are included on each transaction up to
                            the next bracket, so if you buy between 500 and 1000
                            credits, you’ll get a 5% discount on your total. All
                            you need to do is add credits to your basket and
                            your discount will be automatically applied.
                        </Text>
                    </Accordian>
                    <Accordian
                        title={"How many credits do I need to publish a test?"}
                        open={accordians["third"] ? true : false}
                        onChange={() => {
                            let new_accordians = Object.assign({}, accordians);

                            for (var key in accordians) {
                                if (key == "third") {
                                    if (accordians[key]) {
                                        new_accordians[key] = false;
                                    } else {
                                        new_accordians[key] = true;
                                    }
                                }
                            }
                            //ReactSession.set("accordians",new_accordians);
                            setAccordians(new_accordians);
                        }}
                    >
                        <Text type={"body-text-2"} fontWeight={"medium-font"}>
                            Publishing a test is a set fee of 10 credits. If you
                            want to use our panel, you can use credits to
                            recruit. Depending on the size of the panel you
                            select, and the type and length of your test, the
                            amount of credits you need to use will change.
                            Here’s an overview of average credit costs per
                            respondent:
                        </Text>

                        <ul>
                            <li>
                                <Text
                                    type={"body-text-2"}
                                    fontWeight={"medium-font"}
                                >
                                    Preference tests (short length tests) - 2
                                    credits per tester
                                </Text>
                            </li>
                            <li>
                                <Text
                                    type={"body-text-2"}
                                    fontWeight={"medium-font"}
                                >
                                    Card sorting, tree testing and online survey
                                    (medium length tests) - 6 credits per tester
                                </Text>
                            </li>
                        </ul>
                        <Text type={"body-text-2"} fontWeight={"medium-font"}>
                            You can also use our credit estimate tool to work
                            out an estimate of how many credits you’ll need to
                            publish your test.
                        </Text>
                    </Accordian>
                    <Accordian
                        title={"How do I purchase credits?"}
                        open={accordians["fourth"] ? true : false}
                        onChange={() => {
                            let new_accordians = Object.assign({}, accordians);

                            for (var key in accordians) {
                                if (key == "fourth") {
                                    if (accordians[key]) {
                                        new_accordians[key] = false;
                                    } else {
                                        new_accordians[key] = true;
                                    }
                                }
                            }
                            //ReactSession.set("accordians",new_accordians);
                            setAccordians(new_accordians);
                        }}
                    >
                        <Text type={"body-text-2"} fontWeight={"medium-font"}>
                            You can buy credits through your UserQ account – and
                            you can save money when you buy credits in bulk.
                            Prices start at as little as $0.80 per credit.
                        </Text>
                    </Accordian>
                </div>
                <a
                    onClick={(e) => {
                        e.preventDefault();

                        setShowSupportPanel(true);
                    }}
                    href={process.env.REACT_APP_URL + "r/support"}
                    target="_blank"
                    className="support-button"
                >
                    <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z"
                            stroke="#9759CB"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    Support
                </a>
            </div>
            {/* <LoadingModal 
                open={formLoading}
                message={"Payment is in progress, don’t leave the page"} 
            /> */}
            <CheckoutModal
                title={"Checkout"}
                open={checkoutModal.open}
                close={() => {
                    setCheckoutModal({ ...checkoutModal, open: false });
                }}
                renderPaymentMethods={renderPaymentMethods}
                user={user}
                paymentMethods={paymentMethods}
                validatePaymentForm={validatePaymentForm}
                formValues={formValues}
                setFormValues={setFormValues}
                applyCouponApi={applyCouponApi}
                couponError={couponError}
                setCouponError={setCouponError}
                couponLoading={couponLoading}
                formErrors={formErrors}
                removePromoCode={removePromoCode}
                creditPrice={creditPrice}
                selected_package={selected_package}
                buyCreditsApi={buyCreditsApi}
                addNewCard={addNewCard}
                setAddNewCard={setAddNewCard}
                sameCardError={sameCardError}
                setSameCardError={setSameCardError}
                formLoading={formLoading}
                resetPaymentMethods={resetPaymentMethods}
            />

            <ConfirmationModal
                confirm_title_class={"text-danger"}
                confirm_message={confirmModal.confirm_message}
                confirm_btn_title="Delete"
                confirm_title="Delete Card"
                openModal={confirmModal.open}
                close={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                }}
                confirm={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                    deletePaymentMethod(confirmModal.target_id);
                    if (
                        formValues.payment_method_id == confirmModal.target_id
                    ) {
                        setFormValue("payment_method_id", "");
                    }
                }}
            />

            {openNewTestModal && (
                <NewTestModal
                    openModal={openNewTestModal}
                    closeModal={closeNewTestModal}
                    projectlist={projects}
                    openProjectModal={() => {
                        setOpenModal(true);
                    }}
                    selectedMethodology={modalMethodology}
                    publishing_credits={userSettings.publishing_credits}
                />
            )}

            <NewProjectModal openModal={openModal} closeModal={closeModal} />
            {openCreditConfirmationModal && (
                <CreditsConfirmationModal
                    openModal={openCreditConfirmationModal}
                    closeModal={() => {
                        closeCreditConfirmationModal();
                        navigate("/r/credits");
                    }}
                    projectlist={projects}
                    openProjectModal={() => {
                        closeCreditConfirmationModal();
                        setOpenModal(true);
                    }}
                    selectedMethodology={modalMethodology}
                    publishing_credits={userSettings.publishing_credits}
                    amount={
                        selected_package.credits
                            ? selected_package.credits
                            : formValues.credits
                    }
                    createTest={(methodology) => {
                        setModalMethodology(methodology);
                        closeCreditConfirmationModal();
                        setOpenNewTestModal(true);
                    }}
                />
            )}
        </LayoutResearcher>
    );
};
