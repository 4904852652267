import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { LoadingIcon } from "../../../../themes/userq/Loader/loadingIcon";
import { Text } from "../../../../themes/userq/Text/Text";
import { Button } from "../../../../themes/userq/Button/Button";
import { Icon } from "../../../../themes/userq/Icon/Icon";
import { Input } from "../../../../themes/userq/Input/Input";

export default function LeaveWorkspaceModal({
    openModal,
    confirm_title,
    confirm_btn_title,
    cancel_btn_title,
    close,
    confirm,
    btnLoading,
    confirm_title_class,
}) {
    const closeModal = () => {
        if(!btnLoading){
            close();
        }
        
    };

    const navigate = useNavigate();

    const wrapperRef = useRef(null);

    const [formValues, setFormValues] = useState({ signature: "" });

    useEffect(() => {
        setFormValues({ ...formValues, signature: "" });
    }, [openModal]);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, btnLoading]);
    }

    useOutsideAlerter(wrapperRef);

    return (
        <Modal
            show={openModal}
            centered
            className="fade custom-modal-wrap  confirmation-modal-wrap leave-workspace-modal-wrap"
        >
            <Modal.Body className="" ref={wrapperRef}>
                {/* {btnLoading ?
                    <div className="modal-loader text-center">
                        <LoadingIcon />
                    </div>
                    : */}
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <h3
                            className={`heading h3 ${
                                confirm_title_class ? confirm_title_class : ""
                            }`}
                        >
                            {confirm_title}
                        </h3>

                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon value="Close" size="large" hover={true} />
                            </span>
                        </button>
                    </div>
                    <Text
                        type="body-text-3"
                        cssClasses={"mb-12 gray-color"}
                        fontWeight="medium-font"
                    >
                        By continuing, you you will lose access to all tests and
                        credits.
                    </Text>
                    <Text
                        type="body-text-3"
                        cssClasses={"gray-color"}
                        fontWeight="medium-font"
                    >
                        Kindly confirm by signing your full name:
                    </Text>

                    <div className="add-name-input mt-16">
                        <Input
                            cssClasses={"w-100"}
                            type="text"
                            label="Your full name"
                            value={formValues.signature}
                            onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    signature: e.target.value,
                                });
                            }}
                            readOnly={btnLoading ? true : false}
                        />
                    </div>
                    <div className="confirm-buttons-wrap">
                        <Button
                            type="primary"
                            size="large"
                            label={confirm_btn_title ? confirm_btn_title : "OK"}
                            onClick={() => {
                                confirm(formValues.signature);
                            }}
                            microLoading={btnLoading}
                            state={
                                !formValues.signature ? "disabled" : "active"
                            }
                        />
                        <Button
                            type="secondary"
                            size="large"
                            label={
                                cancel_btn_title ? cancel_btn_title : "Cancel"
                            }
                            onClick={() => {
                                closeModal();
                            }}
                        />
                    </div>
                </div>
                {/* } */}
            </Modal.Body>
        </Modal>
    );
}
