import { Link, useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession.js";
import ResearcherProfileNavigation from "../../profile/researcher_profile_navigation.js";
import React, { useEffect, useState, useRef } from "react";
//import LayoutResearcher from "../../layouts/layout_researcher.js";
import { LayoutResearcher } from "../../themes/userq/Layouts/layout_researcher";
import {
  getCreditSummaryService,
  getCreditHistoryService,
} from "../../../services/credits";
import {roundNumber} from "../../../lib/helpers";
import toast from "react-hot-toast";
import moment from "moment";
import { LoadingIcon } from "../../themes/userq/Loader/loadingIcon";
import { Text } from "../../themes/userq/Text/Text";
import { Button } from "../../themes/userq/Button/Button";
import { ToastMessage } from "../../themes/userq/ToastMessage/ToastMessage";
import { settings } from "../../../data/settings";
import { Icon } from "../../themes/userq/Icon/Icon.js";
import Skeleton from "react-loading-skeleton";
import { CircularNameIcon } from "../../themes/userq/CircularNameIcon/CircularNameIcon.js";
import { Helmet } from "react-helmet";
import { useTestBuilderData } from "../tests/contexts/TestBuilderContext.js";


export default function ResearcherCredits() {

  ReactSession.setStoreType("localStorage");
  let user = ReactSession.get("user");
  const navigate = useNavigate();

  const scrollContainerRef = useRef(null);

  const [isScrolled, setIsScrolled] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const [summary, setSummary] = useState({
    credits_added: 0,
    credits_used: 0,
    credits_balance: ReactSession.get("credits")
      ? ReactSession.get("credits")
      : 0,
  });

  const [transLoading, setTransLoading] = useState(false);

  const [transactions, setTransactions] = useState([]);

  const [hasTransactions, setHasTransactions] = useState(true);

  const [transactionPage, setTransactionPage] = useState(1);
  const [transactionOrder, setTransactionOrder] = useState('');
  const [show_support_panel, setShowSupportPanel] = useState(false);
  const { workspaceid } = useParams();


  const [teamMember, setTeamMember] = useState(null);

  const [workspace, setWorkspace] = useState(null);

  const [openFrozenModal, setOpenFrozenModal] = useState(0);

  const {events} = useTestBuilderData();

  useEffect(() => {

      events.forEach(function(event){
          if(event.event_type === "frozen_workspace_active"){
              if(workspace && workspace.id === event.event_id){
                  
                  let workspaceLoc   = Object.assign({}, workspace);
                  workspaceLoc.status = "active";

                  setWorkspace(workspaceLoc);
              }
          }/* else if(event.event_type === "workspace_admin_added"){

            if(workspace && workspace.id === event.event_id && user && user.user_id === event.user_id){
                
                setTransactionPage(1);
                
            }

        } */
      })
  },[events]);

  useEffect(()=>{
    if(transactionPage==1){
      getCreditHistory();
    }
  },[transactionPage])

  const showError = (error) => {
    toast(
      <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
      {
        className: "errortoast",
        position: "bottom-center",
        duration: settings.toast_duration,
      }
    );
  };

  const getCreditHistory = () => {
    let token = ReactSession.get("token");

    setTransLoading(true);

    getCreditHistoryService(transactionPage, 'desc', workspaceid, token).then((response) => {
      setTransLoading(false);

      if (response.success) {

        if(response.workspace){
          setWorkspace(response.workspace);
          setTeamMember(response.workspace_team_member);

          if (
            response.workspace_team_member.status !==
            "invitation_accepted" &&
              response.workspace_team_member.status !== "active"
          ) {
              navigate(
                  "/wsp/" + response.workspace.id + "/noaccess"
              );
          }

        } else {
          navigate("/401");
        }
        setTransactions(transactionPage==1?response.credit_history:transactions.concat(response.credit_history));
        if (response.more_records) {
          setHasTransactions(true);
        } else {
          setHasTransactions(false); 
        }
        setTransactionPage(transactionPage + 1);
        if (scrollContainerRef && scrollContainerRef.current && transactions.length > 0) {
          const firstTransactionElement = scrollContainerRef.current.querySelector(`#trans${transactions[0].id}`);
          if (firstTransactionElement) {
            firstTransactionElement.scrollIntoView({ behavior: 'smooth' });
          }
        }
      } else {
        if(response.message=="Workspace not found."){
          navigate("/401");
        } else {
          showError(response.message);
        }
      }

    });
  };

  useEffect(function () {

    if (transactionOrder) {
      let token = ReactSession.get("token");

      //setTransLoading(true);

      getCreditHistoryService(1, transactionOrder, workspaceid, token).then((response) => {
        // setTransLoading(false);

        if (response.success) {
          setTransactions(response.credit_history);
          if (response.more_records) {
            setHasTransactions(true);
            // setTransactionOrder(transactionOrder);
          } else {
            setHasTransactions(false);
          }
          setTransactionPage(1);
        } else {
          showError(response.message);

        }
      });
    }
  },
    [transactionOrder]

  )
  
  const renderTransactions = () => {
    var transactionsRender = [];
    const user = ReactSession.get("user");
    transactions.forEach(function (transaction) {
      transactionsRender.push(
          <tr key={"trans" + transaction.id}>
              <td>
                  <Text type={"body-text-2"} fontWeight={"medium-font"}>
                      {moment(transaction.created_at).format("DD-MM-YY")}
                  </Text>
              </td>
              {/*<td><Text type={'body-text-2'} fontWeight={'medium-font'}>{(transaction.test_name) ? transaction.test_name : '-'}</Text></td>*/}
              <td>
                  <Text type={"body-text-2"} fontWeight={"medium-font"}>
                      {transaction.description}
                  </Text>
              </td>
              <td className="user-data-col">
                  <div className="d-flex align-items-center user-info-col">
                      <CircularNameIcon
                          label={
                              transaction.workspace_team_member &&
                              ["invitation_accepted", "active"].includes(
                                  transaction.workspace_team_member.status
                              )
                                  ? transaction.user.first_name
                                        .toUpperCase()
                                        .charAt(0)
                                  : "D"
                          }
                          style={{
                              background: ![
                                  "invitation_accepted",
                                  "active",
                              ].includes(
                                  transaction.workspace_team_member.status
                              )
                                  ? "#CCC"
                                  : transaction.workspace_team_member.color,
                          }}
                          className="mr-3"
                      />
                      <Text type={"body-text-2"} fontWeight={"medium-font"}>
                          <>{transaction.added_by}</>
                      </Text>
                  </div>
              </td>
              <td>
                  <div className="td-flex-wrap body-text bodt-text-2 medium-font ">
                      <span
                          className={
                              transaction.transaction_type == "credit"
                                  ? "green-color"
                                  : transaction.credits === 0
                                  ? "green-color"
                                  : "red-color"
                          }
                      >
                          {transaction.transaction_type == "credit" ? "+" : "-"}{" "}
                          {roundNumber(transaction.credits, 2)}
                      </span>
                      {transaction.invoice_url && (
                          <a href={transaction.invoice_url} target="_blank">
                              <span className="td-icon-span">
                                  <Icon
                                      value={"download-invoice"}
                                      size={"medium"}
                                  ></Icon>
                              </span>
                          </a>
                      )}
                  </div>
              </td>
          </tr>
      );
    });
    return transactionsRender;
  };
  const fetchCreditsSummary = () => {
    let token = ReactSession.get("token");

    setLoading(true);

    let session_workspace_id = null;

    let session_workspace = ReactSession.get("workspace");

    if (session_workspace) {
        session_workspace_id = session_workspace.workspace_id;
    }

    //let workspace_id = workspaceid ? workspaceid : session_workspace_id;

    let workspace_id = session_workspace_id;

    getCreditSummaryService({workspace_id:workspace_id},token).then((response) => {
      setLoading(false);

      if (response.success) {
        setSummary(response.summary);

        ReactSession.set("credits", response.summary.credits_balance);
      } else {
        showError(response.message);
      }
    });
  };

  useEffect(function () {
    // document.title = "Your credits | "+process.env.REACT_APP_NAME;
    fetchCreditsSummary();
    //getCreditHistory(); 
  }, []);


  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;

    // Check if already at the bottom
    const atBottom = scrollTop + clientHeight >= scrollHeight;

    if (atBottom && !transLoading && hasTransactions) {
      getCreditHistory();
    }
    // Check the scroll position or any other logic you need
    const scrolled = scrollContainerRef.current.scrollTop > 0;
    setIsScrolled(scrolled);
  };


  useEffect(() => {
     
  const scrollContainer = scrollContainerRef.current;

  if (scrollContainer) {
    scrollContainer.addEventListener('scroll', handleScroll);
    //handleScroll(); 
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }
  }, [transactions,handleScroll]); 


  const frozenPaymentMethodUpdate = (status = null) => {
    let payment_method_modal = {
        open : true,
        workspace_id : workspaceid,
        status: status
    };
    ReactSession.set("payment_method_modal", payment_method_modal);
    navigate(`/wsp/${workspaceid}/workspace-billing`);
  }



  return (
    <LayoutResearcher
      isLoading={false}
      wrapClass={`all-credits-page-wrapper`}
      skipCheck={true}
      extendedFooter={false}
      activeMenu={"credits"}
      openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
      openFrozenModal={openFrozenModal}
    >

      <Helmet>
          <title>{"Credits wallet"} | UserQ</title>
      </Helmet>

      <>
        {workspace && workspace.is_downgrade_scheduled == 1 &&
          <div className="mb-40">
            <ToastMessage
              icon={"group"}
              type={"info"}
              message={
                <>
                  <div className="d-flex align-items-center freez-toast-wrap justify-content-between">
                    <span className="mr-3">
                      Your Team plan is cancelled and will expire on {workspace.next_billing_date
                        ? moment(workspace.next_billing_date).format("DD/MM/YYYY")
                        : "-"}.

                    </span>
                    <span>
                      Changed your mind? <Link className="link-text" onClick={(e) => { e.preventDefault(); frozenPaymentMethodUpdate("reactivate") }} to={"/"}>Re-activate your Team Plan</Link>
                    </span>

                  </div>
                </>
              }
              closable={false}
            />
          </div>
        }

        {/* {workspace && workspace.is_new_admin_proposed && !workspace.payment_method_id && teamMember && teamMember.role === "admin" && (
          <div className="mb-40">
            <ToastMessage
              type={"warning"}
              message={
                <>
                  <>
                    As the admin
                    of this
                    workspace,
                    it's
                    mandatory to
                    maintain a
                    valid
                    payment
                    method.
                    . The next
                    billing date
                    is scheduled
                    for{" "}
                    {workspace.next_billing_date
                      ? moment(
                        workspace.next_billing_date
                      ).format(
                        "DD/MM/YY"
                      )
                      : "-"}
                    . <br/>Please add
                    or update
                    your payment
                    method to
                    ensure it's
                    in place
                    before the
                    next billing
                    cycle.
                  </>

                  <Button
                    type={"primary"}
                    size={"small"}
                    label={
                      "Update payment method"
                    }
                    onClick={(
                      e
                    ) => {
                      e.stopPropagation();

                      frozenPaymentMethodUpdate("expired");
                    }}
                  />
                </>
              }
              closable={false}
            />
          </div>
        )} */}

        {workspace && workspace.status === "frozen" && teamMember && teamMember.role ===
                        "admin" ? (
          <div className="mb-40">
            <ToastMessage
                type={
                    teamMember &&
                    teamMember.role == "member"
                        ? "snowflake"
                        : (workspace && workspace.show_payment_method_saved_msg==1 ? "info" : "error")
                }
                icon={
                    teamMember &&
                    teamMember.role == "member"
                        ? "snowflake"
                        : (workspace && workspace.show_payment_method_saved_msg==1 ? "time" : "error")
                }
                message={
                    <>
                        {teamMember && (
                            <>
                                {teamMember.role ===
                                "admin" ? (
                                    <>
                                    {workspace && workspace.show_payment_method_saved_msg==1
                                    ?
                                    <>
                                        Your payment method has been successfully saved. The subscription payment will be processed soon.
                                    </>
                                    :
                                
                                    <div className="d-flex align-items-center freez-toast-wrap">
                                        <span className="mr-3">
                                            This
                                            workspace is
                                            frozen due
                                            to
                                            unsuccessful
                                            transaction.
                                            Please
                                            update your
                                            payment
                                            method to
                                            retain team
                                            capabilities
                                            or switch to
                                            Pay-As-You-Go
                                        </span>
                                        <Button
                                            type={
                                                "primary"
                                            }
                                            size={
                                                "small"
                                            }
                                            label={
                                                "Update payment method"
                                            }
                                            onClick={(
                                                e
                                            ) => {
                                                e.stopPropagation();

                                                frozenPaymentMethodUpdate("frozen");
                                            }}
                                        />
                                    </div>
                                    }
                                    </>
                                ) : (
                                    <>
                                        The workspace
                                        has been frozen
                                        because we
                                        couldn't renew
                                        the workspace
                                        subscription.
                                        Please get in
                                        touch with the
                                        workspace admin
                                        to resolve the
                                        issue.
                                    </>
                                )}
                            </>
                        )}
                    </>
                }
                closable={false}
            />
          </div>
        ) : (
          <>
            {workspace &&
              workspace.payment_method &&
              workspace.payment_method
                .card_expire_status === 1 &&
              teamMember &&
              teamMember.role == "admin" && (
                <div className="mb-40">
                  <ToastMessage
                    type={"warning"}
                    message={
                      <>
                        {workspace
                          .payment_method
                          .expired ? (
                          <>
                            As the admin
                            of this
                            workspace,
                            it's
                            mandatory to
                            maintain a
                            valid
                            payment
                            method. The
                            credit card
                            connected to
                            this
                            workspace
                            has expired
                            on{" "}
                            {(function () {
                              if (
                                workspace
                                  .payment_method
                                  .card_expire_at
                              ) {
                                return moment(
                                  workspace
                                    .payment_method
                                    .card_expire_at
                                ).format(
                                  "DD/MM/YY"
                                );
                              } else {
                                return "-";
                              }
                            })()}
                            . The next
                            billing date
                            is scheduled
                            for{" "}
                            {workspace.next_billing_date
                              ? moment(
                                workspace.next_billing_date
                              ).format(
                                "DD/MM/YY"
                              )
                              : "-"}
                            .<br/> Please add
                            or update
                            your payment
                            method to
                            ensure it's
                            in place
                            before the
                            next billing
                            cycle.
                          </>
                        ) : (
                          <>
                            As the admin
                            of this
                            workspace,
                            it's
                            mandatory to
                            maintain a
                            valid
                            payment
                            method. The
                            credit card
                            connected to
                            this
                            workspace
                            will expire
                            on{" "}
                            {(function () {
                              if (
                                workspace
                                  .payment_method
                                  .card_expire_at
                              ) {
                                return moment(
                                  workspace
                                    .payment_method
                                    .card_expire_at
                                ).format(
                                  "DD/MM/YY"
                                );
                              } else {
                                return "-";
                              }
                            })()}
                            . The next
                            billing date
                            is scheduled
                            for{" "}
                            {workspace.next_billing_date
                              ? moment(
                                workspace.next_billing_date
                              ).format(
                                "DD/MM/YY"
                              )
                              : "-"}
                            .<br/> Please add
                            or update
                            your payment
                            method to
                            ensure it's
                            in place
                            before the
                            next billing
                            cycle.
                          </>
                        )}
                        <Button
                          type={"primary"}
                          size={"small"}
                          label={
                            "Update payment method"
                          }
                          onClick={(
                              e
                          ) => {
                              e.stopPropagation();

                              frozenPaymentMethodUpdate("expired");
                          }}
                        />
                      </>
                    }
                    closable={false}
                  />
                </div>
              )}
          </>
        )}

        <div className="page-heading-wrap page-heading-wrap d-flex  align-items-center justify-content-between">
          <Text type="h1">Credits</Text>
        </div>

        <div className="credits-page-inner-wrapper">





          {isLoading && (
            <div className={'page-loader accountloading-box creditloading-box'}>
              <LoadingIcon />
            </div>
          )}

          {!isLoading && (



            <div className="all-credits-count-wrap ">
              <Text type={"h3"}>You have <span className={"credits_balance"}>{roundNumber(summary.credits_balance,2)}</span> credits in your wallet</Text>
              <Button
                  label="Buy credits"
                  type={"primary"}
                  size={"medium"}
                  // onClick={() => {
                  //     if (user.permission != "Editor") {
                  //         navigate("/researcher/credits/buy-credits");
                  //     } else {
                  //         showError(
                  //             "You do not have permissions to buy credits. Please contact your Account Owner"
                  //         );
                  //     }
                  // }}
                  onClick={() => {

                    if(workspace && workspace.status === 'frozen' && teamMember && teamMember.role === "member"){
                      
                      setOpenFrozenModal(openFrozenModal+1);
                    } else {
                      navigate(
                          "/wsp/" + workspaceid + "/buy-credits"
                      );
                    }
                  }}
              />

            </div>



          )}

        
          {!isLoading && user.permission != "Editor" && ( 

            <div className="credits-table-wrap">
              <Text type={"h4"}>Transaction history</Text> 
              {transactions.length > 0 && (
                <div className="fixed-header-table-layout transaction-history-table mt-40">
                  <table  className="table"> 

                    <thead className={`fixed-thead-shadow ${isScrolled ? 'scrolled' : ''}`}>
                      <tr>
                        <th >

                          <div className="td-sort-wrap cursor-pointer">

                            <div onClick={() => {
                              if (transactionOrder === 'asc') {
                                setTransactionOrder('desc'); 
                              } else {
                                setTransactionOrder('asc');
                              }
                            }} className={`${(transactionOrder) ? transactionOrder : ''}   col-sort justify-content-between`}>

                              Date
                              <span>

                                  {transactionOrder=="asc" &&
                                  <Icon value={'sortup'}/>
                                  }
                                  {transactionOrder=="desc" &&
                                  <Icon value={'sortdown'}/>
                                  }
                                  {!transactionOrder &&
                                  <Icon value={'sort-icon'}/>
                                  }
                              </span>
                            </div>
                          </div>
                        </th>
                        {/*<th>Methodology</th>*/}
                        <th>Description</th>
                        <th>User</th>
                        <th>Credits</th>
                      </tr>
                    </thead>


                    <tbody className={` max-height-table fixed-header-table-layout  ${isScrolled ? 'scrolled' : ''}  ${transactions && transactions.length>8 ? "responsive-table-scroll":""}`} ref={scrollContainerRef} >
                      {renderTransactions()}

                    

                      {hasTransactions && transLoading && (
                        <>
                          <tr className="credit-skelton-loading">
                            <td><Skeleton width={100} height={30} style={{ borderRadius: '5px' }} /></td>
                            <td><Skeleton width={400} height={30} style={{ borderRadius: '5px' }} /></td>
                            <td><Skeleton width={50} height={30} style={{ borderRadius: '5px' }} /></td>
                            <td><Skeleton width={50} height={30} style={{ borderRadius: '5px' }} /></td>
                          </tr>
                          <tr className="credit-skelton-loading">
                            <td><Skeleton width={100} height={30} style={{ borderRadius: '5px' }} /></td>
                            <td><Skeleton width={400} height={30} style={{ borderRadius: '5px' }} /></td>
                            <td><Skeleton width={50} height={30} style={{ borderRadius: '5px' }} /></td>
                            <td><Skeleton width={50} height={30} style={{ borderRadius: '5px' }} /></td>
                          </tr> 
                          <tr className="credit-skelton-loading">
                            <td><Skeleton width={100} height={30} style={{ borderRadius: '5px' }} /></td>
                            <td><Skeleton width={400} height={30} style={{ borderRadius: '5px' }} /></td>
                            <td><Skeleton width={50} height={30} style={{ borderRadius: '5px' }} /></td>
                            <td><Skeleton width={50} height={30} style={{ borderRadius: '5px' }} /></td>
                          </tr> 
                        </>
                      )}
                    </tbody>

                  </table>
                </div>
              )}
              {!hasTransactions && transactions.length == 0 && (
                <div className="nodata-wrap text-center">
                  No transactions available at this moment.
                </div>
              )}

              {hasTransactions && !transLoading && (
                <div className={"load-more-data"} style={{ display: 'none' }}>
                  <Button type="primary"
                    size={`large`}
                    label="Load more"
                    onClick={getCreditHistory}
                  />
                </div>
              )}

              {hasTransactions && transLoading && (
                <div className={"load-more-data"} style={{ display: 'none' }}>
                  <Button type="primary"
                    size={`large`}
                    onClick={getCreditHistory}
                    microLoading={true}
                  />
                </div>
              )}

            </div>

          )}

        </div>
        <a onClick={(e)=>{
                                      e.preventDefault();

                                      setShowSupportPanel(true);

                                  }}  href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  </svg>
                                      Support
                                  </a>

      </>
    </LayoutResearcher>
  );
}