import React, { Children, useEffect } from "react";
import { useLayoutEffect, useRef, useState } from "react";
import { Button } from "../Button/Button";
import { Text } from "../Text/Text";
import { Icon } from "../Icon/Icon";
import "./Tag.css";
export const Tag = ({
    title,
    type,
    cssClasses,
    onClose,
    onAdd,
    iconLeft,
    iconRight,
    colorClass,
    size,
}) => {
    return (
        <span
            className={`tag ${type ? type : ""} ${cssClasses ? cssClasses : ""} 
            ${iconLeft ? "tag-icon-left" : ""} ${
                iconRight ? "tag-icon-right" : ""
            }`}
        >
            {iconLeft && (
                <span className="ltr-icon">
                    <Icon
                        colorClass={colorClass}
                        value={iconLeft}
                        size={"small"}
                    />
                </span>
            )}
            {title}
            {onClose && (
                <span onClick={onClose}>
                    <Icon value="close" colorClass={"gray-50-svg"} />
                </span>
            )}

            {onAdd && (
                <span onClick={onAdd}>
                    <Icon value="add" colorClass={"gray-50-svg"} hover={true} />
                </span>
            )}

            {iconRight && (
                <span className="rtl-icon">
                    <Icon
                        colorClass={colorClass}
                        value={iconRight}
                        size={"small"}
                    />
                </span>
            )}
        </span>
    );
};
export const TagGroup = ({ children }) => {
    return <div className="tags-wrap">{children}</div>;
};
