import React, { createContext } from "react";
import {
    useNavigate,
    useParams,
    Link,
    useSearchParams,
} from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
//import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { LayoutResearcher } from "../../themes/userq/Layouts/layout_researcher";
import { Stepper } from "./../../themes/userq/Stepper/Stepper";
import { Button } from "./../../themes/userq/Button/Button";
import { LoadingIcon } from "./../../themes/userq/Loader/loadingIcon";
import { Accordian } from "./../../themes/userq/Accordion/Accordion";
import { Icon } from "./../../themes/userq/Icon/Icon";
import Header from "./components/header";

import Progress from "../study/progress/progress.js";
import Topbar from "../study/topbar/topbar.js";
import { QuestionCard } from "../../themes/userq/QuestionCard/QuestionCard";
import { Question } from "../../themes/userq/Question/Question";
import { QuestionInput } from "../../themes/userq/Question/QuestionInput";
import { EditQuestionInput } from "../../themes/userq/Question/EditQuestionInput";
import { useState, useEffect, useRef, useCallback } from "react";
import {
    getTestData,
    deleteDesignService,
    reorderDesignService,
    updatePreferenceTestService,
    savePreferenceTestQuestions,
    duplicateQuestion,
} from "../../../services/test";
import toast from "react-hot-toast";
import ConfirmModal from "../../themes/userq/ConfirmModal/confirm_modal";
import ConfirmationModal from "../../themes/userq/Modal/ConfirmationModal";
import moment from "moment";
import redirectToStep from "./redirect_to_step";
import { FormattedMessage } from "react-intl";
import { maxLengthCheck, renderTestTitleCreate } from "../../../lib/helpers";
import InputField from "../../layouts/elements/fields/input";
import Checkbox from "../../layouts/elements/fields/checkbox";
import PreferenceTestDesignModal from "./preference_test/preference_test_design_modal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import EditPreferenceTestDesignModal from "./preference_test/edit_preference_test_design_modal";
import { ImageUpload } from "./components/image_upload_design";
import { ToastMessage } from "../../themes/userq/ToastMessage/ToastMessage";
import { Input } from "../../themes/userq/Input/Input";
import { Text } from "../../themes/userq/Text/Text";
import { CheckBox } from "../../themes/userq/CheckBox/CheckBox";
import { ToggleButton } from "../../themes/userq/ToggleButton/ToggleButton";
import { ConclusionQuestions } from "./conclusion_questions";
import { IntroductionQuestions } from "./introduction_questions";
import { settings } from "../../../data/settings";
import _, { isEmpty } from "lodash";
import { PreviewNav } from "../../themes/userq/Preview/preview_nav";
import { GuideLink } from "../../themes/userq/GuideLink/GuideLink";
import { Helmet } from "react-helmet";
import { useTestBuilderData } from "./contexts/TestBuilderContext.js";
import {
    DndContext,
    closestCenter,
    MouseSensor,
    TouchSensor,
    DragOverlay,
    useSensor,
    useSensors,
    DragStartEvent,
    DragEndEvent,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    rectSortingStrategy,
} from "@dnd-kit/sortable";
import Grid from "./Grid";
import SortableItem from "./SortableItem";
import Item from "./Item";

export default function PreferenceTest() {
    let { test_id } = useParams();
    const nodeRef = useRef(null);
    const navigate = useNavigate();
    const questionInputValidateRef = useRef(null);
    const introductionQuestionRef = useRef(null); // introduction ref
    const conclusionQuestionRef = useRef(null); // conclusion ref
    let allowNoQuestionsSave = false;

    const { updateTestLength, getTestLength, allowEditAccess } =
        useTestBuilderData();

    ReactSession.setStoreType("localStorage");

    let testController = useRef();

    const [duplicateProgress, setDuplicateProgress] = useState(false);

    let duplicateController = useRef();

    const [formValues, setFormValues] = useState({
        title: "",
        randomize_order: 0,
        show_fullscreen: 0,
        show_design_titles: 0,
        designs: [],
        validate_form: false,
    });
    const [validate_form, setValidateForm] = useState(false);

    const [pageLoading, setPageLoading] = useState(false);

    const [isSaved, setIsSaved] = useState({ status: "", message: "" });

    const [preferenceTestLoading, setPreferenceTestLoading] = useState(false);

    const [preferenceTestStepLoading, setPreferenceTestStepLoading] =
        useState(false);

    const [addDesigns, setAddDesigns] = useState(2);

    const [lastSaved, setLastSaved] = useState(null);

    const [isLoading, setLoading] = useState(false);

    const [confirmModal, setConfirmModal] = useState({ open: false });

    const [test, setTest] = useState("");

    const [publishing_credits, setPublishingCredits] = useState(0);

    const [editable_text, setEditableText] = useState("");

    const [step, setStep] = useState(0);

    const [addDesignModal, setAddDesignModal] = useState({ open: false });

    const [editDesignModal, setEditDesignModal] = useState({
        open: false,
        design: null,
    });

    const [searchParams] = useSearchParams();

    const [accordians, setAccordians] = useState({
        introduction: false,
        preferencetest: searchParams.get("error") == "true" ? true : false,
        conclusion: false,
    });

    const [formErrors, setFormErrors] = useState({
        title: null,
        error_class: "input_error",
    });

    const [show_support_panel, setShowSupportPanel] = useState(false);

    const [isFirstTimeLoading, setFirstTimeLoading] = useState(false);

    let treeError = false;

    let treeAnswerError = false;

    const [stepperError, setStepperError] = useState(false);

    const [introCount, setIntroCount] = useState(0);
    const [concCount, setConcCount] = useState(0);
    const [introductionQuestions, setIntroductionQuestions] = useState(null);
    const [conclusionQuestions, setConclusionQuestions] = useState(null);

    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});
    const [placeholderDesignProps, setPlaceholderDesignProps] = useState({});
    const [language, setLanguage] = useState("en");
    const [questionType, setQuestionType] = useState("");
    const [surveyQuestions, setSurveyQuestions] = useState([]);
    const [addingSurveyQuestions, setAddingSurveyQuestions] = useState(false);
    const [edittingSurveyQuestions, setEdittingSurveyQuestions] =
        useState(false);
    const [editQuestionId, setEditQuestionId] = useState(null);

    const disableNextButton = useRef(false);
    const questionsArraywithErrorLogics = useRef([]);

    const [previewModal, setPreviewModal] = useState({
        open: false,
        img: null,
        loading: false,
        activeIndex: 0,
    });

    const [items, setItems] = useState([]);
    const [activeId, setActiveId] = useState(null);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    useEffect(() => {
        createDragItems([], 2);
    }, []);

    useEffect(() => {
        createDragItems(formValues.designs, addDesigns);
    }, [formValues.designs, test, addDesigns, formValues.title]);

    const handleDragStartDND = useCallback((event) => {
        setActiveId(event.active.id);
    }, []);
    const handleDragEndDND = (event) => {
        const { active, over } = event;

        if (over && active.id !== over?.id) {
            var oldIndex = 0;

            var newIndex = 0;

            items.forEach((item, index) => {
                if (item.id === active.id) {
                    oldIndex = index;
                }
                if (item.id === over.id) {
                    newIndex = index;
                }
            });
            console.log(active.id, over.id);
            setItems((items) => {
                return arrayMove(items, oldIndex, newIndex);
            });

            console.log(formValues.designs);
            let designs = reorder(formValues.designs, oldIndex, newIndex);
            setFormValues({ ...formValues, designs: designs });

            savePreferenceTestData({ ...formValues, designs: designs }, false);
        }

        setActiveId(null);
    };
    const handleDragCancel = useCallback(() => {
        setActiveId(null);
    }, []);

    const showError = (error) => {
        //toast.dismiss();

        toast(
            <ToastMessage
                type={"error"}
                message={error}
                closable={false}
                onClose={() => {
                    toast.dismiss();
                }}
            />,
            {
                id: "error",
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
    };

    const navigateTree = (tree, task) => {
        tree.forEach(function (item) {
            if (task == "expand") {
                item["expanded"] = true;
            }
            if (task == "error") {
                if (!item.title) {
                    treeError = true;

                    item["error"] = true;
                } else {
                    item["error"] = false;
                }
            }
            if (item.children.length > 0) {
                navigateTree(item.children, task);
            }
        });
    };
    const checkIfNonLeaf = (tree, answer_id) => {
        tree.forEach(function (item) {
            if (answer_id == item.id && item.children.length > 0) {
                treeAnswerError = true;

                item["error"] = true;
            }

            if (item.children.length > 0) {
                checkIfNonLeaf(item.children, answer_id);
            }
        });
    };
    const getTestApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (response.test.workspace_project) {
                    if (response.workspace_project_team_member) {
                        if (
                            response.workspace_project_team_member.status !=
                            "active"
                        ) {
                            navigate(
                                "/wsp/" +
                                    response.test.workspace_project
                                        .workspace_id +
                                    "/p/" +
                                    response.test.workspace_project.id
                            );
                        }
                    }
                }
                allowEditAccess({
                    test_id: test_id,
                    workspace_id: response.test.workspace_id,
                    workspace_project_id: response.test.workspace_project_id,
                });

                getTestLength(test_id);

                setFirstTimeLoading(true);

                setTest(response.test);
                setIntroCount(response.test.briefingQuestion.length);
                setConcCount(response.test.debriefingQuestion.length);
                setIntroductionQuestions(response.test.briefingQuestion);
                setConclusionQuestions(response.test.debriefingQuestion);

                let questions = [];
                response.test.preferenceTestQuestion.forEach((question) => {
                    questions.push(JSON.parse(question));
                });
                // if (questions.length < 1) {
                setAddingSurveyQuestions(true);
                // }
                setSurveyQuestions(questions);

                setPublishingCredits(response.settings.publishing_credits);

                if (response.test.status) {
                    if (response.test.status == "published") {
                        navigate("/r/project/" + response.test.project_id);
                    } else {
                        if (
                            response.test.welcome_title == "" ||
                            response.test.welcome_description == ""
                        ) {
                            localStorage.setItem(
                                "stepper_error",
                                "The welcome page cannot be empty! Please enter a title and a description."
                            );
                            navigate(
                                "/r/test/" + test_id + "/welcome?error=true"
                            );
                            showError(
                                "The welcome page cannot be empty! Please enter a title and a description."
                            );
                        }
                    }
                }

                setLastSaved(moment(response.test.updated_at));

                calcPlaceholderDesigns(
                    response.test.preference_test_designs.length
                );

                setFormValues({
                    title: response.test.preference_test_setting
                        ? response.test.preference_test_setting
                              .preference_test_title == null
                            ? ""
                            : response.test.preference_test_setting
                                  .preference_test_title
                        : "",
                    randomize_order: response.test.preference_test_setting
                        ? response.test.preference_test_setting.randomize_order
                        : 0,
                    show_fullscreen: response.test.preference_test_setting
                        ? response.test.preference_test_setting.show_fullscreen
                        : 0,
                    show_design_titles: response.test.preference_test_setting
                        ? response.test.preference_test_setting
                              .show_design_titles
                        : 0,
                    designs: response.test.preference_test_designs,
                });

                setValidateForm(true);
            } else {
                if (response.message == "Project not found.") {
                    navigate("/404");
                } else {
                    showError(response.message);
                }
            }
        });
    };
    const calcPlaceholderDesigns = (current_designs_count) => {
        if (current_designs_count >= 2) {
            setAddDesigns(0);
        } else if (current_designs_count >= 1) {
            setAddDesigns(1);
        } else if (current_designs_count >= 0) {
            setAddDesigns(2);
        } else {
            setAddDesigns(2);
        }
    };
    const validateFullForm = () => {
        var error = false;

        var firsterrorid = null;

        var form_errors = resetFormErrors();

        if (!formValues.title) {
            error = true;

            firsterrorid = firsterrorid == null ? "title" : firsterrorid;

            form_errors = {
                ...form_errors,
                title: "A task description is required",
                error_class: "input_error",
            };
        } else if (formValues.designs.length < 2) {
            error = true;

            firsterrorid = firsterrorid == null ? "designs" : firsterrorid;

            form_errors = {
                ...form_errors,
                designs: "At least 2 designs are required",
                error_class: "input_error",
            };
        }
        if (error && firsterrorid != null && accordians["preferencetest"]) {
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
        if (error) {
            setStepperError(true);
            localStorage.setItem(
                "stepper_error",
                "You missed some key information. Please check again and fill out any missing fields."
            );
            showError(
                "You missed some key information. Please check again and fill out any missing fields."
            );
        }
        setFormErrors(form_errors);

        return error;
    };
    const saveBuildTestStep = () => {
        var checkIntroductionQuestionIfErrorExits = true;

        //
        if (introductionQuestionRef.current) {
            checkIntroductionQuestionIfErrorExits =
                introductionQuestionRef.current.questionValidateForm(true);
        }

        // check if any error exists in introduction question
        if (
            !checkIntroductionQuestionIfErrorExits &&
            checkIntroductionQuestionIfErrorExits != undefined
        ) {
            console.log("error exits intro");

            return false;
        }

        var error = validateFullForm();

        if (error) {
            return false;
        }

        // check if any question has enable editing then show error
        if (edittingSurveyQuestions) {
            var checkIfErrorExits =
                questionInputValidateRef.current.handleQuestionSubmit(true);

            if (!checkIfErrorExits && !error) {
                var checkError = checkIntroductionConclusionError();
                console.log(checkError);
                if (checkError || checkError === undefined) {
                    handleSubmitTest();
                }
            }
            return false;
        } else {
            if (!error) {
                var checkErrors = checkIntroductionConclusionError();
                console.log(checkErrors);
                if (checkErrors || checkErrors === undefined) {
                    handleSubmitTest();
                }
                //savePreferenceTestData(formValues, true);
            }
        }
    };

    const checkIntroductionConclusionError = () => {
        var checkConclusionQuestionIfErrorExits = true;

        if (conclusionQuestionRef.current) {
            checkConclusionQuestionIfErrorExits =
                conclusionQuestionRef.current.questionValidateForm(true);
        }

        // check if any error exists in conclusion question
        if (
            !checkConclusionQuestionIfErrorExits &&
            checkConclusionQuestionIfErrorExits != undefined
        ) {
            console.log("error exits conclusion");

            return false;
        }
    };

    const handleSubmitTest = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "create_test_step2",
            type: test.methodology,
            userID: ReactSession.get("user")
                ? ReactSession.get("user").user_id
                : "",
        });

        navigate("/r/test/" + test_id + "/thank-you");
    };

    const savePreferenceTestData = (form, shouldnavigate) => {
        if (shouldnavigate) {
            setPreferenceTestStepLoading(true);
        } else {
            //setLoading(true);
        }
        setIsSaved({ status: "loading", message: "" });

        let new_designs = [];

        for (var i = 0; i < form.designs.length; i++) {
            let temp_design = form.designs[i];

            delete temp_design["test_id"];

            new_designs.push(temp_design);
        }
        let data = Object.assign({}, form);

        data["test_id"] = test_id;

        data["designs"] = new_designs;

        data["autosave"] = 1;

        if (shouldnavigate) {
            data["autosave"] = 0;
        }
        if (data && data.title != "") {
            setStepperError(false);
        }

        updatePreferenceTestService(data, ReactSession.get("token")).then(
            (response) => {
                setLoading(false);

                setPreferenceTestStepLoading(false);

                if (response.success) {
                    setIsSaved({ status: "success", message: "" });
                    setLastSaved(moment.now());
                    if (shouldnavigate) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            event: "create_test_step2",
                            type: test.methodology,
                            userID: ReactSession.get("user")
                                ? ReactSession.get("user").user_id
                                : "",
                        });
                        navigate("/r/test/" + test_id + "/thank-you");
                    }
                } else {
                    setIsSaved({ status: "error", message: response.message });
                    showError(response.message);
                }
            }
        );
    };
    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Preference test";

        //window.addEventListener('scroll', handleScroll);

        getTestApi();
    }, []);

    useEffect(() => {
        if (nodeRef.current) {
            nodeRef.current.focus();
        }
    }, [formValues]);

    useEffect(() => {
        if (validate_form) {
            setValidateForm(false);

            if (searchParams.get("error") == "true") {
                setStepperError(true);

                validateFullForm();
            }
        }
    }, [validate_form]);

    useEffect(() => {
        if (nodeRef.current) {
            nodeRef.current.focus();
        }
        // if(surveyQuestions.length>0){
        if (isFirstTimeLoading) {
            save5SecondsTestEveryChange(true, false);
        }
        // }
    }, [surveyQuestions, edittingSurveyQuestions]);

    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
        };
    };
    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);
        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;
        formErrorsLocal["error_class"] = "input_error";
        setFormErrors(formErrorsLocal);
        // console.log(formErrorsLocal);
    };
    const clearFormValue = (name) => {
        setFormValues({ ...formValues, [name]: "" });

        /*setTimeout(function () {
			
		}, 1000);*/
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);
        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
        return formErrorsLocal;
    };

    const addDesign = (e) => {
        e.preventDefault();

        if (addDesigns + formValues.designs.length + 1 <= 5) {
            setAddDesigns(addDesigns + 1);

            createDragItems(formValues.designs, addDesigns + 1);
        }
        //setAddDesignModal({ ...addDesignModal, open: true });
    };
    const renderAddDesign = (e) => {
        e.preventDefault();
    };

    const deleteDesign = (design_id) => {
        let token = ReactSession.get("token");

        setLoading(true);
        deleteDesignService({ id: design_id, test_id: test_id }, token).then(
            (response) => {
                setLoading(false);
                if (response.success) {
                    setLastSaved(moment());

                    var designs = [];

                    formValues.designs.forEach(function (item, index) {
                        if (item.id != design_id) {
                            var new_design = item;
                            new_design.sequence = index + 1;
                            designs.push(new_design);
                        }
                    });

                    setFormValues({ ...formValues, designs: designs });

                    calcPlaceholderDesigns(designs.length);

                    getTestLength(test_id);

                    //createDragItems(designs, designs < 2 ? 2 - designs.length : 0);
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                        }
                    );
                }
            }
        );
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    function onDragEnd(result) {
        setPlaceholderDesignProps({});
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const designs = reorder(
            formValues.designs,
            result.source.index,
            result.destination.index
        );

        setFormValues({ ...formValues, designs: designs });

        savePreferenceTestData({ ...formValues, designs: designs }, false);

        /*let token = ReactSession.get("token");

		setLoading(true);

		var design_id = [];

		designs.forEach(function (item) {
			design_id.push(item.id);
		});

		reorderDesignService(
			{ design_id: design_id, test_id: test_id },
			token
		).then((response) => {
			setLoading(false);
			if (response.success) {
				setLastSaved(moment());
			} else {
				toast(
					<div className="toastinner">
						{response.message}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: "errortoast",
						position: "bottom-center",
					}
				);
			}
		});*/
    }
    const renderDesigns = () => {
        var designs_render = [];

        formValues.designs.forEach(function (item, i) {
            designs_render.push(
                <Draggable
                    draggableId={"design" + item.id}
                    key={"design" + item.id}
                    index={i}
                >
                    {(provided, snapshot) => (
                        <div
                            // className="preference-test-designs-repeat"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`preference-test-designs-repeat ${
                                snapshot.isDragging
                                    ? "draggable-inner-element-start"
                                    : "grey-bg"
                            }`}
                        >
                            <ImageUpload
                                onDesignUpdated={(design, index) => {
                                    var designs = [...formValues.designs];

                                    designs[index] = design;

                                    setLastSaved(moment());

                                    setFormValues({
                                        ...formValues,
                                        designs: designs,
                                    });
                                    //console.log(designs);
                                }}
                                index={i}
                                design={item}
                                test={test}
                                setEditDesignModal={setEditDesignModal}
                                setConfirmModal={setConfirmModal}
                                updateHeaderLoader={(isSaved) => {
                                    setIsSaved(isSaved);
                                }}
                                onPreview={(index) => {
                                    setPreviewModal({
                                        ...previewModal,
                                        open: true,
                                        activeIndex: index,
                                        items: formValues.designs,
                                    });
                                }}
                            />
                        </div>
                    )}
                </Draggable>
            );
        });

        for (var i = 0; i < addDesigns; i++) {
            designs_render.push(
                <div className="upload-image-hold" onClick={(e)=>{e.preventDefault(); e.stopPropagation()}}>
                    <ImageUpload
                        design={null}
                        index={formValues.designs.length + i}
                        test={test}
                        onDesignAdded={(design) => {
                            var designs = Object.assign([], formValues.designs);
                            designs.push(design);

                            setFormValues({ ...formValues, designs: designs });

                            setAddDesigns(addDesigns - 1);

                            if (designs.length >= 2) {
                                setStepperError(false);
                                setFormErrors({ ...formErrors, designs: null });
                            }
                            getTestLength(test_id);
                        }}
                    />
                </div>
            );
        }
        return (
            <DragDropContext
                onDragEnd={onDragEnd}
                onDragStart={handleDragDesignStart}
                onDragUpdate={handleDragDesignUpdate}
                style={{ position: "relative" }}
                className="row"
            >
                <Droppable
                    droppableId={"list"}
                    // direction={'horizontal'}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            // className="preference-designs-inner-hold"
                            style={{ width: "100%" }}
                            className={
                                snapshot.isDragging
                                    ? "question-answers-dragging draggable-element-start preference-designs-inner-hold"
                                    : "preference-designs-inner-hold"
                            }
                        >
                            {designs_render}
                            {provided.placeholder}

                            {/*{!isEmpty(placeholderDesignProps) && (*/}
                            {/*	<div*/}
                            {/*		className="placeholder"*/}
                            {/*		style={{*/}
                            {/*			top: placeholderDesignProps.clientY,*/}
                            {/*			left: placeholderDesignProps.clientX,*/}
                            {/*			height: placeholderDesignProps.clientHeight,*/}
                            {/*			width: placeholderDesignProps.clientWidth,*/}
                            {/*			position: "absolute",*/}
                            {/*			borderColor: "#000000",*/}
                            {/*			background:placeholderDesignProps.background,*/}
                            {/*			borderRadius:'10px',*/}
                            {/*			border:placeholderDesignProps.border,*/}
                            {/*			margin: '-15px 0px 10px'*/}
                            {/*		}}*/}
                            {/*	/>*/}
                            {/*)}*/}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };
    const createDragItems = (designs, more_designs) => {
        var designs_render = [];

        designs.forEach(function (item, i) {
            designs_render.push({
                id: item.id,
                item: (
                    <div key={"design" + item.id}
                        onTouchStart={(e)=>{e.preventDefault(); e.stopPropagation();}}
                    >
                        <ImageUpload
                            onDesignUpdated={(design, index) => {
                                var designs = [...formValues.designs];

                                designs[index] = design;

                                setLastSaved(moment());

                                setFormValues({
                                    ...formValues,
                                    designs: designs,
                                });
                                console.log(designs);

                                //createDragItems(designs,addDesigns);
                            }}
                            index={i}
                            design={item}
                            test={test}
                            setEditDesignModal={setEditDesignModal}
                            setConfirmModal={setConfirmModal}
                            updateHeaderLoader={(isSaved) => {
                                setIsSaved(isSaved);
                            }}
                            onPreview={(index) => {
                                setPreviewModal({
                                    ...previewModal,
                                    open: true,
                                    activeIndex: index,
                                    items: formValues.designs,
                                });
                            }}
                        />
                    </div>
                ),
                type: "design",
            });
        });

        for (var i = 0; i < more_designs; i++) {
            designs_render.push({
                id: "added_design" + i,
                item: (
                    <div
                        onMouseDown={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onTouchStart={(e)=>{e.preventDefault(); e.stopPropagation()}}
                        className="preference_to_add_design"
                        key={"added_desing" + i}
                    >
                        <ImageUpload
                            design={null}
                            index={designs.length + i}
                            test={test}
                            onDesignAdded={(design) => {
                                var designs = Object.assign(
                                    [],
                                    formValues.designs
                                );
                                designs.push(design);

                                setFormValues({
                                    ...formValues,
                                    designs: designs,
                                });

                                setAddDesigns(addDesigns - 1);

                                if (designs.length >= 2) {
                                    setStepperError(false);
                                    setFormErrors({
                                        ...formErrors,
                                        designs: null,
                                    });
                                }
                                getTestLength(test_id);

                                //createDragItems(designs, addDesigns - 1);
                            }}
                        />
                    </div>
                ),
                type: "add_design",
            });
        }
        setItems(designs_render);
    };
    const renderSortedDesigns = () => {
        console.log(items);
        return (
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStartDND}
                onDragEnd={handleDragEndDND}
                onDragCancel={handleDragCancel}
            >
                <SortableContext
                    items={items.map((item) => {
                        return item.id;
                    })}
                    strategy={rectSortingStrategy}
                >
                    <Grid columns={2}>
                        {items.map((item, index) => (
                            <SortableItem
                                class_name={
                                    item.type == "add_design"
                                        ? "add_design"
                                        : ""
                                }
                                key={"descign" + index}
                                id={item.id}
                            >
                                {item.item}
                            </SortableItem>
                        ))}
                    </Grid>
                </SortableContext>
                <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
                    {activeId ? (
                        <Item id={activeId} isDragging>
                            {items.map((item) => {
                                if (item.id == activeId) {
                                    return item.item;
                                }
                            })}
                        </Item>
                    ) : null}
                </DragOverlay>
            </DndContext>
        );
    };
    const designTitleMissing = () => {
        var error = false;
        formValues.designs.forEach(function (item) {
            if (!item.title) {
                error = true;
            }
        });
        return error;
    };

    const handleDragDesignStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(
                window.getComputedStyle(draggedDOM.parentNode.parentNode)
                    .paddingTop
            ) +
            [...draggedDOM.parentNode.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style =
                        curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    const marginTop = parseFloat(style.marginTop);
                    return total + curr.clientHeight + marginTop;
                }, 0);

        setPlaceholderDesignProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background: "#CCCCCC",
            border: "none",
        });
    };

    const handleDragDesignUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];

        var clientY =
            parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingTop
            ) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style =
                    curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                const marginTop = parseFloat(style.marginTop);
                return total + curr.clientHeight + marginTop;
            }, 0);

        setPlaceholderDesignProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background: "none",
            border: "1px dashed",
        });
    };

    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(
                window.getComputedStyle(draggedDOM.parentNode.parentNode)
                    .paddingTop
            ) +
            [...draggedDOM.parentNode.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style =
                        curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    const marginTop = parseFloat(style.marginTop);
                    return total + curr.clientHeight + marginTop;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background: "#CCCCCC",
            border: "none",
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];

        var clientY =
            parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingTop
            ) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style =
                    curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                const marginTop = parseFloat(style.marginTop);
                return total + curr.clientHeight + marginTop;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
            background: "none",
            border: "1px dashed",
        });
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    const save5SecondsTestEveryChange = (saveUsingNavigation, taskName) => {
        if (testController.current) {
            testController.current.abort();
        }
        const controller = new AbortController();

        testController.current = controller;

        let token = ReactSession.get("token");
        if (!saveUsingNavigation) {
            setLoading(true);
        }

        setIsSaved({ status: "loading", message: "" });
        var data = new FormData();

        data.append("test_id", test_id);
        // if (image) {
        //     data.append("task_file", image);
        // }
        // data.append("file_type", fileType);
        // set blank task name if clear input field

        if (!saveUsingNavigation) {
            data.append("saved_step", 2);
        }

        surveyQuestions.forEach((item) => {
            data.append("survey_questions[]", JSON.stringify(item));
        });

        savePreferenceTestQuestions(
            data,
            token,
            testController.current?.signal
        ).then((response) => {
            setLoading(false);
            if (response.success) {
                setIsSaved({ status: "success", message: "" });

                setLastSaved(moment.now());
                if (!saveUsingNavigation) {
                    navigate("/r/test/" + test_id + "/thank-you/");
                }
                getTestLength(test_id);
            } else {
                setIsSaved({ status: "error", message: response.message });
            }
        });
    };

    function Position(obj) {
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
        }
    }

    const duplicatequestion = (index) => {
        if (!edittingSurveyQuestions) {
            if (!duplicateProgress && surveyQuestions.length < 3) {
                let list = [...surveyQuestions];
                // let question = Object.assign({},surveyQuestions[index]);
                let question = _.cloneDeep(surveyQuestions[index]);
                // list.push(question);

                if (duplicateController.current) {
                    duplicateController.current.abort();
                }
                const controller = new AbortController();

                duplicateController.current = controller;

                setDuplicateProgress(true);

                setLoading({ status: "loading", message: "" });

                let token = ReactSession.get("token");

                var data = {};

                data.test_id = test.id;

                data.question = question;

                duplicateQuestion(
                    data,
                    token,
                    duplicateController.current?.signal
                ).then((response) => {
                    setDuplicateProgress(false);

                    if (response.success) {
                        setLoading({ status: "success", message: "" });

                        list.splice(index, 0, response.question);
                        setSurveyQuestions(list);

                        //scrolling to duplicated question

                        var fixedElementHeight =
                            document.querySelector(".researcher-header")
                                .offsetHeight +
                            document.querySelector(
                                ".three-column-layout-top-header"
                            ).offsetHeight;
                        var offsetTop = Position(
                            document.getElementById("ques-" + index)
                        );
                        console.log(offsetTop - fixedElementHeight);
                        window.scrollTo({
                            top: offsetTop - fixedElementHeight + 0,
                            behavior: "smooth",
                        });

                        setTimeout(function () {
                            var duplicateQuestionElement =
                                document.getElementById(
                                    "question-" + (index + 1)
                                );
                            //console.log(duplicateQuestionElement)
                            if (duplicateQuestionElement) {
                                duplicateQuestionElement.classList.add(
                                    "question-duplicate-animation"
                                );

                                setTimeout(function () {
                                    duplicateQuestionElement.classList.remove(
                                        "question-duplicate-animation"
                                    );
                                    // console.log(duplicateQuestionElement);
                                }, 5000);
                            }
                        }, 100);
                    } else {
                        setLoading({
                            status: "error",
                            message: response.message,
                        });
                    }
                });
            } else {
                showError(
                    "You've hit the maximum amount of follow up questions! If you need to add more, consider removing earlier ones."
                );
            }
        } else {
            showError("Finish editing question");
        }
    };

    const edittingQuestionsHandler = (index) => {
        if (!edittingSurveyQuestions) {
            setEdittingSurveyQuestions(true);
            setEditQuestionId(index);
        } else {
            showError("Finish editing earlier question.");
        }
    };

    const removequestion = (index) => {
        const list = [...surveyQuestions];
        list.splice(index, 1);
        setSurveyQuestions(list);
        // if (list.length == 0) {
        setAddingSurveyQuestions(true);
        // }

        setEdittingSurveyQuestions(false);
    };

    const toggleLogicHandler = (logicstatus, questionindex) => {
        if (questionType === "singlechoice") {
            const surveyQuestionsEdit = [...surveyQuestions];
            surveyQuestionsEdit[questionindex]["is_logic"] = logicstatus;
            surveyQuestionsEdit[questionindex]["jumpTo"] = [];
            var jumptolist = [];
            surveyQuestionsEdit[questionindex].options.forEach(() => {
                jumptolist.push(
                    questionindex + 1 < surveyQuestionsEdit.length
                        ? questionindex + 2
                        : "End Survey"
                );
            });
            surveyQuestionsEdit[questionindex]["jumpTo"] = jumptolist;

            setSurveyQuestions(surveyQuestionsEdit);
        } else {
            const surveyQuestionsEdit = [...surveyQuestions];
            surveyQuestionsEdit[questionindex]["is_logic"] = logicstatus;
            surveyQuestionsEdit[questionindex]["jump_to_question"] = logicstatus
                ? "End Survey"
                : "";

            setSurveyQuestions(surveyQuestionsEdit);
        }
    };

    const saveOptionJumpToHandler = (
        optionindex,
        value,
        questionType,
        questionindex
    ) => {
        // console.log(questionType)
        if (questionType === "singlechoice") {
            // console.log(surveyQuestions[questionindex]);
            const surveyQuestionsEdit = [...surveyQuestions];
            let question = Object.assign({}, surveyQuestions[questionindex]);
            question["jumpTo"][optionindex] = value;
            surveyQuestionsEdit.splice(questionindex, 1, question);
            setSurveyQuestions([...surveyQuestionsEdit]);
        } else {
            const surveyQuestionsEdit = [...surveyQuestions];
            let question = Object.assign({}, surveyQuestions[questionindex]);
            question["jump_to_question"] = value;
            surveyQuestionsEdit.splice(questionindex, 1, question);
            setSurveyQuestions([...surveyQuestionsEdit]);
        }
    };

    const cancelQuestionHandler = () => {
        if (surveyQuestions.length != 0) {
            // setAddingSurveyQuestions(false);
        }
        setQuestionType("");
        setEdittingSurveyQuestions(false);
    };
    const cancelEditQuestionHandler = () => {
        setEdittingSurveyQuestions(false);
        setEditQuestionId(null);
    };

    const addquestion = (type) => {
        if (surveyQuestions.length < 3) {
            setQuestionType(type);
        } else {
            showError(
                "You've hit the maximum amount of follow up questions! If you need to add more, consider removing earlier ones."
            );
        }
    };

    const validatelogic = (questions) => {
        var error = false;
        questions.forEach((question, index) => {
            if (question.questionType != "singlechoice") {
                if (question.is_logic && question.is_logic == 1) {
                    if (
                        question.is_logic == 1 &&
                        question.jump_to_question < index + 2
                    ) {
                        error = true;
                    }
                    if (
                        question.is_logic == 1 &&
                        question.jump_to_question == "End survey"
                    ) {
                        error = false;
                    }
                    if (question.error && question.error == 1) {
                        error = true;
                    }
                }
            } else {
                if (question.is_logic && question.is_logic == 1) {
                    question.jumpTo.forEach((jumptoOption, i) => {
                        if (
                            jumptoOption != "End survey" &&
                            jumptoOption < index + 2
                        ) {
                            error = true;
                        }
                        if (question.error && question.error[i] == 1) {
                            error = true;
                        }
                    });
                }
            }
        });
        if (error == true) {
            disableNextButton.current = true;
        } else {
            disableNextButton.current = false;
        }
        return error;
    };
    const updateLogic = (srcI, desI) => {
        // console.log(srcI);
        // console.log(desI);
        // console.log(surveyQuestions);
        if (srcI != desI) {
            surveyQuestions.forEach((question, index) => {
                if (index > desI && index <= srcI) {
                    // console.log(question);
                    // console.log("question");
                    if (question.is_logic == 1) {
                        if (question.questionType != "singlechoice") {
                            if (question.jump_to_question != "End survey") {
                                if (
                                    question.jump_to_question > desI + 1 &&
                                    question.jump_to_question < srcI + 1
                                ) {
                                    question.jumpTo = question.jumpTo + 1;
                                    question.jump_to_question =
                                        question.jump_to_question + 1;
                                    surveyQuestions.splice(index, 1, question);
                                }
                            }
                        } else {
                            var jumparray = [];
                            question.jumpTo.forEach((jumptooption) => {
                                if (jumptooption != "End survey") {
                                    if (
                                        jumptooption > desI + 1 &&
                                        jumptooption < srcI + 1
                                    ) {
                                        jumparray.push(jumptooption + 1);
                                    } else {
                                        jumparray.push(jumptooption);
                                    }
                                } else {
                                    jumparray.push(jumptooption);
                                }
                            });
                            question.jumpTo = jumparray;
                            surveyQuestions.splice(index, 1, question);
                        }
                    }
                }
                if (index < desI && index >= srcI) {
                    // console.log(question);
                    // console.log("question");
                    if (question.is_logic == 1) {
                        if (question.questionType != "singlechoice") {
                            if (question.jump_to_question != "End survey") {
                                if (
                                    question.jump_to_question <= desI + 1 &&
                                    question.jump_to_question != srcI + 1
                                ) {
                                    question.jumpTo = question.jumpTo - 1;
                                    question.jump_to_question =
                                        question.jump_to_question - 1;
                                    surveyQuestions.splice(index, 1, question);
                                }
                            }
                        } else {
                            var jumparray = [];
                            question.jumpTo.forEach((jumptooption) => {
                                if (
                                    jumptooption != "End survey" &&
                                    jumptooption != srcI + 1
                                ) {
                                    if (jumptooption <= desI + 1) {
                                        jumparray.push(jumptooption - 1);
                                    } else {
                                        jumparray.push(jumptooption);
                                    }
                                } else {
                                    jumparray.push(jumptooption);
                                }
                            });
                            question.jumpTo = jumparray;
                            surveyQuestions.splice(index, 1, question);
                        }
                    }
                }
                if (index < desI && index < srcI) {
                    if (question.is_logic == 1) {
                        if (question.questionType != "singlechoice") {
                            // console.log("question");
                            // console.log(question);
                            if (question.jump_to_question != "End survey") {
                                if (srcI < desI) {
                                    if (
                                        question.jump_to_question > srcI + 1 &&
                                        question.jump_to_question <= desI + 1
                                    ) {
                                        // console.log("question drag down");
                                        // console.log(question);
                                        question.jumpTo = question.jumpTo - 1;
                                        question.jump_to_question =
                                            question.jump_to_question - 1;
                                        surveyQuestions.splice(
                                            index,
                                            1,
                                            question
                                        );
                                        return;
                                    }
                                }
                                if (srcI > desI) {
                                    if (
                                        question.jump_to_question < srcI + 1 &&
                                        question.jump_to_question >= desI + 1
                                    ) {
                                        // console.log("question drag up")
                                        // console.log(question);
                                        question.jumpTo = question.jumpTo + 1;
                                        question.jump_to_question =
                                            question.jump_to_question + 1;
                                        surveyQuestions.splice(
                                            index,
                                            1,
                                            question
                                        );
                                        return;
                                    }
                                }
                            }
                        } else {
                            // console.log("questionsingle");
                            // console.log(question);
                            var jumparray = [];
                            var changed = false;
                            question.jumpTo.forEach((jumptooption) => {
                                if (jumptooption != "End survey") {
                                    if (srcI < desI) {
                                        if (
                                            jumptooption > srcI + 1 &&
                                            jumptooption <= desI + 1
                                        ) {
                                            // console.log("questionjumpdown");
                                            // console.log(question);
                                            jumparray.push(jumptooption - 1);
                                        } else if (jumptooption == srcI + 1) {
                                            // console.log("questionjumpdownequaltosource");
                                            // console.log(question);
                                            jumparray.push(desI + 1);
                                        } else {
                                            jumparray.push(jumptooption);
                                        }
                                        changed = true;
                                    }
                                    if (srcI > desI) {
                                        if (
                                            jumptooption < srcI + 1 &&
                                            jumptooption >= desI + 1
                                        ) {
                                            // console.log("questionjumpup");
                                            // console.log(question);
                                            jumparray.push(jumptooption + 1);
                                        } else if (jumptooption == srcI + 1) {
                                            // console.log("questionjumpdownequaltosource");
                                            // console.log(question);
                                            jumparray.push(desI + 1);
                                        } else {
                                            jumparray.push(jumptooption);
                                        }
                                        changed = true;
                                    }
                                } else {
                                    jumparray.push(jumptooption);
                                }
                            });
                            question.jumpTo = jumparray;
                            surveyQuestions.splice(index, 1, question);
                            if (changed == true) {
                                return;
                            }
                        }
                    }
                }
                if (question.is_logic == 1) {
                    if (question.questionType != "singlechoice") {
                        // console.log("question");
                        // console.log(question);
                        if (question.jump_to_question != "End survey") {
                            if (question.jump_to_question == srcI + 1) {
                                console.log(
                                    "question whose jumpto matches to source"
                                );
                                console.log(question);
                                question.jumpTo = desI + 1;
                                question.jump_to_question = desI + 1;
                                surveyQuestions.splice(index, 1, question);
                            }
                        }
                    } else {
                        // console.log("questionsingle");
                        // console.log(question);
                        var jumparray = [];
                        question.jumpTo.forEach((jumptooption) => {
                            if (jumptooption != "End survey") {
                                if (jumptooption == srcI + 1) {
                                    jumparray.push(desI + 1);
                                } else {
                                    jumparray.push(jumptooption);
                                }
                            } else {
                                jumparray.push(jumptooption);
                            }
                        });
                        question.jumpTo = jumparray;
                        surveyQuestions.splice(index, 1, question);
                    }
                }

                if (question.is_logic == 1 && index == desI) {
                    if (question.questionType != "singlechoice") {
                        question.error = 1;
                        surveyQuestions.splice(index, 1, question);
                    } else {
                        var errorarray = [];
                        question.jumpTo.forEach((jumptooption) => {
                            errorarray.push(1);
                        });
                        question.error = errorarray;
                        surveyQuestions.splice(index, 1, question);
                    }
                }
            });
            var errorarray = questionsArraywithErrorLogics.current;
            errorarray.push(desI);
            questionsArraywithErrorLogics.current = errorarray;
            // console.log(surveyQuestions);
            validatelogic(surveyQuestions);
        }
        save5SecondsTestEveryChange(true, false);
    };

    return (
        <LayoutResearcher
            isLoading={false}
            fixed_header_target={true}
            wrapClass={`create-test-data-wrapper ${
                test && test.language == "ar" ? "arabic_wrapper_main" : ""
            }`}
            skipCheck={false}
            extendedFooter={false}
            activeMenu={""}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
        >
            <Helmet>
                <title>Create preference test - step 2 | UserQ</title>
            </Helmet>
            <div className="three-column-layout">
                <Header
                    test={test}
                    isLoading={isLoading}
                    isSaved={isSaved}
                    credits_required={publishing_credits}
                />
                <div className="three-column-layout-body">
                    <div className="three-column-layout-left">
                        <Stepper
                            test={test}
                            completed_step={1}
                            stepCallback={() => {
                                savePreferenceTestData(formValues, false);
                            }}
                            error={stepperError}
                        />
                    </div>
                    {isLoading && (
                        <div className="test-section-loader">
                            {" "}
                            <LoadingIcon />
                        </div>
                    )}
                    {!isLoading && (
                        <>
                            <div className="three-column-layout-center">
                                <div className="create-prefer-text-wrapper">
                                    <div className="card-sorting-creating-test-wrap">
                                        <div className="section-minimum-height">
                                            <Accordian
                                                title={"Introduction questions"}
                                                subtitle={"(Optional)"}
                                                tooltip={"Some cool text"}
                                                open={
                                                    accordians["introduction"]
                                                        ? true
                                                        : false
                                                }
                                                onChange={() => {
                                                    let new_accordians =
                                                        Object.assign(
                                                            {},
                                                            accordians
                                                        );

                                                    for (var key in accordians) {
                                                        if (
                                                            key ==
                                                            "introduction"
                                                        ) {
                                                            if (
                                                                accordians[key]
                                                            ) {
                                                                new_accordians[
                                                                    key
                                                                ] = false;
                                                            } else {
                                                                new_accordians[
                                                                    key
                                                                ] = true;
                                                            }
                                                        }
                                                    }
                                                    setAccordians(
                                                        new_accordians
                                                    );
                                                }}
                                                questionCount={introCount}
                                            >
                                                <IntroductionQuestions
                                                    ref={
                                                        introductionQuestionRef
                                                    }
                                                    questions={
                                                        introductionQuestions
                                                    }
                                                    test={test}
                                                    setLoading={(status) =>
                                                        setIsSaved(status)
                                                    }
                                                    changeCount={(newCount) => {
                                                        setIntroCount(newCount);
                                                    }}
                                                    updateQuestions={(
                                                        questions
                                                    ) => {
                                                        setIntroductionQuestions(
                                                            [...questions]
                                                        );
                                                    }}
                                                />
                                            </Accordian>
                                            <Accordian
                                                title={"Preference test"}
                                                tooltip={"Some cool text"}
                                                open={
                                                    accordians["preferencetest"]
                                                        ? true
                                                        : false
                                                }
                                                onChange={() => {
                                                    let new_accordians =
                                                        Object.assign(
                                                            {},
                                                            accordians
                                                        );

                                                    for (var key in accordians) {
                                                        if (
                                                            key ==
                                                            "preferencetest"
                                                        ) {
                                                            if (
                                                                accordians[key]
                                                            ) {
                                                                new_accordians[
                                                                    key
                                                                ] = false;
                                                            } else {
                                                                new_accordians[
                                                                    key
                                                                ] = true;
                                                            }
                                                        }
                                                    }
                                                    setAccordians(
                                                        new_accordians
                                                    );
                                                }}
                                            >
                                                <div className="card-sorting-test-wrap prefer-design-wrap">
                                                    <div className="cardsorting-wrap-top">
                                                        <Text
                                                            type={"subtitle-2"}
                                                            fontWeight={
                                                                "medium-font"
                                                            }
                                                        >
                                                            Task
                                                        </Text>
                                                        <Text
                                                            type={"body-text-2"}
                                                            fontWeight={
                                                                "medium-font"
                                                            }
                                                        >
                                                            Describe the options
                                                            you want
                                                            participants to
                                                            choose from. For
                                                            example, “Which
                                                            design do you
                                                            prefer?”
                                                        </Text>
                                                    </div>
                                                    <div
                                                        className={`cardsorting-wrap-mid-data ${
                                                            test.language ==
                                                            "ar"
                                                                ? "arabic_wrapper"
                                                                : ""
                                                        }`}
                                                        id="title"
                                                    >
                                                        <Input
                                                            id={"Add Task"}
                                                            rtl={
                                                                test.language ==
                                                                "ar"
                                                                    ? true
                                                                    : false
                                                            }
                                                            label={
                                                                "Task description"
                                                            }
                                                            maxLength="200"
                                                            error={
                                                                formErrors.title
                                                            }
                                                            error_class={
                                                                formErrors.error_class
                                                            }
                                                            onClear={() => {
                                                                clearFormValue(
                                                                    "title"
                                                                );
                                                                savePreferenceTestData(
                                                                    {
                                                                        ...formValues,
                                                                        title: "",
                                                                    }
                                                                );
                                                            }}
                                                            value={
                                                                formValues.title
                                                            }
                                                            onChange={(e) => {
                                                                setStepperError(
                                                                    false
                                                                );
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                    title: null,
                                                                });
                                                                setFormValues({
                                                                    ...formValues,
                                                                    title: e
                                                                        .target
                                                                        .value
                                                                        ? e
                                                                              .target
                                                                              .value
                                                                        : "",
                                                                });
                                                            }}
                                                            /*onInput={(e) => {
																maxLengthCheck(e);
																if (e.target.value.length > 0) {
																	setFormErrors({ ...formErrors, title: null });
																}
															}}*/
                                                            onBlur={(e) => {
                                                                if (
                                                                    e.target
                                                                        .value
                                                                ) {
                                                                    setStepperError(
                                                                        false
                                                                    );
                                                                }
                                                                maxLengthCheck(
                                                                    e
                                                                );
                                                                savePreferenceTestData(
                                                                    {
                                                                        ...formValues,
                                                                        title: e
                                                                            .target
                                                                            .value,
                                                                    }
                                                                );
                                                            }}
                                                            required={true}
                                                        />
                                                    </div>
                                                    <div class="create-test-preview-area">
                                                        <Text
                                                            type={"overline"}
                                                            children={"Preview"}
                                                        />
                                                        <div className="create-test-preview-area-inner">
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_URL +
                                                                    "img/prefer-preview.svg"
                                                                }
                                                                alt="img"
                                                            />
                                                        </div>
                                                        <GuideLink
                                                            methodology={
                                                                "preference test"
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="card-sorting-test-wrap prefer-text-designs-wrap">
                                                    <div className="cardsorting-wrap-top">
                                                        <Text
                                                            type={"subtitle-2"}
                                                            fontWeight={
                                                                "medium-font"
                                                            }
                                                        >
                                                            Designs
                                                        </Text>
                                                        <Text
                                                            type={"body-text-2"}
                                                            fontWeight={
                                                                "medium-font"
                                                            }
                                                        >
                                                            The designs you
                                                            choose will be shown
                                                            to your
                                                            participants, where
                                                            they’ll pick the one
                                                            they prefer. You can
                                                            randomise the order
                                                            of the options are
                                                            shown to
                                                            participants, and
                                                            you can also choose
                                                            to show them one by
                                                            one, rather than in
                                                            a grid.
                                                        </Text>
                                                    </div>
                                                    <div className="cardsorting-wrap-mid-data">
                                                        <div className="toggle-button-group-wrap">
                                                            <div className="toggle-btn-repeat">
                                                                <ToggleButton
                                                                    isChecked={
                                                                        formValues.randomize_order ==
                                                                        1
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        setFormValues(
                                                                            {
                                                                                ...formValues,
                                                                                randomize_order:
                                                                                    !formValues.randomize_order,
                                                                            }
                                                                        );
                                                                        savePreferenceTestData(
                                                                            {
                                                                                ...formValues,
                                                                                randomize_order:
                                                                                    !formValues.randomize_order,
                                                                            },
                                                                            false
                                                                        );
                                                                    }}
                                                                    label="Randomise the order that designs are shown to participants"
                                                                />
                                                            </div>
                                                            <div className="toggle-btn-repeat">
                                                                <ToggleButton
                                                                    isChecked={
                                                                        formValues.show_fullscreen ==
                                                                        1
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        setFormValues(
                                                                            {
                                                                                ...formValues,
                                                                                show_fullscreen:
                                                                                    !formValues.show_fullscreen,
                                                                            }
                                                                        );
                                                                        savePreferenceTestData(
                                                                            {
                                                                                ...formValues,
                                                                                show_fullscreen:
                                                                                    !formValues.show_fullscreen,
                                                                            },
                                                                            false
                                                                        );
                                                                    }}
                                                                    label="Show designs one at a time"
                                                                />
                                                            </div>
                                                            <div className="toggle-btn-repeat">
                                                                <ToggleButton
                                                                    isChecked={
                                                                        formValues.show_design_titles ==
                                                                        1
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() => {
                                                                        setFormValues(
                                                                            {
                                                                                ...formValues,
                                                                                show_design_titles:
                                                                                    !formValues.show_design_titles,
                                                                            }
                                                                        );

                                                                        savePreferenceTestData(
                                                                            {
                                                                                ...formValues,
                                                                                show_design_titles:
                                                                                    !formValues.show_design_titles,
                                                                            },
                                                                            false
                                                                        );
                                                                    }}
                                                                    label="Show design titles to participants"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div id="designs">
                                                            <div className="add-pfere-design-guide-text">
                                                                <Text
                                                                    type={
                                                                        "body-text-2"
                                                                    }
                                                                >
                                                                    <Icon
                                                                        value="error"
                                                                        colorClass={
                                                                            "warning-200-svg"
                                                                        }
                                                                        size={
                                                                            "medium"
                                                                        }
                                                                    />{" "}
                                                                    A minimum of
                                                                    2 designs
                                                                    and a
                                                                    maximum of 5
                                                                    should be
                                                                    uploaded
                                                                </Text>
                                                            </div>

                                                            <div className="upload-prefer-design-img-video-wrap">
                                                                <div
                                                                    className={`preference-designs-wrapper ${
                                                                        formErrors.designs
                                                                            ? "preference-design-error"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    {/*renderDesigns()*/}
                                                                    {renderSortedDesigns()}
                                                                    {addDesigns +
                                                                        formValues
                                                                            .designs
                                                                            .length <
                                                                        5 && (
                                                                        <div className="add-more-design-opton w-100">
                                                                            <Button
                                                                                onClick={
                                                                                    addDesign
                                                                                }
                                                                                size={
                                                                                    "medium"
                                                                                }
                                                                                label="Add another design"
                                                                                iconLeft={
                                                                                    <Icon
                                                                                        size={
                                                                                            "small"
                                                                                        }
                                                                                        value="add"
                                                                                        colorClass={
                                                                                            "gray-50-svg"
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {formErrors.designs && (
                                                                <div
                                                                    className={`img-upload-design-error ${formErrors.error_class}`}
                                                                >
                                                                    {
                                                                        formErrors.designs
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-sorting-test-wrap five-second-follw-up-questions prefer-follow-up-section-1">
                                                    <div
                                                        className="cardsorting-wrap-top"
                                                        id="questions"
                                                    >
                                                        <Text
                                                            type={"subtitle-2"}
                                                            fontWeight={
                                                                "medium-font"
                                                            }
                                                        >
                                                            Follow up questions{" "}
                                                            <span className="optional">
                                                                (Optional)
                                                            </span>
                                                        </Text>
                                                        <Text
                                                            type={"body-text-2"}
                                                            fontWeight={
                                                                "medium-font"
                                                            }
                                                        >
                                                            Add up to 3
                                                            questions you want
                                                            to ask the
                                                            participant
                                                            regarding your
                                                            design. We suggest
                                                            adding open-ended
                                                            questions to
                                                            understand what they
                                                            can recall or what
                                                            grabbed their
                                                            attention.
                                                        </Text>
                                                    </div>

                                                    <div className="cardsorting-wrap-mid-data">
                                                        {surveyQuestions.length ===
                                                        0 ? (
                                                            <div className="nodata-wrap"></div>
                                                        ) : (
                                                            <div
                                                                className="added-question-wrap mb-32"
                                                                style={{
                                                                    position:
                                                                        "relative",
                                                                }}
                                                            >
                                                                <DragDropContext
                                                                    onDragEnd={(
                                                                        param
                                                                    ) => {
                                                                        setPlaceholderProps(
                                                                            {}
                                                                        );
                                                                        if (
                                                                            edittingSurveyQuestions
                                                                        ) {
                                                                            showError(
                                                                                "Finish editing your question first."
                                                                            );
                                                                        } else {
                                                                            const srcI =
                                                                                param
                                                                                    .source
                                                                                    .index;
                                                                            const desI =
                                                                                param
                                                                                    .destination
                                                                                    .index;
                                                                            surveyQuestions.splice(
                                                                                desI,
                                                                                0,
                                                                                surveyQuestions.splice(
                                                                                    srcI,
                                                                                    1
                                                                                )[0]
                                                                            );
                                                                            save5SecondsTestEveryChange(
                                                                                true,
                                                                                false
                                                                            );
                                                                            // updateLogic(srcI,desI);
                                                                        }
                                                                    }}
                                                                    onDragStart={
                                                                        handleDragStart
                                                                    }
                                                                    onDragUpdate={
                                                                        handleDragUpdate
                                                                    }
                                                                >
                                                                    <Droppable
                                                                        droppableId="droppable-1"
                                                                        isCombineEnabled
                                                                    >
                                                                        {(
                                                                            provided,
                                                                            snapshot
                                                                        ) => (
                                                                            <div
                                                                                ref={
                                                                                    provided.innerRef
                                                                                }
                                                                                {...provided.droppableProps}
                                                                            >
                                                                                {surveyQuestions.map(
                                                                                    (
                                                                                        question,
                                                                                        index
                                                                                    ) => (
                                                                                        <div
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            className="survey-added-question-repeat"
                                                                                        >
                                                                                            <Draggable
                                                                                                draggableId={
                                                                                                    "draggable-" +
                                                                                                    index
                                                                                                }
                                                                                                index={
                                                                                                    index
                                                                                                }
                                                                                            >
                                                                                                {(
                                                                                                    provided,
                                                                                                    snapshot
                                                                                                ) => (
                                                                                                    <div
                                                                                                        ref={
                                                                                                            provided.innerRef
                                                                                                        }
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                        className={
                                                                                                            snapshot.isDragging
                                                                                                                ? "question-answers-dragging draggable-element-start"
                                                                                                                : ""
                                                                                                        }
                                                                                                    >
                                                                                                        {editQuestionId ===
                                                                                                        index ? (
                                                                                                            <>

                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <div
                                                                                                                key={
                                                                                                                    index
                                                                                                                }
                                                                                                                id={
                                                                                                                    "question-" +
                                                                                                                    index
                                                                                                                }
                                                                                                                className={`survey-question-preview ${
                                                                                                                    snapshot.isDragging
                                                                                                                        ? "draggable-inner-element-start"
                                                                                                                        : "grey-bg"
                                                                                                                }`}
                                                                                                            >
                                                                                                                <div
                                                                                                                    className={`question-answer-holder ${
                                                                                                                        test.language ==
                                                                                                                        "en"
                                                                                                                            ? ""
                                                                                                                            : "arabic_wrapper"
                                                                                                                    }`}
                                                                                                                >
                                                                                                                    <div
                                                                                                                        {...provided.dragHandleProps}
                                                                                                                    >
                                                                                                                        <Question
                                                                                                                            duplicateProgress={
                                                                                                                                duplicateProgress
                                                                                                                            }
                                                                                                                            index={
                                                                                                                                index
                                                                                                                            }
                                                                                                                            question={
                                                                                                                                question
                                                                                                                            }
                                                                                                                            onCopy={() =>
                                                                                                                                duplicatequestion(
                                                                                                                                    index
                                                                                                                                )
                                                                                                                            }
                                                                                                                            onEdit={() =>
                                                                                                                                edittingQuestionsHandler(
                                                                                                                                    index
                                                                                                                                )
                                                                                                                            }
                                                                                                                            onDelete={() =>
                                                                                                                                removequestion(
                                                                                                                                    index
                                                                                                                                )
                                                                                                                            }
                                                                                                                            allQuestions={
                                                                                                                                surveyQuestions
                                                                                                                            }
                                                                                                                            saveJumpTo={(
                                                                                                                                optionindex,
                                                                                                                                value
                                                                                                                            ) =>
                                                                                                                                saveOptionJumpToHandler(
                                                                                                                                    optionindex,
                                                                                                                                    value,
                                                                                                                                    question.questionType,
                                                                                                                                    index
                                                                                                                                )
                                                                                                                            }
                                                                                                                            onToggleLogic={(
                                                                                                                                logicstatus,
                                                                                                                                indexQ
                                                                                                                            ) =>
                                                                                                                                toggleLogicHandler(
                                                                                                                                    logicstatus,
                                                                                                                                    indexQ
                                                                                                                                )
                                                                                                                            }
                                                                                                                            // type="survey"
                                                                                                                            language={
                                                                                                                                test.language
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        id={
                                                                                                                            "ques-" +
                                                                                                                            index
                                                                                                                        }
                                                                                                                    ></div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                )}
                                                                                            </Draggable>
                                                                                            <div
                                                                                                className={`question-edit ${
                                                                                                    test.language ==
                                                                                                    "ar"
                                                                                                        ? "arabic_wrapper"
                                                                                                        : ""
                                                                                                }`}
                                                                                            >
                                                                                                {edittingSurveyQuestions &&
                                                                                                    editQuestionId ===
                                                                                                        index && (
                                                                                                        <EditQuestionInput
                                                                                                            questionDetails={
                                                                                                                surveyQuestions[
                                                                                                                    index
                                                                                                                ]
                                                                                                            }
                                                                                                            cancelEditQuestion={
                                                                                                                cancelEditQuestionHandler
                                                                                                            }
                                                                                                            editQuestions={(
                                                                                                                question
                                                                                                            ) => {
                                                                                                                surveyQuestions.splice(
                                                                                                                    index,
                                                                                                                    1,
                                                                                                                    question
                                                                                                                );
                                                                                                                setSurveyQuestions(
                                                                                                                    [
                                                                                                                        ...surveyQuestions,
                                                                                                                    ]
                                                                                                                );
                                                                                                            }}
                                                                                                            ref={
                                                                                                                questionInputValidateRef
                                                                                                            }
                                                                                                            language={
                                                                                                                test.language
                                                                                                            }
                                                                                                            other={
                                                                                                                test.language ==
                                                                                                                "en"
                                                                                                                    ? question.questionType ==
                                                                                                                      "free"
                                                                                                                        ? "Other"
                                                                                                                        : surveyQuestions[
                                                                                                                              index
                                                                                                                          ].options.slice(
                                                                                                                              -1
                                                                                                                          ) ==
                                                                                                                          "Other"
                                                                                                                        ? true
                                                                                                                        : false
                                                                                                                    : question.questionType ==
                                                                                                                      "free"
                                                                                                                    ? "آخر"
                                                                                                                    : surveyQuestions[
                                                                                                                          index
                                                                                                                      ].options.slice(
                                                                                                                          -1
                                                                                                                      ) ==
                                                                                                                      "آخر"
                                                                                                                    ? true
                                                                                                                    : false
                                                                                                            }
                                                                                                            questionno={
                                                                                                                index +
                                                                                                                1
                                                                                                            }
                                                                                                            questionlist={
                                                                                                                surveyQuestions
                                                                                                            }
                                                                                                            changeQuestionType={(
                                                                                                                questionType
                                                                                                            ) => {
                                                                                                                surveyQuestions[
                                                                                                                    index
                                                                                                                ].questionType =
                                                                                                                    questionType;
                                                                                                            }}
                                                                                                            addLikertSubquestions={() => {
                                                                                                                surveyQuestions[
                                                                                                                    index
                                                                                                                ].subQuestions =
                                                                                                                    [
                                                                                                                        "",
                                                                                                                    ];
                                                                                                            }}
                                                                                                            // islogic={true}
                                                                                                            onDelete={() =>
                                                                                                                removequestion(
                                                                                                                    index
                                                                                                                )
                                                                                                            }
                                                                                                            methodology={
                                                                                                                "preference_test"
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                                {
                                                                                    provided.placeholder
                                                                                }
                                                                                {!isEmpty(
                                                                                    placeholderProps
                                                                                ) && (
                                                                                    <div
                                                                                        className="placeholder"
                                                                                        style={{
                                                                                            top: placeholderProps.clientY,
                                                                                            left: placeholderProps.clientX,
                                                                                            height: placeholderProps.clientHeight,
                                                                                            width: placeholderProps.clientWidth,
                                                                                            position:
                                                                                                "absolute",
                                                                                            borderColor:
                                                                                                "#000000",
                                                                                            background:
                                                                                                placeholderProps.background,
                                                                                            borderRadius:
                                                                                                "10px",
                                                                                            border: placeholderProps.border,
                                                                                            margin: "-15px 0px 10px",
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </DragDropContext>
                                                            </div>
                                                        )}
                                                        {!edittingSurveyQuestions &&
                                                            (!addingSurveyQuestions ? (
                                                                <Button
                                                                    iconLeft={
                                                                        <Icon value="add" />
                                                                    }
                                                                    label="Add question"
                                                                    size={
                                                                        "medium"
                                                                    }
                                                                    type={
                                                                        "ghost"
                                                                    }
                                                                    onClick={() => {
                                                                        if (
                                                                            surveyQuestions.length <
                                                                            3
                                                                        ) {
                                                                            setAddingSurveyQuestions(
                                                                                true
                                                                            );
                                                                        } else {
                                                                            showError(
                                                                                "You've hit the maximum amount of follow up questions! If you need to add more, consider removing earlier ones."
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            ) : (
                                                                <></>
                                                            ))}
                                                        {addingSurveyQuestions &&
                                                        questionType === "" ? (
                                                            <div
                                                                className={`ans-list-wrap question-prev-wrap  survey-test-option-wrap mb-0 `}
                                                            >
                                                                {/* <h3 className="mt-32 mb-0 d-flex justify-content-between">
																	Question {surveyQuestions.length + 1}
																	{surveyQuestions.length > 0 && (
																		<span className="close-question-options"
																			onClick={() => {
																				setAddingSurveyQuestions(false)
																			}}>
																			<img alt="close-icon"
																				src={process.env.REACT_APP_URL + "images/cross.svg"} />
																		</span>
																	)}
																</h3> */}
                                                                {surveyQuestions.length <
                                                                    3 && (
                                                                    <>
                                                                        {surveyQuestions.length >
                                                                            0 && (
                                                                            <div className="add-more-question-heading">
                                                                                <Text
                                                                                    type="body-text-2"
                                                                                    fontWeight="medium"
                                                                                >
                                                                                    Add
                                                                                    more
                                                                                    question(s)
                                                                                </Text>
                                                                            </div>
                                                                        )}
                                                                        <div
                                                                            className={
                                                                                "add-more-question-option"
                                                                            }
                                                                        >
                                                                            <div className="stud-tiles-hold">
                                                                                <QuestionCard
                                                                                    questiontype="Free Text"
                                                                                    onClick={() => {
                                                                                        addquestion(
                                                                                            "free"
                                                                                        );

                                                                                        setEdittingSurveyQuestions(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                <QuestionCard
                                                                                    questiontype="Single choice"
                                                                                    onClick={() => {
                                                                                        addquestion(
                                                                                            "singlechoice"
                                                                                        );
                                                                                        setEdittingSurveyQuestions(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                <QuestionCard
                                                                                    questiontype="Multiple-choice"
                                                                                    onClick={() => {
                                                                                        addquestion(
                                                                                            "multiplechoice"
                                                                                        );
                                                                                        setEdittingSurveyQuestions(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                <QuestionCard
                                                                                    questiontype="Likert scale"
                                                                                    onClick={() => {
                                                                                        addquestion(
                                                                                            "likertscale"
                                                                                        );
                                                                                        setEdittingSurveyQuestions(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                <QuestionCard
                                                                                    questiontype="Rating scale"
                                                                                    onClick={() => {
                                                                                        setQuestionType(
                                                                                            "ratingscale"
                                                                                        );
                                                                                        setEdittingSurveyQuestions(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                <QuestionCard
                                                                                    questiontype="Ranking scale"
                                                                                    onClick={() => {
                                                                                        addquestion(
                                                                                            "rankingscale"
                                                                                        );
                                                                                        setEdittingSurveyQuestions(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={`${
                                                                    test.language ==
                                                                    "en"
                                                                        ? ""
                                                                        : "arabic_wrapper"
                                                                }`}
                                                            >
                                                                <QuestionInput
                                                                    questionType={
                                                                        questionType
                                                                    }
                                                                    cancelQuestion={
                                                                        cancelQuestionHandler
                                                                    }
                                                                    addQuestions={(
                                                                        question
                                                                    ) => {
                                                                        setSurveyQuestions(
                                                                            [
                                                                                ...surveyQuestions,
                                                                                question,
                                                                            ]
                                                                        );
                                                                        setAddingSurveyQuestions(
                                                                            true
                                                                        );
                                                                        setQuestionType(
                                                                            ""
                                                                        );
                                                                        setEdittingSurveyQuestions(
                                                                            false
                                                                        );
                                                                    }}
                                                                    language={
                                                                        test.language
                                                                    }
                                                                    ref={
                                                                        questionInputValidateRef
                                                                    }
                                                                    questionno={
                                                                        surveyQuestions.length +
                                                                        1
                                                                    }
                                                                    questionlist={
                                                                        surveyQuestions
                                                                    }
                                                                    changeQuestionType={(
                                                                        questionType
                                                                    ) => {
                                                                        setQuestionType(
                                                                            questionType
                                                                        );
                                                                    }}
                                                                    // islogic={true}
                                                                    methodology={
                                                                        "preference_test"
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                        {formErrors.questions !=
                                                            null && (
                                                            <span
                                                                className={
                                                                    formErrors.error_class +
                                                                    " followup-question-error"
                                                                }
                                                            >
                                                                {
                                                                    formErrors.questions
                                                                }
                                                            </span>
                                                        )}
                                                    </div>

                                                    {/*<div className="create-test-preview-area">
														<Text
															type={'overline'}
															children={'Preview'}
														/>
														<div
															className="create-test-preview-area-inner questionpreviewinner">
															choose a briefing question <br /> to preview
														</div>
														<GuideLink methodology={"preference test"}/>
													</div>*/}
                                                </div>
                                            </Accordian>
                                            <Accordian
                                                title={"Conclusion questions"}
                                                subtitle={"(Optional)"}
                                                tooltip={"Some cool text"}
                                                open={
                                                    accordians["conclusion"]
                                                        ? true
                                                        : false
                                                }
                                                onChange={() => {
                                                    let new_accordians =
                                                        Object.assign(
                                                            {},
                                                            accordians
                                                        );

                                                    for (var key in accordians) {
                                                        if (
                                                            key == "conclusion"
                                                        ) {
                                                            if (
                                                                accordians[key]
                                                            ) {
                                                                new_accordians[
                                                                    key
                                                                ] = false;
                                                            } else {
                                                                new_accordians[
                                                                    key
                                                                ] = true;
                                                            }
                                                        }
                                                    }
                                                    setAccordians(
                                                        new_accordians
                                                    );
                                                }}
                                                questionCount={concCount}
                                            >
                                                <ConclusionQuestions
                                                    ref={conclusionQuestionRef}
                                                    questions={
                                                        conclusionQuestions
                                                    }
                                                    test={test}
                                                    setLoading={(status) =>
                                                        setIsSaved(status)
                                                    }
                                                    changeCount={(newCount) => {
                                                        setConcCount(newCount);
                                                    }}
                                                    updateQuestions={(
                                                        questions
                                                    ) => {
                                                        setConclusionQuestions([
                                                            ...questions,
                                                        ]);
                                                    }}
                                                />
                                            </Accordian>
                                        </div>
                                        <div class="btns-wrap-bottom">
                                            <Button
                                                onClick={() => {
                                                    navigate(
                                                        "/r/test/" +
                                                            test_id +
                                                            "/welcome"
                                                    );
                                                }}
                                                type={"ghost"}
                                                label={"Previous"}
                                                size={"large"}
                                                iconLeft={
                                                    <Icon
                                                        value="back-arrow"
                                                        colorClass="gray-900-svg"
                                                        size={"large"}
                                                    />
                                                }
                                            />

                                            <Button
                                                id="r_create_step_2_build_next_btn"
                                                onClick={saveBuildTestStep}
                                                iconRight={
                                                    <Icon
                                                        value="forward-arrow"
                                                        colorClass="gray-50-svg"
                                                    />
                                                }
                                                label="Next"
                                                size={"large"}
                                                state={
                                                    formValues.designs.length <
                                                        2 ||
                                                    !formValues.title ||
                                                    designTitleMissing()
                                                        ? "disabled"
                                                        : "active"
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="three-column-layout-right">
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();

                                        setShowSupportPanel(true);
                                    }}
                                    href={
                                        process.env.REACT_APP_URL + "r/support"
                                    }
                                    target="_blank"
                                    className="support-button"
                                >
                                    <Icon
                                        value={"support"}
                                        colorClass={"gray-50-svg"}
                                        size={"medium"}
                                    />
                                    Support
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {previewModal.items && previewModal.items.length > 0 && (
                <>
                    <PreviewNav
                        open={previewModal.open}
                        withMinimizeIcon={true}
                        loading={false}
                        file_type={previewModal.file_type}
                        close={() => {
                            setPreviewModal({ ...previewModal, open: false });
                        }}
                        index={previewModal.activeIndex}
                        items={previewModal.items}
                    />
                </>
            )}
            <ConfirmationModal
                confirm_message="Are you sure you want to delete this design?"
                confirm_btn_title="Delete"
                confirm_title="Delete Design"
                confirm_title_class={"text-danger"}
                openModal={confirmModal.open}
                close={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                }}
                confirm={() => {
                    setConfirmModal({ ...confirmModal, open: false });

                    deleteDesign(confirmModal.design_id);
                }}
            />
            <PreferenceTestDesignModal
                test={test}
                open={addDesignModal.open}
                close={() => {
                    setAddDesignModal({ ...addDesignModal, open: false });
                }}
                confirm={(design) => {
                    setAddDesignModal({ ...addDesignModal, open: false });

                    var designs = [...formValues.designs];

                    designs.push(design);

                    setLastSaved(moment());

                    setFormValues({ ...formValues, designs: designs });
                }}
            />

            <EditPreferenceTestDesignModal
                design={editDesignModal.design}
                test={test}
                open={editDesignModal.open}
                close={() => {
                    setEditDesignModal({ ...editDesignModal, open: false });
                }}
                confirm={(design) => {
                    setEditDesignModal({ ...editDesignModal, open: false });

                    var designs = [...formValues.designs];

                    designs[editDesignModal.index] = design;

                    setLastSaved(moment());

                    setFormValues({ ...formValues, designs: designs });
                }}
            />
        </LayoutResearcher>
    );
}
