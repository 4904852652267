import { useNavigate, useParams, Link, useSearchParams } from 'react-router-dom';
import { ReactSession } from '../../../lib/secure_reactsession.js';
import React, { useEffect, useRef, useState } from 'react';
import { getFeeRewardSettingsService, getTestData, getTestOrderData } from "../../../services/test";
import moment from "moment";
import toast from "react-hot-toast";
import { LayoutResearcher } from "../../themes/userq/Layouts/layout_researcher";
import { Stepper } from "../../themes/userq/Stepper/Stepper";
import { Text } from "../../themes/userq/Text/Text";
import { IncDecCounter } from "../../themes/userq/IncDecCounter/IncDecCounter";

import { saveTargetGroup } from "../../../services/test";
import 'react-rangeslider/lib/index.css';
import { Button } from '../../themes/userq/Button/Button.js';
import { Icon } from '../../themes/userq/Icon/Icon.js';
import RecruitmentTargetGroup from './recruitment_target_group.js';
import Header from './components/header.js';
import { ToastMessage } from '../../themes/userq/ToastMessage/ToastMessage.js';
import { LoadingIcon } from '../../themes/userq/Loader/loadingIcon';
import EstimatedPanel from './components/estimated_panel.js';
import { settings } from '../../../data/settings.js';
import { Helmet } from 'react-helmet';
import { renderTestTitleCreate, roundNumber } from '../../../lib/helpers.js';
import { useTestBuilderData } from "./contexts/TestBuilderContext.js";

export default function TargetGroupTest() {
    let { test_id } = useParams();
    const navigate = useNavigate();


    let saveTargetGroupController = useRef();

    const { updateTestLength, getTestLength, allowEditAccess } = useTestBuilderData();

    ReactSession.setStoreType("localStorage");

    const [order, setOrder] = useState(null);

    const [isLoading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [isSaved, setIsSaved] = useState({ 'status': '', 'message': '' });

    const [perParticipantsCreditCharge, setPerParticipantsCreditCharge] = useState(null);

    const [formValues, setFormValues] = useState({ panel_size: 0, participants_selected: 50, target_type: '', credits_required: 0, targetGroupId: '', totalCredits: 0 });

    const [test, setTest] = useState("");

    const [credits_required, setCreditsRequired] = useState(null);

    const [test_publishing_fees, setTestPublishingFees] = useState(0);

    const [stepperError, setStepperError] = useState(false);

    const [feeRewardSettings, setFeeRewardSettings] = useState([]);

    const [searchParams] = useSearchParams()


    const handleScroll = ref => {
        window.scrollTo({
            behavior: "smooth",
            top: ref.current.offsetTop
        });
    };
    const [show_support_panel, setShowSupportPanel] = useState(false);

    let treeEmptyNodeError = false;

    const showError = (error) => {
        localStorage.setItem("stepper_error", error);
        toast(
            <ToastMessage type={"error"} message={error} closable={false} onClose={() => { toast.dismiss(); }} />,
            {
                id:"error",
                className: "errortoast",
                position: "bottom-center",
                duration: settings.toast_duration,
            }
        );
        
    };
    function checkEmptyNodeInTree(tree){
        tree.forEach(function (item) {
          
            if (!item.title) {
              treeEmptyNodeError = true;
      
              item["error"] = true;
            } else {
              item["error"] = false;
            }
            if (item.children.length > 0) {
            
                checkEmptyNodeInTree(item.children);
            }
        });
      };
    const getTestApi = () => {
        let token = ReactSession.get("token");

        setPageLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {


            if (response.success) {

                if(response.test.workspace_project){
                    if(response.workspace_project_team_member){
                        if(response.workspace_project_team_member.status!="active"){
                            navigate("/wsp/"+response.test.workspace_project.workspace_id+"/p/"+response.test.workspace_project.id);
                        }
                    }
                }
                getFeeRewardSettingsApi();

                //getOrderApi();

                if (response.test.status) {
                    
                    
                    if (response.test.status == "published") {
                        navigate("/r/project/" + response.test.project_id);
                    }

                    else {
                        getTestLength(test_id);

                        allowEditAccess(test_id, "/wsp/"+ response.test.workspace_id+"/p/" + response.test.workspace_project_id);
                        
                        if (
                            response.test.welcome_title == "" ||
                            response.test.welcome_description == ""
                        ) {
                            navigate("/r/test/" + test_id + "/welcome?error=true");
                            showError("The welcome page cannot be empty! Please enter a title and a description.");
                        } /*else if (response.test.methodology == "Tree Test" && response.test.tasks.length == 0) {
                            navigate("/r/test/" + test_id + "/tree-test/");
                            showError("Please add tasks");
                        } */ else if (response.test.methodology === "Card Sorting") {
                            if (response.test.card_sorting === null || !response.test.card_sorting.title) {
                                showError(
                                    "You missed some key information. Please check again and fill out any missing fields."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/card-sorting-test?error=true"
                                );

                            }else if (response.test.card_sorting && response.test.card_sorting_card.length === 0) {
                                showError(
                                    "Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/card-sorting-test?error=true"
                                );

                            }else if (response.test.card_sorting.card_sorting === 'close' && response.test.card_sorting_category.length < 2) {
                                navigate("/r/test/" + test_id + "/card-sorting-test?error=true");
                                showError("Looks like you haven't created enough categories for this test. Please add at least two categories to move to the next steps.");

                            }
                            else if (response.test.card_sorting_card.length < 2) {
                                showError(
                                    "Looks like you haven't created enough cards for this test. Please add at least two cards to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/card-sorting-test?error=true"
                                );

                            }
                            else if (
                                response.test.thankyou_title == "" || response.test.thankyou_title == null ||
                                response.test.thankyou_description == "" || response.test.thankyou_description == null
                            ) {
                                navigate("/r/test/" + test_id + "/thank-you?error=true");
                                showError("The thank you page cannot be empty! Please enter a title and a description.");
                            }
                            

                        }
                        
                        else if (response.test.methodology === "Five Seconds Test") {

                            if (response.test.fiveSecondsTestTask == null) {
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                            }else if ((response.test.fiveSecondsTestTask.task == "" || response.test.fiveSecondsTestTask.task == null) && response.test.fiveSecondsTestTask.task_file == null && response.test.fiveSecondsTestQuestion.length === 0) {
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );

                            }
                            else if (response.test.fiveSecondsTestTask.task == "" || response.test.fiveSecondsTestTask.task == null) {
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                            } else if (response.test.fiveSecondsTestTask.task_file == null) {
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );
                                showError(
                                    "Looks like you haven't added design for this test! Please add design to move to the next steps."
                                );
                            }
                            else if (response.test.fiveSecondsTestQuestion.length === 0) {
                                navigate(
                                    "/r/test/" + test_id + "/five-seconds-test?error=true"
                                );
                                showError(
                                    "Looks like you haven't added questions for this test! Please add at least 1 question to move to the next steps."
                                );
                            }
                            else if (response.test.fiveSecondsTestQuestion.length > 0) {
                                let nonoptionalcount = 0;
                                response.test.fiveSecondsTestQuestion.forEach((question) => {
                                    if (JSON.parse(question).is_optional == 0) {
                                        nonoptionalcount = 1
                                    }
                                }
                                );

                                if (nonoptionalcount == 0) {
                                    navigate(
                                        "/r/test/" + test_id + "/five-seconds-test?error=true"
                                    );
                                    showError(
                                        "At least one question should not be optional."
                                    );
                                }
                                else if (
                                    response.test.thankyou_title == "" || response.test.thankyou_title == null ||
                                    response.test.thankyou_description == "" || response.test.thankyou_description == null
                                ) {
                                    navigate("/r/test/" + test_id + "/thank-you?error=true");
                                    showError("The thank you page cannot be empty! Please enter a title and a description.");
                                }

                            }
                        }
                        else if (
                            response.test.methodology == "Survey"
                        ) {
                            console.log("inside survey");
                            if (response.test.surveyQuestion.length === 0) {
                                showError(
                                    "Please add at least one question for the survey before proceeding."
                                );
                                navigate(
                                    "/r/test/" + test_id + "/survey?error=true"
                                );
                                
                            }
                            else if (response.test.surveyQuestion.length > 0) {
                                let nonoptionalcount = 0;
                                validatelogic(response.test.surveyQuestion);
                                response.test.surveyQuestion.forEach((question) => {
                                    if (JSON.parse(question).is_optional == 0) {
                                        nonoptionalcount = 1
                                    }
                                }
                                );

                                if (nonoptionalcount == 0) {
                                    showError(
                                        "At least one question should not be optional."
                                    );
                                    navigate(
                                        "/r/test/" + test_id + "/survey?error=true"
                                    );
                                    
                                }
                                else if (
                                    response.test.thankyou_title == "" || response.test.thankyou_title == null ||
                                    response.test.thankyou_description == "" || response.test.thankyou_description == null
                                ) {
                                    navigate("/r/test/" + test_id + "/thank-you?error=true");
                                    showError("The thank you page cannot be empty! Please enter a title and a description.");
                                }

                            }
                             else if (response.test.target_group == null) {
                                navigate("/r/test/" + test_id + "/recruit?error=true");
                                showError("Please choose your recruitment preference before proceeding to publish.");
                            } else if (
                                response.test.target_group != null &&
                                response.test.target_group.target_type !== 'test-links'
                            ) {
                                if (response.test.target_group.participants_selected == null) {
                                    navigate("/r/test/" + test_id + "/recruit?error=true");
                                    showError("Please choose your recruitment preference before proceeding to publish.");
                                } else if (response.test.target_group.participants_selected === 0) {
                                    navigate("/r/test/" + test_id + "/recruit?error=true");
                                    showError(
                                        <span className={"w-100"}>
                                            Unfortunately, we can't fulfil your recruitment specifications.
                                            Please contact us at
                                            <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}>support@userq.com</a>
                                        </span>
                                    );
                                } else if (response.test.target_group && response.test.target_group.participants_selected*2  > response.test.testers_count) {
                                    navigate("/r/test/" + test_id + "/recruit?error=true");
                                    showError(
                                        <span className={"w-100"}>
                                            Uh oh! Try again by reducing the number of participants or changing the selected criteria. For additional support,
                                            <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}>get in touch with us.</a>
                                        </span>
                                    );
                                    localStorage.setItem("stepper_error", "Uh oh! Try again by reducing the number of participants or changing the selected criteria.");
                                    
                                }

                            }
                        }
                        else if (
                            response.test.thankyou_title == "" || response.test.thankyou_title == null ||
                            response.test.thankyou_description == "" || response.test.thankyou_description == null
                        ) {
                            navigate("/r/test/" + test_id + "/thank-you?error=true");
                            showError("The thank you page cannot be empty! Please enter a title and a description.");
                        }  else if (
                            response.test.methodology == "Tree Test"
                        ) {
                            var tree_error = false;
                            
                            if(response.test.tree){

                                if(response.test.tree.length==0){
                                    tree_error = true;
                                    showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                    navigate("/r/test/" + test_id + "/tree-test?error=true");    
                                } else {
                                    treeEmptyNodeError = false;

                                    checkEmptyNodeInTree(response.test.tree);

                                    if(treeEmptyNodeError){
                                        tree_error = true;
                                        showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                        navigate("/r/test/" + test_id + "/tree-test?error=true");    
                                    }
                                }
                                
                            }
                            if(!tree_error){
                                if (response.test.tasks.length === 0) {
                                    showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                    navigate("/r/test/" + test_id + "/tree-test?error=true");

                                } else {
                                    
                                    for (var i = 0; i < response.test.tasks.length; i++) {
                                        if (!response.test.tasks[i].title || response.test.tasks[i].title == null) {
                                            showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                            navigate("/r/test/" + test_id + "/tree-test?error=true");
                                            break;
                                        }
                                        else if (response.test.tasks[i].correct_answer_id == null) {
                                            showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                            navigate("/r/test/" + test_id + "/tree-test?error=true");
                                            break;
                                        }
                                    }

                                }
                            }

                        } else if (
                            response.test.methodology == "Preference Test"
                        ) {
                            var error = false;
                            if (response.test.preference_test_designs.length < 2) {
                                error = true;
                                navigate("/r/test/" + test_id + "/preference-test?error=true");
                                showError("You missed some key information. Please check again and fill out any missing fields.");
                            }
                        }  else if(response.test.methodology=="Prototype test"){

                            var error = false;

                            for(var i=0; i<response.test.prototype_test_tasks.length; i++){
			
                                var task = response.test.prototype_test_tasks[i];
                    
                                
                                if(!task.prototype_link){
                                    
                                    error = true;
                                    break;
                                }
                                else if(!task.is_synced){
                                    error = true;
                                    break;
                                }
                                else if(!task.description){
                                    error = true;
                                    break;
                                }
                                else if(response.test.prototype_test_setting.objective=="closed" && !task.goal_screen_node_id){
                                    error = true;
                                    break;
                                }
                                else if(response.test.prototype_test_setting.objective=="open" && task.questions.length==0){
                                    error = true;
                                    break;
                                }
                                else if(response.test.prototype_test_setting.objective=="open" && task.questions.length>0){
                                    var no_of_optional = 0;
                    
                                    task.questions.forEach(function(item){
                                        if(item.is_optional){
                                            no_of_optional++;
                                        }
                                    })
                                    if(no_of_optional==task.questions.length){
                                        error=true;
                                        break;
                                    }
                                    
                                }
                                
                            }
                            if(error){
                                showError("You missed some key information. Please check again and fill out any missing fields.");
                                navigate("/r/test/" + test_id + "/prototype-test?error=true");
                            }

                        }  else if(response.test.methodology=="First click"){

                            var error = false;

                            for(var i=0; i<response.test.firstclick_test_tasks.length; i++){
			
                                var task = response.test.firstclick_test_tasks[i];
                    
                                
                                if(!task.description){
                                    error = true;
                                    break;
                                }
                                else if(!task.design_file){
                                    error = true;
                                    break;
                                }
                            
                            }
                            if(error){
                                showError("You missed some key information. Please check again and fill out any missing fields.");
                                navigate("/r/test/" + test_id + "/first-click?error=true");
                            }

                        }
                    } 
                }
                setTest(response.test);

                setCreditsRequired(response.settings.publishing_credits);
                setTestPublishingFees(response.settings.test_publishing_fees);
                setPerParticipantsCreditCharge(response.per_participants_credits ? response.per_participants_credits : 0); // set credits

                const targetGroupOption = [];
                if (response.projectTargetGroup.length > 0) {
                    response.projectTargetGroup.forEach((items) => {
                        targetGroupOption.push({ value: items.id, label: items.target_group_name });
                    });
                } else {
                    //targetGroupOption.push({ value: null, label: 'No target group created' });
                }

                // redirectToStep({test_id:test_id, current_step:4,saved_step:response.test.saved_step,navigate:navigate});

                const responseTargetGroup = response.test.target_group || '';

                if (responseTargetGroup) {

                    setFormValues({ ...formValues, target_type: responseTargetGroup.target_type, participants_selected:responseTargetGroup.participants_selected ? responseTargetGroup.participants_selected : 50 });
                    
                }


                setPageLoading(false);

            } else {

                setPageLoading(false);

                if(response.message == "Project not found.") {
                    navigate("/404");
                } else {
                    toast((
                        <ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />
                    ), {
                        className: 'errortoast',
                        position: 'bottom-center',
                        duration: settings.toast_duration,
                    });
                }
            }
        });
    }
    const getFeeRewardSettingsApi = () => {
        let token = ReactSession.get("token");

        setPageLoading(true);

        getFeeRewardSettingsService({ test_id: test_id }, token).then((response) => {

            setPageLoading(false);

            if (response.success) {
                setFeeRewardSettings(response.settings);
            } else {
                toast((
                    <ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />
                ), {
                    className: 'errortoast',
                    position: 'bottom-center',
                    duration: settings.toast_duration,
                });
            }
        });
    }

    useEffect(() => {
        //document.title = process.env.REACT_APP_NAME + " - Target Group";

        getTestApi();
        if(searchParams.get("error")=="true"){
            setStepperError(true);
        }

    }, []);



    const saveTargetGroupApi = (form_data, shouldnavigate) => {


        if (saveTargetGroupController.current) {
            saveTargetGroupController.current.abort();
        }
        const controller = new AbortController();
        saveTargetGroupController.current = controller;


        const token = ReactSession.get("token");

        const data = new FormData();

        data.append("test_id", test_id);

        data.append("target_type", form_data.target_type);

        data.append("participants_selected", form_data.participants_selected);

        data.append("autosave", 1);

        setIsSaved({ 'status': 'loading', 'message': '' })

        if (shouldnavigate) {
            data.append("autosave", 0);
        }

        saveTargetGroup(data, token, saveTargetGroupController.current?.signal).then((response) => {
            setLoading(false);

            if (response.success) {

                getOrderApi();

                setIsSaved({ 'status': 'success', 'message': '' })

                if (shouldnavigate) {

                    if(form_data.target_type=="test-links"){
                    
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event' : 'create_test_step4',
                            'type': test.methodology,
                            'option': "share",
                            'userID' : ReactSession.get("user") ? ReactSession.get("user").user_id : ""
                        });
                    }

                    navigate("/r/test/" + test_id + "/publish");
                }
                

            } else {
                setIsSaved({ 'status': 'error', 'message': response.message });

                toast(
                    <ToastMessage type={"error"} message={response.message} closable={true} onClose={() => { toast.dismiss(); }} />,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: settings.toast_duration,
                    }
                );
            }
        });

    }

    const validatelogic = (surveyQuestions) => {
        // console.log(surveyQuestions);
        // console.log("validatelogic");
        var error=false;
        surveyQuestions.forEach((question,index) => {
            if (JSON.parse(question).questionType != "singlechoice") {
                if(JSON.parse(question).is_logic==1 && JSON.parse(question).jump_to_question<index+2){
                    
                    error=true;
                }
                if(JSON.parse(question).is_logic==1 && JSON.parse(question).jump_to_question=="End survey"){
                    error=false;
                }
                //
                if(JSON.parse(question).error && JSON.parse(question).error==1){
                    // console.log(JSON.parse(question));
                    // console.log("logictobeupdated");
                    error=true;
                }
            }else{
                if(JSON.parse(question).is_logic==1){
                    JSON.parse(question).jumpTo.forEach((jumptoOption,i)=>{
                        if(jumptoOption!="End survey" && jumptoOption<index+2){
                            // console.log(JSON.parse(question));
                            // console.log("jumptoless");
                            error=true;
                        }
                        //
                        if(JSON.parse(question).error && JSON.parse(question).error[i]==1){
                            // console.log(JSON.parse(question));
                            // console.log("logictobeupdated");
                            error=true;
                        }
                    })
                }
            }
        }
        );

        if(error==true){
            showError(
                "Update the correct logic to proceed further."
            );
            navigate(
                "/r/test/" + test_id + "/survey?error=true"
            );
        }
    }
    const getOrderApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);


        getTestOrderData({ test_id: test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {

                if (response.order) {
                    setOrder(response.order);

                    
                    
                }


            } else {
                showError(response.message);
            }
        });
    };
    return (
        <>
            <LayoutResearcher
                isLoading={false}
                wrapClass={"create-test-data-wrapper"}
                openSupportPanel={show_support_panel} hideSupportPanel={()=>{setShowSupportPanel(false)}}
            >
                <Helmet>
                    <title>Create {test && test.methodology ? renderTestTitleCreate(test.methodology):"test"} - Recruit from UserQ Panel | UserQ</title>
                </Helmet>

                <div className="three-column-layout">
                    <Header
                        test={test}
                        isLoading={pageLoading}
                        credits={credits_required}
                        isSaved={isSaved}
                    />
                    <div className="three-column-layout-body">
                        <div className="three-column-layout-left">

                            <Stepper
                                test={test}
                                completed_step={3}
                                stepCallback={() => {
                                    //saveTargetGroupData(formValues, false, showActiveOption.activeOption); 
                                }}
                                error={stepperError}
                            />
                        </div>
                        {pageLoading &&
                            <div className="test-section-loader">
                                <LoadingIcon />
                            </div>
                        }
                        {!pageLoading && formValues['target_type'] != "target-group" &&
                            <>
                                <div className="three-column-layout-center">
                                    <div className='recruitment-first-page-wrap'>
                                        <div className="section-minimum-height">
                                            <div className='recruitment-top-head'>
                                                <Text type={"subtitle-1"} fontWeight={"medium-font"}>Get participants for this study</Text>
                                                <Text type={"body-text-2"} fontWeight={"medium-font"}>You can either choose to recruit from the UserQ panel or share the link with your own participants.</Text>
                                            </div>
                                            <div className='row recruitment-tiles-wrap'>
                                                <div className='col-md-6'>
                                                    <div className="recruit-tile">
                                                        <Text type={"h3"}>Recruit participants from UserQ panel</Text>
                                                        <ul className='recurt-order-listing mt-32'>
                                                            <li className='d-flex'>
                                                                <span>
                                                                    <Icon colorClass={'success-green-200-svg'} value={'check mark'}/>
                                                                </span>
                                                                <Text type={'body-text-2'} fontWeight={'medium-font'}>Recruit quickly from our +10k panel</Text>
                                                            </li>
                                                            <li className='d-flex'>
                                                                <span>
                                                                    <Icon colorClass={'success-green-200-svg'} value={'check mark'}/>
                                                                </span>
                                                                <Text type={'body-text-2'} fontWeight={'medium-font'}>Select from specific demographics</Text>
                                                            </li>
                                                            <li className='d-flex'>
                                                                <span>
                                                                    <Icon colorClass={'success-green-200-svg'} value={'check mark'}/>
                                                                </span>
                                                                <Text type={'body-text-2'} fontWeight={'medium-font'}>Add screening questions</Text>
                                                            </li>
                                                            <li className='d-flex'>
                                                                <span>
                                                                    <Icon colorClass={'success-green-200-svg'} value={'check mark'}/>
                                                                </span>
                                                                <Text type={'body-text-2'} fontWeight={'medium-font'}>Share link for your own participants</Text>
                                                            </li>
                                                            <li className='d-flex'>
                                                                <span>
                                                                    <Icon colorClass={'success-green-200-svg'} value={'check mark'}/>
                                                                </span>
                                                                <Text type={'body-text-2'} fontWeight={'medium-font'}>Report participants</Text>
                                                            </li>
                                                            <li className='d-flex'>
                                                                <span>
                                                                    <Icon colorClass={'success-green-200-svg'} value={'check mark'}/>
                                                                </span>
                                                                <Text type={'body-text-2'} fontWeight={'medium-font'}>View results with demographic data</Text>
                                                            </li>
                                                        </ul>
                                                        <div className="recruit-tile-footer">
                                                            <div className="recruit-footer-info d-flex align-items-center mt-20 mb-20">
                                                                <div className='user-response-wrap'>
                                                                    <Icon value={"profile"} size={"medium"} />
                                                                    <Text type={'body-text-3'} fontWeight={'medium-font'} cssClasses={'black-color'}>1 Response</Text>
                                                                </div>
                                                                <div className='user-response-oprat'>
                                                                    <Text type={'body-text-3'} fontWeight={'medium-font'} cssClasses={'black-color'}>=</Text>
                                                                </div>
                                                                <div className='user-response-wrap'>
                                                                    <Icon value={"credit"} size={"medium"} />
                                                                    <Text type={'body-text-3'} fontWeight={'medium-font'} cssClasses={'black-color credit-sub d-flex align-items-center'}>
                                                                        {test.researcher_fee ? roundNumber(test.researcher_fee) : 0 } credits
                                                                        <span className='info-wrap'>
                                                                            <Icon value={"information"} colorClass="gray-700-svg" size={"small"} />
                                                                            <div className="tooltip-wrapper bottom-tooltip">
                                                                                <Text type='body-text-2' cssClasses={'white-color'}>
                                                                                    Credits required to recruit participants are determined by the length of the test.
                                                                                </Text>
                                                                                <div className='credit-score-tooltip-info'>
                                                                                    {feeRewardSettings.map(function(setting){
                                                                                        return (
                                                                                            <Text type='body-text-3' cssClasses={'d-flex align-items-center'} fontWeight={'medium-fone'}>
                                                                                                <Icon value={'length'} colorClass={'gray-50-svg'} size={'small'}/>
                                                                                                {setting.duration_label} length {setting.sub_label}
                                                                                                <Icon value={'credit'} colorClass={'pink-200-svg'} size={'small'}/>
                                                                                                <span className='pink-color'>{roundNumber(setting.researcher_fee,2)} credits</span>
                                                                                            </Text>
                                                                                        );
                                                                                    })}
                                                                                    
                                                                                    
                                                                                </div>
                                                                                <Text type='body-text-2' cssClasses={'white-color'}>
                                                                                    You can save up to 20% when
                                                                                    <Link className={'link-text'} to={"/r/buy-credits/"}>
                                                                                    bulk buying credits.</Link>
                                                                                </Text>
                                                                            </div>
                                                                        </span>
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                            <div className="recruit-tile-button">
                                                                <Button
                                                                type={"primary"}
                                                                label={"Hire from the panel"}
                                                                size={"large"}
                                                                iconLeft={
                                                                <Icon value="group" colorClass={'gray-50-svg'} size={"medium"} />
                                                                }
                                                                onClick={() => {
                                                                    
                                                                    //saveTargetGroupApi({ ...formValues, "participants_selected": 20, "target_type": "target-group" }, 0);
                                                                    setFormValues({ ...formValues, "target_type": "target-group" })
                                                                }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="recruit-tile">
                                                        <Text type={"h3"}>Share with your own participants for free</Text>
                                                        <ul className='recurt-order-listing mt-32'>
                                                            <li className='d-flex'>
                                                                <span>
                                                                    <Icon colorClass={'success-green-200-svg'} value={'check mark'}/>
                                                                </span>
                                                                <Text type={'body-text-2'} fontWeight={'medium-font'}>Share link for your own participants</Text>
                                                            </li>
                                                            <li className='d-flex'>
                                                                <span>
                                                                    <Icon colorClass={'danger-red-200-svg'} value={'close'}/>
                                                                </span>
                                                                <Text type={'body-text-2'} fontWeight={'medium-font'}>Add screening questions</Text>
                                                            </li>
                                                            <li className='d-flex'>
                                                                <span>
                                                                    <Icon colorClass={'danger-red-200-svg'} value={'close'}/>
                                                                </span>
                                                                <Text type={'body-text-2'} fontWeight={'medium-font'}>Report participants</Text>
                                                            </li>
                                                            <li className='d-flex'>
                                                                <span>
                                                                    <Icon colorClass={'danger-red-200-svg'} value={'close'}/>
                                                                </span>
                                                                <Text type={'body-text-2'} fontWeight={'medium-font'}>View results with demographic data</Text>
                                                            </li>
                                                        </ul>
                                                        <div className="recruit-tile-footer">
                                                            <div className="recruit-footer-info d-flex align-items-center mt-20 mb-20">
                                                                <div className='user-response-wrap'>
                                                                    <Icon value={"credit"} size={"medium"} />
                                                                    <Text type={'body-text-3'} fontWeight={'medium-font'} cssClasses={'black-color'}>No credits needed</Text>
                                                                </div>
                                                            </div>
                                                            <div className="recruit-tile-button">
                                                                <Button
                                                                type={"primary"}
                                                                label={"Proceed to publish"}
                                                                iconLeft={
                                                                <Icon value="link" colorClass={'gray-50-svg'} size={"medium"} />
                                                                }
                                                                onClick={() => {
                                                                setFormValues({ ...formValues, "participants_selected": 0, "target_type": "test-links" });
                                                                saveTargetGroupApi({ ...formValues, target_type: "test-links" }, 1);
                                                                }}
                                                                size={"large"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='btns-wrap-bottom'>
                                            <Button
                                            type={"ghost"}
                                            label={"Previous"}
                                            size={"large"}
                                            iconLeft={
                                            <Icon value="back-arrow" colorClass="gray-900-svg" size={"large"} />
                                            }
                                            onClick={() => { navigate("/r/test/" + test.id + "/thank-you") }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="three-column-layout-right">
                                    <a onClick={(e)=>{
                                    e.preventDefault();

                                    setShowSupportPanel(true);

                                }} href={process.env.REACT_APP_URL+"r/support"} target="_blank" className='support-button'><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.879 8.2817C11.05 7.2567 12.95 7.2567 14.121 8.2817C15.293 9.3067 15.293 10.9687 14.121 11.9937C13.918 12.1727 13.691 12.3197 13.451 12.4357C12.706 12.7967 12.001 13.4347 12.001 14.2627V15.0127M21 12.7627C21 13.9446 20.7672 15.1149 20.3149 16.2068C19.8626 17.2988 19.1997 18.2909 18.364 19.1267C17.5282 19.9624 16.5361 20.6253 15.4442 21.0776C14.3522 21.5299 13.1819 21.7627 12 21.7627C10.8181 21.7627 9.64778 21.5299 8.55585 21.0776C7.46392 20.6253 6.47177 19.9624 5.63604 19.1267C4.80031 18.2909 4.13738 17.2988 3.68508 16.2068C3.23279 15.1149 3 13.9446 3 12.7627C3 10.3757 3.94821 8.08656 5.63604 6.39873C7.32387 4.71091 9.61305 3.7627 12 3.7627C14.3869 3.7627 16.6761 4.71091 18.364 6.39873C20.0518 8.08656 21 10.3757 21 12.7627ZM12 18.0127H12.008V18.0207H12V18.0127Z" stroke="#9759CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                        Support
                                    </a>
                                </div>
                            </>
                        }
                        {!pageLoading && formValues["target_type"] == "target-group" &&
                            <>
                                <RecruitmentTargetGroup order={order} setShowSupportPanel={setShowSupportPanel} setStepperError={setStepperError} formData={formValues} setFormData={setFormValues} setIsSaved={setIsSaved} publishing_credits={credits_required}  test_publishing_fees={test_publishing_fees} perParticipantsCreditCharge={perParticipantsCreditCharge} saveTargetGroupApi={saveTargetGroupApi}  test={test}   updateTargetGroupLoading={(is_saved) => { setIsSaved(is_saved) }}  />
                                
                            </>
                        }
                    </div>
                </div>
            </LayoutResearcher>

        </>
    )
}