//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { Text } from "../../themes/userq/Text/Text";
import { Button } from "../../themes/userq/Button/Button";
import { Icon } from "../../themes/userq/Icon/Icon";
import { Input } from "../../themes/userq/Input/Input";
import "./delete-researcher-account-modal.css";
export default function DeleteReseacherAccountModal({
    open,
    close,
    confirm,
    btnLoading,
    workspaceRoleCount
}) {
    //console.log(test);
    const navigate = useNavigate();

    const wrapperRef = useRef(null);

    const [formValues, setFormValues] = useState({
        signature: "",
    });

    const [formErrors, setFormErrors] = useState({
        error_class: null,
    });

    const closeModal = (send = false) => {
        if (send) {
            close(true, { ...formValues });
        } else {
            close(false, null);
        }
    };

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);

    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        };
    };
    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
        setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    };

    const deleteAccount = () => {
        if (formValues.signature) {
            // console.log("what is confirm", confirm);
            confirm(formValues.signature);
        }
    };
    useEffect(() => {
        if(open){
            setFormValues({ ...formValues, signature: "" });
        }
        
    }, [open]);

    return (
        <Modal
            show={open}
            centered
            className={`fade custom-modal-wrap delete-workspace-modal`}
        >
            <Modal.Body className="" ref={wrapperRef}>
                <div className="modal-inner-text">
                    <div className="modal-header-top">
                        <Text type={"h3"} cssClasses={"red-text"} >Delete account</Text>

                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">
                                <Icon value="Close" size="large" hover={true} />
                            </span>
                        </button>
                    </div>

                    
                    <div>
                        <Text
                            type="body-text-1"
                            fontWeight="medium-font"
                        >
                            Are you sure you want to delete your account?
                        </Text>

                        <Text
                            type="body-text-2"
                            cssClasses={"gray-color mt-32"}
                            fontWeight={"medium-font"}
                        >
                            You are currently an admin for {workspaceRoleCount.admin} workspace{workspaceRoleCount.admin>1?'s':''} and a member of {workspaceRoleCount.member} workspace{workspaceRoleCount.member>1?'s':''}.
                        </Text>

                        <div className="red-bg-wrap mt-20 mb-20">
                            <Text
                                type="body-text-2"
                                cssClasses={"gray-color"}
                                fontWeight="medium-font"
                            >
                                By continuing, you acknowledge that all tests, projects and workspaces will be deleted and that all unused credits will be lost. This action cannot be undone.
                            </Text>
                        </div>
                    </div>
                    

                    

                    
                    <div>
                        
                        <Text
                            type="body-text-2"
                            cssClasses={"gray-color"}
                            fontWeight="medium-font"
                        >
                            Kindly confirm by signing your full name:
                        </Text>
                        <div className="add-name-input mt-16">
                            <Input
                                cssClasses={"w-100"}
                                type="text"
                                label="Your full name"
                                value={formValues.signature}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        signature: e.target.value,
                                    });
                                }}
                                onClear={() => {
                                    setFormValues({
                                        ...formValues,
                                        signature: "",
                                    });
                                }}
                                // readOnly={btnLoading ? true : false}
                            />
                        </div>

                        <div className="confirm-buttons-wrap">
                            <Button
                                type="secondary"
                                size="large"
                                iconLeft={
                                    <Icon
                                        value={"delete"}
                                        colorClass={"gray-900-svg"}
                                    />
                                }
                                label="Delete account"
                                onClick={() => {
                                    if (formValues.signature) {
                                        deleteAccount();
                                    }
                                }}
                                state={
                                    !formValues.signature ? "disabled" : ""
                                }
                                microLoading={btnLoading}
                            />
                            <Button
                                type="primary"
                                size="large"
                                label={"Close"}
                                onClick={() => {
                                    closeModal();
                                }}
                            />
                        </div>
                    </div>
                    

                    
                </div>
            </Modal.Body>
        </Modal>
    );
}
