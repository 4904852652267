import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { Logo } from "../Logo/Logo";
import { getCreditSummaryService } from "../../../../services/credits";
import { getTesterAccountDetails } from "../../../../services/user";
import { logoutService } from "../../../../services/user";
import {
    atom,
    useRecoilState,
    useResetRecoilState
} from 'recoil';
import Skeleton from "react-loading-skeleton";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { Dropdown } from "react-bootstrap";
import {roundNumber} from "../../../../lib/helpers";
import Cookies from "js-cookie";
import { Tooltip } from "../Tooltip/Tooltip";
import { LoadingIcon } from "../../../loader/loadingIcon";

export const TesterHeader = ({isAccount, researcher, user, score, credit}) => {

    const navigate = useNavigate();

    const location = useLocation();

    const [creditsLoading, setCreditsLoading] = useState(false);

    const [menuClass, setMenuClass] = useState('');

    const creditsState = atom({
        key: 'credits',
        default: 0
    });
    const [credits, setCredits] = useRecoilState(creditsState);
    const [testerscore, setTesterScore] = useState(null);
    const [scoreLoading, setScoreLoading] = useState(false);

    const resetCredits = useResetRecoilState(creditsState);

    const [first_name, setFirstName] = useState(user);

    let { workspaceid } = useParams();

    const [loggingOutLoader, setLoggingOutLoader] =useState(false);

    useEffect(()=>{

        if(user){
            setFirstName(user);
        }

    },[user]);


    const logout = (e) => {

        if(e)
            e.preventDefault();
        
        let token = ReactSession.get("token");

        document.body.classList.remove('open-m-menu');

        setLoggingOutLoader(true);

        logoutService(token).then((response) => {

            setLoggingOutLoader(false);

            if (response.success) {

                let user = ReactSession.get("user");
                if (!user) {
                    navigate("/tester/sign-in");
                }
                resetCredits();

                Cookies.remove('expires_at', { path: '' }) 

                ReactSession.get("workspace",null);
                ReactSession.set("token", null);
                ReactSession.set("user", null);
                ReactSession.set("plan", null);
                ReactSession.set("show_subscribed", null);
                ReactSession.set("username", null);
                ReactSession.set("new_target_id", null);

                localStorage.clear();

                if (!user) {
                    navigate("/tester/sign-in");
                    return false;
                }

                if (user.role == "Tester") {
                    navigate("/tester/sign-in");
                } else {
                    navigate("/researcher/sign-in");
                }
            }
        });
        
    };
    const goToHome = () => {
        let user = ReactSession.get("user");

        if (user.role == "Tester") {
            return process.env.REACT_APP_TESTER_URL;
        } else {
            return process.env.REACT_APP_RESEARCHER_URL;
        }
    };
    const fetchCreditsSummary = () => {


        let token = ReactSession.get("token");

        setCreditsLoading(true);

        if(!token){

            setCreditsLoading(false);

            ReactSession.set("credits", 0);
            setCredits(credit);


        } else {

            let user = ReactSession.get("user");

            let session_workspace_id = null;

            let session_workspace = ReactSession.get("workspace");

            if (session_workspace) {
                session_workspace_id = session_workspace.workspace_id;
            }

            let workspace_id = workspaceid ? workspaceid : session_workspace_id;
            //setWorkspaceLoading(true);
            if(workspace_id || user.role=="Tester"){
                getCreditSummaryService({workspace_id: workspace_id},token).then((response) => {
                    setCreditsLoading(false);
                    if (response.success) {
                        ReactSession.set("credits", response.summary.credits_balance);
                        setCredits(response.summary.credits_balance);
                    }
                });
            }
        }

    };
    const getAccountDetails =()=>{
        setScoreLoading(true);
        const token = ReactSession.get("token");
        if(!token){
            setScoreLoading(false);
            setTesterScore(score);

        }else{
        getTesterAccountDetails(token).then(response=> {

            if(response.success){
                
                let user_local = ReactSession.get("user");

                let user_new = Object.assign({}, user_local);
                user_new.first_name = response.first_name;
                user_new.last_name = response.last_name;

                ReactSession.set("user", user_new);
                setFirstName(user_new.first_name);

                    setTesterScore(response.score);
                    ReactSession.set("testerscore", response.score.toString());
                    ReactSession.set("accountstatus", response.account_status);
                
            }
            setScoreLoading(false);
        });
        }

    }
    useEffect(() => {
        let user = ReactSession.get("user");
        if (!user) {
            navigate("/tester/sign-in");
        }
        fetchCreditsSummary();
        getAccountDetails();
    }, [credits]);


    return (
        <>
        {(process.env.REACT_APP_URL.includes("localhost")|| process.env.REACT_APP_URL.includes("apprevampui") || process.env.REACT_APP_URL.includes("appuat")) &&
            <div className="test-environment-header">This is a test environment</div>
        }
        {loggingOutLoader &&
        <div className="header-logout-loader">
            <LoadingIcon />
        </div>
        }
        <div className="header researcher-header tester-header">
            <div className="container">
                <div className="header-inner-data body-text body-text body-text-2 medium-font none-for-mobile">
                    <div className="header-lhs-side">
                        <div className="logo">
                            <Link to={"/"} >
                                <Logo theme={"white"} background={"black"} />
                            </Link>
                        </div>

                    </div>

                    <div className="header-rhs-side">



                        <div className="header-credits-wrap">
                            {!isAccount &&
                                <span className="header-link logout-tester-btn" onClick={logout}>
                                   <Icon colorClass="gray-50-svg exit-icon" size={"medium"} value={"exit"} />
                                    <span>Logout</span>
                                </span>
                            }

                            {isAccount &&
                            <>
                            <span className="header-link credits-link-header" onClick={() => {
                                navigate("/t/wallet")
                            }}>
                                
                                <Icon colorClass="gray-50-svg credit-icon" size={"large"} value={"credit"}/>

                                {!creditsLoading &&
                                <>{roundNumber(credits, 2)} $</>
                                }
                                {creditsLoading &&
                                <><Skeleton className="header-credit-skeleton" width={30} height={14}/></>
                                }
                            </span>
                            <span className={`header-link credits-link-header score-link-header ${testerscore>80 ? "green-star":""} ${60<testerscore && testerscore<=80 ? "purple-star":""} ${50<testerscore && testerscore<=60 ? "yellow-star":""} ${testerscore<=50 && testerscore!=null  ? "red-star":""} ${testerscore==null?"white-star":""}`}>
                                <div className="score-star-icon">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.3067 4.66529C15.3631 4.52774 15.4591 4.41006 15.5826 4.32724C15.706 4.24442 15.8513 4.2002 16 4.2002C16.1487 4.2002 16.294 4.24442 16.4174 4.32724C16.5409 4.41006 16.6369 4.52774 16.6933 4.66529L19.5267 11.48C19.5797 11.6075 19.6669 11.7179 19.7786 11.799C19.8904 11.8802 20.0223 11.929 20.16 11.94L27.5173 12.5293C28.1827 12.5826 28.452 13.4133 27.9453 13.8466L22.34 18.6493C22.2353 18.7389 22.1572 18.8556 22.1144 18.9866C22.0716 19.1176 22.0657 19.2578 22.0973 19.392L23.8107 26.572C23.8451 26.716 23.8361 26.867 23.7847 27.006C23.7334 27.1449 23.642 27.2655 23.5222 27.3525C23.4023 27.4395 23.2594 27.4891 23.1114 27.4949C22.9634 27.5008 22.817 27.4626 22.6907 27.3853L16.3907 23.5386C16.273 23.4669 16.1378 23.429 16 23.429C15.8622 23.429 15.727 23.4669 15.6093 23.5386L9.30933 27.3866C9.18301 27.464 9.03661 27.5021 8.88861 27.4963C8.74061 27.4904 8.59767 27.4409 8.47782 27.3538C8.35798 27.2668 8.26662 27.1462 8.21527 27.0073C8.16392 26.8684 8.1549 26.7173 8.18933 26.5733L9.90267 19.392C9.93444 19.2578 9.9286 19.1175 9.88579 18.9865C9.84297 18.8555 9.76485 18.7388 9.66 18.6493L4.05467 13.8466C3.94242 13.7501 3.86121 13.6226 3.82127 13.48C3.78132 13.3375 3.78441 13.1863 3.83015 13.0455C3.8759 12.9047 3.96225 12.7806 4.07835 12.6887C4.19446 12.5969 4.33512 12.5414 4.48267 12.5293L11.84 11.94C11.9777 11.929 12.1096 11.8802 12.2214 11.799C12.3331 11.7179 12.4203 11.6075 12.4733 11.48L15.3067 4.66529Z" stroke="#BFF0A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                </div>
                                {!scoreLoading &&
                                <>{roundNumber(testerscore, 0)}%</>
                                }
                                {scoreLoading &&
                                <><Skeleton className="header-credit-skeleton" width={30} height={14}/></>
                                }
                                <Tooltip
                                type={"top"}
                                data={<>
                                    Your score has been calculated based on your performance in the tests, considering your answers and overall actions. Want to know more about our scoring system? Check out the <a href={process.env.REACT_APP_URL+"t/tester-guidelines"} target="_blank" className="tooltip-guidelines">guidelines</a> for all the details you need. 
                                </>}
                            />
                            </span>
                            </>
                            }
                            {/*<Link to={"/profile"}  className="header-link"> */}


                            {isAccount &&
                            <Dropdown className="dropdown">
                                <Dropdown.Toggle className="dropdown-toggle">
                                    <Icon colorClass="gray-50-svg profile-icon" size={"large"} value={"profile"}/>
                                    
                                    <span className={`profile-menu-btn`}>
                                        {first_name}
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className={`hamburger-items`}>

                                        <Dropdown.Item onClick={
                                            () => {
                                                navigate("/t/profile");
                                            }}
                                            className={`${location.pathname.includes("/t/profile")?"active-menu":""}`}
                                        >

                                        <Icon colorClass="gray-600-svg profile-icon" size={"medium"} value={"profile"}/>

                                            Your profile
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={
                                            () => {
                                                navigate("/t/test-history");
                                            }}
                                            className={`${location.pathname.includes("/t/test-history")?"active-menu":""}`}
                                        >
                                        <Icon colorClass="gray-600-svg billing-icon" size={"medium"} value={"billing"}/>

                                            Test history
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={
                                            () => {
                                                navigate("/t/wallet");
                                            }}
                                            className={`${location.pathname.includes("/t/wallet")?"active-menu":""}`}
                                        >
                                             <Icon colorClass="gray-600-svg credit-icon" size={"medium"} value={"credit"}/>

                                            Wallet
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={
                                            () => {
                                                navigate("/t/settings");
                                            }}
                                            className={`${location.pathname.includes("/t/settings")?"active-menu":""}`}
                                        >
                                            <Icon colorClass="gray-600-svg notification-icon" size={"medium"} value={"notification"}/>

                                            Notification settings
                                        </Dropdown.Item>

                                        <Dropdown.Item onClick={logout}
                                        >
                                        <Icon colorClass="gray-600-svg exit-icon" size={"medium"} value={"exit"}/>

                                            Log out
                                        </Dropdown.Item>


                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                            }
                            {/*</Link>*/}
                        </div>
                    </div>
                </div>
                <div className="header-for-mobile none-for-desktop">
                    <div className="mobile-header-top">
                        <div className="logo">
                            <Link to={"/"} >
                                <Logo theme={"white"} background={"black"} />
                            </Link>
                        </div>
                        <div className="mobile-menu-wrap">
                            <span className="open-m-menu" onClick={()=>{setMenuClass("open-menu");  document.body.classList.add('open-m-menu'); }}>
                                <Icon colorClass="gray-50-svg menu-icon" size={"large"} value={"menu"}/>
                            </span>
                        </div>
                    </div>
                    <div className={`m-nagivation-wrap ${menuClass}`}>
                        <div className="mobile-header-top">
                            <div className="logo">
                                <Link to={"/"} >
                                    <img src={process.env.REACT_APP_URL+"img/UQ.svg"} alt="UserQ"/>
                                </Link>
                            </div>
                            <div className="mobile-menu-wrap">
                                <span className="close-m-menu" onClick={()=>{setMenuClass("");  document.body.classList.remove('open-m-menu');}}>
                                    <Icon colorClass="gray-50-svg close-icon" size={"large"} value={"close"}/>
                                </span>
                            </div>
                        </div>

                        <div className="header-workspaceheading">
                            {/*researcher ? "Researcher" : "Tester"*/ }{first_name}

                        </div>

                        {isAccount &&
                        <div className="middlenavigationlist navigation-listing">
                            <ul>
                                <li>
                                    <span className={`header-link credits-link-header score-link-header ${testerscore>80 ? "green-star":""} ${60<testerscore && testerscore<=80 ? "purple-star":""} ${50<testerscore && testerscore<=60 ? "yellow-star":""} ${testerscore<=50 && testerscore!=null  ? "red-star":""} ${testerscore==null?"white-star":""}`}>
                                        <div className="score-star-icon">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.3067 4.66529C15.3631 4.52774 15.4591 4.41006 15.5826 4.32724C15.706 4.24442 15.8513 4.2002 16 4.2002C16.1487 4.2002 16.294 4.24442 16.4174 4.32724C16.5409 4.41006 16.6369 4.52774 16.6933 4.66529L19.5267 11.48C19.5797 11.6075 19.6669 11.7179 19.7786 11.799C19.8904 11.8802 20.0223 11.929 20.16 11.94L27.5173 12.5293C28.1827 12.5826 28.452 13.4133 27.9453 13.8466L22.34 18.6493C22.2353 18.7389 22.1572 18.8556 22.1144 18.9866C22.0716 19.1176 22.0657 19.2578 22.0973 19.392L23.8107 26.572C23.8451 26.716 23.8361 26.867 23.7847 27.006C23.7334 27.1449 23.642 27.2655 23.5222 27.3525C23.4023 27.4395 23.2594 27.4891 23.1114 27.4949C22.9634 27.5008 22.817 27.4626 22.6907 27.3853L16.3907 23.5386C16.273 23.4669 16.1378 23.429 16 23.429C15.8622 23.429 15.727 23.4669 15.6093 23.5386L9.30933 27.3866C9.18301 27.464 9.03661 27.5021 8.88861 27.4963C8.74061 27.4904 8.59767 27.4409 8.47782 27.3538C8.35798 27.2668 8.26662 27.1462 8.21527 27.0073C8.16392 26.8684 8.1549 26.7173 8.18933 26.5733L9.90267 19.392C9.93444 19.2578 9.9286 19.1175 9.88579 18.9865C9.84297 18.8555 9.76485 18.7388 9.66 18.6493L4.05467 13.8466C3.94242 13.7501 3.86121 13.6226 3.82127 13.48C3.78132 13.3375 3.78441 13.1863 3.83015 13.0455C3.8759 12.9047 3.96225 12.7806 4.07835 12.6887C4.19446 12.5969 4.33512 12.5414 4.48267 12.5293L11.84 11.94C11.9777 11.929 12.1096 11.8802 12.2214 11.799C12.3331 11.7179 12.4203 11.6075 12.4733 11.48L15.3067 4.66529Z" stroke="#BFF0A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        </div>
                                        {!scoreLoading &&
                                        <>{roundNumber(testerscore, 2)}%</>
                                        }
                                        {scoreLoading &&
                                        <><Skeleton className="header-credit-skeleton" width={30} height={14}/></>
                                        }
                                        <Tooltip
                                            type={"top"}
                                            data={<>
                                                This score has been calculated based on your performance including your answers and overall actions during the test. Check the <a href={process.env.REACT_APP_URL+"t/support"} target="_blank" className="tooltip-guidelines">guidelines</a> to know more about the score rating system. 
                                            </>}
                                        />
                                    </span>
                                </li>
                                <li className={`${location.pathname.includes("/dashboard")?"active-menu":""}`}>
                                    <Link to={"/"}>
                                        <Icon colorClass="gray-600-svg dashboard-icon" size={"medium"} value={"dashboard"}/>
                                        <span> Dashboard </span>
                                    </Link>
                                </li>
                                <li className={`${location.pathname.includes("/t/profile")?"active-menu":""}`}>
                                    <Link to={"/t/profile"}>
                                    <Icon colorClass="gray-600-svg profile-icon" size={"medium"} value={"profile"}/>

                                        <span> Your profile </span>
                                    </Link>
                                </li>
                                <li className={`${location.pathname.includes("/t/test-history")?"active-menu":""}`}>
                                    <Link to={"/t/test-history"}>
                                    <Icon colorClass="gray-600-svg billing-icon" size={"medium"} value={"billing"}/>

                                        <span>
                                            Test history
                                        </span>
                                    </Link>
                                </li>
                                <li className={`${location.pathname.includes("/t/wallet")?"active-menu":""}`}>
                                    <Link to={"/t/wallet"}>
                                    <Icon colorClass="gray-600-svg credit-icon" size={"medium"} value={"credit"}/>
                                        <span>
                                             {/*{!creditsLoading ?*/}
                                             {/*    <>{" "}{roundNumber(credits, 2)} $ {" "}</>*/}
                                             {/*    :*/}
                                             {/*    <><Skeleton className="header-credit-skeleton" width={30}*/}
                                             {/*                height={14}/></>*/}
                                             {/*}*/}
                                            Wallet
                                        </span>
                                    </Link>
                                </li>
                                <li className={`${location.pathname.includes("/t/settings")?"active-menu":""}`}>
                                    <Link to={"/t/settings"}>
                                     <Icon colorClass="gray-600-svg notification-icon" size={"medium"} value={"notification"}/>
                                        <span> Notification settings </span>

                                    </Link>
                                </li>

                            </ul>
                        </div>
                        }


                        {isAccount &&
                        <div className="supportnavigationbox navigation-listing">
                            <ul>
                                <li className={`${location.pathname.includes("/t/support")?"active-menu":""}`}>
                                    <Link to={"/t/support"}>
                                    <Icon colorClass="gray-600-svg support-icon" size={"medium"} value={"support"}/>
                                        <span> Support </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        }

                        <div className="bottomnavigationlist navigation-listing">
                            <ul>
                                <li><a href="#" onClick={logout}>
                                
                                Logout</a></li>
                            </ul>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        </>
    );
};