import { useEffect, useRef, useState } from "react";
import "./MembersDropdownList.css";
import { CheckBox } from "../CheckBox/CheckBox.js";
import { RatingScaleIcon } from "../RatingScaleIcon/RatingScaleIcon";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";
import { Chip } from "../Chips/Chips.js";

export const MembersDropdownList = ({
    label,
    error,
    defaultMembers = [],
    options,
    readOnly,
    onChange,
    value,
    className,
    no_results_message,
}) => {
    const [selected, setSelected] = useState([]);

    const [showDropdown, setShowDropDown] = useState(false);

    const ref = useRef(null);

    useEffect(() => {
        if (value) {
            let locValue = [];

            value.forEach(function (item) {
                if (item != "") {
                    locValue.push(item);
                }
            });
            setSelected(locValue);
        }
    }, [value]);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowDropDown(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    const renderSelectedValues = () => {
        var optionsRender = [];

        defaultMembers.forEach(function (item) {
            optionsRender.push(
                <>
                    <Chip title={item.label} />
                </>
            );
        });

        for (var i = 0; i < selected.length; i++) {
            var value = selected[i];

            var label = <></>;

            options.forEach(function (item) {
                if (item.value === selected[i]) {
                    label = item.label;
                }
            });

            optionsRender.push(
                <>
                    <Chip
                        type={"icon-right"}
                        title={label}
                        value={value}
                        onRemove={(value) => {
                            var locSelected = [...selected];

                            const index = locSelected.indexOf(value);
                            if (index > -1) {
                                // only splice array when item is found
                                locSelected.splice(index, 1); // 2nd parameter means remove one item only
                            } else {
                                locSelected.push(value);
                            }
                            setSelected(locSelected);

                            if (onChange) {
                                onChange(locSelected);
                            }
                        }}
                    />
                </>
            );
        }

        return (
            <>
                {optionsRender.map(function (item) {
                    return item;
                })}
            </>
        );
    };
    const renderSelectBox = () => {
        return (
            <div className="members-multiselect-dropdown-inner">
                <div
                    className={`multiselectlist-dropdown-box-container`}
                    onClick={(e) => {
                        e.preventDefault();
                        setShowDropDown(!showDropdown);
                    }}
                >
                    <Text type={"body-text-2"} fontWeight={"normal-font"}>
                        {label}
                    </Text>
                    <div
                        className={`multiselectlist-dropdown-dropdown-icon-container`}
                    >
                        <Icon
                            colorClass={"gray-900-svg"}
                            value={showDropdown ? "Chevron up" : "Chevron"}
                            hover={true}
                        />
                    </div>
                </div>
                <div
                    className={`multiselectlist-dropdown-selected-values-container`}
                >
                    {renderSelectedValues()}
                </div>
            </div>
        );
    };
    const renderDropDown = () => {
        if (showDropdown) {
            return (
                <div
                    className="multiselectlist-dropdown-options"
                    style={{
                        position: "absolute",
                        background: "#fff",
                        width: "200px",
                    }}
                >
                    {options.map((option, index) => {
                        return (
                            <div
                                key={"select-option" + index}
                                className={`multiselectlist-dropdown-option ${
                                    selected.indexOf(option.value) !== -1
                                        ? "disabled-option"
                                        : "active-option"
                                } `}
                                onClick={(e) => {
                                    if (selected.indexOf(option.value) == -1) {
                                        var locSelected = [...selected];

                                        if (option.value != "Other") {
                                            locSelected = locSelected.filter(
                                                (item) => {
                                                    if (item != "Other") {
                                                        return item;
                                                    }
                                                }
                                            );
                                        }
                                        const index = locSelected.indexOf(
                                            option.value
                                        );
                                        if (index > -1) {
                                            // only splice array when item is found
                                            locSelected.splice(index, 1); // 2nd parameter means remove one item only
                                        } else {
                                            locSelected.push(option.value);
                                        }
                                        setSelected(locSelected);

                                        if (onChange) {
                                            onChange(locSelected);
                                        }
                                    }
                                }}
                            >
                                <span className="member-label d-flex align-items-center">
                                    {option.label}
                                </span>

                                <span className="added-member d-none align-items-center">
                                    <Icon
                                        colorClass={"success-green-200-svg"}
                                        value={"check mark"}
                                        size={"small"}
                                    />
                                    <Text
                                        type={"caption"}
                                        fontWeight={"medium-font"}
                                        cssClasses={"green-color"}
                                    >
                                        Added
                                    </Text>
                                </span>
                            </div>
                        );
                    })}
                    {options.length == 0 && no_results_message && (
                        <div
                            key={"select-option" + "end"}
                            className={`multiselectlist-dropdown-option active-option `}
                        >
                            <div>{no_results_message}</div>
                        </div>
                    )}
                </div>
            );
        } else {
            return <></>;
        }
    };
    return (
        <div
            ref={ref}
            className={`multiselectlist-dropdown-box members-multiselect-dropdown position-relative ${
                error ? "field-error" : ""
            } ${className ? className : ""} ${
                readOnly ? "multiselectlist-dropdown-readonly" : ""
            }`}
        >
            {renderSelectBox()}
            {renderDropDown()}
            {error != null && <span className="caption">{error}</span>}
        </div>
    );
};
