import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../themes/userq/Button/Button";
import { Input } from "../../../themes/userq/Input/Input";
import { MultiSelecteDropdownList } from "../../../themes/userq/MultiSelecteDropdownList/MultiSelecteDropdownList";
import { CircularNameIcon } from "../../../themes/userq/CircularNameIcon/CircularNameIcon";
import { getWorkspaceService } from "../../../../services/workspaces";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { showToast } from "../../../../lib/helpers";
import { MembersDropdownList } from "../../../themes/userq/MembersDropdownList/MembersDropdownList";
import { createProjectService } from "../../../../services/workspaceprojects";
import "./create-new-project-modal.css";

export default function CreateNewProjectModal({
    workspace,
    onCreateProject,
    ...props
}) {
    const wrapperRef = useRef(null);

    const [formValues, setFormValues] = useState({
        project_name: "",
        members: [],
    });

    const [formErrors, setFormErrors] = useState({ project_name: null });

    const [loading, setLoading] = useState(false);

    const [createProjectLoading, setCreateProjectLoading] = useState(false);

    const [teamMembers, setTeamMembers] = useState([]);

    const [defaultMembers, setDefaultMembers] = useState([]);

    const navigate = useNavigate();

    const closeModal = () => {
        if (props.closeModal) {
            props.closeModal();
        }
    };
    const checkIfFormValid = () => {
        if (!formValues.project_name) {
            return false;
        }

        return true;
    };
    useEffect(() => {
        setFormErrors({ project_name: null });
        setFormValues({...formValues, project_name: "",members: []});
        getWorkspaceApi();
    }, [props.openModal]);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (event.target.nodeName == "DIV") {
                        closeModal();
                    }
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef);
    
    const getWorkspaceApi = () => {
        let token = ReactSession.get("token");
        setLoading(true);

        getWorkspaceService(token, workspace.id)
            .then((response) => {
                setLoading(false);
                if (response.success) {
                    setFormValues({
                        ...formValues,
                        project_name:
                            "Project " + (response.total_projects_count + 1),
                        members:[]
                    });

                    let user = ReactSession.get("user");

                    // Transform workspace members into teamMembers format
                    const transformedMembers =
                        response.workspace.workspace_team_members.map(
                            (member) => {
                                if (member.email || member.user) {
                                    const {
                                        id,
                                        user,
                                        status,
                                        role,
                                        color,
                                        email,
                                        user_id,
                                    } = member;

                                    let memberInfo = { status };

                                    if (user) {
                                        if (user.email) {
                                            memberInfo.email = user.email;
                                        }

                                        if (user.first_name || user.last_name) {
                                            memberInfo.name = `${
                                                user.first_name || ""
                                            } ${user.last_name || ""}`.trim();
                                        }

                                        // Set the role directly in the memberInfo
                                        memberInfo.role = role;
                                        memberInfo.color = color;
                                    } else {
                                        memberInfo.name = email;

                                        memberInfo.email = email;

                                        // Set the role directly in the memberInfo
                                        memberInfo.role = role;
                                        memberInfo.color = color;
                                    }
                                    memberInfo.user_id = user_id;

                                    memberInfo.id = id;

                                    return memberInfo;
                                }
                            }
                        );

                        //console.log(transformedMembers)
                    // Filter out members with the role "admin"
                    const filteredMembers = transformedMembers.filter(
                        (member) =>
                            member &&
                            (member.status === "active" || member.status === "invitation_accepted") &&
                            member.user_id !== user.user_id &&
                            member.role != "admin"
                    );

                    const defaultFilterMembers = transformedMembers.filter(
                        (member) =>
                            member &&
                            (member.user_id === user.user_id ||
                            member.role == "admin")
                    );
                    
                    setDefaultMembers(defaultFilterMembers);
                    
                    // Set the teamMembers state
                    setTeamMembers(filteredMembers);
                } else {
                    if (response.message == "Workspace not found.") {
                        navigate("/404/");
                    } else {
                        showToast(response.message, "error");
                    }
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error fetching workspace:", error);

                setLoading(false);
            });
    };
    const validateFullForm = () => {
        var error = false;

        var formErrors = { project_name: null };

        if (!formValues.project_name) {
            formErrors = {
                ...formErrors,
                project_name: "This field is required",
            };

            error = true;
        }
        if (error) {
            setFormErrors(formErrors);
        }
        return error;
    };
    const createProjectApi = () => {
        var error = false;

        error = validateFullForm();

        if (!error && !createProjectLoading) {
            let formData = {};

            formData.title = formValues.project_name;

            formData.workspace_id = workspace.id;

            formData.members = formValues.members;

            let token = ReactSession.get("token");

            setCreateProjectLoading(true);

            createProjectService(formData, token).then((response) => {
                if (response.success) {
                    onCreateProject();

                    setCreateProjectLoading(false);
                } else {
                    setCreateProjectLoading(false);
                    showToast(response.message, "error");
                }
            });
        }
    };
    return (
        <>
            <Modal
                show={props.openModal}
                //onHide={props.closeModal}
                size="lg"
                className="fade create-new-project-modal"
            >
                <Modal.Body ref={wrapperRef}>
                    <div className="create-new-project-modal-data">
                        <div className="modal-header-top">
                            <Text type={"h3"}>Create a new project</Text>
                            <button
                                type="button"
                                className="close"
                                onClick={() => {
                                    closeModal();
                                }}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">
                                    <Icon
                                        value="Close"
                                        size="medium"
                                        hover={true}
                                    />
                                </span>
                            </button>
                        </div>

                        <div className="create-new-project-modal-inner-data">
                            <Input
                                type="text"
                                label="Project name"
                                maxLength={45}
                                value={formValues.project_name}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        project_name: e.target.value,
                                    });

                                    if (formErrors.project_name) {
                                        setFormErrors({
                                            ...formErrors,
                                            project_name: null,
                                        });
                                    }
                                }}
                                error={formErrors.project_name}
                                onClear={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        project_name: "",
                                    });
                                }}
                                onBlur={(e) => {
                                    if (!formValues.project_name) {
                                        setFormErrors({
                                            ...formErrors,
                                            project_name:
                                                "This field is required.",
                                        });
                                    }
                                }}
                            />
                            {workspace && workspace.type === "Individual" ?
                            <div className="mt-32 mb-32">
                                <div style={{position:"relative", width:"100%", bottom:"0px"}} className="switch-mamber-team d-flex align-items-center">
                                    <Text
                                        type={"body-text-2"}
                                        fontWeight={"medium-font"}
                                    >
                                        Do you want to
                                        invite team
                                        <br /> members to
                                        this workspace?
                                    </Text>

                                    <Button
                                        type="primary"
                                        size={`medium`}
                                        cssclass={
                                            "white-bg-btn"
                                        }
                                        iconLeft={
                                            <Icon
                                                value={
                                                    "group"
                                                }
                                                size={
                                                    "medium"
                                                }
                                            />
                                        }
                                        onClick={() => {
                                            navigate(
                                                "/wsp/" +
                                                workspace.id +
                                                "/upgrade-workspace"
                                            );
                                        }}
                                        label="Switch to Team plan"
                                    />
                                </div>
                            </div>
                            :
                            
                            <div className="mt-32 mb-32">
                                <Text
                                    type={"body-text-2"}
                                    fontWeight={"medium-font"}
                                    cssClasses={"mb-12"}
                                >
                                    Project members
                                </Text>
                                <MembersDropdownList
                                    value={formValues.members}
                                    onChange={(selected) => {
                                        
                                        setFormValues({
                                            ...formValues,
                                            members: selected,
                                        });
                                    }}
                                    defaultMembers={defaultMembers.map((user, index)=>{
                                        let backgroundColor = user.color;
                                        
                                        let current_user = ReactSession.get("user");

                                        return {
                                            label: (
                                                <>
                                                    <CircularNameIcon
                                                        style={{
                                                            backgroundColor,
                                                        }}
                                                        key={index}
                                                        label={
                                                            user && user.name
                                                                ? user.name &&
                                                                  user.name
                                                                      .charAt(0)
                                                                      .toUpperCase()
                                                                : user.email
                                                                      .charAt(0)
                                                                      .toUpperCase()
                                                        }
                                                    />
                                                    {user.name
                                                        ? user.name
                                                        : user.email} 
                                                    {user.user_id == current_user.user_id &&
                                                    <>{" (You)"}</>
                                                    }
                                                    {user && user.role == 'admin' &&
                                                    <>{" (Admin)"}</>
                                                    }
                                                    
                                                </>
                                            )
                                        }
                                    })}
                                    options={teamMembers.map((user, index) => {
                                        let backgroundColor = user.color;

                                        return {
                                            label: (
                                                <>
                                                    <CircularNameIcon
                                                        style={{
                                                            backgroundColor,
                                                        }}
                                                        key={index}
                                                        label={
                                                            user && user.name
                                                                ? user.name &&
                                                                  user.name
                                                                      .charAt(0)
                                                                      .toUpperCase()
                                                                : user.email
                                                                      .charAt(0)
                                                                      .toUpperCase()
                                                        }
                                                    />
                                                    {user.name
                                                        ? user.name
                                                        : user.email}
                                                </>
                                            ),
                                            value: user.id,
                                        };
                                    })}
                                    label={
                                        <div className="d-flex align-items-center select-member-dropdown">
                                            <Icon
                                                value={"user"}
                                                size={"medium"}
                                                colorClass={"gray-600-svg"}
                                            />
                                            <Text type={"body-text-2"}>
                                                Select team member
                                            </Text>
                                        </div>
                                    }
                                    no_results_message={"No members found. Please invite them to the workspace first."}
                                />
                                {false && teamMembers.length == 0 && (
                                    <Text
                                        type={"body-text-3"}
                                        fontWeight={"medium-font"}
                                        cssClasses={"mt-8 gray-color"}
                                    >
                                        No team members invited to this project
                                        yet.
                                    </Text>
                                )}
                            </div>
                            }
                            <div className="confirm-buttons-wrap modal-button-wrap">
                                <Button
                                    cssclass={"w-100"}
                                    type="primary"
                                    size={`large`}
                                    label={"Create"}
                                    onClick={() => {
                                        createProjectApi();
                                    }}
                                    state={
                                        checkIfFormValid()
                                            ? "active"
                                            : "disabled"
                                    }
                                    microLoading={createProjectLoading}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
