import React from "react";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { LayoutResearcher } from '../../themes/userq/Layouts/layout_researcher';
import { useState, useEffect, useRef, useCallback  } from "react";
import { Link } from 'react-router-dom';
import {
    getResearcherTestResultData,
	getTreeChartData
} from "../../../services/researcher_test_results";
import toast, { LoaderIcon } from "react-hot-toast";
import Topbar from "./components/topbar";
import moment from "moment";
import SankeyChart from "./components/sankey_chart_v2";
import {atom, useRecoilState, useRecoilValueLoadable_TRANSITION_SUPPORT_UNSTABLE} from 'recoil';
import { LoadingIcon } from "../../loader/loadingIconNew";
import exportAsImage from "../../../lib/exportAsImage";
import { downloadBlob } from "../../../lib/helpers";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import Checkbox from "../../layouts/elements/fields/checkbox";
import { Dropdown } from "react-bootstrap";
import {FilterButtons} from "../../themes/userq/FilterButtons/FilterButtons";
import { Text } from "../../themes/userq/Text/Text";
import { Icon } from "../../themes/userq/Icon/Icon";

import { Button } from "../../themes/userq/Button/Button";
import { Footer } from "../../themes/userq/Footer/Footer";
import { Helmet } from "react-helmet";

export default function TreeChart() {
	let { task_id } = useParams();

    let { test_id } = useParams();

    const location = useLocation();

    const navigate = useNavigate();

    const exportRef = useRef();


	const [isLoading, setLoading] = useState(false);

    const [sectionLoading, setSectionLoading] = useState(false);

    const testState = atom({
        key: 'testresult-'+test_id, 
        default: ""
    });

    const [test, setTest] = useRecoilState(testState);

    const [treeState, setTreeState] = useState("Horizontal");

    const [treeData,setTreeData] = useState({});
	
    useEffect(() => {
		//document.title = process.env.REACT_APP_NAME + " - Analytics | Tree Testing";
		getTest(task_id);
	}, []);

    const getTest = () => {

        if(!test){
            let token = ReactSession.get("token");

            setLoading(true);

            let guest_result_token = '';

            

            if(location.pathname.substr(1, 1) === 'v'){

                // create guest_result_token
                if (!ReactSession.get("guest_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("guest_result_token", randomToken);
                }

                guest_result_token = ReactSession.get("guest_result_token");
            }
            let admin_result_token = '';

            

            if(location.pathname.substr(1, 1) === 'a'){

                // create guest_result_token
                if (!ReactSession.get("admin_result_token")) {
                    const randomToken = Math.random().toString(36).substr(2, 15);
                    ReactSession.set("admin_result_token", randomToken);
                }

                admin_result_token = ReactSession.get("admin_result_token");
            }

            getResearcherTestResultData({ test_id: test_id, guest_result_token: guest_result_token, admin_result_token:admin_result_token }, token).then(
                (response) => {
                    setLoading(false);

                    if (response.success) {

                        if(response.workspace_project_team_member){
                            if(response.workspace_project_team_member.status!='active'){
                                navigate("/wsp/"+response.workspace_project_team_member.workspace_id+"/p/"+response.workspace_project_team_member.workspace_project_id+"/project-no-access")
                            }
                        }
                        if(location.pathname.substr(1, 1) === 'v' && response.is_share_result){
                            navigate(`/v/results/${test_id}/results-not-available`);
                        }

                        if(location.pathname.substr(1, 1) === 'v' && response.test.password && !response.test_password_verify){

							navigate(`/v/results/${test_id}/password`);
						}
                        setTest(response.test);

                        getTreeChart(task_id);
                    
                    } else {

                        if(response.message == "Project not found.") {
							navigate("/404");
						} else if(response.message == "Test Record Not found") {
							navigate("/404");
						} else {
                            toast(
                                <div className="toastinner">
                                    {response.message}
                                    <a
                                        onClick={() => {
                                            toast.dismiss();
                                        }}
                                    >
                                        &times;
                                    </a>
                                </div>,
                                {
                                    className: response.success ? "successtoast" : "errortoast",
                                    position: "bottom-center",
                                    duration: 2000,
                                }
                            );
                        }
                    }
                }
            );
        } else {
            getTreeChart(task_id);
        }
	};

    const getTreeChart = (task_id) => {
        let token = ReactSession.get("token");

        let guest_result_token = '';

        if(location.pathname.substr(1, 1) === 'v'){

            // create guest_result_token
            if (!ReactSession.get("guest_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("guest_result_token", randomToken);
            }

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = '';

        if(location.pathname.substr(1, 1) === 'a'){

            // create guest_result_token
            if (!ReactSession.get("admin_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("admin_result_token", randomToken);
            }

            admin_result_token = ReactSession.get("admin_result_token");
        }

		setLoading(true);

		getTreeChartData({ test_id: test_id,task_id: task_id, guest_result_token: guest_result_token, admin_result_token:admin_result_token }, token).then((response) => {
			setLoading(false);

			if (response.success) {
				
                var tab = {};

                tab['api_tree'] = response.data.tree_chart.api_tree;

                tab['answer_path'] = response.data.tree_chart.answer_path;

                tab['total_navigation'] = response.data.tree_chart.total_navigation;

                tab['depth'] = response.data.tree_chart.depth;

                tab['leaf_nodes'] = response.data.tree_chart.leaf_nodes;

                tab['task_title'] = response.data.tree_chart.task_title;

                tab['subtitle'] = response.data.tree_chart.subtitle;

                setTreeData(tab);
                
			} else {
				toast(
					<div className="toastinner">
						{response.message}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: response.success ? "successtoast" : "errortoast",
						position: "bottom-center",
						duration: 2000,
					}
				);
			}
		});
    }

    

    const callbackTopbar = (data)=>{
        getTest();
    }
    const downloadSVG = useCallback(() => {
        const svg = exportRef.current.getElementsByTagName('div')[0].innerHTML;
        const blob = new Blob([svg], { type: "image/svg+xml" });
    
        downloadBlob(blob, `treechart.svg`);
      }, []);
    
	return (
		<>
            <Helmet>
                <title>Tree chart - Analyse tree testing  | UserQ</title>
            </Helmet>
            {isLoading &&
            <div className={'page-loader accountloading-box result-sections-loader'}>
                <LoadingIcon />
            </div>
            }
            
			{!isLoading && test && (
				<div className="analyze-results-page-wrapper"  >
					
					<div className={`matrix-full-view hide-for-mobile`}>
						<div className="sp5-content-wrap analyze-overflow-wrap">
                            <div className="sp5-right-side-wrap card-sorting-anayl-data" style={{width:"100%"}}>
                                {isLoading &&
                                <LoadingIcon />
                                }
                                {!isLoading &&
                                <div className="bd-que-ans-table-data-inner bd-que-buy-user-table  tree-chart-full-top-row" id="field2">
                      
                                    <div className="tree-chart-head">
                                          <div className="full ">    
                                        <Text type={"subtitle-1"} fontWeight={"medium-font"}>Tree view</Text>
                                        <Text type={"body-text-1"} fontWeight={"medium-font"}>Tree view shows you the paths that users have taken for a specific task.</Text>
                                        
                                    </div>
                                    </div>
                                    
                                    <div className="tree-chart-top-bottom">
                                    <div className="full"> 
                                    <div className="bd-question-top-slide d-flex justify-content-between">
                                        
                                        <div className="bd-que-slide-left">
                                            <Text type={"h3"} fontWeight={"semi-bold-font"} cssClasses={`${test.language=="ar"?"arabic-font":""}`}>{treeData.task_title}</Text>
                                           
                                        </div>
                                        <div className="bd-que-slide-right tree-options-right treechartbtn">
                                            <div className="d-flex treechartbtnbox">
                                                <FilterButtons options={["Horizontal","Radial"]} selected={treeState} onChange={(option)=>{ setTreeState(option)} } />
                                                {/*<Checkbox className={"plan-change-switch"} onChange={(event)=>{ setTreeState(treeState=='horizontal' ? "radia7l" : "horizontal")} } value={"radial"} checked={treeState=="radial"?true:false} label="Radial" bigLabel={false} beforeLabel={"Horizontal"} />*/}
                                            </div>
                                            <div className="treechartDropdown">
                                            
                                                <Dropdown>
                                                    <Dropdown.Toggle id={"dropdownMenuButton"} className="dropdown-toggle ">
                                                        <Button 
                                                            type="secondary"
                                                            iconLeft={
                                                                <Icon value={"download"} size={"medium"} />
                                                            }
                                                            size={"medium"}
                                                            label={"Download"}
                                                        />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="hamburger-items">
                                                        <Dropdown.Item href="#" onClick={() =>  exportAsImage(exportRef.current, "treechart")}>
                                                            Download PNG
                                                            <span><img src={process.env.REACT_APP_URL+"images/download.svg"} /></span>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#" onClick={() =>  downloadSVG(exportRef.current)}> 
                                                            Download  SVG
                                                            <span><img src={process.env.REACT_APP_URL+"images/download.svg"} /></span>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="participants-overview-data-wrap">
                                        <div className="confid-tech-hraph-hold">
                                            <div className="chart-info-wrap" style={{justifyContent:"left"}}>
                                                <div className="chart-info-repeat" >
                                                    <span style={{"display": "inline-block", "backgroundColor": "rgba(55, 139, 16, 1)"}}></span>
                                                    <p>Correct path</p>
                                                </div>
                                                <div className="chart-info-repeat">
                                                    <span style={{"display": "inline-block", "backgroundColor": "rgba(156, 156, 156, 1)"}}></span>
                                                    <p>Incorrect path</p>
                                                </div>
                                                <div className="chart-info-repeat">
                                                    <span style={{"display": "inline-block", "backgroundColor": "rgba(151, 89, 203, 1)"}}></span>
                                                    <p>Nodes</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                    <div className="treew-view-graph-wrap" ref={exportRef}>
                                         {treeData && treeData.answer_path && treeData.api_tree.length > 0 &&

                                         <div style={{width:treeState=="Radial"  ?"100%":((treeData.depth-1)*300 < exportRef.current.getBoundingClientRect().width?(treeData.depth-1)*300:"100%") , margin:"0 auto"}}>
                                        <SankeyChart test={test} radial={treeState=="Radial"?true:false}  height={treeState=="Radial" ? (treeData.depth-1)*350 : treeData.leaf_nodes*(100)} depth={treeData.depth}  answer_path={treeData.answer_path} total_navigation={treeData.total_navigation}  rawdata={{"id":treeData.api_tree[0]["id"], "depth": treeData.api_tree[0]["depth"], "participants": treeData.api_tree[0]["participants"], "correct_answer_count": treeData.api_tree[0]["correct_answer_count"],"incorrect_answer_count": treeData.api_tree[0]["incorrect_answer_count"],"skipped_count": treeData.api_tree[0]["skipped_count"],"nominated_answer_count": treeData.api_tree[0]["nominated_answer_count"], "back_count": treeData.api_tree[0]["back_count"], "value": treeData.api_tree[0]["value"], title:treeData.api_tree[0]["title"], children:treeData.api_tree[0]['children']} } owidth={100} />
                                        </div>
                                        }
                                    </div>
                                </div>
                                
                                }
                            </div>
						</div>
					</div>
                    <div className="participants-result-responsive-section hide-for-desktop">
                        <div className="mobile-charts-not-available">
                            <div className="no-data-icon">
                                <Icon colorClass={"gray-700-svg"} value={"attention"} size={"large"} />
                            </div>
                            <p>
                            This page is not available on mobile. Please log in from a desktop device to access it.
                            </p>
                        </div>

                    </div>
                    <Footer />
				</div>
			)}
		</>
	);
}
