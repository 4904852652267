import React, { useEffect, useState } from "react";
import { Link, Navigate, redirect, useNavigate } from "react-router-dom";
import { LayoutResearcher } from "../../../themes/userq/Layouts/layout_researcher";
import { Helmet } from "react-helmet";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";
import { ToggleButton } from "../../../themes/userq/ToggleButton/ToggleButton";
import {
    comparePlanService,
    getListOfWorkspaces,
} from "../../../../services/workspaces";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { roundNumber, showToast, amountFormat } from "../../../../lib/helpers";
import { Button } from "../../../themes/userq/Button/Button";
import { Tag } from "../../../themes/userq/Tag/Tag";
import IndividualWorkspaceModal from "../modals/individual-workspace-modal";
import { FilterButtons } from "../../../themes/userq/FilterButtons/FilterButtons";

const CompareWorkspaces = () => {
    ReactSession.setStoreType("localStorage");
    const navigate = useNavigate();
    const [show_support_panel, setShowSupportPanel] = useState(false);
    const [billing_cycle, setBillingCycle] = useState("monthly");

    const [individualPlan, setIndividualPlan] = useState(null);
    const [teamPlanMonthly, setTeamPlanMonthly] = useState(null);
    const [teamPlanYearly, setTeamPlanYearly] = useState(null);

    const [activeTeamPlan, setActiveTeamPlan] = useState(null);
    const [openWorkspaceModal, setOpenWorkspaceModal] = useState(false);

    const [pageLoading, setPageLoading] = useState(false);

    const [user, setUser] = useState(ReactSession.get("user"));
    const [workspaceCount, setWorkspaceCount] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [filterOption, setFilterOption] = useState("Pay-As-You-Go");

    const comparePlanApi = () => {
        const token = ReactSession.get("token");
        setPageLoading(true);

        comparePlanService(token).then((response) => {
            if (response.success) {
                response.plans.forEach((plan) => {
                    if (plan.type === "Individual") {
                        setIndividualPlan(plan);
                    } else if (
                        plan.type === "Team" &&
                        plan.billing_cycle === "yearly"
                    ) {
                        setTeamPlanYearly(plan);
                        setActiveTeamPlan(plan);
                    } else if (
                        plan.type === "Team" &&
                        plan.billing_cycle === "monthly"
                    ) {
                        setTeamPlanMonthly(plan);
                    }
                });
                workSpaceList();
            } else {
                setPageLoading(false);
                showToast(response.message, "error");
            }
        });
    };

    const workSpaceList = () => {
        const token = ReactSession.get("token");
        setPageLoading(true);
        let planType = "Individual";
        getListOfWorkspaces(token).then((response) => {
            setPageLoading(false);
            if (response.success) {
                if (response.workspace_count) {
                    setWorkspaceCount(response.workspace_count);
                } else {
                    setWorkspaceCount(0);
                }
            } else {
                showToast(response.message, "error");
            }
        });
    };

    useEffect(() => {
        const user = ReactSession.get("user");
        setFirstName(user.first_name);
        comparePlanApi();
    }, []);

    const closeIndividualWorkspaceModal = (reload) => {
        setOpenWorkspaceModal(false);
    };

    return (
        <LayoutResearcher
            isLoading={false}
            skipCheck={false}
            activeMenu={"dashboard"}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
        >
            <Helmet>
                <title>Compare workspaces plan | UserQ</title>
            </Helmet>
            <div className="compare-plan-page-wrap">
                {pageLoading ? (
                    <>
                        <div className="workspace-page-loader">
                            <div
                                className={
                                    "page-loader accountloading-box creditloading-box"
                                }
                            >
                                <LoadingIcon />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="compare-plan-heading text-center">
                            <Text type={"h2"} fontWeight={"bold-font"}>
                                Choose a plan for the new workspace
                            </Text>
                        </div>

                        <div className="plan-options-hold row none-for-desktop">
                            <div className="plan-filter mb-32">
                                <FilterButtons
                                    options={["Pay-As-You-Go", `Team plan`]}
                                    selected={filterOption}
                                    onChange={(item) => {
                                        setFilterOption(item);
                                    }}
                                />
                            </div>

                            {filterOption == "Pay-As-You-Go" && (
                                <>
                                    {" "}
                                    {individualPlan && (
                                        <div className="col-md-6 plan-options-repeat">
                                            <div className="plan-options-data p-32">
                                                <div className="plan-icon text-center">
                                                    <Icon
                                                        value="user"
                                                        size={"large"}
                                                        colorClass={
                                                            "gray-900-svg"
                                                        }
                                                    />
                                                </div>

                                                <div className="plan-top-area text-center">
                                                    <Text
                                                        type={"h1"}
                                                        cssClasses={
                                                            "mt-12 mb-12"
                                                        }
                                                        fontWeight={"bold-font"}
                                                    >
                                                        {individualPlan.title}
                                                    </Text>

                                                    <Text
                                                        cssClasses={
                                                            "plan-name purple-color"
                                                        }
                                                        type={"body-text-2"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        {individualPlan.type}{" "}
                                                        workspace
                                                    </Text>

                                                    <Text
                                                        type={"body-text-3"}
                                                        cssClasses={
                                                            "gray-color mt-32 mb-32"
                                                        }
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        {
                                                            individualPlan.subtitle
                                                        }
                                                    </Text>
                                                </div>

                                                <div className="plan-switch-wrap">
                                                    &nbsp;
                                                </div>

                                                <div className="plan-row-hold">
                                                    <div className="plan-pricing-row d-flex alignment-center justify-content-between mb-20">
                                                        <div>
                                                            <Text
                                                                type={
                                                                    "subtitle-2"
                                                                }
                                                                fontWeight={
                                                                    "semi-bold-font"
                                                                }
                                                            >
                                                                Workspace
                                                                pricing
                                                            </Text>
                                                        </div>
                                                        <div>
                                                            <Text
                                                                type={"h4"}
                                                                fontWeight={
                                                                    "semi-bold-font"
                                                                }
                                                            >
                                                                {individualPlan.price
                                                                    ? "$" +
                                                                      amountFormat(
                                                                          individualPlan.price,
                                                                          2
                                                                      )
                                                                    : "Free"}
                                                            </Text>
                                                        </div>
                                                    </div>

                                                    <div className="plan-row-repeat d-flex justify-content-between">
                                                        <div className="plan-row-left">
                                                            <Text
                                                                type={
                                                                    "body-text-2"
                                                                }
                                                                cssClasses={
                                                                    "gray-color"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                Team size
                                                            </Text>
                                                        </div>

                                                        <div className="plan-row-right">
                                                            <Text
                                                                type={
                                                                    "body-text-2"
                                                                }
                                                                cssClasses={
                                                                    "black-color d-flex align-items-center max-seat-col"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                <Icon
                                                                    value="user"
                                                                    size={
                                                                        "medium"
                                                                    }
                                                                    colorClass={
                                                                        "gray-900-svg"
                                                                    }
                                                                />
                                                                {
                                                                    individualPlan.max_seats
                                                                }
                                                            </Text>
                                                        </div>
                                                    </div>

                                                    <div className="plan-row-repeat d-flex justify-content-between mt-12">
                                                        <div className="plan-row-left">
                                                            <Text
                                                                type={
                                                                    "body-text-2"
                                                                }
                                                                cssClasses={
                                                                    "gray-color"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                Test publishing
                                                                fee
                                                            </Text>
                                                        </div>
                                                        <div className="plan-row-right">
                                                            <Text
                                                                type={
                                                                    "body-text-2"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                {"$" +
                                                                    amountFormat(
                                                                        individualPlan.publishing_fees,
                                                                        2
                                                                    )}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="plan-btn mt-40">
                                                    <Button
                                                        type="primary"
                                                        cssclass={"w-100"}
                                                        label={
                                                            "Create individual workspace"
                                                        }
                                                        size={"large"}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setOpenWorkspaceModal(
                                                                true
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            {filterOption == "Team plan" && (
                                <>
                                    {activeTeamPlan && (
                                        <div className="col-md-6 plan-options-repeat">
                                            <div className="plan-options-data p-32">
                                                <div className="plan-icon text-center">
                                                    <Icon
                                                        value="group"
                                                        size={"large"}
                                                        colorClass={
                                                            "gray-900-svg"
                                                        }
                                                    />
                                                </div>

                                                <div className="plan-top-area text-center">
                                                    <Text
                                                        type={"h1"}
                                                        cssClasses={
                                                            "mt-12 mb-12"
                                                        }
                                                        fontWeight={"bold-font"}
                                                    >
                                                        {activeTeamPlan.title}
                                                    </Text>

                                                    <Text
                                                        cssClasses={
                                                            "plan-name purple-color"
                                                        }
                                                        type={"body-text-2"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        {activeTeamPlan.type}{" "}
                                                        workspace
                                                    </Text>

                                                    <Text
                                                        type={"body-text-3"}
                                                        cssClasses={
                                                            "gray-color mt-32 mb-32"
                                                        }
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        {
                                                            activeTeamPlan.subtitle
                                                        }
                                                    </Text>
                                                </div>

                                                <div className="plan-switch-wrap">
                                                    <div
                                                        className={`plan-type-wrap d-flex align-items-center`}
                                                    >
                                                        <Text
                                                            type={"body-text-3"}
                                                            cssClasses={`monthly-plan ${
                                                                activeTeamPlan.billing_cycle ===
                                                                "monthly"
                                                                    ? "active-plan"
                                                                    : ""
                                                            }`}
                                                            fontWeight={
                                                                "medium-font"
                                                            }
                                                        >
                                                            Monthly
                                                        </Text>
                                                        <ToggleButton
                                                            isChecked={
                                                                activeTeamPlan.billing_cycle ===
                                                                "yearly"
                                                                    ? true
                                                                    : false
                                                            }
                                                            label={
                                                                <Text
                                                                    type={
                                                                        "body-text-3"
                                                                    }
                                                                    fontWeight={
                                                                        "medium-font"
                                                                    }
                                                                    cssClasses={`${
                                                                        activeTeamPlan.billing_cycle ===
                                                                        "yearly"
                                                                            ? "active-plan"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    {" "}
                                                                    Yearly{" "}
                                                                </Text>
                                                            }
                                                            onChange={(e) => {
                                                                activeTeamPlan.billing_cycle ===
                                                                "yearly"
                                                                    ? setActiveTeamPlan(
                                                                          teamPlanMonthly
                                                                      )
                                                                    : setActiveTeamPlan(
                                                                          teamPlanYearly
                                                                      );
                                                            }}
                                                        />
                                                        {teamPlanYearly && (
                                                            <div
                                                                className={`plan-offer-wrap ${
                                                                    activeTeamPlan.billing_cycle ===
                                                                    "monthly"
                                                                        ? "disabled-offer"
                                                                        : ""
                                                                }`}
                                                            >
                                                                <Tag
                                                                    type={
                                                                        "pink-filled-tag"
                                                                    }
                                                                    title={`Save ${teamPlanYearly.plan_disc_perc}%`}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="plan-row-hold">
                                                    <div className="plan-pricing-row d-flex alignment-center justify-content-between mb-20">
                                                        <div>
                                                            <Text
                                                                type={
                                                                    "subtitle-2"
                                                                }
                                                                fontWeight={
                                                                    "semi-bold-font"
                                                                }
                                                            >
                                                                Workspace
                                                                pricing
                                                            </Text>
                                                        </div>
                                                        <div>
                                                            <Text type={"h4"}>
                                                                {activeTeamPlan.amount
                                                                    ? "$" +
                                                                      (activeTeamPlan.billing_cycle ==
                                                                      "yearly"
                                                                          ? amountFormat(
                                                                                activeTeamPlan.amount /
                                                                                    12,
                                                                                2
                                                                            )
                                                                          : amountFormat(
                                                                                activeTeamPlan.amount,
                                                                                2
                                                                            ))
                                                                    : "Free"}{" "}
                                                                <sub>
                                                                    / per month
                                                                    <br />
                                                                    <span className="gray-color">
                                                                        billed{" "}
                                                                        {activeTeamPlan.billing_cycle ==
                                                                        "yearly"
                                                                            ? "yearly"
                                                                            : "monthly"}
                                                                    </span>
                                                                </sub>{" "}
                                                            </Text>
                                                        </div>
                                                    </div>

                                                    <div className="plan-row-repeat d-flex justify-content-between">
                                                        <div className="plan-row-left">
                                                            <Text
                                                                type={
                                                                    "body-text-2"
                                                                }
                                                                cssClasses={
                                                                    "gray-color"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                Team size
                                                            </Text>
                                                        </div>

                                                        <div className="plan-row-right">
                                                            <Text
                                                                type={
                                                                    "body-text-2"
                                                                }
                                                                cssClasses={
                                                                    "black-color d-flex align-items-center max-seat-col"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                <Icon
                                                                    value="group"
                                                                    size={
                                                                        "medium"
                                                                    }
                                                                    colorClass={
                                                                        "gray-900-svg"
                                                                    }
                                                                />
                                                                {
                                                                    activeTeamPlan.max_seats
                                                                }
                                                            </Text>
                                                        </div>
                                                    </div>

                                                    <div className="plan-row-repeat d-flex justify-content-between mt-12">
                                                        <div className="plan-row-left">
                                                            <Text
                                                                type={
                                                                    "body-text-2"
                                                                }
                                                                cssClasses={
                                                                    "gray-color"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                Test publishing
                                                                fee
                                                            </Text>
                                                        </div>
                                                        <div className="plan-row-right">
                                                            <Text
                                                                type={
                                                                    "body-text-2"
                                                                }
                                                                fontWeight={
                                                                    "medium-font"
                                                                }
                                                            >
                                                                {
                                                                    activeTeamPlan.publishing_fees
                                                                }
                                                            </Text>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="plan-btn mt-40">
                                                    <Button
                                                        type="primary"
                                                        cssclass={"w-100"}
                                                        label={
                                                            "Create team workspace"
                                                        }
                                                        size={"large"}
                                                        onClick={(e) => {
                                                            e.stopPropagation();

                                                            let navigate_to =
                                                                "/r/create-team-workspace";

                                                            const searchParams =
                                                                new URLSearchParams(
                                                                    window.location.search
                                                                );

                                                            const from =
                                                                searchParams.get(
                                                                    "from"
                                                                );

                                                            if (
                                                                searchParams.has(
                                                                    "from"
                                                                ) &&
                                                                from !== null &&
                                                                from ===
                                                                    "noaccess"
                                                            ) {
                                                                navigate_to =
                                                                    "/r/create-team-workspace?from=noaccess";
                                                            }
                                                            navigate(
                                                                navigate_to
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="plan-options-hold row none-for-mobile">
                            {individualPlan && (
                                <div className="col-md-6 plan-options-repeat">
                                    <div className="plan-options-data p-32">
                                        <div className="plan-icon text-center">
                                            <Icon
                                                value="user"
                                                size={"large"}
                                                colorClass={"gray-900-svg"}
                                            />
                                        </div>

                                        <div className="plan-top-area text-center">
                                            <Text
                                                type={"h1"}
                                                cssClasses={"mt-12 mb-12"}
                                                fontWeight={"bold-font"}
                                            >
                                                {individualPlan.title}
                                            </Text>

                                            <Text
                                                cssClasses={
                                                    "plan-name purple-color"
                                                }
                                                type={"body-text-2"}
                                                fontWeight={"medium-font"}
                                            >
                                                {individualPlan.type} workspace
                                            </Text>

                                            <Text
                                                type={"body-text-3"}
                                                cssClasses={
                                                    "gray-color mt-32 mb-32"
                                                }
                                                fontWeight={"medium-font"}
                                            >
                                                {individualPlan.subtitle}
                                            </Text>
                                        </div>

                                        <div className="plan-switch-wrap">
                                            &nbsp;
                                        </div>

                                        <div className="plan-row-hold">
                                            <div className="plan-pricing-row d-flex alignment-center justify-content-between mb-20">
                                                <div>
                                                    <Text
                                                        type={"subtitle-2"}
                                                        fontWeight={
                                                            "semi-bold-font"
                                                        }
                                                    >
                                                        Workspace pricing
                                                    </Text>
                                                </div>
                                                <div>
                                                    <Text
                                                        type={"h4"}
                                                        fontWeight={
                                                            "semi-bold-font"
                                                        }
                                                    >
                                                        {individualPlan.price
                                                            ? "$" +
                                                              amountFormat(
                                                                  individualPlan.price,
                                                                  2
                                                              )
                                                            : "Free"}
                                                    </Text>
                                                </div>
                                            </div>

                                            <div className="plan-row-repeat d-flex justify-content-between">
                                                <div className="plan-row-left">
                                                    <Text
                                                        type={"body-text-2"}
                                                        cssClasses={
                                                            "gray-color"
                                                        }
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        Team size
                                                    </Text>
                                                </div>

                                                <div className="plan-row-right">
                                                    <Text
                                                        type={"body-text-2"}
                                                        cssClasses={
                                                            "black-color d-flex align-items-center max-seat-col"
                                                        }
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        <Icon
                                                            value="user"
                                                            size={"medium"}
                                                            colorClass={
                                                                "gray-900-svg"
                                                            }
                                                        />
                                                        {
                                                            individualPlan.max_seats
                                                        }
                                                    </Text>
                                                </div>
                                            </div>

                                            <div className="plan-row-repeat d-flex justify-content-between mt-12">
                                                <div className="plan-row-left">
                                                    <Text
                                                        type={"body-text-2"}
                                                        cssClasses={
                                                            "gray-color"
                                                        }
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        Test publishing fee
                                                    </Text>
                                                </div>
                                                <div className="plan-row-right">
                                                    <Text
                                                        type={"body-text-2"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        {"$" +
                                                            amountFormat(
                                                                individualPlan.publishing_fees,
                                                                2
                                                            )}
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="plan-btn mt-40">
                                            <Button
                                                type="primary"
                                                cssclass={"w-100"}
                                                label={
                                                    "Create individual workspace"
                                                }
                                                size={"large"}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setOpenWorkspaceModal(true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {activeTeamPlan && (
                                <div className="col-md-6 plan-options-repeat">
                                    <div className="plan-options-data p-32">
                                        <div className="plan-icon text-center">
                                            <Icon
                                                value="group"
                                                size={"large"}
                                                colorClass={"gray-900-svg"}
                                            />
                                        </div>

                                        <div className="plan-top-area text-center">
                                            <Text
                                                type={"h1"}
                                                cssClasses={"mt-12 mb-12"}
                                                fontWeight={"bold-font"}
                                            >
                                                {activeTeamPlan.title}
                                            </Text>

                                            <Text
                                                cssClasses={
                                                    "plan-name purple-color"
                                                }
                                                type={"body-text-2"}
                                                fontWeight={"medium-font"}
                                            >
                                                {activeTeamPlan.type} workspace
                                            </Text>

                                            <Text
                                                type={"body-text-3"}
                                                cssClasses={
                                                    "gray-color mt-32 mb-32"
                                                }
                                                fontWeight={"medium-font"}
                                            >
                                                {activeTeamPlan.subtitle}
                                            </Text>
                                        </div>

                                        <div className="plan-switch-wrap">
                                            <div
                                                className={`plan-type-wrap d-flex align-items-center`}
                                            >
                                                <Text
                                                    type={"body-text-3"}
                                                    cssClasses={`monthly-plan ${
                                                        activeTeamPlan.billing_cycle ===
                                                        "monthly"
                                                            ? "active-plan"
                                                            : ""
                                                    }`}
                                                    fontWeight={"medium-font"}
                                                >
                                                    Monthly
                                                </Text>
                                                <ToggleButton
                                                    isChecked={
                                                        activeTeamPlan.billing_cycle ===
                                                        "yearly"
                                                            ? true
                                                            : false
                                                    }
                                                    label={
                                                        <Text
                                                            type={"body-text-3"}
                                                            fontWeight={
                                                                "medium-font"
                                                            }
                                                            cssClasses={`${
                                                                activeTeamPlan.billing_cycle ===
                                                                "yearly"
                                                                    ? "active-plan"
                                                                    : ""
                                                            }`}
                                                        >
                                                            {" "}
                                                            Yearly{" "}
                                                        </Text>
                                                    }
                                                    onChange={(e) => {
                                                        activeTeamPlan.billing_cycle ===
                                                        "yearly"
                                                            ? setActiveTeamPlan(
                                                                  teamPlanMonthly
                                                              )
                                                            : setActiveTeamPlan(
                                                                  teamPlanYearly
                                                              );
                                                    }}
                                                />
                                                {teamPlanYearly && (
                                                    <div
                                                        className={`plan-offer-wrap ${
                                                            activeTeamPlan.billing_cycle ===
                                                            "monthly"
                                                                ? "disabled-offer"
                                                                : ""
                                                        }`}
                                                    >
                                                        <Tag
                                                            type={
                                                                "pink-filled-tag"
                                                            }
                                                            title={`Save ${teamPlanYearly.plan_disc_perc}%`}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="plan-row-hold">
                                            <div className="plan-pricing-row d-flex alignment-center justify-content-between mb-20">
                                                <div>
                                                    <Text
                                                        type={"subtitle-2"}
                                                        fontWeight={
                                                            "semi-bold-font"
                                                        }
                                                    >
                                                        Workspace pricing
                                                    </Text>
                                                </div>
                                                <div>
                                                    <Text type={"h4"}>
                                                        {activeTeamPlan.amount
                                                            ? "$" +
                                                              (activeTeamPlan.billing_cycle ==
                                                              "yearly"
                                                                  ? amountFormat(
                                                                        activeTeamPlan.amount /
                                                                            12,
                                                                        2
                                                                    )
                                                                  : amountFormat(
                                                                        activeTeamPlan.amount,
                                                                        2
                                                                    ))
                                                            : "Free"}{" "}
                                                        <sub>
                                                            / per month
                                                            <br />
                                                            <span className="gray-color">
                                                                billed{" "}
                                                                {activeTeamPlan.billing_cycle ==
                                                                "yearly"
                                                                    ? "yearly"
                                                                    : "monthly"}
                                                            </span>
                                                        </sub>{" "}
                                                    </Text>
                                                </div>
                                            </div>

                                            <div className="plan-row-repeat d-flex justify-content-between">
                                                <div className="plan-row-left">
                                                    <Text
                                                        type={"body-text-2"}
                                                        cssClasses={
                                                            "gray-color"
                                                        }
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        Team size
                                                    </Text>
                                                </div>

                                                <div className="plan-row-right">
                                                    <Text
                                                        type={"body-text-2"}
                                                        cssClasses={
                                                            "black-color d-flex align-items-center max-seat-col"
                                                        }
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        <Icon
                                                            value="group"
                                                            size={"medium"}
                                                            colorClass={
                                                                "gray-900-svg"
                                                            }
                                                        />
                                                        {
                                                            activeTeamPlan.max_seats
                                                        }
                                                    </Text>
                                                </div>
                                            </div>

                                            <div className="plan-row-repeat d-flex justify-content-between mt-12">
                                                <div className="plan-row-left">
                                                    <Text
                                                        type={"body-text-2"}
                                                        cssClasses={
                                                            "gray-color"
                                                        }
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        Test publishing fee
                                                    </Text>
                                                </div>
                                                <div className="plan-row-right">
                                                    <Text
                                                        type={"body-text-2"}
                                                        fontWeight={
                                                            "medium-font"
                                                        }
                                                    >
                                                        {
                                                            activeTeamPlan.publishing_fees
                                                        }
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="plan-btn mt-40">
                                            <Button
                                                type="primary"
                                                cssclass={"w-100"}
                                                label={"Create team workspace"}
                                                size={"large"}
                                                onClick={(e) => {
                                                    e.stopPropagation();

                                                    let navigate_to =
                                                        "/r/create-team-workspace";

                                                    const searchParams =
                                                        new URLSearchParams(
                                                            window.location.search
                                                        );

                                                    const from =
                                                        searchParams.get(
                                                            "from"
                                                        );

                                                    if (
                                                        searchParams.has(
                                                            "from"
                                                        ) &&
                                                        from !== null &&
                                                        from === "noaccess"
                                                    ) {
                                                        navigate_to =
                                                            "/r/create-team-workspace?from=noaccess";
                                                    }
                                                    navigate(navigate_to);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {openWorkspaceModal && (
                            <IndividualWorkspaceModal
                                openModal={openWorkspaceModal}
                                closeModal={closeIndividualWorkspaceModal}
                                workspaceCount={workspaceCount}
                                firstName={firstName}
                            />
                        )}
                    </>
                )}
            </div>
        </LayoutResearcher>
    );
};
export default CompareWorkspaces;