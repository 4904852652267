import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import NdaModal from "./dialog/nda_modal";
import {Text} from "../themes/userq/Text/Text";
import {Icon} from "../themes/userq/Icon/Icon";
import {Button} from "../themes/userq/Button/Button";
import "./introduction.css";

export default function Introduction({test,result,isNda,score,isPaidUser,isRegisteredUser,introductionCallback,timeSpent,testerNotMatchedInTargetGroup,testerLimitExceed}) {

    const [openNdaModal, setOpenNdaModal] = useState(false );
    const [checkNda, setCheckNda] = useState(false );
    const closeNdaModal = (data) => {
        setOpenNdaModal(false);
        if(data){
            setCheckNda(true);
        }
    };

    useEffect(()=>{
        // console.log(isNda)
        // check if user has not accepted nda then user will show form
        if(result && (result.is_nda === 0) && isNda){
            setOpenNdaModal(true);
        }

        if(!isNda){
            setOpenNdaModal(false);
        }
    },[isNda]);


    return (
        <div className="tester-page-wrapper tester-welcome-page-wrap">
            <div className="container inner-page-container">
                <div className="taking-test-page-height">
                <div className="tester-screens-hold">
                    <div className="tester-selected-page-wrap"> 
                        
                        <div className="selected-page-right-side">
                            <div className="tester-page-selected-img-wrap">
                                <img src={
                                    (test && test.image)?
                                        process.env.REACT_APP_IMG_URL+''+test.image   :

                                            process.env.REACT_APP_URL + "img/taking-test-welcome-default.svg"

                                }/>
                            </div>
                        </div>


                        <div className={`selected-page-left-side ${test.language=="ar"?"arabic_wrapper":""}`}>
                            <Text type={"h1"}>
                                {
                                    test && test.welcome_title
                                }
                            </Text>
                         
                            <Text type={"subtitle-2"} fontWeight={'medium-font'}>
                                {
                                    test && test.welcome_description
                                }
                            </Text>

                            <div className="button-wrapper none-for-mobile">
                                <Button
                                    size={'large'}
                                    iconRight={<Icon colorClass={"gray-50-svg"} value={test && test.language=="ar"?'back-arrow':'forward-arrow'}/>}
                                    label={test.language=="en"?"Let’s start":'لنبدأ'}
                                    onClick={() => {
                                        introductionCallback(checkNda);
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                    </div>
                    
                </div>
                <div className="button-wrapper none-for-desktop taking-test-button">
                    <Button
                        size={'large'}
                        iconRight={<Icon colorClass={"gray-50-svg"} value={test && test.language=="ar"?'back-arrow':'forward-arrow'}/>}
                        label={test.language=="en"?"Let’s start":'لنبدأ'}
                        onClick={() => {
                            introductionCallback(checkNda);
                        }}
                    />
                </div>
            </div>
            <NdaModal
                timeSpent={timeSpent}
                result={result}
                isPaidUser={isPaidUser}
                isRegisteredUser={isRegisteredUser}
                score={score}
                // openModal={(result && test.methodology === 'Card Sorting' && result.device === 'Mobile')?false:openNdaModal}
                openModal={openNdaModal}
                closeModal={closeNdaModal}
                testLanguage={test.language}
                testDevice={test.device}
                methodology={test.methodology}
                testerLimitExceed={testerLimitExceed}
                testerNotMatchedInTargetGroup={testerNotMatchedInTargetGroup}
            />
        </div>
    );
}
