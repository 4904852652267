//import { ReactSession } from "react-client-session";
import { ReactSession } from "./secure_reactsession";
import toast from "react-hot-toast";
import React from "react";
import AES from 'crypto-js/aes';
import CryptoJS from "crypto-js";
import {components} from "react-select";
import { Icon } from './../components/themes/userq/Icon/Icon';
import { ToastMessage } from "../components/themes/userq/ToastMessage/ToastMessage";
import { settings } from "../data/settings";
import { createBrowserHistory } from 'history';

// Create a history object
export const history = createBrowserHistory();

export function preventClick(e){
    e.preventDefault();
}
export function maxLengthCheck(object){
    if (object.target.value.length > object.target.maxLength) {
     object.target.value = object.target.value.slice(0, object.target.maxLength)
      }
    }
export   function isFileImage(file) {
    return file && file['type'].split('/')[0] === 'image';
}
export   function renderTestTitle(methodology) {
    if(methodology=="Tree test"){
      return "tree testing";
    } else if(methodology=="Five Seconds Test"){
      return "5 second test";
    } else {
      return methodology.toLowerCase();
    }
}
export   function renderTestTitleCreate(methodology) {
   if(methodology=="Five Seconds Test"){
    return "5 second test";
  } else {
    return methodology.toLowerCase();
  }
}
export   function callNewUI() {
  document.getElementById("webstyle").href = process.env.REACT_APP_URL+"css/userq.css";
  document.getElementById("responsive_webstyle").href = process.env.REACT_APP_URL+"css/userq-responsive.css";

  //document.getElementById("webstyle").setAttribute("disabled", true);
  //document.getElementById("webstyle_userq").removeAttribute("disabled");

  //document.getElementById("responsive_webstyle").setAttribute("disabled", true);

  //document.getElementById("responsive_webstyle_userq").removeAttribute("disabled");
}
export   function callOldUI() {
   document.getElementById("webstyle").href = process.env.REACT_APP_URL+"css/style.css";
  document.getElementById("responsive_webstyle").href = process.env.REACT_APP_URL+"css/responsive.css";

  //document.getElementById("webstyle").removeAttribute("disabled");
  //document.getElementById("webstyle_userq").setAttribute("disabled", true);

  //document.getElementById("responsive_webstyle").removeAttribute("disabled");

  //document.getElementById("responsive_webstyle_userq").setAttribute("disabled", true);
}
export function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }
  export   function normalize(arr) {
    var new_arr = [];

    arr.forEach(function(item){
      new_arr.push(item.value);
    })
      return new_arr;
  }
  export   function unnormalize(arr) {
    var new_arr = [];

    arr.forEach(function(item){
      new_arr.push({value: item, label:item});
    })
    return new_arr;
  }
  export function checkIfEmail(input){
    if(input.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
      return true;
    }
    return false;
  }
export function removeItemFromArray(array, item){
  var index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}
export function  calculateTime(time){
  var d = Math.floor(time / (3600*24));
  var h = Math.floor(time % (3600*24) / 3600);
  var m = Math.floor(time % 3600 / 60);
  var s = Math.floor(time % 60);
  if (m > 0) {
    return  (`${m}min ${s}sec`);

  } else if(s > 0) {
      return (` ${s}sec`);
  }else{
      return ('-');
  }
}
export function getFileType(file) {

  if(file.type.match('image.*')){
    
    return 'image';
  }

  if(file.type.match('video.*')){
    console.log(file.type)
    return 'video';
  }
  if(file.type.match('audio.*')){
    return 'audio';
  }
  

  return 'other';
}
export function matchExtensions(extns, file) {

  return extns.indexOf(file.name.split('.').pop())!=-1 ? true: false;
}


export function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}
export function getAge(dateString) {
  var ageInMilliseconds = new Date() - new Date(dateString);
  return Math.floor(ageInMilliseconds/1000/60/60/24/365); // convert to years
}

export function roundNumber(num, decimalPlaces =2) {
  if(!num){
    return 0;
  }
  var format =  num.toFixed(decimalPlaces);

  if(format.toString().endsWith(".00")){
    return format.toString().replace(".00","");
  }else if(format.toString().endsWith(".0")){
      return format.toString().replace(".0","");
    } else {
    return format;
  }
}
export function isJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}
export const showError = (error) => {
  
  toast(
    <ToastMessage type={"error"} message={error} closable={true} onClose={() => { toast.dismiss(); }} />,
    {
      id:"error",
      className: "errortoast",
      position: "bottom-center",
      duration: settings.toast_duration,
    }
  );
};
export function handleApiErrorResponse(res) {
  
  // check user status 401 or not

  var check = true;

  if(typeof res === 'object' && 'type' in res && res.type=='cors'){
    
    
    check =false;
  }
  if(check){
    if(res.status === 401 || res==401  || res=="Unauthenticated."){

      let user = ReactSession.get("user");
      ReactSession.set("workspace", null);
      ReactSession.set("token", null);
      ReactSession.set("user", null);
      ReactSession.set("plan", null);
      ReactSession.set("show_subscribed", null);
      ReactSession.set("username", null);

      if (user && user.role == "Tester") {
        window.location.href = process.env.REACT_APP_URL+"tester/sign-in";
      } else{
        window.onbeforeunload = null;
        window.location.href = process.env.REACT_APP_URL+"401";

        //navigate("/researcher/sign-in");
      }

    }else if(res.status == 500 || res==500 || res.status == 404 || res==404){
      
      toast(
        <ToastMessage type={"error"} message={"Internal Server error. Please contact UserQ Support."} closable={true} onClose={() => { toast.dismiss(); }} />,
          {
            className: "errortoast",
            position: "bottom-center",
            duration: 3000,
          }
      );
    }
  }
}

export function encodeTestUrl(text) {

  // if(str.toString().length < 8){
  //
  //   console.log(str.toString().length)
  //   var a = '';
  //   for(var i=0;i<8-str.toString().length;i++){
  //     a += 'a';
  //   }
  //
  //   console.log(a)
  //
  //   str = a+str;
  // }
  // return encoder.encode(str, 16);

  // const cipher = salt => {
  //   const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  //   const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
  //   const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
  //
  //   return text => text.split('')
  //       .map(textToChars)
  //       .map(applySaltToChar)
  //       .map(byteHex)
  //       .join('');
  // }
}

export function decodeTestUrl(str) {


  // var decodeData =  encoder.decode(str, 16);
  // console.log(decodeData)
}
export function saveLocalCache(key, value){
  
  var save_value = value;
  if(typeof value==='object'){
    save_value = JSON.stringify(value);
  }
  var b64 = CryptoJS.AES.encrypt(JSON.stringify(save_value), "").toString();
  var e64 = CryptoJS.enc.Base64.parse(b64);
  var eHex = e64.toString(CryptoJS.enc.Hex);

  ReactSession.set(key,eHex);
};
export function getLocalCache(key){
  
  let cipherText = ReactSession.get(key);

  var reb64 = CryptoJS.enc.Hex.parse(cipherText);
   var bytes = reb64.toString(CryptoJS.enc.Base64);
   var decrypt = CryptoJS.AES.decrypt(bytes, "");
   var plain = decrypt.toString(CryptoJS.enc.Utf8);

   if(isJson(plain)){
    return JSON.parse(plain);
   } else {
    return plain;
   }
   

   
}
function isJson(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}
export function encryptId(id, test=null){
  if(test){
    if(test.encrypted_test_id){
      return test.encrypted_test_id;
    } else{
      return btoa(id);
    }
  }
  /*if(id){
  var b64 = CryptoJS.AES.encrypt(id.toString(), "").toString();
    var e64 = CryptoJS.enc.Base64.parse(b64);
    var eHex = e64.toString(CryptoJS.enc.Hex);
    return eHex;
  }*/
  return btoa(id);

};
export function decryptId(cipherText){
  return atob(cipherText);
  if(cipherText.toString().length<20){
return atob(cipherText);
  } else {
  
  var reb64 = CryptoJS.enc.Hex.parse(cipherText);
   var bytes = reb64.toString(CryptoJS.enc.Base64);
   var decrypt = CryptoJS.AES.decrypt(bytes, "");
   var plain = decrypt.toString(CryptoJS.enc.Utf8);
   return plain;
  }
}
export function downloadBlob(blob, filename) {
  const objectUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = objectUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
}

// multiple select dropdown option
const CaretUp = (props) => (
    <>
    {/*<svg width="20" height="20" className={"multi-select-arrow-up"} >*/}
    {/*  <path*/}
    {/*      fillRule="evenodd"*/}
    {/*      clipRule="evenodd"*/}
    {/*      d="M4.33123 16.6323C4.74183 17.0017 5.37411 16.9682 5.74347 16.5576L11.9969 9.60592L18.2569 16.558C18.6264 16.9684 19.2587 17.0015 19.6692 16.632C20.0796 16.2624 20.1127 15.6301 19.7431 15.2197L12.7396 7.44192C12.5499 7.23125 12.2797 7.111 11.9963 7.11107C11.7128 7.11114 11.4426 7.23153 11.253 7.44229L4.25655 15.2201C3.88719 15.6307 3.92063 16.2629 4.33123 16.6323Z"*/}
    {/*      fill="#cccccc"*/}
    {/*  />*/}
    {/*</svg>*/}
        {/* <img src={process.env.REACT_APP_URL+"images/up-chevron.png"} alt="img"/> */}
        <Icon value="chevron-up" size="medium" hover={true}/>
    </>
);

const CaretDown = (props) => (
    <>
    {/*<svg width="20" height="20" className={"multi-select-arrow-down"}>*/}
    {/*  <path*/}
    {/*      fillRule="evenodd"*/}
    {/*      clipRule="evenodd"*/}
    {/*      d="M4.33147 7.36765C4.74207 6.99829 5.37436 7.03173 5.74371 7.44233L11.9971 14.394L18.2571 7.44196C18.6267 7.03154 19.259 6.99842 19.6694 7.36798C20.0798 7.73754 20.1129 8.36984 19.7434 8.78026L12.7399 16.558C12.5502 16.7687 12.28 16.889 11.9965 16.8889C11.713 16.8888 11.4429 16.7684 11.2533 16.5577L4.25679 8.77989C3.88744 8.36929 3.92087 7.737 4.33147 7.36765Z"*/}
    {/*      fill="#cccccc"*/}
    {/*  />*/}
    {/*</svg>*/}
        {/* <img src={process.env.REACT_APP_URL+"images/down-chevron.png"} alt="img"/> */}
        <Icon value="chevron" size="medium" hover={true}/>
    </>
);

export function DropdownIndicator({children, ...props}){
  
  
    return (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? <CaretUp /> : <CaretDown />}

        </components.DropdownIndicator>
    );
  
};
export function DropdownIndicatorV3({children, ...props}){
  var value = "";
  if(props.getValue().length==1){
    value = props.getValue()[0].value;
  }
 
  if((!value && !props.selectProps.menuIsOpen) || props.selectProps.menuIsOpen){
    return (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? <CaretUp /> : <CaretDown />}

        </components.DropdownIndicator>
    );
  } else {
   return <></>;
  }
};
export function ClearIndicator({children, ...props}){
   
  var value = "";

  if(props.getValue().length==1){
    value = props.getValue()[0].value;
  }
  if(value && !props.selectProps.menuIsOpen){
  return (
      <components.ClearIndicator {...props}>
        {<Icon value="close" size="medium" hover={true}/>}

      </components.ClearIndicator>
  );
  }
  return <></>;

};
export function ClearIndicatorV2(search){

  return ({children, ...props})=>{
    var value = "";

    if(props.getValue().length==1){
      value = props.getValue()[0].value;
    }
    if(value && !search && !props.selectProps.menuIsOpen){
      return (
          <components.ClearIndicator {...props}>
            {<Icon value="close" size="medium" hover={true}/>}

          </components.ClearIndicator>
      );
    } else {
      return <></>
    }
  }
};
export function DropdownIndicatorV2(search){

  return ({children, ...props}) => {
    //console.log(search)
      var value = "";

      if(props.getValue().length==1){
        value = props.getValue()[0].value;
      }
     
      if((!value && !props.selectProps.menuIsOpen) || props.selectProps.menuIsOpen){
      return (
          <components.DropdownIndicator {...props}>
            {props.selectProps.menuIsOpen ? <CaretUp /> : <CaretDown />}

          </components.DropdownIndicator>
      );
    } else {
      return <></>
    }
  }
};

var CryptoJSAesJson = {
  stringify: function (cipherParams) {
      var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
      if (cipherParams.iv) j.iv = cipherParams.iv.toString();
      if (cipherParams.salt) j.s = cipherParams.salt.toString();
      return JSON.stringify(j).replace(/\s/g, '');
  },
  parse: function (jsonStr) {
      var j = JSON.parse(jsonStr);
      var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
      if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
      if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
      return cipherParams;
  }
}
export function encryptClient(message) {
  return CryptoJS.AES.encrypt(JSON.stringify(message), process.env.REACT_APP_ENCKEY, {format:CryptoJSAesJson}).toString();
  
}

export function showToast(message,type) {
    toast(
        <ToastMessage type={type} message={message} closable={true} onClose={() => { toast.dismiss(); }} />,
        {
            className: "successtoast",
            position: "bottom-center",
            duration: 5000,
        }
    ); 
}
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function amountFormat(num) {
  return (Math.round(num * 100) / 100).toFixed(2);
}
export function roundAnlyticsNumber(num, with2decimals=false) {

  if(num < 1){
    return Math.round((num)*10)/10
  }
  if(with2decimals){
    return Math.round(num*100)/100;
  }
  return Math.round(num);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function containsArabic(text) {
  const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
  return arabicRegex.test(text);
}
export const createWorker = (worker) => {
  const blob = new Blob([worker], { type: 'application/javascript' });
  return new Worker(URL.createObjectURL(blob));
};